var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "breadcrumb md-layout md-align md-align--start-center topHeaderProjectNameSection"
    },
    [
      _vm._l(_vm.breadCrumbs, function(item, index) {
        return [
          item.type === "button"
            ? [
                _c(
                  "button",
                  {
                    key: index,
                    staticClass:
                      "md-button md-button--primary md-button--dense rx-button--project",
                    staticStyle: {
                      "text-transform": "none",
                      "padding-right": "2px"
                    },
                    attrs: {
                      "data-target": "#" + _vm.menuId,
                      "data-toggle": ![
                        _vm.UserStatus.KIDemoOld,
                        _vm.UserStatus.KIDemoNew,
                        _vm.UserStatus.ReadingDemo,
                        _vm.UserStatus.RedditDemo
                      ].includes(_vm.user.onboardingStatus)
                        ? "menu"
                        : ""
                    },
                    on: { click: _vm.checkUserEmail }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("truncate")(item.title, 20)) +
                        "\n        "
                    ),
                    _vm._m(0, true)
                  ]
                )
              ]
            : item.type === "link"
            ? [
                _c(
                  "i",
                  {
                    key: item.title,
                    staticClass:
                      "material-icons md-button__icon--right md-button__icon tw-text-sm tw-text-black-72",
                    staticStyle: { "margin-left": "0" }
                  },
                  [_vm._v("\n        chevron_right\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "nuxt-link",
                  {
                    key: item.title + "link",
                    staticClass:
                      "md-button md-button-link md-button--dense rx-button--project tw-max-w-44 xl:tw-max-w-60 tw-truncate breadcrumb__item",
                    staticStyle: {
                      "text-transform": "none",
                      "min-width": "inherit",
                      "margin-left": "20px",
                      "font-size": "16px",
                      padding: "0px 8px"
                    },
                    attrs: {
                      title: item.title,
                      to: item.link,
                      event: ![
                        _vm.UserStatus.KIDemoOld,
                        _vm.UserStatus.KIDemoNew,
                        _vm.UserStatus.ReadingDemo,
                        _vm.UserStatus.RedditDemo
                      ].includes(_vm.user.onboardingStatus)
                        ? "click"
                        : ""
                    }
                  },
                  [_vm._v("\n        " + _vm._s(item.title) + "\n      ")]
                )
              ]
            : item.type == "header"
            ? [
                _c(
                  "div",
                  {
                    key: item.title,
                    staticClass: "md-header__sub-title md-page-crumb__item",
                    staticStyle: { "font-size": "16px" }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: _vm.assetsUrl + "img/icon/" + item.icon + ".svg"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "nuxt-link",
                      {
                        key: item.title + "link",
                        staticClass:
                          "md-button md-button--dense rx-button--project tw-max-w-44 xl:tw-max-w-60 tw-truncate breadcrumb__item",
                        staticStyle: {
                          "text-transform": "none",
                          "min-width": "inherit",
                          "margin-left": "20px",
                          "font-size": "16px",
                          padding: "0px 8px"
                        },
                        attrs: { to: item.link }
                      },
                      [
                        _c("span", { staticClass: "tw-truncate" }, [
                          _vm._v(
                            "\n          " + _vm._s(item.title) + "\n        "
                          )
                        ])
                      ]
                    )
                  ],
                  1
                )
              ]
            : [
                _c(
                  "div",
                  {
                    key: item.title + "other",
                    staticClass: "md-page-crumb crumbOuterSection",
                    staticStyle: { width: "fit-content" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "md-page-crumb__wrapper tw-max-w-64 xl:tw-max-w-184 tw-truncate",
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          height: "100%"
                        }
                      },
                      [
                        _c(
                          "i",
                          {
                            key: item.title,
                            staticClass:
                              "material-icons md-button__icon--right md-button__icon tw-text-sm tw-text-black-72",
                            staticStyle: { "margin-left": "0" }
                          },
                          [_vm._v("\n            chevron_right\n          ")]
                        ),
                        _vm._v(" "),
                        !item.persistent
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "md-header__title md-page-crumb__item"
                              },
                              [_vm._v("\n             \n          ")]
                            )
                          : _c(
                              "div",
                              {
                                staticClass:
                                  "md-header__sub-title md-page-crumb__item",
                                staticStyle: {
                                  "font-size": "16px",
                                  "margin-left": "20px",
                                  padding: "0px 8px"
                                },
                                attrs: { title: item.title }
                              },
                              [
                                _c("span", { staticClass: "tw-truncate" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.title) +
                                      "\n            "
                                  )
                                ])
                              ]
                            )
                      ]
                    )
                  ]
                )
              ]
        ]
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "i",
      {
        staticClass: "md-button__icon md-button__icon--right material-icons",
        staticStyle: { "margin-left": "0" }
      },
      [
        _c("span", { staticClass: "hide-on-affix" }, [
          _vm._v("keyboard_arrow_right")
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "show-on-affix" }, [
          _vm._v("arrow_drop_down")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }