import findObjectIndex from "@/assets/js/commonHelper";
import arraySearch from "@/assets/js/arraySearch";
import Vue from "vue";
const defaultSharedCommentState = () => {
  return {
    comments: [],
    threads: []
  }
};

export default {
  namespaced: true,
  state: defaultSharedCommentState,

  mutations: {
    setData(state, obj) {
      Vue.set(state.data, obj.key, obj.data);
    },

    addData(state, obj) {
      state[obj.key].push(obj.data);
    },

    updateCommentData(state, obj) {
      state.comments.splice(obj.index, 1, obj.data);
    },

    updateComment(state, obj) {
      state.comments[obj.index][obj.key] = obj.value;
    },

    deleteComment(state, index) {
      state.comments.splice(index, 1);
    },

    deleteThread(state, index) {
      state.threads.splice(index, 1);
    },
    resetState(state) {
      Object.assign(state, defaultSharedCommentState());
    }
  },

  actions: {
    async fetch({ commit, state, dispatch }, obj) {
      const { data } = await this.$axios.post(`/shared/item/comments/fetch`, obj);
      let filterArray = data.filter((item) => {
        return !arraySearch(state.comments, item.id);
      });
      for (let i = 0; i < filterArray.length; i++) {
        commit("addData", { key: "comments", data: filterArray[i] });
      }
    },

    async fetchThreads({ commit, state }, obj) {
      const { data } = await this.$axios.post(`/shared/item/comments/fetch-child`, obj);
      let filterArray = data.filter((item) => {
        return !arraySearch(state.threads, item.id);
      });
      for (let i = 0; i < filterArray.length; i++) {
        commit("addData", { key: "threads", data: filterArray[i] });
      }
    },

    async update({ commit, state }, inputs) {
      if (!inputs.title && inputs.content) {
        inputs.title = inputs.content.substring(0, 64) + "...";
      }
      const { data } = await this.$axios.put("/shared/item/comments/update", inputs);
      let index = findObjectIndex(state.comments, inputs);
      let data_ = Object.assign(state.comments[index], data[0])
      commit("updateCommentData", { data: data_, index: index });
    },

    async createComment({ commit }, inputs) {
      const { data } = await this.$axios.post("/shared/item/comments/create", inputs);
      commit("addData", { key: "comments", data: data });
    },

    async createThreads({ commit, state }, inputs) {
      const { data } = await this.$axios.post("/shared/item/comments/create-child", inputs);
      commit("addData", { key: "threads", data: data });
      let object = state.comments.filter((item) => {
        return item.id === data.parentId;
      });
      let index = findObjectIndex(state.comments, object[0]);
      commit("updateComment", {index: index, key: "childCount", value: (state.comments[index].childCount + 1)});
    },

    async deleteThread({ commit, state }, inputs) {
      await this.$axios.post(`/shared/item/comments/delete/`, inputs);
      let index = findObjectIndex(state.threads, inputs);
      commit("deleteThread", index);
      let object = state.comments.filter((item) => {
        return item.id === inputs.parentId;
      });
      index = findObjectIndex(state.comments, object[0]);
      commit("updateComment", {index: index, key: "childCount", value: (state.comments[index].childCount - 1)});
    },

    async deleteComment({ commit, state }, inputs) {
      let itemTypeMap = { 1 : 'drafts', 2 : 'literatures', 3 : 'reviews', 4 : 'projects' };
      inputs["type"] = itemTypeMap[inputs.type];
      await this.$axios.post(`/shared/item/comments/delete/`, inputs);
      let index = findObjectIndex(state.comments, inputs);
      if (inputs.type !== '1') {
        if (inputs.type == 'literatures') {
          // select annotation store
          this.commit(`annotations/removeComment`, state.comments[index].id);
          this.commit(`kiAnnotations/removeComment`, state.comments[index].id);
          this.commit(`tldrAnnotations/removeComment`, state.comments[index].id);
        }else{
          this.dispatch(`${inputs.storeName}/removeComment`, state.comments[index].id);
        }

      }
      commit("deleteComment", index);
    }
  },

  getters: {
    getComments(state) {
      return state.comments;
    },
    getThreads(state) {
      return state.threads;
    }
  }
};
