import Vue from "vue";
import arraySearch from "@/assets/js/arraySearch";
import findObjectIndex from "@/assets/js/commonHelper";

let annotation = {};
let pdfPageBody;

const defaultSharedLiteraturesState = () => {
  return {
    data: [],
    currentData: {},
    assetsUrl: process.env.assetsUrl,
    waitTimeoutId: 0,
    firstFetching: { literatureId: "", isFetching: false },
    titleList: [],
    currentPdfAction: {
      projectId: "",
      literatureId: "",
      show: false,
      pdfUrl: ""
    },
    isFetching: false,
    openComments: {},
    mainSelectedText: "",
    commentText: ""
  };
};

export default {
  namespaced: true,
  state: defaultSharedLiteraturesState,

  mutations: {
    setDataByKey(state, obj) {
      state[obj.key] = obj.data;
    },

    setData(state, data) {
      state["data"] = data;
    },

    addData(state, data) {
      state["data"].unshift(data);
    },

    addDataAfter(state, data) {
      state["data"].push(data);
    },

    updateData(state, data) {
      let index = findObjectIndex(state.data, data);
      state.data.splice(index, 1, data);
    },

    updateDataPosition(state, data) {
      let index = findObjectIndex(state.data, data);
      state.data.splice(index, 1);
      state.data.unshift(data);
      Vue.set(state["currentData"], "accessedAt", data.accessedAt);
    },

    deleteData(state, index) {
      state.data.splice(index, 1);
    },

    setCurrentData(state, data) {
      state.currentData = data;
    },

    setIsFetching(state, data) {
      state.isFetching = data;
    },

    updateCitation(state, index) {
      state.currentData.citations[index].isAddedLiterature = true;
    },

    setObjData(state, obj) {
      Vue.set(state[obj.name], obj.KeyName, obj.data);
    },

    setCurrentPdfAction(state, data) {
      state.currentPdfAction = data;
    },

    resetState(state) {
      Object.assign(state, defaultSharedLiteraturesState());
    },

    setTitleList(state, data) {
      state["titleList"] = data;
    }
  },

  actions: {
    async fetch({ commit }) {
      const { data } = await this.$axios.get(`/shared/with-me/literatures`);
      commit("setData", data);
    },

    async fetchExceptExits({ commit, state }) {
      const { data } = await this.$axios.get(`/shared/with-me/literatures`);
      let filterArray = data.filter((item) => {
        return !arraySearch(state.data, item.id);
      });
      for (let i = 0; i < filterArray.length; i++) {
        commit("addDataAfter", filterArray[i]);
      }
    },

    async revoke({ commit, state }, params) {
      if (!params.userId) {
        delete params.userId;
      }
      const updatedParamas = { ...params };
      if (updatedParamas.isLeft) {
        delete updatedParamas.userId;
        delete updatedParamas.user;
      }
      await this.$axios.post(`/collaborators/revoke`, updatedParamas);
      let index = findObjectIndex(state.data, params);
      commit("deleteData", index);
    },

    updateCurrentPage({ commit, dispatch }, data) {
      commit("setObjData", { name: "firstFetching", keyName: "literatureId", value: data.id });
      commit("setObjData", { name: "firstFetching", keyName: "isFetching", value: true });
      setTimeout(async () => {
        await dispatch("show", data);
        commit("setObjData", { name: "firstFetching", keyName: "literatureId", value: data.id });
        commit("setObjData", { name: "firstFetching", keyName: "isFetching", value: false });
      }, 8000);
    },

    async updateFromUrl({ state, commit, dispatch }, inputs) {
      const { data } = await this.$axios.post("/literatures/update/url/import?withContacts=true&withTags=true", inputs)
        .catch(error => {
          return Promise.reject(error);
        });
      commit("updateData", data);
      commit("setObjData", { name: "firstFetching", keyName: "literatureId", value: data.id });
      commit("setObjData", { name: "firstFetching", keyName: "isFetching", value: false });
      setTimeout(async () => {
        await dispatch("show", data);
        commit("setObjData", { name: "firstFetching", keyName: "literatureId", value: data.id });
        commit("setObjData", { name: "firstFetching", keyName: "isFetching", value: false });
      }, 8000);
    },

    async createFromResources({ commit, dispatch }, inputs) {
      const { data } = await this.$axios.post("/literatures/resources", inputs);
      commit("addData", data);
    },

    async delete({ commit, state }, inputs) {
      await this.$axios.delete(`/literatures/${inputs.slug}`);
      let index = findObjectIndex(state.data, inputs);
      commit("deleteData", index);
    },

    async populateLiterature({ state, dispatch, commit }) {
      // let data = "";
      // commit("setIsFetching", true);
      // if (state.currentData.isHtmlAvailable) {
      await dispatch("htmlAvailable");
      // } else {
      //   try {
      //     data = await this.$axios.get(process.env.baseUrl.substring(0, process.env.baseUrl.length - 1) + state.currentData.localFileId.replace("pdf", "html"));
      //     state.currentData.isHtmlAvailable = true;
      //     await dispatch("htmlAvailable");
      //   } catch (e) {
      //     state.currentData.isHtmlAvailable = false;
      //     dispatch("htmlUnavailable");
      //   }
      // }
    },

    async htmlAvailable({ state, dispatch, commit }) {
      try {
        // document.getElementById("pdf-viewer").innerHTML = "<iframe id=\"lit_iframe\" style=\"width: 100%; height: 100%;\" src=\"" + process.env.baseUrl.substring(0, process.env.baseUrl.length - 1) + state.currentData.localFileId.replace("pdf", "html") + "\" ></iframe>";
        commit("setIsFetching", true);
        await dispatch("getComments");
        // while (!($("body", $("#lit_iframe").get(0).contentWindow.document) && $("body", $("#lit_iframe").get(0).contentWindow.document)[0] && $("body", $("#lit_iframe").get(0).contentWindow.document)[0].ownerDocument.styleSheets[2])) {
        //   console.log("while 1");
        //   await dispatch("wait");
        // }
        // await dispatch("selectMenu");
        // await dispatch("populateNoteIcons");
        // $("#zoom-in").show();
        // $("#zoom-out").show();
        commit("setIsFetching", false);
      } catch (e) {
        commit("setIsFetching", false);
        console.log(e);
      }
    },

    htmlUnavailable({ state, commit }) {
      document.getElementById("pdf-viewer").innerHTML = "<iframe id=\"lit_iframe\" name=\"lit_iframe\" style=\"width: 100%; height: 100%;\" src=\"" + process.env.baseUrl.substring(0, process.env.baseUrl.length - 1) + state.currentData.localFileId + "\"></iframe>"; //cheap solution(improve later)
      $("#zoom-in").hide();
      $("#zoom-out").hide();
      commit("setIsFetching", false);
    },

    async getComments({ state }) {
      const { data } = await this.$axios.post("/shared/item/comments/fetch", {
        itemId: state.currentData.id,
        type: "literatures"
      });
      state.currentData["annotations"] = [];
      state.currentData["annotations"] = state.currentData["annotations"].concat(...data);
      let filterArray = data.filter((item) => {
        return !arraySearch(this.state.sharedComments.comments, item.id);
      });
      for (let i = 0; i < filterArray.length; i++) {
        this.commit("sharedComments/addData", { key: "comments", data: filterArray[i] });
      }
      this.dispatch("sharedItemUsers/fetch", { itemId: state.currentData.id, type: "literatures" });
    },

    wait({ state }) {
      return new Promise((resolve) => {
        if (state.waitTimeoutId) {
          clearTimeout(state.waitTimeoutId);
        }
        console.log("waiting....");
        state.waitTimeoutId = setTimeout(function() {
          resolve();
        }, 2000);
      });
    },

    selectMenu({ dispatch, commit }) {
      let pageX, pageY, selectedText = "", typingTimer;
      let iframeElement = $("#lit_iframe");
      let inlineMenu = $("#inline-menu");
      let highlightNoteBackdrop = $(".highlight_note_backdrop");
      pdfPageBody = $("body", iframeElement.get(0).contentWindow.document);
      pdfPageBody.unbind().bind("mouseup", function(e) {
        selectedText = iframeElement.get(0).contentWindow.document.getSelection().toString();
        let showMenu = true;
        if (selectedText.length > 1) {
          commit("setDataByKey", { key: "mainSelectedText", data: selectedText });
          let child1 = iframeElement.get(0).contentWindow.document.getSelection().anchorNode.parentNode;
          while (child1 && child1.tagName !== "DIV" || child1.className.indexOf("t ") === -1) {
            child1 = child1.parentNode;
          }
          let child2 = iframeElement.get(0).contentWindow.document.getSelection().focusNode.parentNode;
          while (child2.tagName !== "DIV" || child2.className.indexOf("t ") === -1) {
            child2 = child2.parentNode;
          }
          annotation.startDiv = 0;
          annotation.endDiv = 0;
          pageX = child1;
          pageY = child2;
          while (!($(pageX.parentNode).attr("id"))) {
            pageX = pageX.parentNode;
          }
          while (!($(pageY.parentNode).attr("id"))) {
            pageY = pageY.parentNode;
          }
          annotation.startPage = $(pageX.parentNode).attr("id");
          annotation.endPage = $(pageY.parentNode).attr("id");

          while ((child1 = child1.previousSibling) != null) {
            if (child1.tagName === "DIV") {
              annotation.startDiv++;
            }
          }
          while ((child2 = child2.previousSibling) != null) {
            if (child2.tagName === "DIV") {
              annotation.endDiv++;
            }
          }
          // for reverse selection
          if (annotation.startDiv > annotation.endDiv) {
            pageX = annotation.startDiv;
            annotation.startDiv = annotation.endDiv;
            annotation.endDiv = pageX;
          }
          // set actual container of element to be selected
          dispatch("setPaperElementContainer");

          for (let i = annotation.startDiv; i <= annotation.endDiv; i++) {
            let selectedDiv = pdfPageBody.find("#" + annotation.startPage + annotation.elContainer + "div:eq(" + i + ")");
            if (selectedDiv[0].getAttribute("data-annotation-type") === "comment") {
              showMenu = false;
            }
          }
          annotation.id = "";
        }

        if ((annotation.startPage !== annotation.endPage) || (selectedText.replace(/ /g, "") === "")) {
          showMenu = false;
        }
        if (showMenu) {
          pageX = e.clientX;
          pageY = e.clientY + 100;
          inlineMenu.css({
            "left": pageX,
            "top": pageY,
          }).addClass("md-menu-wrapper--open");
          highlightNoteBackdrop.show();
          inlineMenu.show();
          if ((pageX + inlineMenu.width()) > ($("#pdf-viewer").offset().left + $("#pdf-viewer").width())) {
            pageX -= 150;
            inlineMenu.css({
              "left": pageX,
              "top": pageY
            });
          }
          pdfPageBody.find("#page-container").on("scroll", function() {
            inlineMenu.removeClass("md-menu-wrapper--open");
            pdfPageBody.find("#page-container").unbind("scroll");
            highlightNoteBackdrop.hide();
          });
        } else {
          inlineMenu.removeClass("md-menu-wrapper--open");
          highlightNoteBackdrop.hide();
          pdfPageBody.find("#page-container").unbind("scroll");
        }
      });
      $(".inline_note textarea").unbind("keyup").bind("keyup", function() {
        clearTimeout(typingTimer);
        if ($(".inline_note textarea").val()) {
          typingTimer = setTimeout(function() {
            dispatch("updateInlineNotes", {
              "startIndex": $(".inline_note").attr("id"),
              "pageNo": annotation.startPage,
              "text": $(".inline_note textarea").val()
            });
          }, 700);
        }
      });
    },

    setPaperElementContainer() {
      if (pdfPageBody.find("#" + annotation.startPage + " .pc > div:eq(" + annotation.startDiv + ")").length) {
        annotation.elContainer = " .pc > ";
      } else {
        annotation.elContainer = " .pc > .c > ";
      }
    },

    highlight({ dispatch }, from) {
      dispatch("highlightContent", from);
    },

    async highlightContent({ dispatch, commit, state }, from) {
      for (let k = annotation.startDiv; k <= annotation.endDiv; k++) {
        let selectedDiv = pdfPageBody.find("#" + annotation.startPage + annotation.elContainer + "div:eq(" + k + ")");
        if (from === "createComment" && !selectedDiv.find(".commented_content").length) { // wrap commented content
          selectedDiv.contents().wrapAll("<span class=\"commented_content\"/>");
          selectedDiv.css({ "background-color": "rgba(25, 247, 36, 0.32)", "cursor": "pointer" });
          selectedDiv.attr({ "data-annotation-type": "comment", "data-comm-start-div": annotation.startDiv });
        }
      }
      if (annotation.id === "") {
        await dispatch("saveComment", state.commentText);
      }
    },

    async saveComment({ state, commit }, text) {
      let params = {
        itemId: state.currentData.id,
        selection: state.mainSelectedText,
        text: text,
        type: 'literatures',
        pageNo: annotation.startPage,
        startIndex: annotation.startDiv,
        endIndex: annotation.endDiv
      };
      const { data } = await this.$axios.post("/shared/item/comments/create", params);
      if (!state.currentData.annotations) {
        state.currentData.annotations = [];
      }
      state.currentData.annotations.push(data);
      data["refItemId"] = state.currentData.id;
      data["type"] = "2";
      this.commit("sharedComments/addData", { key: "comments", data: data });
      commit("setDataByKey", { key: "openComments", data: { id: data.id, type: "literatures", open: false } });
      this.dispatch("core/updateLastAccess", {item: state.currentData, type: "literature", storeName: "sharedLiteratures"});
    },

    async populateNoteIcons({ state, dispatch, commit }) {
      state.currentData.linePositions = await dispatch("getLinePositions");
      if (!state.currentData.annotations) {
        state.currentData.annotations = [];
      }
      if (!state.currentData.annotations.length) {
        commit("setIsFetching", false);
      }
      let count = 0;
      state.currentData.annotations.forEach(async (f) => {
        count++;
        annotation.startPage = f.pageNo;
        annotation.endPage = f.pageNo;
        annotation.startDiv = f.startIndex;
        annotation.endDiv = f.endIndex;
        annotation.text = f.text;
        annotation.id = f.id;
        // set actual container of element to be selected
        dispatch("setPaperElementContainer");
        if (f.userId && f.text) {
          await dispatch("createComment", Object.assign({}, annotation));
        }
        if (count === state.currentData.annotations.length) {
          commit("setIsFetching", false);
        }
      });
    },

    getLinePositions({ state }) {
      return new Promise((resolve, reject) => {
        let x = pdfPageBody[0].ownerDocument.styleSheets[2].cssRules;
        let linePositions = {};
        for (let i = 0; i < x.length; i++) {
          if (x[i].cssText[1] === "x") {
            linePositions[x[i].cssText.split(" ")[0]] = x[i].cssText.split(" ")[3];
          }
          if ((x[i].cssText.split(" ")[3]) === "transparent;") {
            state.currentData.transparentClass = x[i].cssText.split(" ")[0];
          }
        }
        resolve(linePositions);
      });
    },

    createInlineComment({ dispatch }) {
      dispatch("createComment");
    },

    async createComment({ state, dispatch, commit }, commentInfo) {
      commentInfo = commentInfo ? commentInfo : annotation;
      // check if selected line already contains notes or comments
      let alreadyNoted = pdfPageBody.find("#" + commentInfo.startPage + commentInfo.elContainer + "div:eq(" + commentInfo.startDiv + ") .comment_icon").length;
      if (alreadyNoted < 1) {
        await dispatch("highlightContent", "createComment");
        let noDomCounter = 0;
        while (!pdfPageBody.find("#" + commentInfo.startPage + commentInfo.elContainer + "div:eq(" + commentInfo.startDiv + ")")[0]) {
          noDomCounter++;
          console.log("while 3");
          pdfPageBody = $("body", $("#lit_iframe").get(0).contentWindow.document);
          if (pdfPageBody.find("#" + commentInfo.startPage + " .pc > div:eq(" + commentInfo.startDiv + ")").length) {
            commentInfo.elContainer = " .pc > ";
          } else {
            commentInfo.elContainer = " .pc > .c > ";
          }
          if (noDomCounter > 1) {
            dispatch("populateNoteIcons");
            commit("setIsFetching", true);
          }
          await dispatch("wait");
        }
        commit("setIsFetching", false);
        let selectedDiv = pdfPageBody.find("#" + commentInfo.startPage + commentInfo.elContainer + "div:eq(" + commentInfo.startDiv + ")");
        if (!selectedDiv.find(".comment_icon").length) {
          let comment_html = `<div class="comment_icon" style="width: 50px; height: 50px; text-align: center; cursor: pointer;"><img src="${process.env.assetsUrl}/img/svg/comment_normal_state.svg" style="position: absolute; top: 4%; left: 8%;" width="44"></div>`;
          selectedDiv.append(comment_html);
          if (state.currentData.linePositions && parseInt(state.currentData.linePositions["." + selectedDiv[0].classList[2]]) > 285) {
            selectedDiv.children("div").css({ position: "absolute", top: "0px", left: "1056px" });
          } else {
            selectedDiv.children("div").css({ position: "absolute", top: "0px", left: "-100px" });
          }
          dispatch("bindComments");
        }
      }
    },

    bindComments({ dispatch, state, commit }, unOverlapped) {
      let commentId;
      let bindFunc = function() {
        let currElm = $(this), comment = {};
        comment.startDiv = currElm.data().commStartDiv;
        while (!currElm.attr("id")) {
          currElm = currElm.parent();
        }
        comment.startPage = currElm.attr("id");
        state.currentData.annotations.forEach((f) => {
          if (f.pageNo === comment.startPage && f.startIndex === comment.startDiv && f.userId) {
            commentId = f.id;
          }
        });
        commit("setDataByKey", { key: "openComments", data: { id: commentId, type: "literatures" } });
      };
      if (!!unOverlapped && unOverlapped.length) {
        unOverlapped.each((f) => {
          f.unbind().bind("click", bindFunc);
        });
      } else {
        pdfPageBody.find("[data-annotation-type=comment]").not(".note_div").not("highlight_div").unbind().bind("click", bindFunc);
      }
    },

    removeComment({ state, dispatch }, commentId) {
      let startDiv = "";
      state.currentData.annotations.forEach((f) => {
        if (f.id === commentId && f.userId) {
          startDiv = f.startIndex;
        }
      });
      let onlyComments = pdfPageBody.find(`[data-comm-start-div=${startDiv}]`).not(".note_div").not("highlight_div");
      let highComments = pdfPageBody.find(`[data-comm-start-div=${startDiv}].highlight_div`);
      let noteComments = pdfPageBody.find(`[data-comm-start-div=${startDiv}].note_div`);
      let unionComments = pdfPageBody.find(`[data-comm-start-div=${startDiv}]`);

      unionComments.removeAttr("data-comm-start-div");
      unionComments.removeAttr("data-annotation-type");
      unionComments.removeData("commStartDiv");
      unionComments.removeData("annotationType");
      unionComments.unbind("click");
      unionComments.find(".comment_icon").remove();
      let c = unionComments.find(".commented_content");
      c.contents().unwrap();

      if (onlyComments.length) {
        onlyComments.css({ "background": "initial", "cursor": "auto" });
      }
      if (highComments.length) {
        highComments.css({ "background-color": "#FFFF3B", "cursor": "pointer" });
        dispatch("bindHighlightedOption");
      }
      if (noteComments.length) {
        noteComments.css({ "background-color": "rgba(6, 151, 254, 0.3)", "cursor": "pointer" });
        dispatch("bindInlineNotes");
      }
    },
    async fetchCitations({ state }, id) {
      const { data } = await this.$axios.post(
        `${ process.env.serverLessBaseUrl }/literature-citations/citations/get`,
        {
          id
        }
      );
      Vue.set(state["currentData"], "citations", data);
    }
  },

  getters: {
    getData(state) {
      return state.data;
    },
    getCurrentData(state) {
      return state.currentData;
    },
    getFirstFetching(state) {
      return state.firstFetching;
    },
    getTags(state) {
      if (Object.keys(state.currentData).length && state.currentData.tags) {
        return state.currentData.tags;
      }
    },
    getIsFetching(state) {
      return state.isFetching;
    },
    currentPdfAction(state) {
      return state.currentPdfAction;
    },
    openCommentsInfo(state) {
      return state.openComments;
    }
  }
};
