var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "credit-card-inputs tw-relative" },
    [
      _c("div", { staticClass: "tw-my-4" }, [
        _c(
          "div",
          {
            staticClass:
              "tw-py-1 tw-text-sm tw-font-normal tw-leading-snug tw-text-black-72 tw-mb-1"
          },
          [_vm._v("Debit/Credit card number")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "tw-flex" }, [
          _c("div", {
            ref: "cardNumber",
            staticClass: "stripe-element card-number tw-w-3/4 tw-h-10"
          }),
          _vm._v(" "),
          _c("div", { staticClass: "tw-flex tw-items-center" }, [
            _c("img", {
              staticClass: "tw-h-5",
              attrs: {
                src: _vm.assetsUrl + "img/pricing/visa-card.png",
                alt: "visa"
              }
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "tw-h-5",
              attrs: {
                src: _vm.assetsUrl + "img/pricing/master-card.png",
                alt: "master card"
              }
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "tw-flex tw-items-center tw-my-4" }, [
        _c("div", { staticClass: "tw-mr-10" }, [
          _c(
            "div",
            {
              staticClass:
                "tw-py-1 tw-text-sm tw-font-normal tw-leading-snug tw-text-black-72 tw-mb-1"
            },
            [_vm._v("Expiry date")]
          ),
          _vm._v(" "),
          _c("div", {
            ref: "cardExpiry",
            staticClass: "stripe-element card-expiry tw-h-10"
          })
        ]),
        _vm._v(" "),
        _c("div", [
          _c(
            "div",
            {
              staticClass:
                "tw-py-1 tw-text-sm tw-font-normal tw-leading-snug tw-text-black-72 tw-mb-1"
            },
            [_vm._v("CVV")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "tw-flex tw-items-center" }, [
            _c("div", {
              ref: "cardCVC",
              staticClass: "stripe-element card-cvc tw-mr-2 tw-h-10"
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "tw-h-8",
              attrs: {
                src: _vm.assetsUrl + "img/pricing/credit-card.png",
                alt: "CVV"
              }
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "tw-my-4 tw-w-3/4" }, [
        _c(
          "div",
          {
            staticClass:
              "tw-py-1 tw-text-sm tw-font-normal tw-leading-snug tw-text-black-72 tw-mb-1"
          },
          [_vm._v("Name on the card")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "stripe-element" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.nameOnCard,
                expression: "nameOnCard"
              }
            ],
            ref: "cardName",
            staticClass:
              "tw-border-none tw-w-full tw-placeholder-black-40 tw-text-base",
            attrs: { type: "name", placeholder: "Enter the name on card" },
            domProps: { value: _vm.nameOnCard },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.nameOnCard = $event.target.value
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _vm.hasFormError || _vm.error
        ? _c("div", { staticClass: "tw-my-4" }, [
            _vm.hasFormError
              ? _c(
                  "div",
                  {
                    staticClass:
                      "error tw-py-3 tw-px-4 tw-rounded tw-bg-red-lightest tw-text-xs"
                  },
                  [
                    _c("strong", { staticClass: "tw-font-bold" }, [
                      _vm._v("Invalid information:")
                    ]),
                    _vm._v(
                      " Please confirm the details and try again. Contact us on\n      "
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "tw-underline tw-italic",
                        attrs: { href: "mailto:support@read.enago.com" }
                      },
                      [_vm._v("support@read.enago.com")]
                    ),
                    _vm._v(" for any query.\n    ")
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.hasFormError && _vm.error
              ? _c(
                  "div",
                  {
                    staticClass:
                      "error tw-py-3 tw-px-4 tw-rounded tw-bg-red-lightest tw-text-xs"
                  },
                  [
                    _c("strong", { staticClass: "tw-font-bold" }, [
                      _vm._v("Invalid information:")
                    ]),
                    _vm._v(" " + _vm._s(_vm.error)),
                    _c("br"),
                    _vm._v(" Contact us on\n      "),
                    _c(
                      "a",
                      {
                        staticClass: "tw-underline tw-italic",
                        attrs: { href: "mailto:support@read.enago.com" }
                      },
                      [_vm._v("support@read.enago.com")]
                    ),
                    _vm._v(" for any query.\n    ")
                  ]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoading || !_vm.hasStripeLoaded ? _c("loader") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }