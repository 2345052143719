var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "layout-block layout-block--center layout-block--overlay layout-block--fixed",
      attrs: { id: "app-global-loader" }
    },
    [
      _c("app-progress", {
        attrs: {
          config: {
            fixed: true,
            circle: true,
            overlay: true,
            parent: true,
            target: "#app-global-loader"
          },
          unid: "app-loading",
          "data-type": "indeterminate"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }