import { AnnotationTypeMap, AnnotationTypeEnum, AnnotationType, AnnotationSourceType } from "~/components/literature/constants";
import { generateHighlightId, getProperSelection, restoreSelection, saveSelection } from "~/components/literature/helper";
import { delay } from '~/assets/js/commonHelper';
import Vue from "vue";
const KI_ANNOTATION_API = `${process.env.serverLessBaseUrl}/ki-annotations/`
const defaultAnnotationState = () => {
  return {
    currentLiteratureData:null,
    data:[],
    createdCommentData : null,
    highlighter: null,
    selectedAnnotationIdForAction:null,
    selectedActionType: null,
    selectedRange: null,
    selectedText: '',
    isHtmlRendered: false,
    selectedAnnotationPin: null,
    selectedAnnotationAttachment:null,
    selectedAnnotationNote:null,
    selectedAnnotationUrl:null,
    lastAttachmentHId: null,
    isUpdateHighlight: false,
    selectionHighlighter:null,
    selectionHighlightData: null,
    selectedSection: null,
    sectionToAnnotateFromRange: null,
    sectionToNoteFromRange:null,
    contextActionClick:null,
    annotationToRemove:null,
    lastUsedRange:null,
    isTextSelected: false,
  };
};

export default {
  namespaced: true,
  state: defaultAnnotationState,

  mutations: {
    setData(state, payload) {
      state.data = payload;
    },
    setSelectedText(state, data) {
      state.selectedText = data;
    },
    updateLastUsedRange(state) {
      state.lastUsedRange = saveSelection();
    },
    applyLastUsedRange(state){
      restoreSelection(state.lastUsedRange);
    },
    setAnnotationToRemove(state, data){
      // get statementKey and
     //  statementKey,      sectionKey: data.sectionKey, summaryType: this.showQuickRead
     // complete hsData
     state.annotationToRemove = {
      ...data
    };
    },
    setContextActionClick(state, data){
      // get statementKey and
     //  statementKey,      sectionKey: data.sectionKey, summaryType: this.showQuickRead
     state.contextActionClick = {
      ...data
    };

    },
    setSectionToNoteFromRange(state, range){
      // get statementKey and
     //  statementKey,      sectionKey: data.sectionKey, summaryType: this.showQuickRead
     if (state.selectedSection) {
      state.sectionToNoteFromRange = {
        ...state.selectedSection,
        range
      };
     }else{
       console.log('no selected section');
     }

    },
    setSectionToAnnotateFromRange(state, range){
      // get statementKey and
     //  statementKey,      sectionKey: data.sectionKey, summaryType: this.showQuickRead
     if (state.selectedSection) {
      state.sectionToAnnotateFromRange = {
        ...state.selectedSection,
        range
      };
      Vue.set(state.sectionToAnnotateFromRange, range, range)
     }else{
       console.log('no selected section');
     }

    },
    setSelectionSection(state,data){
      state.selectedSection = data;
    },
    setCurrentData(state, data) {
      state.currentLiteratureData = data;
    },
    setFlagForHighlightUpdateTo(state, payload){
      state.isUpdateHighlight = payload;
    },
    setCreatedCommentData(state, payload){
      state.createdCommentData = payload;
    },
    setForPinAnnotation(state, item){
      state.selectedAnnotationPin = item;
    },
    setForAttachmentAnnotation(state, item){
      state.selectedAnnotationAttachment = item;
    },
    setLastAttachmentHId(state, id){
      state.lastAttachmentHId = id;
    },
    setForNoteAnnotation(state, item){
      state.selectedAnnotationNote = item;
    },
    setForUrlAnnotation(state, item){
      state.selectedAnnotationUrl = item;
    },
    addToHighlightStore(state, payload){
      if (state.data) {
        state.data.push(payload);
      }else{
        state.data = [payload];
      }
    },
    removeFromHighlightStoreWithIndex(state,index){
      if (index !== -1) {
        state.data.splice(index, 1);
      }
    },
    removeComment(state, commentId){
      const selectedIndex = state.data.findIndex(i=> i.content == commentId);
      if (selectedIndex > -1) {
        const selectedData = state.data[selectedIndex];
        // Remove from Id,
        this.commit('kiAnnotations/setAnnotationToRemove', selectedData);
      }
    },

    setSelectionHighlighterInstance(state, payload){
      state.selectionHighlighter = payload;
    },
    setSelectionHighlightData(state, payload){
      state.selectionHighlightData = payload;
    },
    setAnnotationIdForAction(state, payload){
      state.selectedAnnotationIdForAction = payload;
    },
    setSelectedActionType(state, payload){
      state.selectedActionType = payload;
    },
    deleteFromId(state, id){
      const selectedItemIndex = state.data.findIndex(i=>i.id === id);
      const selectedItem = state.data[selectedItemIndex];
      state.annotationToRemove = selectedItem;
      //Delete from id
    },
    hasHtmlRendered(state, payload){
      state.isHtmlRendered = payload;
    },
    updateRange(state, payload){
      state.selectedRange = payload;
    },
    destroy(state){
      // Merge rather than replace so we don't lose observers
      // https://github.com/vuejs/vuex/issues/1118
      Object.assign(state, defaultAnnotationState())
    },
    setIsTextSelected(state, data) {
      state.isTextSelected = data;
    }
  },
  actions: {
    async fetch({ commit, state }) {
      const { data } = await this.$axios.post(KI_ANNOTATION_API+ 'reviewer-fetch', { literatureId: state.currentLiteratureData.id });
      commit("setData", data);
    },
    async create({ commit, state }, inputs) {
      // creating comment
      if (inputs.type === AnnotationTypeEnum.comment) {
        inputs.content = `${state.createdCommentData.id}`;
      }
      const { data } = await this.$axios.post(KI_ANNOTATION_API + "reviewer-create", {
        ..._.omit(inputs, ['pageNo']),
        literatureId:state.currentLiteratureData.id,
        ...state.selectedSection
      });
      commit('addToHighlightStore', {...data, annotationSource: AnnotationSourceType.KeyInsight, endMeta: JSON.parse(data.endMeta), startMeta: JSON.parse(data.startMeta)});
      return data;
    },
    async delete({ commit, state }, hId){
      const selectedItemIndex = state.data.findIndex(i=>i.hId === hId);
      if (selectedItemIndex !== -1) {
        const selectedItem = state.data[selectedItemIndex];
        await this.$axios.post(KI_ANNOTATION_API + "reviewer-remove", { id: selectedItem.id });
        //TODO: Add snackbar for deletion
        commit('removeFromHighlightStoreWithIndex',selectedItemIndex);
      }
    },
    async update({commit, state}, payload){
      const selectedItemIndex = state.data.findIndex(i=>i.hId === payload.hId);
      if (selectedItemIndex !== -1) {
        const selectedItem = state.data[selectedItemIndex];
        const data = await this.$axios.patch(KI_ANNOTATION_API+ 'reviewer-update', { id:selectedItem.id, content:payload.content });
        selectedItem.content = payload.content;
      }

    },
    async createComment({ state, commit, getters, dispatch }, text) {
      // check if new creation or conversion from highlight to comment
      let params;
      let hsData= null;
      const selectedIdFromAction = state.isUpdateHighlight && state.selectedAnnotationIdForAction;
      const lastData = state.selectionHighlightData;
      if (!selectedIdFromAction && !lastData) {
        console.log('No selection');
        // TODO: Show warning.
        return;
      }

      if (selectedIdFromAction) {
        const getCurrentItemToBeConverted = state.data.find(i=> i.hId === selectedIdFromAction);
        const id = String(Math.random()).slice(2);
        const newId = generateHighlightId(id , AnnotationType.Comment);
        hsData = {
          startMeta: getCurrentItemToBeConverted.startMeta,
          endMeta: getCurrentItemToBeConverted.endMeta,
          text: getCurrentItemToBeConverted.text,
          content: '',
          literatureId: getCurrentItemToBeConverted.literatureId,
          type:AnnotationTypeEnum.comment,
          hId: newId
        }
        params = {
          itemId: state.currentLiteratureData.id,
          selection: hsData.text,
          text: text,
          type: "literatures",
          commentedOn: "ki"
        };
      } else {
        params = {
          itemId: state.currentLiteratureData.id,
          selection: lastData.text,
          text: text,
          type: "literatures",
          commentedOn: "ki"
        };

      }

      const { data } = await this.$axios.post(
        "/shared/item/comments/create",
        params
      );
      commit('setCreatedCommentData', data);
      commit('setAnnotationIdForAction', null);
      commit('setFlagForHighlightUpdateTo', false);
      // if having hs store data
      if (hsData) {
        hsData.content = data.id+ ''
        await dispatch('create', hsData);
         // remove highlightId
        const selectedItemIndex = state.data.findIndex(i=>i.hId === selectedIdFromAction);
        const selectedItem = state.data[selectedItemIndex];
        commit('setAnnotationToRemove', selectedItem);

      }else{

        // trigger annotation from stored range
        // Convert range object to actual highlight
              // get hsData
              // remove highlight
        const id = String(Math.random()).slice(2);
        const newId = generateHighlightId(id+ 'ki', AnnotationType.Comment);
        const newData = {
          startMeta: lastData.startMeta,
          endMeta: lastData.endMeta,
          text: lastData.text,
          content: data.id+ '',
          literatureId: state.currentLiteratureData.id,
          type:AnnotationTypeEnum.comment,
          hId: newId
        }
        if (state.selectionHighlighter) {
          state.selectionHighlighter.remove('Rax__Ki__Selection__Text');
          await delay(300);
          commit('applyLastUsedRange');
          commit('setSelectedActionType',AnnotationType.Comment);
          await delay(300);
          const selection = window.getSelection();

          if (selection.isCollapsed) {
            return;
          }

          const range = selection.getRangeAt(0);
          commit('setSectionToAnnotateFromRange', range);
        }
      }

      if (state.selectionHighlighter) {
        state.selectionHighlighter.remove('Rax__Ki__Selection__Text');
      }

      data["refItemId"] = state.currentLiteratureData.id;
      data["type"] = "2"; //literature
      this.commit("sharedComments/addData", { key: "comments", data });
      this.dispatch("core/updateLastAccess", {
        item: state.currentLiteratureData,
        type: "literature",
        storeName: "literatures"
      });
      console.log(data);
    },
    async createPin({commit, dispatch, state}){
      // create promise for id
      // check if state.selectedRange is available
      if (!(state.selectedRange && state.selectedRange.toString())) {
          return null;
      }
      commit('setSectionToAnnotateFromRange', state.selectedRange);

      return new Promise((resolve, reject) =>{
        let count = 0
        const intervalRef = setInterval(()=>{
          count++;
        if (state.selectedAnnotationPin) {
          commit('setForPinAnnotation', state.selectedAnnotationPin);
          clearInterval(intervalRef);
          resolve(state.selectedAnnotationPin.id);
        }
        if (count > 6) {
          console.log('late response');
          reject('late response');
          clearInterval(intervalRef);
        }
      }, 1000);
    })
  },
    async createAttachment({commit, dispatch, state}){
      // create promise for id
      // check if state.selectedRange is available
      if (!(state.selectedRange && state.selectedRange.toString())) {
        return null;
      }
      commit('setSectionToAnnotateFromRange', state.selectedRange);
      const self = this;
      return new Promise((resolve, reject) =>{
        let count = 0
        const intervalRef = setInterval(()=>{
          count++;
        if (state.selectedAnnotationAttachment) {
          commit('setForAttachmentAnnotation', state.selectedAnnotationAttachment);
          clearInterval(intervalRef);
          resolve(state.selectedAnnotationAttachment.id);
        }
        if (count > 6) {
          console.log('late response');
          reject('late response');
          clearInterval(intervalRef);
        }
      }, 1000);
    })
  },
  createUrlAttachment({ commit, state }) {
      // create promise for id
      // check if state.selectedRange is available
      if (!(state.selectedRange && state.selectedRange.toString())) {
        return null;
      }

      try {
        commit('setSectionToAnnotateFromRange', state.selectedRange);
      } catch (error) {
        console.log(error);
      }

      return new Promise((resolve, reject) => {
        let count = 0
        const intervalRef = setInterval(() => {
          count++;
          if (state.selectedAnnotationUrl) {
            commit("setForUrlAnnotation", state.selectedAnnotationUrl);
            clearInterval(intervalRef);
            resolve(state.selectedAnnotationUrl.id);
          }
          if (count > 6) {
            console.log('late response');
            reject('late response');
            clearInterval(intervalRef);
          }
        }, 1000);
      })
    },
  },
  getters: {
    getCurrentLiteratureData(state){
      return state.currentLiteratureData;
    },
    getAnnotations(state){
      return state.data;
    },
    selectionHighlighter(state){
      return state.selectionHighlighter;
    },
    selectedAnnotationIdForAction(state){
      return state.selectedAnnotationIdForAction;
    },
    selectedActionType(state){
      return state.selectedActionType;
    },
    getRange(state){
      return state.selectedRange;
    },
    getSelectedText(state){
      if (state.selectionHighlightData) {
        return state.selectionHighlightData.text;
      }
      return state.selectedRange ? getProperSelection({selectionRange: state.selectedRange}).replace(/\s+/g, ' ') : '';
    },
    getSelectedPinnedItem(state){
      if (!state.selectedAnnotationPin) {
        return null;
      }
      return state.selectedAnnotationPin;
    },
    getCurrentNoteAnnotation(state){
      if (state.selectedAnnotationNote) {
        return state.selectedAnnotationNote
      }
      return null
    },
    getUrlAnnotation(state){
      if (state.selectedAnnotationUrl) {
        return state.selectedAnnotationUrl;
      }
      return null;
    },
    getAnnotationAttachment(state){
      if (!state.selectedAnnotationAttachment) {
        return null;
      }
      return state.selectedAnnotationAttachment;
    },
    getLastAttachmentHId(state){
      return state.lastAttachmentHId;
    },
    getSectionToAnnotateFromRange(state){
      return state.sectionToAnnotateFromRange;
    },
    getSectionToNoteFromRange(state){
      return state.sectionToNoteFromRange;
    },
    getContextActionClick(state){
      return state.contextActionClick;
    },
    getAnnotationToRemove(state){
      return state.annotationToRemove;
    },
    getIsTextSelected(state) {
      return state.isTextSelected;
    },
  }
};
