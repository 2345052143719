var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "md-snackbar", attrs: { id: _vm.unid } }, [
    _c("div", { staticClass: "md-snackbar__surface" }, [
      _c(
        "div",
        {
          staticClass: "md-snackbar__text",
          staticStyle: { display: "flex", "justify-content": "space-between" }
        },
        [_vm._v("\n      " + _vm._s(_vm.message) + "\n    ")]
      ),
      _vm._v(" "),
      _c("div", [
        _vm.isUndo
          ? _c(
              "button",
              {
                staticStyle: { "font-size": "14px", color: "#93C5FC" },
                on: { click: _vm.emitUndoEvent }
              },
              [_vm._v("\n        Undo\n      ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "md-button md-snackbar__action md-button--dense md-button--accent md-snackbar__button",
            class: { "md-button--icon": _vm.actionText === "" },
            staticStyle: { margin: "0px" }
          },
          [
            !_vm.actionText
              ? [
                  _c("i", { staticClass: "material-icons md-button__icon" }, [
                    _vm._v("close")
                  ])
                ]
              : [_vm._v("\n          " + _vm._s(_vm.actionText) + "\n        ")]
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }