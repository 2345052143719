<template>
  <div
    id="pinned-tags-menu"
    :style="{ height: pinTagLimitReached ? '400px' : '370px' }"
  >
    <div class="tw-flex">
      <input
        id="search-pinned-tag"
        v-model="searchKey"
        type="text"
        placeholder="Search Tags"
        @input="searchTags"
        @change="searchTags"
        :style="{
          'border': `1px solid #${searchKey.length ? '1A64E2' : 'DDE2E9'}`
        }"
      />
      <button
        v-if="searchKey.length > 0"
        id="reset-search-button"
        class="md-chip__remove md-button md-button--icon"
        :style="{ background: 'transparent' }"
        @click.prevent="resetSearchKey"
      >
        <i
          id="reset-search-icon"
          class="md-icon material-icons"
          :style="{ color: '#000' }"
        >close</i>
      </button>
    </div>
    <div
      v-if="filteredTagsList.length !== 0"
      id="search-suggested-tags"
      :style="{ height: pinTagLimitReached ? '77%' : '75%' }"
    >
      <p
        v-if="pinTagLimitReached"
        id="pin-limit-message"
      >
        Pinning limit reached, please remove few pins to add some new.
      </p>
      <p
        class="suggested-tags-header tw-my-2"
      >
        Suggested Tags
      </p>
      <div
        id="search-pinned-tags-list"
        class="tw-flex tw-flex-col tw-m-1"
      >
        <div
          v-for="tagItem in filteredTagsList.slice(0, 5)"
          :key="tagItem.name + tagItem.color"
          class="tw-flex tw-justify-between tw-items-center tw-w-full search-pin-tag-row"
          @click.prevent.stop="togglePinTag(tagItem)"
        >
          <div
            class="tw-items-center tw-justify-center search-pin-tag-item"
            :style="{ border: `2px solid ${chipColors[tagItem.color - 1]}` }"
            v-tooltip="{
              content: tagItem.name,
              delay: {
                show: 300,
                hide: 100
              },
              placement: 'bottom-start'
            }"
          >
            {{ tagItem.name }}
          </div>
          <span
            class="rax-icon rax-icon-phase2-pin-search-tag tw-mr-2"
            :class="{ 'rax-icon-phase2-pin-tag --active' : isTagPresent(tagItem) }"
          />
        </div>
      </div>
    </div>
    <p
      v-else
      id="no-suggested-tags-text"
    >
      No tags found, please search for different tags.
    </p>
    <hr class="tw-mb-2" />
    <div class="search-pinned-tags-buttons">
      <button
        id="pinned-tag-cancel"
        class="search-pinned-tags-button"
        @click="hideTagsList"
      >
        Cancel
      </button>
      <button
        id="pinned-tag-save"
        class="search-pinned-tags-button"
        @click="saveSelectedPinnedTags"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ChipColors } from '../literature/constants';
import { MixpanelEvents, MixpanelEventGroup } from "@/shared/constants";

const { Pin, Unpin } = MixpanelEvents[MixpanelEventGroup.PinnedTag]

export default {
  data() {
    return {
      chipColors: ChipColors,
      filteredTagsList: [],
      selectedPinnedTags: [],
      searchKey: '',
      tagSearched: false,
      pinTagLimitReached: false
    }
  },
  computed: {
    ...mapGetters({
      allTagsList: "tags/getData",
      pinnedTagsList: "profile/getUserPinnedTags"
    }),
    nonPinnedTagsList() {
      const pinnedTagNames = this.pinnedTagsList.map(({ name }) => name.toLowerCase());
      return this.allTagsList.filter(({ name }) => !pinnedTagNames.includes(name.toLowerCase()));
    }
  },
  async mounted() {
    if (this.allTagsList && !this.allTagsList.length) {
      await this.$store.dispatch("tags/fetch");
    }
    this.filteredTagsList = [...this.pinnedTagsList, ...this.nonPinnedTagsList];
    this.selectedPinnedTags = [...this.pinnedTagsList];
  },
  methods: {
    togglePinTag(tagItem) {
      const isPinnedTagPresent = this.selectedPinnedTags.some(({ id }) => id === tagItem.id);
      if (!isPinnedTagPresent) {
        if (this.selectedPinnedTags.length >= 4)
          this.pinTagLimitReached = true;
        else {
          this.pinTagLimitReached = false;
          this.selectedPinnedTags.push(tagItem);
          this.$mixpanel.track(Pin, {
            from: 'Pin Modal',
            tag_name: tagItem.name
          });
        }
      } else {
        this.pinTagLimitReached = false;
        this.selectedPinnedTags = this.selectedPinnedTags.filter(({ id }) => id !== tagItem.id);
        this.$mixpanel.track(Unpin, {
          from: 'Pin Modal',
          tag_name: tagItem.name
        });
      }
    },
    resetSearchKey() {
      this.searchKey = '';
      this.filteredTagsList = this.allTagsList;
    },
    searchTags({ target }) {
      this.tagSearched = true;
      const inputVal = target.value;
      this.searchKey = inputVal;
      this.filteredTagsList = this.allTagsList.filter(({ name }) => name.toLowerCase().includes(inputVal.toLowerCase()));
      if (!inputVal)
        this.filteredTagsList = this.allTagsList;
    },
    isTagPresent(tagItem) {
      return this.selectedPinnedTags.some(({ id }) => id === tagItem.id);
    },
    async saveSelectedPinnedTags(ev) {
      const newPinnedTagsList = this.selectedPinnedTags.map((tagData) => ({
        ...tagData,
        isPinned: false
      }));
      this.hideTagsList(ev, true);
      try {
        await this.$store.dispatch("profile/modifyUserPinnedTags", newPinnedTagsList);
        this.$mixpanel.track(MixpanelEvents[MixpanelEventGroup.PinnedTag].Save_Pinned_Tag, {
          tags: newPinnedTagsList.map(({ name }) => name).join(", "),
          tag_searched: this.tagSearched
        });
      } catch (err) {
        console.log('Something went wrong', err);
      }
    },
    hideTagsList(ev, saveAction = false) {
      if (!saveAction)
        this.$mixpanel.track(MixpanelEvents[MixpanelEventGroup.PinnedTag].Cancel_Pinned_Tag, {
          tag_searched: this.tagSearched
        });
      this.selectedPinnedTags = [];
      this.pinTagLimitReached = false;
      this.searchKey = '';
      this.tagSearched = false;
      this.$emit("hideTagsList", ev);
    }
  }
}
</script>

<style>
#pinned-tags-menu {
  padding: 20px 0px;
  width: 250px;
  height: 400px;
}
#search-pinned-tag {
  border-radius: 34px;
  padding: 3px 8px;
  margin: 4px 10px;
  width: 92%;
}
#reset-search-button {
  margin-left: -37px;
  margin-top: 10px;
}
#reset-search-icon {
  font-size: 24px;
}
#reset-search-button:hover,
#reset-search-icon:hover {
  background: transparent;
}
#no-suggested-tags-text {
  padding: 10px 15px;
  width: 90%;
}
#no-suggested-tags-text {
  height: 78%;
} 
#pin-limit-message {
  font-size: 12px;
  color: red;
  font-weight: 600;
  margin: 0px 18px;
}
.suggested-tags-header {
  color: #9CA3AF;
  font-size: 12px;
  font-weight: 500;
  margin: 8px 18px;
}
#search-pinned-tags-list {
  height: 260px;
  overflow: auto;
}
#search-pinned-tags-list .search-pin-tag-row:hover {
  background: #FAFBFC;
  cursor: pointer;
}
.pinned-tags-header-title {
  font-weight: 600;
  font-size: 14px;
  font-family: Roboto;
}
.pinned-tags-header-icon {
  font-size: 18px;
}
.pinned-tag-item {
  border-radius: 20px;
  padding-left: 8px;
  padding-right: 8px;
}
.search-pinned-tags-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0px 8px;
}
.search-pinned-tags-button {
  padding: 3px;
  width: 63px;
  height: 32px;
}
#pinned-tag-cancel {
  color: #146AE2;
}
#pinned-tag-save {
  background-color: #146AE2;
  color: white;
  margin: 2px 5px;
  padding: 5px;
  border-radius: 8px;
}
#pinned-tag-save:disabled {
  opacity: 0.48;
  cursor: not-allowed;
}
</style>