import Vue from "vue";
import { SORT_BY } from "~/components/literature/constants";
const defaultProjectFeedState = () => {
  return {
    literatures: [],
    currentSort: SORT_BY.Relevancy,
    shouldShowNewTag: true,
    hideNewCount: false
  }
};
export default {
  namespaced: true,
  state: defaultProjectFeedState,

  mutations: {
    setData(state, obj) {
      state[obj.key] = obj.data;
    },
    addData(state, obj) {
      state[obj.key].push(obj.data);
    },
    setObjData(state, obj) {
      Vue.set(state[obj.name], obj.key, obj.data);
    },
    resetState(state) {
      Object.assign(state, defaultProjectFeedState());
    },
    setCurrentSort(state, data) {
      state.currentSort = data;
    }
  },

  actions: {
    async literature({ commit, state }, params, loadMore = false) {
      let { data } = await this.$axios.post('reviewer-feed/project/literature', {projectId: params.id});
      data.forEach((item) => {
        item.projectId = params.id
        item.type = "paper"
      });
      if (loadMore) {
        data.forEach((item) => {
          commit("addData", { key: "literatures", data: item });
        });
      } else {
        commit("setData", { key: "literatures", data: data });
      }
    },
    async updateSortData({ commit, dispatch, state }, data){
      commit('setCurrentSort', data);
    },
  },

  getters: {
    getLiteratures(state) {
      return state.literatures;
    },
    getCurrentSort(state) {
      return state.currentSort;
    },
    getShouldShowNewTag(state) {
      return state.shouldShowNewTag;
    },
    getHideNewCount(state) {
      return state.hideNewCount;
    }
  }
};
