<template>
  <div id="search-container">
    <div
      id="search-box-container"
      class="tw-flex tw-items-center"
      :class="{ '--focus': searchText.length }"
    >
      <img
        id="full-text-search-icon"
        :src="assetsUrl + 'img/icon/phase3/search.svg'"
        alt="Search Icon"
      />
      <input
        id="full-text-searchbox"
        v-model="searchText"
        type="text"
        placeholder="Search"
        autocomplete="off"
        :class="{ '--focus': showSearchResults }"
        @input="onInputChange"
        @change="onInputChange"
        @focus="onInputFocus"
        @blur="onInputBlur"
        @keydown="onKeyDown"
      />
      <button
        :class="{ '--show' : searchText.length }"
        id="reset-searchbox-button"
        class="md-chip__remove md-button md-button--icon"
        @click.prevent="onClearText"
      >
        <i
          id="reset-searchbox-icon"
          class="md-icon material-icons"
        >
          close
        </i>
      </button>
    </div>
    <div
      v-if="showSearchResults"
      id="search-results-container"
      :class="`
        ${showSearchResults ? '--open': ''}
        ${searchLoading ? '--loading': ''}
      `"
    >
      <div
        v-if="searchLoading"
        style="height: auto; margin: auto;"
      >
        <img
          id="search-loading-state"
          :src="assetsUrl + 'img/v2-assets/file-uploader-loading-icon.GIF'"
          alt="Loading Search Results"
        />
      </div>
      <template v-else>
        <div
          v-if="!searchResultsList.length"
          style="height: auto; margin: auto;"
          id="no-search-results-container"
        >
          <img
            id="search-no-results-state"
            :src="assetsUrl + 'img/icon/phase3/no-search-results.svg'"
            alt="No Search Results"
          />
          <p id="search-no-items-text">Oops!! No result found</p>
          <p id="search-no-items-subtext" class="tw-mt-2">You can try searching with a different word.</p>
        </div>
        <div
          v-if="searchResultsList.length"
          id="search-result-items-container"
          class="tw-flex tw-flex-col"
        >
          <span id="search-result-label">Showing results in <span id="search-result-label-title">"Literature"</span></span>
          <div id="search-results-list">
            <result-card
              v-for="(searchItem, index) in searchResultsList"
              :key="searchItem.literatureId + index"
              :search-item="searchItem"
            />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
  
<script>
import { mapGetters } from "vuex";
import resultCard from "@/components/full-text-search/resultCard";
import { MixpanelEventGroup, MixpanelEvents } from '~/shared/constants'; 

export default {
  components: {
    resultCard
  },
  data() {
    return {
      assetsUrl: process.env.assetsUrl,
      searchText: "",
      searchLoading: false,
      showSearchResults: false
    };
  },
  computed: {
    ...mapGetters({
      searchResultsList: "profile/getSearchResults",
    })
  },
  methods: {
    onInputChange({ target }) {
      this.searchText = target.value;
    },
    onClearText() {
      this.searchText = "";
      this.showSearchResults = false;
    },
    onInputFocus() {
      $('#search-box-container').addClass('--focus');
    },
    onInputBlur() {
      $('#search-box-container').removeClass('--focus');
    },
    async onKeyDown({ key }) {
      if (key === "Enter" && this.searchText.length) {
        this.showSearchResults = true;
        $('#search-box-top-container').removeClass('unfocus');
        await this.initiateSearch(this.searchText);
      }
    },
    selectRecentSearchItem(recentSearchText) {
      this.searchText = recentSearchText;
    },
    async initiateSearch(searchKey) {
      this.searchLoading = true;
      try {
        await this.$store.dispatch("profile/initateFullTextSearch", searchKey);
        this.$mixpanel.track(MixpanelEvents[MixpanelEventGroup.Search].Search_Text, {
          text: searchKey,
          results_found: this.searchResultsList.length > 0
        });
      } catch (err) {
        console.log(err);
      }
      this.searchLoading = false;
      document.addEventListener('click', this.hideSearchWindow, true);
    },
    hideSearchWindow({ target }) {
      const popover = $(`#search-results-container`);
      const searchInput = $(`#search-box-container`);
      const isNotTarget = $(target).closest('#search-results-container').is(popover) || $(target).closest('#search-box-container').is(searchInput);
      if (isNotTarget) return;
      else {
        this.showSearchResults = false;
        $('#search-box-top-container').addClass('unfocus');
      } 
    },
  }
};
</script>
  
<style scoped>
#search-container {
  font-family: 'Open Sans';
  margin: 0px 20px;
}
#search-box-container {
  border-radius: 8px;
  border: 1px solid #DDE2E9;
  width: 100%;
  background: #F8F8FA;
  padding: 5px 8px;
}
#search-box-container.--focus {
  background: #FFF;
  border: 1px solid #1A64E2;
}
#search-loading-state {
  margin: auto;
  margin-bottom: 10px;
  width: 170px;
  height: 180px;
}
#search-loading-text {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #111A27;
  margin: 3px 0px;
}
#full-text-search-icon {
  z-index: 100;
  height: 16px;
  width: 16px;
  margin: 2px 8px;
}
#full-text-searchbox {
  background: #F8F8FA;
  color: #111A27;
  width: 344px;
  font-size: 16px;
}
#full-text-searchbox.--focus {
  width: 707px;
}
#full-text-searchbox:focus {
  background: #FFF;
}
#reset-searchbox-button {
  background: transparent;
  visibility: hidden;
}
#reset-searchbox-button.--show {
  visibility: visible;
}
#reset-searchbox-icon {
  font-size: 24px;
  color: #000;
}
#reset-searchbox-button:hover,
#reset-searchbox-icon:hover {
  background: transparent;
}
#search-results-container {
  position: absolute;
  z-index: 2000;
  background: #FFF;
  display: flex;
  flex-direction: column;
  border: 1px solid #DDE2E9;
  box-shadow: 0px 4px 40px rgba(30, 58, 138, 0.08);
  border-radius: 8px;
  padding: 13px;
  margin-top: 5px;
  height: auto;
  max-height: 530px;
  overflow-y: auto;
}
#search-results-container.--open,
#search-results-container.--loading {
  width: 777px;
}
#recent-search-label,
#search-result-label {
  padding: 5px 0px;
  color: #414E62;
  font-size: 14px;
  line-height: 16px;
  font-family: 'Open Sans';
}
.recent-search-item {
  display: inline-flex;
  align-items: center;
  padding: 6px 0px;
  width: 100%;
  color: #000;
  cursor: pointer;
}
.recent-search-item-text {
  padding: 0px 5px;
  font-family: 'Open Sans';
  font-size: 14px;
}
#search-result-label {
  color: #414E62;
  font-size: 12px;
}
#search-result-label-title {
  color: #111A27;
  font-size: 12px;
  font-weight: 600;
}
#no-search-results-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#search-no-results-state {
  width: 170px;
  height: 180px;
}
#search-no-items-text {
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  color: #111A27;
  margin-top: 10px;
}
#search-no-items-subtext {
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #414E62;
  margin-bottom: 10px;
}
@media (max-width: 1300px) {
  #search-loading-text {
    font-size: 20px;
  }
  #search-no-items-text {
    font-size: 22px;
  }
}
</style>