<template>
  <header
    v-if="getShowHeader"
    role="banner"
    :class="{ 'rx-nav-hide-mobile': !isAuthenticated }"
    class="md-navbar md-navbar--dense md-navbar--has-menu-button rx-header-animate md-navbar--fixed md-navbar--light md-navbar--casting-border tw-p-0"
    style="background-color: #FFFFFF;border-bottom: 1px solid #FFFFFF;box-shadow: inset 0px -1px 0px #E6E9EE;"
    @click="hideAppSheet"
  >
    <div class="md-container topHeaderContainer">
      <!-- <a
        :class="{ 'hidden--gt-sm hideMenuLoginPage': !isAuthenticated }"
        href="javascript:;"
        :data-toggle="![UserStatus.KIDemoOld, UserStatus.KIDemoNew, UserStatus.ReadingDemo, UserStatus.RedditDemo].includes(user.onboardingStatus) ? 'drawer' : ''"
        data-target="#drawer"
        @click="checkUserEmail"
        class="md-button md-button--icon md-navbar__menu-button"
      >
        <i class="material-icons">menu</i>
      </a> -->
      <div
        class="md-navbar__row"
        :class="{ 'tw-justify-between': displayLandingNav, 'menuOuterSection': !isAuthenticated }"
      >
      <div class="pageNameSection" :class="{ 'userLogIn': isAuthenticated }">
        <!-- <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M3 2.00781C2.73478 2.00781 2.48043 2.11317 2.29289 2.30071C2.10536 2.48824 2 2.7426 2 3.00781V14.0078C2 14.273 2.10536 14.5274 2.29289 14.7149C2.48043 14.9025 2.73478 15.0078 3 15.0078H17C17.2652 15.0078 17.5196 14.9025 17.7071 14.7149C17.8946 14.5274 18 14.273 18 14.0078V6.00781C18 5.7426 17.8946 5.48824 17.7071 5.30071C17.5196 5.11317 17.2652 5.00781 17 5.00781H10C9.73478 5.00781 9.48043 4.90246 9.29289 4.71492L6.58579 2.00781H3ZM0.87868 0.886492C1.44129 0.323883 2.20435 0.0078125 3 0.0078125H7C7.26522 0.0078125 7.51957 0.113169 7.70711 0.300706L10.4142 3.00781H17C17.7957 3.00781 18.5587 3.32388 19.1213 3.88649C19.6839 4.4491 20 5.21216 20 6.00781V14.0078C20 14.8035 19.6839 15.5665 19.1213 16.1291C18.5587 16.6917 17.7957 17.0078 17 17.0078H3C2.20435 17.0078 1.44129 16.6917 0.87868 16.1291C0.31607 15.5665 0 14.8035 0 14.0078V3.00781C0 2.21216 0.31607 1.4491 0.87868 0.886492Z" fill="#414E62"/>
        </svg> -->
        <!-- <span style="font-weight:500">{{headerTitle}}</span> -->
      </div> 
        <!-- <nuxt-link
          v-if="isAuthenticated"
          :event="![UserStatus.KIDemoOld, UserStatus.KIDemoNew, UserStatus.ReadingDemo, UserStatus.RedditDemo].includes(user.onboardingStatus) ? 'click' : ''"
          to="/projects"
          class="md-navbar__brand md-align--start-center is-active"
        >
          <appLogo style="height: 40px" />
        </nuxt-link>
        <a v-else :href="landingUrl" class="md-navbar__brand md-align--start-center is-active">
          <appLogo style="height: 40px" />
        </a> -->
        <app-logo class="app-logo" />
        <!-- <bread-crumb/> -->
        <div v-if="!displayLandingNav" class="md-layout-spacer" />
        <div
          v-if="
            isLibraryAccess &&
              !isAuthenticated &&
              routerName !== 'discover-library'
          "
          class="md-nav md-nav--compact hidden--lt-sm md-nav--color-dark-primary md-align md-align--center-center"
        >
          <nuxt-link
            to="/discover/library"
            class="md-nav__link md-text-primary"
            tag="a"
          >RC Resources</nuxt-link>
        </div>
        <div
          v-if="displayLandingNav"
          class="md-nav hidden--lt-sm md-nav--compact md-nav--color-dark-primary md-align md-align--center-center"
        >
          <a
            v-for="item in navItems"
            :key="item.title"
            :href="landingUrl + item.value"
            class="md-nav__link tw-relative"
            style="font-weight: 500"
          >
            <span
              v-if="item.value === 'review-assistant'"
              class="tw-absolute tw-font-medium tw-border tw-border-solid tw-border-green-primary tw-rounded tw-text-green-primary tw-px-1"
              style="top: 5px; width: max-content; font-size: 10px; margin-left: 3px"
            >
              Get early access
            </span>
            {{ item.title }}
          </a>
          <!-- <div class="resources-parent tw-cursor-pointer">
            <div
             class="md-nav__link resources-tab tw-flex tw-items-center tw-font-bold"
            >
              Resources
              <span class="raxIcon raxIcon-arrow-drop-down raxIcon--black56 tw-transition-transform tw-duration-200" />
            </div>
            <ul
              class="tw-items-center tw-ml-2 tw-justify-between tw-fixed tw-bg-white tw-text-sm tw-text-gray-500 tw-flex resources-list"
            >
              <li
                class="hover:tw-bg-black-4 tw-transition-colors tw-duration-300"
              >
                <a
                  class="tw-block tw-py-3 tw-px-4 tw-text-black-56 tw-text-sm tw-font-medium tw-border-b-2 tw-border-transparent"
                  href="https://blog.raxter.io/"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  Blog
                </a>
              </li>
              <li
                class="hover:tw-bg-black-4 tw-transition-colors tw-duration-300"
              >
                <a
                  class="tw-block tw-py-3 tw-text-black-56 tw-px-4 tw-text-sm tw-font-medium tw-border-b-2 tw-border-transparent"
                  :href="assetsUrl + 'rax-guide.pdf'"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  User Guide
                </a>
              </li>
              <li
                class="hover:tw-bg-black-4 tw-transition-colors tw-duration-300"
              >
                <a
                  class="tw-block tw-py-3 tw-text-black-56 tw-px-4 tw-text-sm tw-font-medium tw-border-b-2 tw-border-transparent"
                  href="https://www.youtube.com/playlist?list=PLeQNr1XWrB0xaDgRN3zgn0ihY9Q1k33p5"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  Tutorials
                </a>
              </li>
            </ul>
          </div> -->
        </div>
        <!-- Mobile menu -->
        <div v-show="showMobileMenu"
          class="mobileMenuSection md-nav--color-dark-primary md-align md-align--center-center"
        >
          <a
            v-for="item in navItems"
            :key="item.title"
            :href="landingUrl + item.value"
            class="md-nav__link tw-relative"
            style="font-weight: 500"
          >
            <span
              v-if="item.value === 'review-assistant'"
              class="tw-absolute tw-font-medium tw-border tw-border-solid tw-border-green-primary tw-rounded tw-text-green-primary tw-px-1"
              style="top: 5px; width: max-content; font-size: 10px; margin-left: 3px"
            >
              Get early access
            </span>
            {{ item.title }}
          </a>
          <div class="resources-parent tw-cursor-pointer">
            <div style="position: relative;"
             class="md-nav__link resources-tab tw-flex tw-items-center tw-font-bold"
            >
              Resources
              <span class="raxIcon raxIcon-arrow-drop-down raxIcon--black56 tw-transition-transform tw-duration-200" />
            </div>
            <ul
              class="tw-items-center tw-ml-2 tw-justify-between tw-fixed tw-bg-white tw-text-sm tw-text-gray-500 tw-flex resources-list"
            >
              <li
                class="hover:tw-bg-black-4 tw-transition-colors tw-duration-300"
              >
                <a
                  class="tw-block tw-py-3 tw-px-4 tw-text-black-56 tw-text-sm tw-font-medium tw-border-b-2 tw-border-transparent"
                  href="https://blog.raxter.io/"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  Blog
                </a>
              </li>
              <!-- <li
                class="hover:tw-bg-black-4 tw-transition-colors tw-duration-300"
              >
                <a
                  class="tw-block tw-py-3 tw-text-black-56 tw-px-4 tw-text-sm tw-font-medium tw-border-b-2 tw-border-transparent"
                  :href="assetsUrl + 'rax-guide.pdf'"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  User Guide
                </a>
              </li> -->
              <li
                class="hover:tw-bg-black-4 tw-transition-colors tw-duration-300"
              >
                <a
                  class="tw-block tw-py-3 tw-text-black-56 tw-px-4 tw-text-sm tw-font-medium tw-border-b-2 tw-border-transparent"
                  href="https://www.youtube.com/playlist?list=PLeQNr1XWrB0xaDgRN3zgn0ihY9Q1k33p5"
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  Tutorials
                </a>
              </li>
            </ul>
          </div>
        </div>
        <!-- Mobile meun end -->
        <!-- <ReferUser v-if="shouldShowReferAndEarnBtn" class="tw-mx-4">
          <template class="" v-slot:referUserButton>
            <button style="height: 36px" class="tw-flex tw-w-max-content tw-justify-center tw-items-center tw-bg-blue-primary tw-rounded-full tw-text-white tw-px-7 tw-py-2">
              <img style="transform: scale(0.8)" :src="assetsUrl + 'img/referral-program/announcement-ref.svg'" />
              <span class="tw-text-sm tw-ml-2">Refer & Earn $12</span>
            </button>
          </template>
        </ReferUser> -->

        <!-- Activate Review Assistant is commented as discussed with Piyush, will introduce this feature in coming future as discussed -->
        <!-- <button
          v-if="!shouldShowReferAndEarnBtn && isAuthenticated && isMounted && !reviewAssistantAccess && !disableBtn"
          class="tw-text-pink tw-mr-4 tw-normal-case tw-flex tw-items-center tw-border-2 tw-font-medium tw-h-9 tw-px-4 tw-border-solid tw-border-pink md-button hover:tw-text-white hover:tw-bg-pink tw-transition-colors tw-duration-200 tw-cursor-pointer try-review-assistant-button"
          style="border-radius: 20px;"
          @click="$emit('tryReview')"
        >
          Try Review Assistant
        </button>
        <button
          v-else-if="!shouldShowReferAndEarnBtn && isAuthenticated && reviewAssistantAccess === 'eligible' && !disableBtn"
          class="tw-text-pink tw-mr-4 tw-normal-case tw-flex tw-items-center tw-border-2 tw-font-medium tw-h-9 tw-px-4 tw-border-solid tw-border-pink md-button hover:tw-text-white hover:tw-bg-pink tw-transition-colors tw-duration-200 tw-cursor-pointer activate-review-assistant-button"
          style="border-radius: 20px;"
          @click="activateReview"
        >
          Activate Review Assistant
        </button> -->
        <div
          v-if="isAuthenticated"
          class="md-navbar__toolbar layout-m-r-1 md-nav--color-dark-primary tw-items-center"
          style = "margin-right: 8px !important;"
        >
          <search-box />
          <tooltip id="notification" title="Notifications">
            <img @click="onReleaseNoteClicked" class="tw-cursor-pointer bell" :src="`${assetsUrl}img/icon/bell.svg` "/>
          </tooltip>
        </div>

        <!-- <div v-if="isAuthenticated">
          <button
          class="mobileSignUpForFree tw-h-8 tw-text-white tw-text-sm tw-rounded tw-bg-blue-primary"
          @click="submitClickHandler()"
          >
            <div style="display:flex;width:98px;align-items:center;padding:0px 10px;">
              <div>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.79614 3.48388C6.81847 3.29568 7.09158 3.29568 7.11391 3.48388L7.31095 5.14449C7.52871 6.97983 8.97576 8.42688 10.8111 8.64464L12.4717 8.84167C12.6599 8.864 12.6599 9.13712 12.4717 9.15944L10.8111 9.35648C8.97576 9.57424 7.52871 11.0213 7.31095 12.8566L7.11391 14.5172C7.09158 14.7054 6.81847 14.7054 6.79614 14.5172L6.59911 12.8566C6.38134 11.0213 4.9343 9.57424 3.09896 9.35648L1.43835 9.15945C1.25015 9.13712 1.25015 8.864 1.43834 8.84167L3.09896 8.64464C4.93429 8.42688 6.38134 6.97983 6.59911 5.14449L6.79614 3.48388Z" fill="white"/>
                  <path d="M12.4344 0.813106C12.4456 0.719007 12.5822 0.719007 12.5933 0.813106L12.6897 1.62565C12.7973 2.53245 13.5123 3.24741 14.4191 3.355L15.2316 3.45141C15.3257 3.46258 15.3257 3.59913 15.2316 3.6103L14.4191 3.70671C13.5123 3.8143 12.7973 4.52926 12.6897 5.43606L12.5933 6.24861C12.5822 6.34271 12.4456 6.34271 12.4344 6.24861L12.338 5.43606C12.2304 4.52926 11.5155 3.8143 10.6087 3.70671L9.79614 3.6103C9.70204 3.59913 9.70204 3.46258 9.79614 3.45141L10.6087 3.355C11.5155 3.24741 12.2304 2.53245 12.338 1.62565L12.4344 0.813106Z" fill="white"/>
                </svg>
              </div>
              <div style="margin-left:8px;">Upgrade</div>
            </div>
          </button>
        </div> -->

        
        <!-- <div
          v-if="isAuthenticated"
          class="md-navbar__toolbar layout-m-r-1 md-nav--color-dark-primary"
        >

          <span
            @click="onReleaseNoteClicked"
            :class="{
              'active-release-notes': canHighlightReleaseNote,
              'md-button': true,
              'release-notes': true,
            }"
          >
            <span v-if="canHighlightReleaseNote">
              <i class="material-icons md-button__icon">star_rate</i>
            </span>
            New updates
          </span>
        </div> -->
        <div
          v-if="isAuthenticated && !disableBtn"
          class="md-navbar__toolbar md-nav--color-dark-primary layout-m-r-1"
        >
          <!-- <tooltip title="Help">
            <button class="md-button md-button--icon" @click="onHelpClicked">
              <i class="material-icons md-button__icon">help_outline</i>
            </button>
          </tooltip> -->
          <!-- <tooltip id="reminder-header" title="Reminders">
            <button class="reminder-header-icon md-button md-button--icon tw-ml-4" @click="openReminderDrawer">
              <img class="tw-cursor-pointer center-align" :src="`${assetsUrl}img/icon/alarm.svg` "/>
            </button>
          </tooltip> -->
          <button
            class="hidden md-button md-button--icon"
            onclick="$('.md-sheet-layer').toggleClass('md-sheet-layer--visible')"
          >
            <i class="material-icons">notifications</i>
          </button>
        </div>
        <div v-if="!isAuthenticated" class="tw-flex tw-items-center tw-mr-4 md:tw-mr-16">
          <nuxt-link
            to="/auth/signup"
            class="mobileSignUpForFree tw-h-8 tw-px-4 tw-font-medium tw-text-white tw-text-sm tw-rounded tw-bg-blue-primary"
            tag="button"
          >
            Sign up for free
          </nuxt-link>
          <nuxt-link
            to="/auth/signin"
            class="mobileSignInButton tw-py-1 tw-h-8 tw-px-4 tw-ml-6 tw-text-sm tw-text-blue-primary tw-rounded-sm tw-border tw-border-solid tw-border-blue-primary"
            tag="button"
          >
            Sign in
          </nuxt-link>
          <button @click="showMobileMenu = !showMobileMenu"
           class="MobileMenuButton tw-h-8 tw-px-4 tw-font-medium tw-text-white tw-text-sm tw-rounded tw-bg-blue-primary">Menu</button>
        </div>
        <profile-btn
          v-if="isAuthenticated"
          :icon="icon"
          :is-img="isImg"
          :img-src="profileImage"
          class="tw-mx-2"
        />
      </div>
    </div>
    <modal
      v-if="false"
      name="payment-update"
      transition="pop-out"
      :adaptive="true"
      :focus-trap="true"
      :scrollable="true"
      :height="'auto'"
    >
      <PaymentMethodUpdate />
    </modal>
  </header>
</template>

<style>
.app-logo {
  width: 168px;
  height: 32px;
}
.center-align{
  margin: 0 auto;
}
@media (min-width: 769px) {
body {
  padding-top: 0px;
}
/* @media (min-width: 769px) {
  .rx-nav-hide-mobile .md-navbar__row {
    padding-left: 0;
  }*/
}
@media (max-width: 1392px) {
  .try-review-assistant-button {
    width: 10.5rem;
  }
  .activate-review-assistant-button {
    width: 12.5rem;
  }
}
</style>

<style scoped>
.resources-list {
  display: none;
  top: 3.5rem;
  width: 9rem;
  filter: drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.1));
}

.resources-tab {
  font-weight: 500;
  text-transform: none;
}

.resources-parent:hover .resources-tab,
.resources-parent:hover .raxIcon-arrow-drop-down::before {
  color: #1a64e2 !important;
  transition-property: color;
  transition-duration: 300ms;
}

.resources-parent:hover .raxIcon-arrow-drop-down {
  transform: rotate(180deg);
}

.resources-tab:hover ~ .resources-list,
.resources-list:hover {
  display: block;
}
.bell{
  padding: 5px;
  border-radius: 50%;
  position: relative;
  left: 5px;
}
.bell:hover{
  padding: 5px;
  border-radius: 50%;
  background:#F5F5F5;
  position: relative;
  left: 5px;
}
</style>

<script>
import * as moment from 'moment';
// import breadCrumb from "../common/child/breadCrumb";
import appLogo from "@/components/ui/appLogo.vue";
import { mapGetters } from "vuex";
import warningModal from '@/plugins/mixins/warningModal';
import PaymentMethodUpdate from '../pricing/paymentMethodUpdate.vue';
import { RaxSpecialOffer, RAxReferBanner } from '../literature/constants';
import { UserStatus, AppSheet } from '~/shared/constants';
import { PlanType, ReviewAssistant, MixpanelEventGroup, MixpanelEvents } from "~/shared/constants";
import searchBox from '@/components/full-text-search/searchBox';

const delay = ms => new Promise(res => setTimeout(res, ms))

export default {
  components: {
    // breadCrumb,
    appLogo,
    PaymentMethodUpdate,
    searchBox
  },
  mixins:[warningModal],
  props: {
    displayLandingNav: {
      type: Boolean,
      default: false,
    },
    disableBtn: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      UserStatus,
      displaySidenav: false,
      isMounted: false,
      isAllowedToSeeOfferBanner:false,
      dealsBackgroundUrl: process.env.assetsUrl + 'img/pricing/offer.png',
      navItems: [
        // { title: "Features", value: "features", },
        // { title: "Pricing", value: "pricing" },
        // { title: "For Teams", value: "teams" },
        // { title: "For Reviewers", value: "review-assistant" },
        // { title: "For Librarians", value: "for-librarians" }
      ],
      assetsUrl: process.env.assetsUrl,
      landingUrl: process.env.landingUrl,
      isAllowedToSeeReferralBanner:false,
      showMobileMenu: false
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getData",
      isAuthenticated: "auth/isAuthenticated",
      haveFullAccess: "subscription/haveFullAccess",
      isTrial: "subscription/isTrial",
      haveNoPreviousPaidPlan: "subscription/haveNoPreviousPaidPlan",
      hasPlanExpired: 'subscription/hasPlanExpired',
      trialDaysRemain: "subscription/trialDaysRemain",
      subscriptionData:'subscription/getSubscriptionData',
      canShowSpecialOffer:'subscription/canShowSpecialOffer',
      profile: "profile/getData",
      affData: "aff/getData",
      isLibraryAccess: "library/getDiscoverAccess",
      haveLibraryId: "core/getLibraryId",
      getShowHeader: "core/getShowHeader",
      showPasswordSentBanner: "core/getShowPasswordSentBanner",
      releaseNoteData: "core/getReleaseNotes",
      canHighlightReleaseNote: "user/canHighlightReleaseNote",
      breadCrumbs: "core/breadCrumbs",
      headerTitle: "core/getHeaderTitle"
    }),
    isBanner(){
      let userBanner = ((this.isAuthenticated && this.subscriptionData && (!this.haveFullAccess || this.trialDaysRemain <= 10 || !this.user.emailVerified)) && this.isValidPageToShow && !this.showPasswordSentBanner && !this.isAllowedToSeeOfferBanner)&&((!this.user.emailVerified)||(this.haveNoPreviousPaidPlan && this.subscriptionData.isPayer)||((this.subscriptionData.isPaymentAttached || !this.haveNoPreviousPaidPlan)&&((this.subscriptionData.isPaymentFailing && !(this.hasPlanExpired && !this.haveNoPreviousPaidPlan))||(this.hasPlanExpired))))
      this.$emit("userBanner", userBanner);
      return userBanner;
    },
    routerName() {
      return this.$route.name;
    },
    icon() {
      return this.profile.profilePic ? "" : "person";
    },
    isImg() {
      return !this.icon;
    },
    profileImage() {
      return this.profile.profilePic
        ? this.assetsUrl + this.profile.profilePic
        : "";
    },
    isValidPageToShow(){
      const lists = ['auth-verify-email', 'auth', 'auth-signin', 'auth-signup', 'subscription' , 'subscription-payment', 'subscription-payment-success', 'newOnboarding'];
      if ((lists.findIndex(i=> i === this.$route.name) !== -1) || [UserStatus.KIDemoOld, UserStatus.KIDemoNew, UserStatus.ReadingDemo, UserStatus.RedditDemo].includes(this.user.onboardingStatus)) {
        return false;
      }
      return true;
    },
    showReferralModal() {
      let showReferral = this.isAuthenticated && this.user.emailVerified && this.subscriptionData &&
        this.isAllowedToSeeReferralBanner && !this.isAllowedToSeeOfferBanner && !this.showPasswordSentBanner &&
        ((this.isTrial && this.trialDaysRemain > 10) || (!this.isTrial && this.isReferralAllowed) ||
        (this.subscriptionData.isPaymentAttached && this.haveNoPreviousPaidPlan)); 
        this.$emit("showReferral", showReferral)

        return showReferral;
    },
    isReferralAllowed(){
      return this.$route.name == 'subscription';
    },
    shouldShowReferAndEarnBtn() {
      let shouldReferAndEarnButton = !this.isAllowedToSeeReferralBanner && this.subscriptionData.planType === PlanType.Individual;
      this.$emit("isReferAndEarn",shouldReferAndEarnButton);
      return shouldReferAndEarnButton;
    },
    reviewAssistantAccess() {
      return this.isAuthenticated && this.user && this.user.flags.reviewAssistantAccess;
    }
  },
  methods: {
    async submitClickHandler(){
      if(this.subscriptionData.category != 1){
        let data = await this.$axios.post(`${process.env.paymentServiceUrl}payment/auth/stripe/get-user-portal`,{});
        window.location.href = data.data.url;
      }
      else{
        if (!!this.user.emailVerified) {
          this.$router.push('/subscription');
        } else {
          this.verifyEmail();
        }
      }
    },
    async activateReview() {
      try {
        await this.$store.dispatch("user/setReviewerActivated");
        await this.$store.dispatch("reviewerProjects/fetch");
        const payload = {
          title: "Sample Conference",
          description: "This is a same conference to give you a feel.",
          year: new Date().getFullYear(),
          deadline: moment().add(90, "d").unix(),
          type: "conference"
        };
        await this.$store.dispatch("reviewerProjects/create", payload).catch();
      } catch (e) {
        console.log(e);
      }
      this.$emit('activateReview');
      this.$store.commit("user/setShowReviewOnboarding", true);
    },
    hideAppSheet() {
      this.$raxEventBus.$emit(AppSheet.Close);
    },
    async openReminderDrawer() {

      this.$mixpanel.track(
        MixpanelEvents[MixpanelEventGroup.Bot].Desk_Reminders
      );
      if (!this.botUserSignupModal('ReminderDrawer Clicked')) {
        return;
      }
      this.$store.commit("core/setData", {
        name: "reminderPanelOpen",
        data: false
      });
      await delay(100);
      this.$store.commit("core/setData", {
        name: "reminderDrawerOpen",
        data: true
      })
    },
    isOfferBannerClosed(){
      if (!localStorage) {
        return true;
      }
      const timeClosedAt = localStorage.getItem(RaxSpecialOffer);
      if (timeClosedAt) {
        try {
          const isSameDay = moment(timeClosedAt).isSame(moment().format('YYYY-MM-DD'), 'day');
          return isSameDay;
        } catch (error) {
          console.log(error);
        }
        return false;
      }
      return false;
    },
    closeOfferBar(){
      // set time stamp  mm-dd-yyyy
      localStorage.setItem(RaxSpecialOffer, moment().format('YYYY-MM-DD') );
      this.isAllowedToSeeOfferBanner = false;
      this.$mixpanel.track('Hellobar Closed', {
        reason: 'special-deal'
      });
    },
    logMixpanelForSubscriptionLink(){
      let reason = ''
       if (this.isTrial) {
         reason = `${this.trialDaysRemain} days left in trial end`;
       }else{
        reason = this.hasPlanExpired && !this.haveNoPreviousPaidPlan ? 'plan ended' : 'trial ended';
       }
      this.$mixpanel.track('Click - Subscribe From Hellobar', {
        reason
      });
    },
    gotoSubscribePage(){
      this.logMixpanelForSubscriptionLink();
      this.$router.push({ name: "subscription" });
    },
    gotoSubscribePageFromDeals(){
      this.$mixpanel.track('Click - Subscribe From Hellobar', {
        reason: 'special-deal'
      });
      this.$router.push({ name: "subscription" });
    },
    checkUserEmail(){
      this.$mixpanel.track('(Desk) Menu');
      if([UserStatus.KIDemoOld, UserStatus.KIDemoNew, UserStatus.ReadingDemo, UserStatus.RedditDemo].includes(this.user.onboardingStatus)){
        this.$store.commit("user/setLastAction", "Side drawer open from header");
        this.$store.commit("user/setShowEmailModal", 1);
      }
    },
    onHelpClicked(e) {

      if (!this.botUserSignupModal('Help Clicked')) {
        return;
      }
      this.$mixpanel.track('Help Clicked');
      $(`#help-rax`).MaterialMenu(
        {
          show: true,
          location: "left",
          position: "bottom left",
        },
        e.target
      );
    },
    async onClosePasswordSentBanner() {
      this.$store.commit("core/setData", {
        name: "showPasswordSentBanner",
        data: false,
      });
      await this.$axios.$post("/onboarding-events/createsignupevent", {
        step: 4,
        skip: 0,
      });
    },
    onReleaseNoteClicked(e) {
      this.$mixpanel.track('Check New Updates');
      if (this.user.onboardingStatus === UserStatus.RedditDemo) {
        this.$mixpanel.track(
          MixpanelEvents[MixpanelEventGroup.Bot].Desk_Help
        );
      }
       if (!this.botUserSignupModal('NewUpdate Clicked')) {
        return;
      }

      if (this.canHighlightReleaseNote) {
        this.$store.dispatch('core/upsertReleaseNoteDataLog', this.releaseNoteData);
        this.$store.commit('user/updateFlag', {key: 'highlightReleaseNotes', payload: false});
      }
      window.open(this.releaseNoteData.url, '_blank');
    },
    closeReferBanner() {
      this.isAllowedToSeeReferralBanner = false;
      localStorage.setItem(RAxReferBanner, false);
    },
    shouldShowReferralModal() {
      if (process.client) {
        const shouldShow = localStorage.getItem(RAxReferBanner);
        if(shouldShow == undefined || shouldShow == null) {
          return true;
        }
        return shouldShow == 'true' ? true: false;
      }
      return false
    },
  },
  watch:{
    $route() {
      this.isAllowedToSeeOfferBanner = this.canShowSpecialOffer && !this.isOfferBannerClosed();
    },
  },
  mounted(){
    console.log(this.isBanner);
    console.log(this.showReferralModal);
    this.isMounted = true;
    this.isAllowedToSeeOfferBanner = this.canShowSpecialOffer && !this.isOfferBannerClosed();
    this.isAllowedToSeeReferralBanner = this.shouldShowReferralModal();
  },
};
</script>
<style scoped>

.raxIcon-mail:before {
  color: #1a64e2;
}

.fix-padding {
  margin-left: -16px !important;
  border: #2E3E4E;
  opacity: 0.1;
}

.active-release-notes {
  color: var(--color-primary);
  background-color: #D7F9FC;
  border-radius: 18px;
}

.release-notes {
  font-size: 14px;
  text-transform: none;
}

.unfocus{
  margin-right: 16% !important;
}
</style>
