export default function({ store, env, redirect, route }) {
  const subscriptionData = store.getters["subscription/getSubscriptionData"];
  if (!subscriptionData) {
    return redirect(env.baseUrl + "profile");
  }
 
  if (subscriptionData && (!subscriptionData.isPayer || subscriptionData.hasPendingUpfrontPlanAssignment)) {
    return redirect(env.baseUrl + "profile");
  }
}
