export default function({ store, error }) {
  const roles = store.getters["user/getData"]["roles"];
  let index = -1;
  if( roles && roles.length) {
    index = roles.indexOf("FACULTY")
  }
  if (index === -1) {
    return error({ statusCode: 401, message: "Unauthorized Request" });
  }
}
