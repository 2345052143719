var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Sidebar",
    {
      attrs: {
        isPanelOpen: _vm.isReminderDrawerOpen,
        isLeftSidePanel: false,
        hasBackdrop: true,
        sideBarPanelStyle: {
          "box-shadow": "-6px 0px 10px rgba(4, 21, 76, 0.24)",
          width: "auto",
          position: "fixed",
          height: "100%",
          overflow: "hidden"
        },
        sideBarBackDropStyle: {
          background: "#000000",
          opacity: 0.3
        }
      },
      on: {
        onBackdropClick: function($event) {
          return _vm.setReminderDrawerOpen(false)
        }
      }
    },
    [
      _c("div", { staticClass: "tw-w-120" }, [
        _vm.isReminderDrawerOpen
          ? _c(
              "div",
              {
                staticClass:
                  "tw-absolute tw-text-black-72 tw-h-full tw-overflow-auto tw-w-120 tw-z-20 tw-right-0 tw-bg-white",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.clickedOnDrawer($event)
                  }
                }
              },
              [
                _c(
                  "header",
                  {
                    staticClass:
                      "tw-sticky tw-top-0 tw-pt-3 tw-z-40 tw-bg-white"
                  },
                  [
                    _c("span", {
                      staticClass:
                        "tw-absolute tw-right-2 tw-top-2 tw-p-2 raxIcon raxIcon-close raxIcon--black56 tw-cursor-pointer",
                      on: {
                        click: function($event) {
                          return _vm.setReminderDrawerOpen(false)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tw-my-3 tw-mx-6 tw-font-medium" },
                      [_vm._v("\n          Reminders\n        ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "tw-h-12 tw-flex tw-items-center tw-w-full tw-border-0 tw-border-b tw-border-solid tw-border-black-6"
                      },
                      _vm._l(_vm.tabs, function(item) {
                        return _c(
                          "span",
                          {
                            key: item,
                            staticClass:
                              "tw-h-full tw-flex tw-justify-center tw-transition-colors tw-duration-300 tw-items-center tw-w-32 tw-text-sm tw-cursor-pointer tw-text-center",
                            class: {
                              "tw-font-medium tw-text-blue-primary tw-border-0 tw-border-b-2 tw-border-solid tw-border-blue-primary":
                                _vm.currentTab === item,
                              "tw-text-black-56 hover:tw-text-blue-primary":
                                _vm.currentTab !== item
                            },
                            on: {
                              click: function($event) {
                                return _vm.switchTab(item)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " + _vm._s(item) + "\n          "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
                ),
                _vm._v(" "),
                _vm.isReminderDrawerOpen
                  ? _c(_vm.isEditComp, { tag: "component" })
                  : _vm._e()
              ],
              1
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }