<template>
  <div
    v-if="
      data.hasSub
        ? data.subBucketData &&
          data.subBucketData.length &&
          data.subBucketData[0].data &&
          data.subBucketData[0].data.length
        : data.data && data.data.length
    "
    class="summary-item-container"
    @mouseup="onMouseUp"
    :class="{ 'tw-mb-4 summary-root': selectedLevel === 0 }"
    :section-key="selectedLevel === 0 && data.sectionKey"
  >
    <h1
      class="section-name tw-flex tw-items-center tw-relative tw-pr-4 tw-select-none"
      :class="{
        'tw-text-base tw-text-black-72 tw-font-semibold tw-px-2':
          selectedLevel !== 0,
        'tw-text-lg tw-font-semibold tw-text-black-80 sticky-header tw-pt-4':
          selectedLevel === 0,
        'tw-cursor-pointer tw-py-2 hover:tw-bg-black-4': isCollapsible,
        'tw-mb-4': !isCollapsed,
      }"
      @click="toggleCollapse"
    >
      <i
        class="raxIcon tw-px-1"
        v-if="isCollapsible"
        :class="{
          'raxIcon-chevron_right': isCollapsed,
          'raxIcon-chevron_down': !isCollapsed,
        }"
      />
      <span :style="isInvalidHeading ">
        {{
          store === "tldr"
            ? initCap(updateInvalidHeading(data.sectionName))
            : data.sectionName
        }}
      </span>
      <span
        v-if="selectedLevel === 0 && !noQuickReadData && !isCollapsed && showHideDetails"
        class="action-btn tw-cursor-pointer tw-text-sm tw-font-normal tw-absolute tw-right-0 tw-mr-4 tw-py-1 tw-px-4 tw-rounded"
        :class="{
          'hover:tw-bg-blue-light tw-text-blue-primary': showQuickRead,
          'hover:tw-bg-black-12 tw-text-black-56': !showQuickRead,
        }"
        @click.prevent.stop="toggleView()"
        >{{ !showQuickRead ? "Hide details" : "Show details" }}</span
      >
    </h1>
    <div class="summary-selectable-content">
      <div ref="summaryContainerDetail">
      <transition name="fade">
        <div
          v-if="!showQuickRead || selectedLevel === 1 || noQuickReadData"
        >
          <ul
            v-if="data.hasSub && (isCollapsible ? !isCollapsed : true)"
            class="tw-ml-6 overview"
          >
            <li>
              <summary-item
                v-for="item in data.subBucketData"
                :data="item"
                :key="item.sectionKey"
                :id="'summary-subitem-' + item.sectionKey"
                :parent="data.sectionKey"
                :selectedLevel="1"
                :isCollapsible="false"
                :summaryType="summaryType"
                :source="source"
                @onPinRequest="$emit('onPinRequest', $event)"
                @openMoreOption="onNestedOpen"
              />
            </li>
          </ul>
          <div
            v-if="!data.hasSub && (isCollapsible ? !isCollapsed : true)"
            class="sub-item-statement"
            :class="{
              'tw-ml-8': isCollapsible && selectedLevel === 1,
              'tw-ml-6': selectedLevel === 0,
            }"
          >
            <SubItem
              :ref="'subItem' + item.key"
              :id="'statement-'+ item.key"
              v-for="item in data.data"
              :key="item.key"
              :data="item"
              :store="store"
              @openPinOption="openPinOption"
            />
          </div>
        </div>
      </transition>
      </div>
      <div ref="summaryContainerQuick">
      <transition name="fade">
        <div v-if="showQuickRead || selectedLevel === 1">
          <ul
            v-if="
              quickReadData &&
              quickReadData.hasSub &&
              (isCollapsible ? !isCollapsed : true)
            "
            class="tw-ml-6 detailed"
          >
            <li>
              <summary-item
                v-for="item in quickReadData.subBucketData"
                :data="item"
                :key="item.sectionKey"
                :ref="item.sectionKey"
                :selectedLevel="1"
                :isCollapsible="false"
                :summaryType="summaryType"
                @onPinRequest="$emit('onPinRequest', $event)"
                @openMoreOption="onNestedOpen"
              />
            </li>
          </ul>
          <div
            v-if="
              quickReadData &&
              !quickReadData.hasSub &&
              (isCollapsible ? !isCollapsed : true)
            "
            class="sub-item-statement"
            :class="{
              'tw-ml-8': isCollapsible && selectedLevel === 1,
              'tw-ml-6': selectedLevel === 0,
            }"
          >
            <SubItem
              :ref="'subItem' + item.key"
              v-for="item in quickReadData.data"
              :key="item.key"
              :data="item"
              :store="store"
              @openPinOption="openPinOption"
            />
          </div>
        </div>
      </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import summaryAnnotations from '~/plugins/mixins/summaryAnnotations';
import { ActionItems, AnnotationSourceType, AnnotationType, AnnotationTypeEnum, AnnotationTypeMap, SummaryTypes } from '../constants';
import SubItem from "./summarySubItem";
import { initCap } from "@/assets/js/commonHelper";
import { UserStatus } from "~/shared/constants";

export default {
  name: "SummaryItem",
  mixins:[summaryAnnotations],
  components: {
    SubItem,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    quickReadData: {
      type: Object,
      default: null,
    },
    selectedLevel: {
      type: Number,
      default: 0,
    },
    parent: {
      type: String,
      default: null,
    },
    isCollapsible: {
      type: Boolean,
      default: false,
    },
    shouldBeCollapsed: {
      type: Boolean,
      default: true,
    },
    summaryType: {
      type: String,
      required: true,
    },
    defaultDetailMode: {
      type: Boolean,
      default: false,
    },
    source: {
      type: Number,
      required: true,
    },
    store: {
      type: String,
      default: "summarizer"
    }
  },
  data() {
    return {
      isCollapsed: true,
      UserStatus,
      showQuickRead: !this.defaultDetailMode,
    };
  },
  computed: {
    ...mapState({
      getSelectedStatementInView(state, getters) {
        return getters[`${this.store}/getSelectedStatementInView`];
      },
      getBriefSummary(state, getters) {
        return this.store === "tldr" ? [] : getters[`${this.store}/getBriefSummary`];
      },
      getPinnedData(state, getters) {
        return getters[`${this.store}/getPinnedData`];
      },
      user(state, getters) {
        return getters["user/getData"];
      },
    }),
    ...mapGetters({
      user: "user/getData"
    }),
    isInvalidHeading() {
      return this.data.sectionName === "HEADING NOT DETECTED" ||
        this.data.sectionName === "UNDETECTED HEADING" ||
        this.data.sectionName === "";
    },
    annotationStore() {
      return this.store === "tldr" ? "tldrAnnotations" : "kiAnnotations";
    },
    noQuickReadData() {
      if (!this.quickReadData) {
        return true;
      }
      return !(this.quickReadData.hasSub
        ? this.quickReadData.subBucketData.length
        : this.quickReadData.data.length);
    },
    pinnedKeys() {
      return this.getPinnedData.map((j) => j.statementKey);
    },
    showHideDetails(){
      if(this.data.hasSub){
        if(this.quickReadData.hasSub && this.data && this.data.subBucketData){
          if(this.data.subBucketData.length===this.quickReadData.subBucketData.length){
            for(let index in this.data.subBucketData){
              let data = this.data.subBucketData[index].data;
              let quickReadData = this.quickReadData.subBucketData[index].data;
              if(!data && !quickReadData){
                if(this.data.subBucketData[index].text!=this.quickReadData.subBucketData[index].text) return true;
              }
              else if(!quickReadData){
                return true; 
              }
              else if(!data){
                return true;
              }
              else{
                if(data.length!==quickReadData.length) return true;
                else {
                  for(let i in data){
                    if(data[i].text!=quickReadData[i].text) return true;
                  }
                }
              }
            }
            return false;
          }
          return true;
        }
        else{
          if(this.data.subBucketData.length===this.quickReadData.data.length){
            for(let index in this.data.subBucketData){
              let data = this.data.subBucketData[index].data;
              let quickReadData = this.quickReadData.data[index].data;
              if(!data && !quickReadData){
                if(this.data.subBucketData[index].text!=this.quickReadData.data[index].text) return true;
              }
              else if(!quickReadData){
                return true; 
              }
              else if(!data){
                return true;
              }
              else{
                if(data.length!==quickReadData.length) return true;
                else {
                  for(let i in data){
                    if(data[i].text!=quickReadData[i].text) return true;
                  }
                }
              }
            }
            return false;
          }
          return true;
        }
      }
      else{
        if(this.quickReadData.hasSub){
          if(this.data.data.length===this.quickReadData.subBucketData.length){
            for(let index in this.data.data){
              let data = this.data.data[index].data;
              let quickReadData = this.quickReadData.subBucketData[index].data;
              if(!data && !quickReadData){
                if(this.data.data[index].text!=this.quickReadData.subBucketData[index].text) return true;
              }
              else if(!quickReadData){
                return true; 
              }
              else if(!data){
                return true;
              }
              else{
                if(data.length!==quickReadData.length) return true;
                else {
                  for(let i in data){
                    if(data[i].text!=quickReadData[i].text) return true;
                  }
                }
              }
            }
            return false;
          }
          return true;
        }
        else{
          if(this.data.data.length===this.quickReadData.data.length){
            for(let index in this.data.data){
              let data = this.data.data[index].data;
              let quickReadData = this.quickReadData.data[index].data;
              if(!data && !quickReadData){
                if(this.data.data[index].text!=this.quickReadData.data[index].text) return true;
              }
              else if(!quickReadData){
                return true; 
              }
              else if(!data){
                return true;
              }
              else{
                if(data.length!==quickReadData.length) return true;
                else {
                  for(let i in data){
                    if(data[i].text!=quickReadData[i].text) return true;
                  }
                }
              }
            }
            return false;
          }
          return true;
        }
      }
    }
  },
  watch: {
    shouldBeCollapsed: {
      immediate: true,
      handler(val) {
        this.isCollapsed = val;
      },
    },
    getSelectedStatementInView(data) {
      if (
        this.store === "tldr" &&
        data.sectionKey !== this.data.sectionKey
      ) {
        return;
      }
      let isDetailedSectionClicked = false;
      // have key in detailed but not in data
      if (this.showQuickRead && this.data.sectionKey === data.parent) {
        isDetailedSectionClicked = !(this.getBriefSummary && this.getBriefSummary.find(
          (j) =>
            j.sectionKey === data.parent &&
            (j.hasSub
              ? j.subBucketData.find((k) => k.sectionKey === data.sectionKey)
              : false)
        ));
        // this.data.subBucketData.find(j=> j.sectionKey === data.sectionKey)
      }
      const subSection = this.data.hasSub
        ? this.data.subBucketData.map((j) => j.sectionKey)
        : [];
      const isSubSectionClicked =
        subSection.findIndex((i) => i == data.sectionKey) > -1;
      const canOpen =
        data.sectionKey === this.data.sectionKey || isSubSectionClicked;
      this.isCollapsed = this.isCollapsed && !canOpen;
      const self = this;
      if (!canOpen && !isDetailedSectionClicked && this.store !== 'tldr') {
        return;
      }
      let childElement;
      if (isDetailedSectionClicked && !this.parent && this.showQuickRead) {
        this.showQuickRead = false;
        // Add delay till dom is loaded
        setTimeout(() => {
          childElement = self.$el.querySelector(
            `#summary-subitem-${data.sectionKey}`
          );
        }, 100);
      }

      setTimeout(() => {
        const container = self.$el.closest(
          ".summary-viewer-container.rx-summary-viewer"
        );
        if (childElement) {
          const el = self.$el.querySelector(
            `#summary-subitem-${data.sectionKey}`
          );
          if (container) {
            container.scrollTo({
              top: el.offsetTop > 140 ? el.offsetTop - 180 : el.offsetTop,
              behavior: "smooth",
            });
          }
        } else {
          const el = self.$el;
          if (container) {
            let top = el.offsetTop;
            if (this.store === 'tldr' && this.source === 1) {
              top -= 130;
            } else if (this.store === 'summarizer') {
              top = el.offsetTop > 140 ? el.offsetTop - 180 : el.offsetTop;
            }
            container.scrollTo({
              top,
              behavior: "smooth",
            });
          }
        }
      }, 150);
    },
  },
  methods: {
    initCap(item) {
      return initCap(item);
    },
    updateInvalidHeading(item) {
      return item === "HEADING NOT DETECTED" || item === "" ? "UNDETECTED HEADING" : item;
    },
    toggleCollapse() {
      if (this.isCollapsible) {
        this.isCollapsed = !this.isCollapsed;
        const eventName = this.isCollapsed? 'Section Summary Collapsed': 'Section Summary Expanded';
        this.$mixpanel.track(eventName);
        if (!this.isCollapsed) {
          this.reRenderAnnotations();
        }
      }
    },
    getStatementMetaData(statementKey){
      let pinData = null;
      const data = this.showQuickRead && !this.noQuickReadData ? this.quickReadData : this.data;
      if (!data) {
        return;
      }

      if (data.hasSub) {
          // check in subBucketData
          const statement = data.subBucketData.find((k) =>
            k.data.some((p) => p.key == statementKey)
          );
          // get id from for loop
          console.log("statement opened", statement);
          if (statement) {
            pinData = {
              statementKey,
              sectionKey: data.sectionKey,
              summaryType:this.showQuickRead && !this.noQuickReadData
                ? SummaryTypes.Overview
                : SummaryTypes.Detailed,
            };
          }
        } else {
          const statement = data.data.find((k) => k.key == statementKey);

          if (statement) {
            pinData = {
              statementKey,
              sectionKey: data.sectionKey,
              summaryType: this.showQuickRead && !this.noQuickReadData
                ? SummaryTypes.Overview
                : SummaryTypes.Detailed,
            };
          }
        }

        return pinData;

    },
    getStatementMetaDataTldr(statementKey){
      let pinData = null;
      const data =  this.data;
      if (!data) {
        return;
      }

      const statement = data.data.find((k) => k.key == statementKey);
          if (statement) {
            pinData = {
              statementKey,
              sectionKey: data.sectionKey,
              sectionName: data.sectionName,
              text: statement.text
            };
          }

        return pinData;

    },
    updateSelectedSection(pinData){
        if (pinData) {
          this.$store.commit(`${this.annotationStore}/setSelectionSection`, pinData);
          this.$store.commit(`${this.store}/setLastPickedStatement`, pinData);
        }
    },
    onMouseUp(evt) {
      const targetEle = evt.target;
      const data = this.showQuickRead && !this.noQuickReadData ? this.quickReadData : this.data;
      if (!data) {
        return;
      }
      if (targetEle && targetEle.getAttribute("statement-key") || targetEle.classList.contains('RAx_KI_highlight_wrap') && targetEle.parentElement.getAttribute("statement-key")) {
        const statementKey = !targetEle.classList.contains('RAx_KI_highlight_wrap') ?  targetEle.getAttribute("statement-key") :  targetEle.parentElement.getAttribute("statement-key");
        let pinData = this.store === "tldr"? this.getStatementMetaDataTldr(statementKey) : this.getStatementMetaData(statementKey);
        // set this statement for create

        if (pinData) {
          // setSelectionSection
          // evt.preventDefault();
          this.updateSelectedSection(pinData);
        }
      }
      // targetEle.getAttribute('statement-key')
      // alert('opened mouseUp', this.data)
    },

    toggleView() {
      if (this.isCollapsible && this.isCollapsed) {
        this.isCollapsed = false;
      }
      this.showQuickRead = !this.showQuickRead;
      this.reRenderAnnotations();

      const self = this;
      setTimeout(() => {
        const container = self.$el.closest(
          ".summary-viewer-container.rx-summary-viewer"
        );
        if (container) {
          container.scrollTo({
            top:
              self.$el.offsetTop > 140
                ? self.$el.offsetTop - 120
                : self.$el.offsetTop,
            behavior: "smooth",
          });
        }
      }, 10);
      // Set Highlight on toggle
      // get list of annotations with all the statements id.
      // scroll to first child
    },
    onNestedOpen(data, element) {
      // this.$emit('onPinRequest', {statementKey: data.key, text: data.text, sectionKey: this.data.sectionKey} )
      this.$emit(
        "openMoreOption",
        { ...data, sectionKey: data.sectionKey },
        element
      );
    },
    openPinOption(data, element) {
      this.$emit(
        "openMoreOption",
        { statementKey: data.key, sectionKey: this.data.sectionKey },
        element
      );
    },
  },
  mounted(){
    //init highlight based on quick-view or full view
    if (this.selectedLevel == 0) {
      this.showQuickRead = !this.defaultDetailMode;
    }
  }
};
</script>
<style>
.sticky-header {
  @apply tw-sticky tw-z-2 tw-bg-white tw-border-b tw-border-solid tw-border-black-6;
}
</style>
