var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ModalContent", {
    staticClass: "tw-px-4",
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function() {
          return [
            _c("section", { staticClass: "tw-flex" }, [
              _c("h1", { staticClass: "tw-font-medium tw-text-black-72" }, [
                _vm._v("Payment method")
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "tw-absolute tw-right-0" }, [
                _c(
                  "i",
                  {
                    staticClass: "material-icons tw-cursor-pointer",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.$modal.hide("payment-update")
                      }
                    }
                  },
                  [_vm._v("\n          close\n        ")]
                )
              ])
            ])
          ]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "section",
              { staticClass: "tw-mb-6 tw-text-sm" },
              [
                _c("StripeCard", {
                  ref: "stripeCardRef",
                  attrs: {
                    "client-secret": _vm.clientSecret,
                    "is-loading": _vm.isLoading,
                    error: _vm.cardError
                  },
                  on: {
                    cardComplete: function($event) {
                      _vm.complete = $event
                    }
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c("div", { staticClass: "tw-flex tw-justify-end tw-mb-4" }, [
              _c(
                "button",
                {
                  staticClass:
                    "tw-flex tw-items-center tw-text-sm tw-border-none tw-px-4 tw-py-2 tw-mr-6 tw-bg-white tw-text-black-56",
                  on: { click: _vm.cancelClickHandler }
                },
                [_vm._v("\n        Cancel\n      ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "\n    tw-bg-blue-primary tw-text-white tw-px-4 tw-py-2 tw-text-base tw-font-medium\n    tw-transition-bg tw-transition-delay-100 tw-transition-ease tw-transition-500\n    tw-focus:outline-none tw-focus:shadow-outline\n    tw-rounded-sm\n    tw-border-none",
                  class: {
                    "tw-opacity-75 tw-cursor-not-allowed hover:tw-bg-primary":
                      !_vm.complete || _vm.isLoading,
                    "hover:tw-bg-blue-dark": _vm.complete
                  },
                  staticStyle: { "min-width": "80px" },
                  attrs: { disabled: !_vm.complete },
                  on: { click: _vm.pay }
                },
                [_vm._v("\n        Update\n      ")]
              )
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }