var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "summary-sub-item tw-relative tw-px-2",
      class: {
        pinned: _vm.isPinned
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "tw-my-1 tw-flex tw-flex-col",
          on: { mousedown: _vm.onStatementClick }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "tw-font-normal tw-text-base tw-text-black-72 tw-py-2 tw-relative",
              class: {
                "tw-bg-blue-light2 tw-px-2 tw-rounded-md": _vm.isPinned
              },
              attrs: { "statement-key": _vm.data.key }
            },
            [
              _c("div", { attrs: { "statement-key": _vm.data.key } }, [
                _vm._v(_vm._s(_vm.data.text))
              ])
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }