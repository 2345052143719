<template>
  <Sidebar
    :isPanelOpen="isReminderDrawerOpen"
    :isLeftSidePanel="false"
    :hasBackdrop="true"
    :sideBarPanelStyle="{
      'box-shadow': '-6px 0px 10px rgba(4, 21, 76, 0.24)',
      width: 'auto',
      position: 'fixed',
      height: '100%',
      overflow: 'hidden'
    }"
    :sideBarBackDropStyle="{
      background: '#000000',
      opacity: 0.3
    }"
    @onBackdropClick="setReminderDrawerOpen(false)"
  >
    <div class="tw-w-120">
      <div
        v-if="isReminderDrawerOpen"
        class="tw-absolute tw-text-black-72 tw-h-full tw-overflow-auto tw-w-120 tw-z-20 tw-right-0 tw-bg-white"
        @click.stop="clickedOnDrawer"
      >
        <header class="tw-sticky tw-top-0 tw-pt-3 tw-z-40 tw-bg-white">
          <span
            class="tw-absolute tw-right-2 tw-top-2 tw-p-2 raxIcon raxIcon-close raxIcon--black56 tw-cursor-pointer"
            @click="setReminderDrawerOpen(false)"
          />
          <div class="tw-my-3 tw-mx-6 tw-font-medium">
            Reminders
          </div>
          <div
            class="tw-h-12 tw-flex tw-items-center tw-w-full tw-border-0 tw-border-b tw-border-solid tw-border-black-6"
          >
            <span
              v-for="item in tabs"
              :key="item"
              class="tw-h-full tw-flex tw-justify-center tw-transition-colors tw-duration-300 tw-items-center tw-w-32 tw-text-sm tw-cursor-pointer tw-text-center"
              :class="{
                'tw-font-medium tw-text-blue-primary tw-border-0 tw-border-b-2 tw-border-solid tw-border-blue-primary':
                  currentTab === item,
                'tw-text-black-56 hover:tw-text-blue-primary':
                  currentTab !== item
              }"
              @click="switchTab(item)"
            >
              {{ item }}
            </span>
          </div>
        </header>
        <component v-if="isReminderDrawerOpen" :is="isEditComp" />
      </div>
    </div>
  </Sidebar>
</template>

<script>
import openFetchDialog from "@/plugins/mixins/openFetchDialog";
import Sidebar from "@/components/newUI/sideBar";
import { TourEvents, ReminderDrawer } from "~/shared/constants";
import { mapGetters } from "vuex";
import { MixpanelEventGroup, MixpanelEvents } from "~/shared/constants";

export default {
  components: {
    Sidebar
  },
  data() {
    return {
      isEditComp: false,
      tabs: ["Upcoming", "Overdue", "Completed"],
      currentTab: "Upcoming"
    };
  },
  mixins: [openFetchDialog],
  computed: {
    ...mapGetters({
      isReminderTourActive: "core/isReminderTourActive",
      isReminderDrawerOpen: "core/isReminderDrawerOpen"
    })
  },
  watch: {
    isReminderDrawerOpen(value) {
      if (this.isReminderTourActive && value) {
        this.$raxEventBus.$emit(TourEvents.ReminderDrawerOpened);
      }
      if (!value) {
        this.$raxEventBus.$emit(TourEvents.ReminderDrawerClosed);
      }
    }
  },
  async mounted() {
    this.isEditComp = false;
    this.openFetchDialog(`reminder/${this.currentTab}Reminders`);
    try {
      await this.$store.dispatch("reminders/fetch");
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    clickedOnDrawer() {
      this.$raxEventBus.$emit(ReminderDrawer.Click);
    },
    switchTab(item) {
      this.currentTab = item;
      this.isEditComp = false;
      this.openFetchDialog(`reminder/${this.currentTab}Reminders`);
    },
    setReminderDrawerOpen(value) {
      this.$store.commit("core/setData", {
        name: "reminderDrawerOpen",
        data: value
      });
      if (value) {
        this.$mixpanel.track(
          MixpanelEvents[MixpanelEventGroup.Reminders].ReminderDrawerOpened
        );
      }
    }
  }
};
</script>
