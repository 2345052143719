export default async function({ redirect, store, env, route }) {
  await store.dispatch("aff/fetch");
  await store.dispatch("researchAreas/fetch");
  const aff = store.getters["aff/getData"];
  const researchAreas = store.getters["researchAreas/getData"];
  if (researchAreas && researchAreas.researchAreas && aff && aff.id) {
    // everything is ok no need to redirect
  } else {
    let redirectUrl = env.baseUrl + route.fullPath.substring(1);
    redirectUrl = encodeURIComponent(redirectUrl);
    return redirect(env.baseUrl + "gettingstarted?continue=" + redirectUrl);
  }
}
