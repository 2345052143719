<template>
  <div
    class="reminder-list"
    :class="{
      'hover:tw-bg-black-6 tw-transition-colors tw-duration-300': [
        'upcoming',
        'overdue'
      ].includes(tab)
    }"
  >
    <div class="tw-flex tw-items-center" :id="'reminder-' + index">
      <div
        class="tw-flex tw-w-fit tw-items-center tw-mt-4 tw-ml-4"
        :class="{
          'tw-text-blue-primary tw-cursor-pointer': tab === 'upcoming',
          'tw-text-blue-primary': tab === 'td-reminders',
          'tw-text-red-primary tw-cursor-pointer': tab === 'overdue',
          'tw-text-black-72': tab === 'completed'
        }"
        @click.stop="
          $emit('editReminder', 'reminder-' + index, item.literature, item)
        "
      >
        <i
          v-if="tab !== 'completed'"
          class="material-icons tw-text-xl tw-mr-1 tw-p-1 tw-rounded-lg"
        >
          alarm
        </i>
        <span
          v-if="tab === 'completed'"
          class="raxIcon raxIcon-check raxIcon--black72 tw-mb-1"
        />
        <span> {{ formatTime(item.scheduledAt) }} </span>
        <span
          v-if="['upcoming', 'overdue'].includes(tab)"
          class="raxIcon raxIcon-edit tw-ml-2 tw-opacity-0 tw-transition-opacity tw-duration-300 tw-flex tw-items-center show-on-hover"
        />
      </div>
      <span
        v-if="['upcoming', 'overdue'].includes(tab)"
        class="tw-mt-4 tw-flex tw-items-center tw-opacity-0 tw-transition-opacity tw-duration-300 show-on-hover"
      >
        <button
          class=" tw-bg-blue-primary tw-ml-15 tw-text-white tw-leading-4 tw-px-4 tw-text-xs tw-h-6 tw-rounded"
          @click="updateData(1)"
        >
          Mark as complete
        </button>
        <span
          class="raxIcon raxIcon-delete tw-ml-8 tw-flex tw-items-center tw-cursor-pointer"
          @click="deleteReminder"
        />
      </span>
    </div>
    <div class="tw-mt-4 tw-ml-10 tw-mr-4 tw-flex tw-items-center">
      <template v-if="item.literature && item.literature.fileId">
        <span
          class="raxIcon raxIcon-pdf raxIcon--red tw-mr-2 tw-flex tw-items-center"
        />
        <a
          class="tw-truncate"
          :href="
            '/projects/' +
              item.project.slug +
              '/literatures/' +
              item.literature.slug +
              '?openReminder=1'
          "
          target="_blank"
          :title="item.literature && item.literature.title"
          @click="trackEvents('literature')"
        >
          {{ item.literature && item.literature.title }}
        </a>
      </template>
      <template v-else>
        <i class="material-icons tw-mr-2 tw-text-xl" style="margin-bottom: 1px">
          link
        </i>
        <span
          class="tw-truncate"
          :title="item.literature && item.literature.title"
        >
          {{ item.literature && item.literature.title }}
        </span>
      </template>
    </div>
    <div
      v-if="tab !== 'completed' || item.remark"
      class="tw-mt-2 tw-ml-10 tw-mr-4"
    >
      <resizable-textarea ref="resize">
        <textarea
          v-model="item.remark"
          rows="1"
          :disabled="!['upcoming', 'overdue'].includes(tab)"
          placeholder="add details about reminder..."
          class="tw-bg-black-4 tw-p-2 tw-flex tw-items-center tw-rounded tw-w-full tw-resize-none"
          :class="{
            'hover-white-bg tw-transition-colors tw-duration-300': [
              'upcoming',
              'overdue'
            ].includes(tab)
          }"
          @input="isChanges = true"
          @blur="updateData(2)"
        />
      </resizable-textarea>
    </div>
    <div
      class="tw-mt-2 tw-ml-10 tw-mr-4 tw-flex tw-items-center"
      :class="{ 'tw-mt-3': tab === 'completed' && !item.remark }"
    >
      <i
        class="material-icons tw-text-xl tw-text-black-40 tw-flex tw-items-center"
      >
        folder
      </i>
      <a
        class="tw-text-black-56 tw-text-xs tw-ml-1 tw-truncate tw-leading-4"
        :href="'/projects/' + item.project.slug + '/literatures/'"
        target="_blank"
        :title="item.project && item.project.title"
        @click="trackEvents('project')"
      >
        {{ item.project && item.project.title }}
      </a>
    </div>
    <hr class="tw-p-0 tw-w-full tw-mt-4" />
  </div>
</template>

<script>
import * as moment from "moment";
import ResizableTextarea from "@/components/ui/ResizableTextarea";
import { MixpanelEventGroup, MixpanelEvents } from "~/shared/constants";

const Update = {
  MarkAsComplete: 1,
  Remark: 2
};

export default {
  components: {
    ResizableTextarea
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number
    },
    tab: {
      type: String,
      default: "upcoming"
    }
  },
  data() {
    return {
      isChanges: false
    };
  },
  methods: {
    trackEvents(clickedOn) {
      this.$mixpanel.track(
        MixpanelEvents[MixpanelEventGroup.Reminders].ClickReminder,
        {
          clickedOn
        }
      );
    },
    async deleteReminder() {
      let message;
      try {
        await this.$store.dispatch("reminders/delete", {
          reqData: {
            id: this.item.id,
            status: "cancelled"
          },
          id: this.item.literature && this.item.literature.id,
          projectId: this.item.project && this.item.project.id
        });
        message = "Reminder deleted";
        this.$mixpanel.track(
          MixpanelEvents[MixpanelEventGroup.Reminders].DeleteReminder,
          {
            from: "upcoming-tab"
          }
        );
      } catch (e) {
        console.log(e);
        message = "Something went wrong";
      }
      this.showSnackbar(message);
    },
    async updateData(val) {
      let message;
      const reqData = {
        id: this.item.id,
        remark: this.item.remark
      };
      const storeData = {
        ...reqData,
        scheduledAt: this.item.scheduledAt,
        literature: this.item.literature,
        project: this.item.project
      };
      try {
        if (val === Update.MarkAsComplete) {
          reqData.status = "completed";
          storeData.status = reqData.status;
          await this.$store.dispatch("reminders/update", {
            reqData,
            storeData
          });
          message = "Reminder saved as completed";
          this.showSnackbar(message);
          this.$mixpanel.track(
            MixpanelEvents[MixpanelEventGroup.Reminders].CompleteReminder,
            {
              from: "upcoming-tab"
            }
          );
        } else if (val === Update.Remark && this.isChanges) {
          await this.$store.dispatch("reminders/update", {
            reqData,
            storeData
          });
          message = "Remark updated";
          this.isChanges = false;
          this.showSnackbar(message);
          this.$mixpanel.track(
            MixpanelEvents[MixpanelEventGroup.Reminders].UpdateReminder,
            {
              from: "upcoming-tab"
            }
          );
        }
      } catch (e) {
        console.log(e);
        message = "Something went wrong";
        this.showSnackbar(message);
      }
    },
    showSnackbar(message) {
      this.$store.commit("core/setSnackbar", {
        message
      });
      $("#snackbar").MaterialSnackbar("show");
    },
    formatTime(time) {
      return moment.unix(time).format("ddd, MMM DD - hh:mm A");
    }
  }
};
</script>

<style>
.reminder-list .raxIcon--red::before {
  color: #ea102a;
  margin-bottom: 2px;
  font-size: 20px;
}

.reminder-list .raxIcon-edit::before {
  font-size: 20px;
  margin-bottom: 4px;
}

.reminder-list .raxIcon-delete::before {
  font-size: 20px;
}

.reminder-list .raxIcon-check::before {
  margin-right: 6px;
  font-size: 20px;
}

.reminder-list:hover .show-on-hover {
  opacity: 100;
}

.reminder-list:hover .hover-white-bg {
  background-color: white;
}
</style>
