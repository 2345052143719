export default async function ({ redirect, store, req, route }) {
  const logout = route.query && route.query.logout;
  if (store.getters["auth/isAuthenticated"] && !logout) {
    return redirect("/");
  } else if (logout) {
    store.dispatch("auth/logout");
  } else {
    store.dispatch("auth/resetAll");
  }
}
