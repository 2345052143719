import Vue from 'vue'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_0c5b953f from 'nuxt_plugin_sentryserver_0c5b953f' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_b2bbb892 from 'nuxt_plugin_sentryclient_b2bbb892' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_axios_a4eeff0a from 'nuxt_plugin_axios_a4eeff0a' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_googleanalytics_336d666a from 'nuxt_plugin_googleanalytics_336d666a' // Source: ./google-analytics.js (mode: 'client')
import nuxt_plugin_corecomponents_4963358a from 'nuxt_plugin_corecomponents_4963358a' // Source: ../plugins/core-components.js (mode: 'all')
import nuxt_plugin_mixins_6f76c2a4 from 'nuxt_plugin_mixins_6f76c2a4' // Source: ../plugins/mixins.js (mode: 'all')
import nuxt_plugin_axios_3566aa80 from 'nuxt_plugin_axios_3566aa80' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_bus_5e46274a from 'nuxt_plugin_bus_5e46274a' // Source: ../plugins/bus (mode: 'all')
import nuxt_plugin_momenttimezoneinject_26b0a429 from 'nuxt_plugin_momenttimezoneinject_26b0a429' // Source: ../plugins/moment-timezone-inject.js (mode: 'all')
import nuxt_plugin_wisepops_aebb1fca from 'nuxt_plugin_wisepops_aebb1fca' // Source: ../plugins/wisepops.js (mode: 'client')
import nuxt_plugin_mixpanel_17e60a99 from 'nuxt_plugin_mixpanel_17e60a99' // Source: ../plugins/mixpanel.js (mode: 'client')
import nuxt_plugin_imageviewer_64af1bfc from 'nuxt_plugin_imageviewer_64af1bfc' // Source: ../plugins/image-viewer.js (mode: 'client')
import nuxt_plugin_vuejsmodal_0719e4a0 from 'nuxt_plugin_vuejsmodal_0719e4a0' // Source: ../plugins/vue-js-modal.js (mode: 'client')
import nuxt_plugin_ga_34d435b2 from 'nuxt_plugin_ga_34d435b2' // Source: ../plugins/ga.js (mode: 'client')
import nuxt_plugin_epicspinners_2646654c from 'nuxt_plugin_epicspinners_2646654c' // Source: ../plugins/epic-spinners.js (mode: 'client')
import nuxt_plugin_nuxtquillplugin_745506c8 from 'nuxt_plugin_nuxtquillplugin_745506c8' // Source: ../plugins/nuxt-quill-plugin.js (mode: 'client')
import nuxt_plugin_vuebar_663c5158 from 'nuxt_plugin_vuebar_663c5158' // Source: ../plugins/vuebar.js (mode: 'client')
import nuxt_plugin_vuetour_c0953762 from 'nuxt_plugin_vuetour_c0953762' // Source: ../plugins/vuetour.js (mode: 'client')
import nuxt_plugin_katexmin_2780ca58 from 'nuxt_plugin_katexmin_2780ca58' // Source: ../plugins/katex.min.js (mode: 'client')
import nuxt_plugin_charts_63c1ba68 from 'nuxt_plugin_charts_63c1ba68' // Source: ../plugins/charts.js (mode: 'client')
import nuxt_plugin_nuxtvideoplayerplugin_7826a9de from 'nuxt_plugin_nuxtvideoplayerplugin_7826a9de' // Source: ../plugins/nuxt-video-player-plugin.js (mode: 'client')
import nuxt_plugin_hotjar_6900f5d3 from 'nuxt_plugin_hotjar_6900f5d3' // Source: ../plugins/hotjar.js (mode: 'client')
import nuxt_plugin_vuetooltip_280ca29e from 'nuxt_plugin_vuetooltip_280ca29e' // Source: ../plugins/vue-tooltip.js (mode: 'client')
import nuxt_plugin_vuenotification_435fd7af from 'nuxt_plugin_vuenotification_435fd7af' // Source: ../plugins/vue-notification.js (mode: 'all')
import nuxt_plugin_vuetimepicker_15e959bf from 'nuxt_plugin_vuetimepicker_15e959bf' // Source: ../plugins/vue-timepicker.js (mode: 'client')
import nuxt_plugin_vuepdf_16ca6c3c from 'nuxt_plugin_vuepdf_16ca6c3c' // Source: ../plugins/vue-pdf.js (mode: 'client')
import nuxt_plugin_vuegoodtable_e6373c88 from 'nuxt_plugin_vuegoodtable_e6373c88' // Source: ../plugins/vue-good-table.js (mode: 'client')
import nuxt_plugin_vuedatepicker_026ca9fe from 'nuxt_plugin_vuedatepicker_026ca9fe' // Source: ../plugins/vue-datepicker.js (mode: 'client')
import nuxt_plugin_gtmtracker_21d6b382 from 'nuxt_plugin_gtmtracker_21d6b382' // Source: ../plugins/gtm-tracker.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)
// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render(h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true
      console.warn(`<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead`)
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>`
Vue.component(Nuxt.name, Nuxt)

// vue-meta configuration
Vue.use(Meta, {
  keyName: 'head', // the component option name that vue-meta looks for meta info on.
  attribute: 'data-n-head', // the attribute name vue-meta adds to the tags it observes
  ssrAttribute: 'data-n-head-ssr', // the attribute name that lets vue-meta know that meta info has already been server-rendered
  tagIDKeyName: 'hid' // the property name that vue-meta uses to determine whether to overwrite or append a tag
})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

async function createApp(ssrContext) {
  const router = await createRouter(ssrContext)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  const registerModule = store.registerModule
  store.registerModule = (path, rawModule, options) => registerModule.call(store, path, rawModule, Object.assign({ preserveState: process.client }, options))

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    router,
    store,
    nuxt: {
      defaultTransition,
      transitions: [ defaultTransition ],
      setTransitions(transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [ transitions ]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },
      err: null,
      dateErr: null,
      error(err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        const nuxt = this.nuxt || this.$options.nuxt
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) ssrContext.nuxt.error = err
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    route,
    next,
    error: app.nuxt.error.bind(app),
    store,
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  const inject = function (key, value) {
    if (!key) throw new Error('inject(key, value) has no key provided')
    if (typeof value === 'undefined') throw new Error('inject(key, value) has no value provided')
    key = '$' + key
    // Add into app
    app[key] = value

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) return
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Vue.prototype.hasOwnProperty(key)) {
        Object.defineProperty(Vue.prototype, key, {
          get() {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_0c5b953f === 'function') {
    await nuxt_plugin_sentryserver_0c5b953f(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_b2bbb892 === 'function') {
    await nuxt_plugin_sentryclient_b2bbb892(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_a4eeff0a === 'function') {
    await nuxt_plugin_axios_a4eeff0a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_googleanalytics_336d666a === 'function') {
    await nuxt_plugin_googleanalytics_336d666a(app.context, inject)
  }

  if (typeof nuxt_plugin_corecomponents_4963358a === 'function') {
    await nuxt_plugin_corecomponents_4963358a(app.context, inject)
  }

  if (typeof nuxt_plugin_mixins_6f76c2a4 === 'function') {
    await nuxt_plugin_mixins_6f76c2a4(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3566aa80 === 'function') {
    await nuxt_plugin_axios_3566aa80(app.context, inject)
  }

  if (typeof nuxt_plugin_bus_5e46274a === 'function') {
    await nuxt_plugin_bus_5e46274a(app.context, inject)
  }

  if (typeof nuxt_plugin_momenttimezoneinject_26b0a429 === 'function') {
    await nuxt_plugin_momenttimezoneinject_26b0a429(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_wisepops_aebb1fca === 'function') {
    await nuxt_plugin_wisepops_aebb1fca(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_mixpanel_17e60a99 === 'function') {
    await nuxt_plugin_mixpanel_17e60a99(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_imageviewer_64af1bfc === 'function') {
    await nuxt_plugin_imageviewer_64af1bfc(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuejsmodal_0719e4a0 === 'function') {
    await nuxt_plugin_vuejsmodal_0719e4a0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_ga_34d435b2 === 'function') {
    await nuxt_plugin_ga_34d435b2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_epicspinners_2646654c === 'function') {
    await nuxt_plugin_epicspinners_2646654c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_nuxtquillplugin_745506c8 === 'function') {
    await nuxt_plugin_nuxtquillplugin_745506c8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuebar_663c5158 === 'function') {
    await nuxt_plugin_vuebar_663c5158(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuetour_c0953762 === 'function') {
    await nuxt_plugin_vuetour_c0953762(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_katexmin_2780ca58 === 'function') {
    await nuxt_plugin_katexmin_2780ca58(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_charts_63c1ba68 === 'function') {
    await nuxt_plugin_charts_63c1ba68(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_nuxtvideoplayerplugin_7826a9de === 'function') {
    await nuxt_plugin_nuxtvideoplayerplugin_7826a9de(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_hotjar_6900f5d3 === 'function') {
    await nuxt_plugin_hotjar_6900f5d3(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuetooltip_280ca29e === 'function') {
    await nuxt_plugin_vuetooltip_280ca29e(app.context, inject)
  }

  if (typeof nuxt_plugin_vuenotification_435fd7af === 'function') {
    await nuxt_plugin_vuenotification_435fd7af(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuetimepicker_15e959bf === 'function') {
    await nuxt_plugin_vuetimepicker_15e959bf(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuepdf_16ca6c3c === 'function') {
    await nuxt_plugin_vuepdf_16ca6c3c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuegoodtable_e6373c88 === 'function') {
    await nuxt_plugin_vuegoodtable_e6373c88(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuedatepicker_026ca9fe === 'function') {
    await nuxt_plugin_vuedatepicker_026ca9fe(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gtmtracker_21d6b382 === 'function') {
    await nuxt_plugin_gtmtracker_21d6b382(app.context, inject)
  }

  // If server-side, wait for async component to be resolved first
  if (process.server && ssrContext && ssrContext.url) {
    await new Promise((resolve, reject) => {
      router.push(ssrContext.url, resolve, () => {
        // navigated to a different route in router guard
        const unregister = router.afterEach(async (to, from, next) => {
          ssrContext.url = to.fullPath
          app.context.route = await getRouteData(to)
          app.context.params = to.params || {}
          app.context.query = to.query || {}
          unregister()
          resolve()
        })
      })
    })
  }

  return {
    app,
    store,
    router
  }
}

export { createApp, NuxtError }
