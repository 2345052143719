<template>
  <ModalContent class="tw-px-4">
    <template v-slot:header>
      <section class="tw-flex">
        <h1 class="tw-font-medium tw-text-black-72" >Payment method</h1>
        <span class="tw-absolute tw-right-0">
          <i class="material-icons tw-cursor-pointer" @click.prevent="$modal.hide('payment-update')">
            close
          </i>
        </span>
      </section>
    </template>

    <template v-slot:body>
      <section class="tw-mb-6 tw-text-sm">
        <StripeCard ref="stripeCardRef" :client-secret="clientSecret" :is-loading="isLoading" @cardComplete="complete = $event" :error="cardError" />
      </section>
    </template>

    <template v-slot:footer>
      <div class="tw-flex tw-justify-end tw-mb-4">
        <button class="tw-flex tw-items-center tw-text-sm tw-border-none tw-px-4 tw-py-2 tw-mr-6 tw-bg-white tw-text-black-56" @click="cancelClickHandler">
          Cancel
        </button>
        <button style="min-width:80px;"
                :disabled="!complete"
                class="
      tw-bg-blue-primary tw-text-white tw-px-4 tw-py-2 tw-text-base tw-font-medium
      tw-transition-bg tw-transition-delay-100 tw-transition-ease tw-transition-500
      tw-focus:outline-none tw-focus:shadow-outline
      tw-rounded-sm
      tw-border-none"
                :class="{
                  'tw-opacity-75 tw-cursor-not-allowed hover:tw-bg-primary': !complete || isLoading ,
                  'hover:tw-bg-blue-dark': complete
                }"
                @click="pay"
        >
          Update
        </button>
      </div>
    </template>
  </ModalContent>
</template>

<script>
import ModalContent from '~/components/ui/modalContent';
import StripeCard from '~/components/pricing/stripeCard';
export default {
  components: {
    ModalContent,
    StripeCard,
  },
  data(){
    return {
      complete: false,
      isLoading: false,
      clientSecret: '',
      cardError:''
    }
  },
  beforeMount(){
    this.$axios.get("/stripe/create/payment-intent").then(res => {
        this.clientSecret = res.data.clientSecret;
    });
  },
  methods: {
    cancelClickHandler(){
      this.$modal.hide('payment-update');
    },
    pay() {
      this.isLoading = true
      this.cardError = '';
      this.$refs.stripeCardRef.pay().then(async (result) => {
           if (result.error) {
            // Display error.message in your UI.
            this.cardError = result.error.message;
            this.isLoading = false;
          } else {
            // The setup has succeeded. Display a success message.
            try {
              await this.$axios.post(`/stripe/set-default/payment-method`, {setupIntent: JSON.stringify(result.setupIntent)});
              this.$store.dispatch('subscription/fetchPaymentMethod');
              this.isLoading = false;
                this.$store.commit("core/setSnackbar", {
                  message: "Payment method updated successfully.",
                  body: ""
                });
                $("#snackbar").MaterialSnackbar("show");

              this.$modal.hide('payment-update');
            } catch (error) {
              this.isLoading = false;
              this.cardError = result.error.message;
              console.log('payment method save error');
            }
          }
      });
  },
  }

}
</script>
