export default function({ store, env, redirect, route }) {
  if (
    !store.getters["auth/isAuthenticated"] &&
    route.fullPath.substring(1, 21) !== "auth/forgot-password"&&
    route.fullPath.substring(1, 19) !== "auth/reset-password"
  ) {
    let redirectUrl = env.baseUrl + route.fullPath.substring(1);
    redirectUrl = encodeURIComponent(redirectUrl);
    return redirect(
      env.baseUrl + "auth/signin?service=assistant&continue=" + redirectUrl
    );
  }
}
