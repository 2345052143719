<template>
  <div class="credit-card-inputs tw-relative">
    <div class="tw-my-4">
      <div
        class="tw-py-1 tw-text-sm tw-font-normal tw-leading-snug tw-text-black-72 tw-mb-1"
      >Debit/Credit card number</div>
      <div class="tw-flex">
        <div ref="cardNumber" class="stripe-element card-number tw-w-3/4 tw-h-10" />
        <div class="tw-flex tw-items-center">
          <img :src="assetsUrl + 'img/pricing/visa-card.png'" class="tw-h-5" alt="visa" />
          <img :src="assetsUrl + 'img/pricing/master-card.png'" class="tw-h-5" alt="master card" />
        </div>
      </div>
    </div>
    <div class="tw-flex tw-items-center tw-my-4">
      <div class="tw-mr-10">
        <div
          class="tw-py-1 tw-text-sm tw-font-normal tw-leading-snug tw-text-black-72 tw-mb-1"
        >Expiry date</div>
        <div ref="cardExpiry" class="stripe-element card-expiry tw-h-10" />
      </div>
      <div>
        <div class="tw-py-1 tw-text-sm tw-font-normal tw-leading-snug tw-text-black-72 tw-mb-1">CVV</div>
        <div class="tw-flex tw-items-center">
          <div ref="cardCVC" class="stripe-element card-cvc tw-mr-2 tw-h-10" />
          <img :src="assetsUrl + 'img/pricing/credit-card.png'" class="tw-h-8" alt="CVV" />
        </div>
      </div>
    </div>
    <div class="tw-my-4 tw-w-3/4">
      <div
        class="tw-py-1 tw-text-sm tw-font-normal tw-leading-snug tw-text-black-72 tw-mb-1"
      >Name on the card</div>
      <div class="stripe-element">
        <input
          ref="cardName"
          v-model="nameOnCard"
          class="tw-border-none tw-w-full tw-placeholder-black-40 tw-text-base"
          type="name"
          placeholder="Enter the name on card"
        />
      </div>
    </div>
    <div v-if="hasFormError || error" class="tw-my-4">
      <div v-if="hasFormError" class="error tw-py-3 tw-px-4 tw-rounded tw-bg-red-lightest tw-text-xs">
        <strong class="tw-font-bold">Invalid information:</strong> Please confirm the details and try again. Contact us on
        <a href="mailto:support@read.enago.com" class="tw-underline tw-italic">support@read.enago.com</a> for any query.
      </div>
      <div v-if="!hasFormError && error" class="error tw-py-3 tw-px-4 tw-rounded tw-bg-red-lightest tw-text-xs">
        <strong class="tw-font-bold">Invalid information:</strong> {{error}}<br/> Contact us on
        <a href="mailto:support@read.enago.com" class="tw-underline tw-italic">support@read.enago.com</a> for any query.
      </div>
    </div>
    <loader v-if="isLoading || !hasStripeLoaded" />
  </div>
</template>

<script>
export default {
  head() {
    return {
      script: [
        {
          src: "https://js.stripe.com/v3/"
        }
      ]
    };
  },
  transitions: "fade",
  props: {
    clientSecret: {
      type: String,
      required: true
    },
    error:{
      type:String,
      default: ''
    },
    isLoading: Boolean,
  },
  data() {
    return {
      hasStripeLoaded: false,
      hasFormError: false,
      assetsUrl: process.env.assetsUrl,
      stripeKey: process.env.stripeKey,
      cardNumber: null,
      cardExpiry: null,
      cardCVC: null,
      nameOnCard: "",
      stripe: null,
      stripeElement: null,
      cardNumberElement: null,
      cardExpiryElement: null,
      cardCVCElement: null,
      options: {
        style: {
          base: {
            color: "#32325d",
            lineHeight: "24px",
            fontFamily: "Roboto, Segoe UI, sans-serif",
            fontWeight: "400",
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
              color: "#aab7c4"
            }
          },
          invalid: {
            color: "#fa755a",
            iconColor: "#fa755a"
          }
        }
        // see https://stripe.com/docs/stripe.js#element-options for details
      }
    };
  },
  watch: {
    nameOnCard(newVal, old) {
      this.hasCompleted();
    },
  },
  mounted() {
    const hasStripeLoaded = setInterval(() => {
      if (Stripe && this.clientSecret) {
        clearInterval(hasStripeLoaded);
        this.stripe = Stripe(this.stripeKey);
        this.stripeElement = this.stripe.elements();
        this.initStripeElements();
        this.hasStripeLoaded = true;
      }
    }, 1000);
  },
  methods: {
    initStripeElements() {
      this.cardNumberElement = this.stripeElement.create(
        "cardNumber",
        this.options
      );
      this.cardExpiryElement = this.stripeElement.create(
        "cardExpiry",
        this.options
      );
      this.cardCVCElement = this.stripeElement.create("cardCvc", this.options);

      this.cardNumberElement.on("change", event => {
        this.cardNumber = event.complete;
        this.hasCompleted();
        this.hasFormError = false;
        if (event.complete) {
          this.cardExpiryElement.focus();
        } else if (event.error) {
          this.hasFormError = true;
          this.$emit("cardHandler", { type: "error", data: event.error });
        }
      });
      this.cardExpiryElement.on("change", event => {
        this.cardExpiry = event.complete;
        this.hasCompleted();
        this.hasFormError = false;
        if (event.complete) {
          this.cardCVCElement && this.cardCVCElement.focus();
        } else if (event.error) {
          this.hasFormError = true;
          this.$emit("cardHandler", { type: "error", data: event.error });
        }
      });
      this.cardCVCElement.on("change", event => {
        this.cardCVC = event.complete;
        this.hasCompleted();
        this.hasFormError = false;
        if (event.complete) {
          this.$refs.cardName.focus();
        } else if (event.error) {
          this.hasFormError = true;
          this.$emit("cardHandler", { type: "error", data: event.error });
        }
      });

      this.cardNumberElement.mount(this.$refs.cardNumber);
      this.cardExpiryElement.mount(this.$refs.cardExpiry);
      this.cardCVCElement.mount(this.$refs.cardCVC);
    },
    pay() {
      return this.stripe.confirmCardSetup(this.clientSecret, {
        payment_method: {
          card: this.cardNumberElement,
          billing_details: {
            name: this.nameOnCard
          }
        }
      });
    },
    hasCompleted() {
      const hasCompleted =
        this.cardNumber &&
        this.cardExpiry &&
        this.cardCVC &&
        this.nameOnCard.trim().length > 0;
      this.$emit("cardComplete", hasCompleted);
    }
  }
};
</script>

<style>
.stripe-element {
  @apply tw-rounded-sm tw-border tw-border-solid tw-border-black-16 tw-px-4 tw-py-2;
}
.stripe-element.card-expiry {
  width: 100px;
}
.stripe-element.card-cvc {
  width: 64px;
}
</style>
