const defaultMixpanelState = () => {
    return {
      from: "",
      location:"",
      targetTab:"",
      rygMenuSelected:false
    };
  };
  
  export default {
    namespaced: true,
    state: defaultMixpanelState,
    mutations: {
      setData(state, data) {
        state[data.key] = data.value;
      },
      resetState(state) {
        Object.assign(state, defaultAffState());
      }
    },
  
    actions: {
      async fetch({ commit }) {
        let { data } = await this.$axios.get(`/showusercurrentaffiliations`);
        commit("setData", data);
      },
  
      async update({ commit, dispatch }, inputs) {
        const { data } = await this.$axios.put(
          `/usercurrentaffiliations`,
          inputs
        );
        commit("setData", data.length ? data[0] : data);
        // update subscription data as per new role
        dispatch('subscription/fetch', {}, { root: true });
        dispatch('subscription/fetchPlanList', {}, { root: true });
      },
  
      async create({ commit }, inputs) {
        const { data } = await this.$axios.post(
          `/usercurrentaffiliations`,
          inputs
        );
        commit("setData", data.length ? data[0] : data);
      },
  
      async verifyFacultyManually({ commit }) {
        let { data } = await this.$axios.get(`/verify/faculty/manually`);
      }
    },
  
    getters: {
      getFrom(state) {
        return state.from;
      },
  
      getLocation(state) {
        return state.location;
      },
      getTargetTab(state){
        return state.targetTab;
      },
      getRygMenuSelected(state){
        return state.rygMenuSelected;
      },
    }
  };
  