const defaultResearchAreaState = () => {
  return {
    data: {},
    suggestions: [],
  }
};

export default {
  namespaced: true,
  state: defaultResearchAreaState,

  mutations: {
    setData(state, data) {
      state.data = data;
    },
    setSuggestions(state, data) {
      state.suggestions = data;
    },
    resetState(state) {
      Object.assign(state, defaultResearchAreaState());
    }
  },

  actions: {
    async fetch({ commit }) {
      let { data } = await this.$axios.get(`/showuserresearchareas`);
      commit("setData", data);
    },

    async fetchSuggestions({ commit }) {
      let data = [];
      try {
        ({ data } = await this.$axios.get(`/userresearchareas/suggestions`));
      } catch (error) {
        data = [];
      }
      commit("setSuggestions", data);
    },

    async update({ commit }, inputs) {
      if (!inputs.researchAreas) {
        throw ({message: "Research areas can not be blank"});
      }
      const { data } = await this.$axios.put(`/userresearchareas`, inputs);
      commit("setData", data.length? data[0] : data);
    }
  },

  getters: {
    getData(state) {
      return state.data;
    },
    getSuggestions(state) {
      return state.suggestions;
    }
  }
};
