export default function({ $axios, redirect, store, $sentry }) {
  $axios.onRequest(config => {
    if (store.getters["auth/isAuthenticated"]) {
      config.headers.common["x-access-token"] = store.getters["auth/botToken"] || store.getters["auth/token"];
      if(store.getters["auth/raxToken"])
      config.headers.common["x-rax-token"] = store.getters["auth/raxToken"];
    }
  });

  $axios.interceptors.response.use((response) => {
    return response;
  }, async function (error) {
    if(error.response&&error.response.status==401&&error.response.data&&error.response.data.description=='access token expired'){
      let originalRequest = error.config;
      try{
        let data  = await $axios.$post("auth/refresh-token", {"refresh_token":store.getters["auth/getRefreshToken"]});
          await store.dispatch("auth/updateAccessToken", data);
          if (store.getters["auth/isAuthenticated"]) {
            originalRequest.headers["x-access-token"] = store.getters["auth/botToken"] || store.getters["auth/token"];
            if(store.getters["auth/raxToken"])
            originalRequest.headers["x-rax-token"] = store.getters["auth/raxToken"];
          }
          return $axios(originalRequest);
      }
      catch(err){
        console.log("this is the store", store);
        console.log("this is the error", err);
        store.dispatch("auth/logout");
        redirect("/auth/signin");
      }
    }
    else if(error.response&&error.response.status==401&&error.response.data&&(error.response.data.description=='invalid access token'||error.response.data.description=='invalid rax token')){
      console.log("this is the error", error);
      store.dispatch("auth/logout");
      redirect("/auth/signin");
    }
    $sentry && $sentry.captureException(error);
    return Promise.reject(error.response);
  });

}