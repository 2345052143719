import Vue from "vue";

const defaultSuggestionsState = () => {
  return {
    data: {},
    selectedItems: {},
    selectAll: false,
    hideNewCount: false,
    favoriteCount: null,
    projectFeedsCitaion: null,
    selectedItems: {},
    feedCount:0,
    recommendationCount:0
  };
};

export default {
  namespaced: true,
  state: defaultSuggestionsState,

  mutations: {
    setData(state, data) {
      state["data"] = data;
    },
    resetState(state) {
      Object.assign(state, defaultSuggestionsState());
    },
    setDataByKey(state, obj) {
      state[obj.key] = obj.data;
    },
    selectAllLiteratures(state, list) {
      state.selectAll = true;
      list.forEach(item => {
        Vue.set(state.selectedItems, item._id, true);
      });
    },
    updateSelectedItems(state, obj){
      if (obj.val) {
        this.commit(
          "suggestions/setDataByKey",
          {
            key: "selectedItems",
            data: { ...state.selectedItems, [obj.id]: true }
          },
          { root: true }
        );
      } else {
        Vue.delete(state.selectedItems, obj.id);
      }
    },
    unselectAllLiteratures(state) {
      state.selectedItems = {};
      state.selectAll = false;
    },
    setDataByKey(state, obj) {
      state[obj.key] = obj.data;
    },
  },
  actions: {
    async bulkDownload({state,rootGetters}){
      try{
        const suggestionsList = [
          ...rootGetters["advanceRecommendations/getLiteratures"],
          ...rootGetters["projectFeeds/getLiteratures"]
        ];
        const selectedItems = Object.keys(state.selectedItems);
        let selectedSuggestions = suggestionsList.filter(suggestion=>selectedItems.includes(suggestion._id));
        const pdfDownloadParams=[];
        selectedSuggestions.forEach(suggestion=>{
          if(suggestion.pdfUrls&&suggestion.pdfUrls.length) pdfDownloadParams.push(suggestion);
        });
        const { data } = await this.$axios.post("/literatures/bulk/download", {
            pdfDownloadParams,
        });  
        const myPromise = new Promise((resolve, reject) => {
          resolve({
            resourceUrl: data.resourceUrl,
            size: data.size
          });
        });
        return myPromise;
      }
       catch (e) {
        throw e;
      }
    },
    async exportSuggestions({ state, commit, dispatch },{format,docIds,title}){

      try {

        var response = await this.$axios.get(`/export-project-feeds/${format}`, {
          params:{docIds}
        }).catch(error => {
          return Promise.reject(error);
        });
        let x = new Date();
        let date = ((x.getMonth()<10?"0"+x.getMonth().toString():x.getMonth().toString())+"-"+(x.getDate()<10?"0"+x.getDate().toString():x.getDate().toString())+"-"+(x.getFullYear().toString()));
        if(title){
          title = `${title}_${format}_${date}.${format}`
        }
        else{
          title = `literature_${format}_${date}.${format}`
        }
        if(window.showSaveFilePicker){
          let data = new Blob([response.data]);
          const handle = await window.showSaveFilePicker({suggestedName: title});
          const writable = await handle.createWritable();
          await writable.write( data );
          writable.close();
        }
        else{
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', title); //or any other extension
          link.setAttribute("target", "_blank");
          link.click();
        }

      } catch (e) {
        throw new Error(e.message);
      }
    },
    async getSuggestionsCitation({ state, dispatch },{docIds,isDownload,target,title,type}){
      try{
        if(!isDownload){
          this.commit(
            "suggestions/setDataByKey",
            {
              key: "projectFeedsCitaion",
              data: null
          });
          let citationData = await this.$axios.get("/export-project-feeds/stylise", {
            params:{docIds,isDownload,target}
          });
          this.commit(
            "suggestions/setDataByKey",
            {
              key: "projectFeedsCitaion",
              data: citationData.data.formatCitation
            });
        }
        else{
          var response = await this.$axios.get(`/export-project-feeds/stylise`, {
            params:{docIds,isDownload,target}
          });
          let x = new Date();
          let date = ((x.getMonth()<10?"0"+x.getMonth().toString():x.getMonth().toString())+"-"+(x.getDate()<10?"0"+x.getDate().toString():x.getDate().toString())+"-"+(x.getFullYear().toString()));
          if(title){
            title = `${title}_${type}_${date}.txt`
          }
          else{
            title = `literature_${type}_${date}.txt`
          }
          if(window.showSaveFilePicker){
            let data = new Blob([response.data]);
            const handle = await window.showSaveFilePicker({suggestedName: title});
            const writable = await handle.createWritable();
            await writable.write( data );
            writable.close();
          }
          else{
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', title); //or any other extension
            link.setAttribute("target", "_blank");
            link.click();
          }
        }
      }
      catch(e){
        throw new Error("Something went wrong");
      }
    },
    updateSuggestionCount({state,commit},data){
      if(data.type=="project-feeds"){
        commit("setDataByKey",{key:"feedCount",data:data.count});
      }
      else if(data.type=="advance-recommendations"){
        commit("setDataByKey",{key:"recommendationCount",data:data.count})
      }
    }
  },
  getters: {
    getData(state) {
      return state.data;
    },
    getSelectedCount(state) {
      return Object.keys(state.selectedItems).length;
    },
    getSelectAll(state) {
      return state.selectAll;
    },
    getSelectedItems(state) {
      return state.selectedItems;
    },
    getSelectedKeys(state) {
      return Object.keys(state.selectedItems);
    },
    getSelectedCount(state) {
      return Object.keys(state.selectedItems).length;
    },
    getCitationFormatting(state){
      return state.projectFeedsCitaion;
    },
    getSelectedSuggestions(state, getters, rootState, rootGetters){
      const suggestionsList = [
        ...rootGetters["advanceRecommendations/getLiteratures"],
        ...rootGetters["projectFeeds/getLiteratures"]
      ];
      let list = suggestionsList.filter(suggestion=>state.selectedItems[suggestion.doc_id]);
      return list; 
    },
    getFeedCount(state){
      return state.feedCount;
    },
    getRecommendationCount(state){
      return state.recommendationCount;
    }
  }
};
