<template>
  <div class="EOF_pinnedTagsListSection">
    <div class="tw-flex tw-items-center tw-justify-between tw-p-3">
      <span class="pinned-tags-header-title">Pinned Tags</span>
      <svg
        id="pinned-tag-header-icon"
        class="tw-cursor-pointer pinned-tags-header-icon"
        @click="openTagsList"
        v-tooltip="{
          content: `
            <p
              class='tw-max-w-xs tw-inline-flex'
            >
              ${pinTagTooltipSVG} Pin a tag
            </p>
            ${pinnedTagsList.length === 0 ? '<p>You have not added any tags,</p><p>please add tags to pin here</p>' : ''}
          `,
          delay: {
            show: 300,
            hide: 100
          },
          placement: 'top'
        }"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.66699 1.32878C6.66699 0.960586 6.36852 0.662109 6.00033 0.662109C5.63214 0.662109 5.33366 0.960586 5.33366 1.32878V5.33378H1.33366C0.965469 5.33378 0.666992 5.63226 0.666992 6.00045C0.666992 6.36864 0.965469 6.66711 1.33366 6.66711H5.33366V10.6621C5.33366 11.0303 5.63214 11.3288 6.00033 11.3288C6.36852 11.3288 6.66699 11.0303 6.66699 10.6621V6.66711H10.667C11.0352 6.66711 11.3337 6.36864 11.3337 6.00045C11.3337 5.63226 11.0352 5.33378 10.667 5.33378H6.66699V1.32878Z"
          fill="#414E62"
        />
      </svg>
    </div>
    <div
      class="pinned-tags-list tw-flex tw-flex-col tw-mt-1"
    >
      <button
        v-for="tagItem in pinnedTagsList"
        :key="tagItem.name + tagItem.color"
        class="tw-flex tw-items-center tw-justify-between pinned-tag-item"
        :style="{ border: `2px solid ${chipColors[tagItem.color - 1]}` }"
        @click.prevent.stop="togglePinTag(tagItem)"
      >
        <div
          class="pinned-tag-tem-name"
          v-tooltip="{
            content: tagItem.name,
            delay: {
              show: 300,
              hide: 100
            },
            placement: 'bottom-start'
          }"
        >
          {{ tagItem.name }}
        </div>
        <span
          :class="`rax-icon rax-icon-phase2-pin-tag ${tagItem.isPinned ? '--active' : ''} tw-text-xs`"
          v-tooltip="{
            content: 'Unpin',
            delay: {
              show: 300,
              hide: 100
            },
            placement: 'bottom-start'
          }"
          @click.prevent.stop="removeFromPinnedTagsList(tagItem)"
        />
      </button>
    </div>
  </div>
</template>
  
<script>
import { mapGetters } from "vuex";
import { ChipColors } from "../literature/constants";
import { MixpanelEvents, MixpanelEventGroup } from "@/shared/constants";

const { Unpin, View_Pinned_Tag } = MixpanelEvents[MixpanelEventGroup.PinnedTag]

export default {
  data() {
    return {
      chipColors: ChipColors,
      searchKey: '',
    }
  },
  computed: {
    ...mapGetters({
      pinnedTagsList: "profile/getUserPinnedTags",
      allTagsList: "tags/getData"
    }),
    pinTagTooltipSVG() {
      return `
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="tw-mt-1 tw-mr-1.5">
          <path d="M11.0734 1.15871C10.8258 0.911056 10.5237 0.724664 10.1913 0.614388C9.85882 0.504112 9.50523 0.472999 9.15866 0.52353C8.81208 0.57406 8.48209 0.704837 8.19499 0.90544C7.90789 1.10604 7.6716 1.37093 7.50497 1.67899L5.21546 5.91465L1.72197 7.30605C1.6066 7.35192 1.50442 7.42573 1.42462 7.52085C1.34482 7.61596 1.2899 7.72941 1.26478 7.851C1.23965 7.97259 1.24513 8.09852 1.2807 8.21747C1.31627 8.33642 1.38083 8.44468 1.46858 8.53252L3.93801 11.0019L0.720404 14.2196L0.5 15.5L1.78045 15.2796L4.99805 12.062L7.46598 14.5299C7.55372 14.6177 7.66187 14.6823 7.78075 14.718C7.89962 14.7537 8.02549 14.7593 8.14706 14.7343C8.26864 14.7094 8.38211 14.6546 8.4773 14.575C8.57249 14.4953 8.64642 14.3933 8.69245 14.278L10.0913 10.7815L14.321 8.49354C14.6291 8.3269 14.894 8.09061 15.0946 7.80351C15.2952 7.51641 15.4259 7.18642 15.4765 6.83984C15.527 6.49327 15.4959 6.13968 15.3856 5.80725C15.2753 5.47482 15.0889 5.17274 14.8413 4.92508L11.0734 1.15871ZM8.82439 2.39118C8.88005 2.28865 8.95886 2.20053 9.05456 2.13382C9.15026 2.06711 9.26021 2.02366 9.37566 2.00692C9.49111 1.99017 9.60888 2.00061 9.71959 2.03738C9.83029 2.07416 9.93089 2.13626 10.0134 2.21875L13.7812 5.98662C13.8637 6.06921 13.9257 6.1699 13.9624 6.28068C13.999 6.39146 14.0093 6.50927 13.9924 6.62473C13.9755 6.74018 13.9319 6.85011 13.8651 6.94574C13.7982 7.04138 13.71 7.12009 13.6073 7.17561L9.13926 9.59106C8.98529 9.67453 8.8654 9.80927 8.8004 9.9719L7.72237 12.6647L3.33527 8.27763L6.02361 7.20559C6.18624 7.14059 6.32098 7.02071 6.40444 6.86674L8.82439 2.39118Z" fill="white"/>
        </svg>
      `;
    }
  },
  async mounted() {
    if (this.allTagsList && !this.allTagsList.length)
      await this.$store.dispatch("tags/fetch");

    this.filteredTagsList = this.allTagsList.slice(0, 5);
  },
  methods:{
    async togglePinTag(tagItem) {
      const dispatchAction = !tagItem.isPinned ? 'activate' : 'deactivate';
      await this.$store.dispatch(`profile/togglePinnedTag`, { tagItem, action: dispatchAction });
      if (!tagItem.isPinned)
        this.$mixpanel.track(View_Pinned_Tag, {
          from: 'Pinned Tag Card',
          tag_name: tagItem.name
        });
    },
    async removeFromPinnedTagsList(tagItem) {
      await this.$store.dispatch(`profile/removeTagFromPinnedList`, tagItem);
      this.$mixpanel.track(Unpin, {
        from: 'Pinned Tag Card',
        tag: tagItem
      });
    },
    openTagsList(ev) {
      this.$emit("openTagsList", ev);
    },
  }
}
</script>
  
<style>
.EOF_pinnedTagsListSection {
  height: 210px;
  border-top: 1px solid #DDE2E9;
}
.pinned-tags-list {
  height: 100%;
}
.pinned-tags-header-title {
  font-weight: 600;
  font-size: 14px;
  font-family: Roboto;
}
.pinned-tags-header-icon {
  font-size: 18px;
}
#pinned-tag-header-icon:hover {
  fill: #1A64E2;
  stroke: #1A64E2;
}
.pinned-tag-item,
.search-pin-tag-item {
  border-radius: 20px;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 15px;
  width: fit-content;
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.pinned-tag-item {
  max-width: 90%;
}
.pinned-tag-tem-name {
  width: fit-content;
  max-width: 87%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.pinned-tag-item .rax-icon-phase2-pin-tag {
  margin-top: 0px;
  margin-left: 8px;
}
.pinned-tag-item-name {
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>