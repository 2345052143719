<template>
  <div>
    <notifications group="message-note" class="mb-not html-snackbar" />
    <section class="tw-overflow-hidden tw-h-screen tw-relative">
      <div
        class="tw-flex tw-justify-between tw-items-end tw-px-6 tw-py-2 tw-z-20 tw-bg-white"
      >
        <a href="https://read.enago.com/">
          <appLogo style="height: 28px" />
        </a>
        <span
          class="raxIcon raxIcon-menu tw-cursor-pointer"
          @click="openMobileDrawer"
        />
      </div>
      <mobile-drawer />
      <section>
        <nuxt />
      </section>
    </section>
  </div>
</template>

<style>
.mb-not.html-snackbar {
  position: fixed;
  z-index: 999;
  border-radius: 8px;
  display: flex;
  width: 100% !important;
  justify-content: center;
}
.mb-not.html-snackbar .vue-notification {
  width: 300px;
}
</style>

<script>
import mobileDrawer from "@/components/navigation/mobileDrawer";
import { mapGetters } from "vuex";
import { MixpanelEventGroup, MixpanelEvents } from "~/shared/constants";

export default {
  components: {
    mobileDrawer
  },
  data() {
    return {
      assetsUrl: process.env.assetsUrl
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated"
    })
  },
  methods: {
    openMobileDrawer() {
      if (
        this.isAuthenticated &&
        !this.$router.currentRoute.fullPath.includes(
          "verify-email-mobile?emailVerified=1"
        )
      ) {
        this.$mixpanel.track(
          MixpanelEvents[MixpanelEventGroup.Bot].Mobile_UserProfile
        );
        this.$store.commit("core/setData", {
          name: "mobileDrawerOpen",
          data: true
        });
      }
    }
  },
  mounted() {
    $("body").addClass("new-mobile-container");
  },
  beforeDestroy() {
    $("body").removeClass("new-mobile-container");
  }
};
</script>
