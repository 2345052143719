const defaultSkillState = () => {
  return {
    data: {}
  }
};

export default {
  namespaced: true,
  state: defaultSkillState,

  mutations: {
    setData(state, data) {
      state.data = data;
    },
    resetState(state) {
      Object.assign(state, defaultSkillState());
    }
  },

  actions: {
    async fetch({ commit }) {
      let { data } = await this.$axios.get(`/showuserskills`);
      commit("setData", data);
    },

    async update({ commit }, inputs) {
      const { data } = await this.$axios.put(`/userskills`, inputs);
      commit("setData", data.length? data[0] : data);
    }
  },

  getters: {
    getData(state) {
      return state.data;
    }
  }
};
