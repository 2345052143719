var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tw-text-center" },
    [
      _c(
        "div",
        {
          on: {
            click: function($event) {
              return _vm.openModal()
            }
          }
        },
        [_vm._t("referUserButton", [_vm._v("Refer now")])],
        2
      ),
      _vm._v(" "),
      _vm.isModalOpen
        ? _c(
            "modal",
            {
              attrs: {
                name: "refer-user-modal",
                transition: "pop-out",
                adaptive: true,
                "focus-trap": true,
                scrollable: true,
                height: "auto",
                width: _vm.modalWidth
              }
            },
            [
              _c("ReferUserModal", { attrs: { "close-modal": _vm.closeModal } })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }