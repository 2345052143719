var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "search-container" } }, [
    _c(
      "div",
      {
        staticClass: "tw-flex tw-items-center",
        class: { "--focus": _vm.searchText.length },
        attrs: { id: "search-box-container" }
      },
      [
        _c("img", {
          attrs: {
            id: "full-text-search-icon",
            src: _vm.assetsUrl + "img/icon/phase3/search.svg",
            alt: "Search Icon"
          }
        }),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.searchText,
              expression: "searchText"
            }
          ],
          class: { "--focus": _vm.showSearchResults },
          attrs: {
            id: "full-text-searchbox",
            type: "text",
            placeholder: "Search",
            autocomplete: "off"
          },
          domProps: { value: _vm.searchText },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.searchText = $event.target.value
              },
              _vm.onInputChange
            ],
            change: _vm.onInputChange,
            focus: _vm.onInputFocus,
            blur: _vm.onInputBlur,
            keydown: _vm.onKeyDown
          }
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "md-chip__remove md-button md-button--icon",
            class: { "--show": _vm.searchText.length },
            attrs: { id: "reset-searchbox-button" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.onClearText($event)
              }
            }
          },
          [
            _c(
              "i",
              {
                staticClass: "md-icon material-icons",
                attrs: { id: "reset-searchbox-icon" }
              },
              [_vm._v("\n        close\n      ")]
            )
          ]
        )
      ]
    ),
    _vm._v(" "),
    _vm.showSearchResults
      ? _c(
          "div",
          {
            class:
              "\n      " +
              (_vm.showSearchResults ? "--open" : "") +
              "\n      " +
              (_vm.searchLoading ? "--loading" : "") +
              "\n    ",
            attrs: { id: "search-results-container" }
          },
          [
            _vm.searchLoading
              ? _c("div", { staticStyle: { height: "auto", margin: "auto" } }, [
                  _c("img", {
                    attrs: {
                      id: "search-loading-state",
                      src:
                        _vm.assetsUrl +
                        "img/v2-assets/file-uploader-loading-icon.GIF",
                      alt: "Loading Search Results"
                    }
                  })
                ])
              : [
                  !_vm.searchResultsList.length
                    ? _c(
                        "div",
                        {
                          staticStyle: { height: "auto", margin: "auto" },
                          attrs: { id: "no-search-results-container" }
                        },
                        [
                          _c("img", {
                            attrs: {
                              id: "search-no-results-state",
                              src:
                                _vm.assetsUrl +
                                "img/icon/phase3/no-search-results.svg",
                              alt: "No Search Results"
                            }
                          }),
                          _vm._v(" "),
                          _c("p", { attrs: { id: "search-no-items-text" } }, [
                            _vm._v("Oops!! No result found")
                          ]),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass: "tw-mt-2",
                              attrs: { id: "search-no-items-subtext" }
                            },
                            [
                              _vm._v(
                                "You can try searching with a different word."
                              )
                            ]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.searchResultsList.length
                    ? _c(
                        "div",
                        {
                          staticClass: "tw-flex tw-flex-col",
                          attrs: { id: "search-result-items-container" }
                        },
                        [
                          _vm._m(0),
                          _vm._v(" "),
                          _c(
                            "div",
                            { attrs: { id: "search-results-list" } },
                            _vm._l(_vm.searchResultsList, function(
                              searchItem,
                              index
                            ) {
                              return _c("result-card", {
                                key: searchItem.literatureId + index,
                                attrs: { "search-item": searchItem }
                              })
                            }),
                            1
                          )
                        ]
                      )
                    : _vm._e()
                ]
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { attrs: { id: "search-result-label" } }, [
      _vm._v("Showing results in "),
      _c("span", { attrs: { id: "search-result-label-title" } }, [
        _vm._v('"Literature"')
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }