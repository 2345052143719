const defaultDataAnalyticsState = () => {
  return {
    data: []
  };
};

export default {
  namespaced: true,
  state: defaultDataAnalyticsState,

  mutations: {

  },

  actions: {
    async recordUserAction({state}, params) {
      try {
        await this.$axios.post(`/data-analytics/record-action`, params);
      } catch (e) {
        console.log(e);
      }
    }
  },

  getters: {
    getData(state) {
      return state.data;
    }
  }
};
