export const DoNotShowMigrationPanel =  'doNotShow_literature_migration_panel';
export const DoNotShowMigrationPanelPaperList =  'literature_migration_panel_list';
export const RaxSpecialOffer = 'rax_offer_banner_closed_on';
export const RAxReferBanner = 'show_rax_refer_banner'
export const RygType = {
  Wiki : 'wikis',
  Literature: 'literatures',
  Lectures: 'lectures',
  LightReads: 'lightreads',
  Books: 'books'
}
export const AnnotationType = {
    Note: "note",
    Comment: "comment",
    Highlight: "highlight",
    Attachment: "attachment",
    Connect: "connect",
    Url: "web-link",
    Copilot: "copilot"
};

export const SortType = {
  Title: "Title (a --> z)",
  PublicationYear: "Year (latest)",
  CreatedAt: "Last Added",
  AccessedAt: "Last Accessed",
  Relevancy: "Relevancy",
  Recency: "Recency",
  CitationCount: "Citation Count",
  Reminder: "Scheduled Reminder"
};

export const SORT_BY = {
  AccessedAt: 'accessedAt',
  Title: 'title',
  CreatedAt: 'createdAt',
  PublicationYear: 'publicationYear',
  Relevancy: "relevance",
  Recency: "recency",
  CitationCount: "citationCount",
  Reminder: "remindAt"
}

 export const AnnotationTypeEnum = Object.freeze({note:2, highlight:1,connect: 3 ,comment:4, attachment:5, url:6, copilot: 7});

//  1 = "highlight";
// 2 = "note";
// 3 = "connect";
// 4 = "comment";
// 5 = "attachment";
// 6 = "url";
export let AnnotationTypeMap;
(function (AnnotationTypeMap) {
    AnnotationTypeMap[AnnotationTypeMap["highlight"] = 1] = "highlight";
    AnnotationTypeMap[AnnotationTypeMap["note"] = 2] = "note";
    AnnotationTypeMap[AnnotationTypeMap["connect"] = 3] = "connect";
    AnnotationTypeMap[AnnotationTypeMap["comment"] = 4] = "comment";
    AnnotationTypeMap[AnnotationTypeMap["attachment"] = 5] = "attachment";
    AnnotationTypeMap[AnnotationTypeMap["web-link"] = 6] = "web-link";
    AnnotationTypeMap[AnnotationTypeMap["copilot"] = 7] = "copilot" 
})(AnnotationTypeMap || (AnnotationTypeMap = {}));


export const ActionItems = {
  Delete: "delete",
  Note: "note",
  Comment: "comment",
  Copy: "copy",
  Url: "web-link"
};

export const MenuItems = {
  Copilot: "copilot",
  Definition: "definition",
  Highlight: "highlight",
  Note: "note",
  Comment: "comment",
  Copy: "copy",
  Url: "web-link",
  Pin:"pin",
  UnPin:'unpin',
  CompareFromLiterature: "compare-from-literature",
  ResearchArticles: "research-articles",
  LibraryResources: "library-resources",
  Wikipedia: "wikipedia",
  OnlineLectureVideo: "online-lecture-video",
  LightReads: "light-reads",
  OpenInLiterature:"open-in-literature"
};

export const AnnotationClasses = {
  [AnnotationType.Note]: "raxIcon raxIcon-notes",
  [AnnotationType.Comment]: "raxIcon raxIcon-chat_2",
  [AnnotationType.Highlight]: '',
  [AnnotationType.Attachment]: "raxIcon raxIcon-attach",
  [AnnotationType.Connect]: "raxIcon raxIcon-connect_2",
  [AnnotationType.Url]: "raxIcon raxIcon-link",
  [AnnotationType.Copilot]: "raxIcon raxIcon-copilot"
};

export const SummaryStatus = {
  Failed:0,
  Success:1,
  InProgress:2,
  LimitExceeded:4
}

export const SummaryTypes = {
  Overview :'overview',
  Detailed: 'detailed'
}

export const SummaryContextMenu = {
  ViewInPaper: 'view-in-paper',
  Pin: 'pinned',
}

export const AnnotationSource = {
  Literature: 'annotations',
  KeyInsight: 'kiAnnotations',
  TLDR: 'tldrAnnotations',
  ReviewerLiterature: 'reviewerAnnotations',
  ReviewerKI: 'reviewerKiAnnotations',
  ReviewerTLDR: 'reviewerTldrAnnotations'
}

export const AnnotationSourceType = {
  Literature: 'literature',
  KeyInsight: 'key_insights',
  TLDR: 'tldr'
}

export const Literatures = {
  FetchLazy: 'fetch-lazy'
}

export const ChipColors = [
  '#F1F0EF',
  '#DCFCE7',
  '#FEE2E2',
  '#D7E8FE',
  '#F3E8FF',
  '#FEF3C7'
]

export const CopilotQnAType = {
  SimplifyText: 1,
  NormalQnA: 2,
  QnAFollowup: 3
}