const defaultTldrState = () => ({
  data: {
    status: 2, // Failed:0,  Success:1,  InProgress:2,
    data: null,
    remainingTldr: 0,
    tldrUsed: 0
  },
  currentLiteratureData: null,
  openWithTldr: false,
  isFetching: false,
  pinnedItems: [],
  selectedDataToUnpin: null,
  isPinnedSectionVisible: false,
  selectedStatementInView: null,
  tldrResultsShowing: null,
  selectedStatement: null
});

export default {
  namespaced: true,
  state: defaultTldrState,

  mutations: {
    setSelectedStatementInView(state, data) {
      // this.$set(state.selectedStatementInView, data);
      state.selectedStatementInView = data;
    },
    setLastPickedStatement(state, payload) {
      state.selectedStatement = payload;
    },
    setSelectedDataToUnpin(state, payload) {
      state.selectedDataToUnpin = payload;
    },
    setTldrResultsShowing(state, data) {
      state.tldrResultsShowing = data;
    },
    setData(state, data) {
      state.data = data;
    },
    insertNewPin(state, data) {
      state.pinnedItems.unshift(data);
    },
    setPinData(state, data) {
      state.pinnedItems = data;
    },
    setIsPinnedVisible(state, data) {
      state.isPinnedSectionVisible = data;
    },
    setOpenWithTldr(state, payload) {
      state.openWithTldr = payload;
    },
    isFetching(state, payload) {
      state.isFetching = payload;
    },
    setCurrentLiteratureData(state, data) {
      state.currentLiteratureData = data;
    },
    removePinById(state, pinId) {
      // delete pin
      const pinIndex = state.pinnedItems.findIndex(j => j.id == pinId);
      if (pinIndex > -1) {
        state.pinnedItems.splice(pinIndex, 1);
      }
    },
    resetState(state) {
      Object.assign(state, defaultTldrState());
    }
  },

  actions: {
    async fetch({ commit, state }, source) {
      commit("isFetching", true);
      try {
        let { data } = await this.$axios.get(
          `reviewer-tldr/${state.currentLiteratureData.id}`
        );

        // store has been reset therefore no need to update
        if (!state.currentLiteratureData) {
          return;
        }
        // data is success then mark as read
        if (data.status === 1 && !state.currentLiteratureData.hasReadTldr) {
          commit(
            "reviewer-literatures/updateLiteratureTldrFetched",
            state.currentLiteratureData,
            { root: true }
          );
        }
        // update tldrUsed
        commit("subscription/updateTldrCurrentUsage", data.tldrUsed, {
          root: true
        });
        if (data && data.data && data.data[0].sectionName === "ABSTRACT") {
          data.data.splice(0, 1);
        }
        commit("setData", data);
        commit("isFetching", false);
        return data;
      } catch (error) {
        console.log(error);
        commit("setData", {
          status: 0,
          data: null
        });
        commit("isFetching", false);
      }
    },
    async fetchPinnedData({ commit, state }) {
      try {
        let { data } = await this.$axios.get(
          `reviewer-tldr/pin/${state.currentLiteratureData.id}`
        );
        commit("setPinData", data);
        return data;
      } catch (error) {
        commit("setPinData", []);
        return [];
      }
    },
    async createPin({ state, commit }) {
      try {
        const literatureId = state.currentLiteratureData.id;
        let { data } = await this.$axios.put(`reviewer-tldr/pin/create`, {
          statementKey: state.selectedStatement.statementKey,
          sectionKey: state.selectedStatement.sectionKey,
          sectionName: state.selectedStatement.sectionName,
          literatureId
        });
        document && document.getSelection().empty();

        commit("insertNewPin", {...data, statementText: state.selectedStatement.text });
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    },
    async deletePin({ state, commit }, pinId) {
      let selectedPinId = pinId;
      if (!pinId && state.selectedStatement) {
        const selectedPinStatement = state.pinnedItems.find(
          j => j.statementKey == state.selectedStatement.statementKey
        );
        if (selectedPinStatement) {
          selectedPinId = selectedPinStatement.id;
        } else {
          return;
        }
      }

      try {
        let { data } = await this.$axios.delete(
          `reviewer-tldr/pin/${selectedPinId}`
        );
        commit("removePinById", selectedPinId);
        document && document.getSelection().empty();
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    },
    async setTldrResultsShowing({ commit }, data) {
      commit("setTldrResultsShowing", data);
      // Adding delay so that background sheet can be loaded into dom before result method called
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(data);
        }, 400);
      });
    }
  },

  getters: {
    getSelectedStatementInView(state) {
      return state.selectedStatementInView;
    },
    getSelectedStatement(state) {
      return state.selectedStatement;
    },
    getData(state) {
      return state.data;
    },
    getTldr(state) {
      return state.data.data;
    },
    openWithTldr(state) {
      return state.openWithTldr;
    },
    isFetching(state) {
      return state.isFetching;
    },
    tldrStatus(state) {
      return state.data ? state.data.status : 1;
    },
    getPaperData(state) {
      return state.currentLiteratureData;
    },
    getPinnedData(state) {
      return state.pinnedItems;
    },
    getIsPinnedVisible(state) {
      return state.isPinnedSectionVisible;
    },
    tldrResultsShowing(state) {
      return state.tldrResultsShowing;
    }
  }
};
