<template>
  <div :id="unid" class="md-menu-wrapper" :class="[containerClass]" data-width="3">
    <div :class="[{ 'md-menu--dense': dense }, customClass]" class="md-menu">
      <slot name="body" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    unid: {
      type: String,
      required: true
    },
    dense: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: ""
    },
    containerClass:{
      type: String,
      default: ""
    }
  },
  mounted() {
    $(`#${this.unid}`).on("selected.ca.menu", (e, obj) => {
      this.$emit("selected", obj);
    });
  },
  beforeDestroy() {
    $(`#${this.unid}`)
      .off("selected.ca.menu")
      .MaterialMenu("destroy");
  }
};
</script>
