import findObjectIndex from "@/assets/js/commonHelper";

import Vue from "vue";
import arraySearch from "@/assets/js/arraySearch";
import { isNumber } from "lodash";
import { SORT_BY } from "~/components/literature/constants";

import axios from 'axios'
let request = axios.CancelToken.source();

const defaultLiteratureState = () => {
  return {
    data: [],
    literatureData:{
      data: [],
      hasMore: true
    },
    filterData:{},
    currentProjectSlug:'',
    currentProjectMetaData:{
      authors:[],
      tags: []
    },
    haveAllData: false,
    isFilterApplied: false,
    archivedData: [],
    currentData: {},
    firstFetching: { literatureId: "", isFetching: false },
    titleList: [],
    currentPdfAction: {
      projectId: "",
      literatureId: "",
      show: false,
      pdfUrl: ""
    },
    isFetching: false,
    openComments: {},
    openPins: null,
    reminderPanelId: null,
    currentSort: SORT_BY.AccessedAt,
    showUpdateReviewModal: false,
    showReviewDetailsModal: false
  };
};

export default {
  namespaced: true,
  state: defaultLiteratureState,

  mutations: {
    setDataByKey(state, obj) {
      state[obj.key] = obj.data;
    },
    setData(state, data) {
      state["data"] = data;
    },
    setArchivedData(state, data) {
      state["archivedData"] = data;
    },
    addData(state, data) {
      state["data"].unshift(data);
      //TODO: check if data is within applied filter then only push
      state.literatureData.data.unshift(data);
    },
    addDataAfter(state, data) {
      state["data"].push(data);
    },
    updateData(state, data) {
      let index = findObjectIndex(state.data, data);
      let indexInLazy = findObjectIndex(state.literatureData.data, data);

      if (indexInLazy !== undefined && isNumber(indexInLazy)) {
        state.literatureData.data.splice(indexInLazy, 1, data)
      }
      index !== undefined && isNumber(index) ? state.data.splice(index, 1, data) : state.data.splice(0, 0, data);
      if (state["currentData"]) {
        if (data.figures_and_tables_status !== undefined) {
          Vue.set(state["currentData"], "figures_and_tables_status", data.figures_and_tables_status);
        }
        if (data.haveContent) {
          Vue.set(state["currentData"], "haveContent", data.haveContent);
        }

      }
    },
    updateFeedbackData(state, data) {
      let index = findObjectIndex(state.data, data);
      if (index !== undefined && index > -1) {
        // state.data[index].feedback = data.feedback;
        Vue.set(state.data[index], "feedback", data.feedback);
      }

      if (state["currentData"]) {
        Vue.set(state["currentData"], "feedback", data.feedback);
      }
    },
    updateArchivedData(state, data) {
      let index = findObjectIndex(state.archivedData, data);
      state.archivedData.splice(index, 1);
    },
    updateLiteratureKeyInsightFetched(state, literature){
      let indexInLazy = findObjectIndex(state.literatureData.data, literature);
      if (indexInLazy !== undefined && isNumber(indexInLazy)) {
        Vue.set(state.literatureData.data[indexInLazy], "hasReadSummary", true);
      }
      let index = findObjectIndex(state.data, literature);
      if (index !== undefined && isNumber(index)) {
        Vue.set(state.data[index], "hasReadSummary", true);
      }
    },
    updateLiteratureTldrFetched(state, literature){
      let indexInLazy = findObjectIndex(state.literatureData.data, literature);
      if (indexInLazy !== undefined && isNumber(indexInLazy)) {
        Vue.set(state.literatureData.data[indexInLazy], "hasReadTldr", true);
      }
      let index = findObjectIndex(state.data, literature);
      if (index !== undefined && isNumber(index)) {
        Vue.set(state.data[index], "hasReadTldr", true);
      }
    },
    updateDataPosition(state, data) {
      let index = findObjectIndex(state.data, data);
      state.data.splice(index, 1);
      state.data.unshift(data);
      Vue.set(state["currentData"], "accessedAt", data.accessedAt);
    },
    deleteData(state, input) {
      let index = findObjectIndex(state.data, input);
      state.data.splice(index, 1);
      let lazyIndex = findObjectIndex(state.literatureData.data, input);
      if (index !== undefined && isNumber(index)) {
        state.literatureData.data.splice(index, 1);
      }

    },
    setCurrentData(state, data) {
      state.currentData = data;
    },
    setIsFetching(state, data) {
      state.isFetching = data;
    },
    setOpenPins(state, pin) {
      state.openPins = pin;
    },
    updateCitation(state, index) {
      state.currentData.citations[index].isAddedLiterature = true;
    },
    setObjData(state, obj) {
      Vue.set(state[obj.name], obj.KeyName, obj.data);
    },
    setCurrentPdfAction(state, data) {
      state.currentPdfAction = data;
    },
    resetState(state) {
      Object.assign(state, defaultLiteratureState());
    },
    setTitleList(state, data) {
      state["titleList"] = data;
    },
    setCurrentSort(state, data) {
      state.currentSort = data;
    },
    setCurrentFilter(state, data){
      state.isFilterApplied = !!data;
      state.filterData = data || {};
    },
    setLiteratureItems(state, data){
      state.literatureData = data;
    },
    updateCurrentProjectSlug(state, data){
      state.currentProjectSlug = data;
    },
    setCurrentProjectAuthors(state, data){
      state.currentProjectMetaData.authors = data;
    },
    setCurrentProjectTags(state, data){
      state.currentProjectMetaData.tags = data;
    },
    setHaveAllData(state, data){
      state.haveAllData = data;
    },
    updateTag(state, {index, data}){
      Vue.set(state.literatureData.data[index], "tags", data);
    }
  },

  actions: {
    async fetchLazy({ commit, state, dispatch }, obj) {
      // get filter and sort value
      if (state.isFetching && request) {
        request.cancel("delete last fetch call");
        return;
      }
      if (!request) {
        request = axios.CancelToken.source();
      }
      if (obj.slug) {
        commit('updateCurrentProjectSlug', obj.slug);
      }
      commit("setIsFetching", true);
      const appliedFilter = state.filterData;
      const appliedSort = state.currentSort;
      const filterData = {
        authors: appliedFilter.addedAuthors && appliedFilter.addedAuthors.map(author => author.label),
        tagIds: (appliedFilter.addedTags && appliedFilter.addedTags.map(tag=> tag.id)) || [],
        publicationYear: appliedFilter.from && appliedFilter.to &&  { start: appliedFilter.from, end: appliedFilter.to}
      }
      if (obj.isFresh) {
        commit('setLiteratureItems', {data:[], hasMore: true});
      }
      const offset = obj.isFresh ? 0 : state.literatureData.data.length;
      try {
        const { data } = await this.$axios.post(
          `${ process.env.apiUrl }literatures/index`,
          // `http://localhost:4012/dev/index`,
          {
            projectSlug: obj.slug,
            archived:0,
            limit:25,
            offset,
            sort:{
              "field": appliedSort,
              "order": appliedSort !== SORT_BY.Title ? "DESC" : "ASC"
            },
            ...filterData
          },
          {cancelToken: request.token,})
          const newData = {
            hasMore: data.hasMore,
            data: [...state.literatureData.data, ...data.data],
          }
        if (!state.isFilterApplied && !data.hasMore) {
          commit('setHaveAllData', true);
        }
         commit("setLiteratureItems", newData);
         commit("setIsFetching", false);
         request = null;

      } catch (error) {
        if (!error) {
          request = null;
          commit('setLiteratureItems', {data:[], hasMore: true});
          dispatch('fetch', { slug: state.currentProjectSlug });
        }
        console.log('error', error);
      }

    },
    async updateFilterData({ commit, dispatch, state }, data){
      // if previously no filter was applied and not hasMore data then no need to kick api. else remove everything and add fresh data
      if ((!state.isFilterApplied && !state.literatureData.hasMore) || state.haveAllData) {
        commit('setCurrentFilter', data);
      }else{
        commit('setCurrentFilter', data);
        commit('setLiteratureItems', {data:[], hasMore: true});
        dispatch('fetch', { slug: state.currentProjectSlug });
      }
      // on update call fetch api
    },
    async updateSortData({ commit, dispatch, state }, data){
      // if previously no filter was applied and not hasMore data then no need to kick api. else remove everything and add fresh data
      if ((!state.isFilterApplied && !state.literatureData.hasMore) || state.haveAllData) {
        commit('setCurrentSort', data);
      }else{
        commit('setCurrentSort', data);
        commit('setLiteratureItems', {data:[], hasMore: true});
        dispatch('fetchLazy', { slug: state.currentProjectSlug });
      }
    },
    async fetch({ commit, dispatch }, obj) {
      const { data } = await this.$axios.get(
        `/reviewer-literatures/${obj.slug}`
      );
      const newData = {
        hasMore: false,
        data: [...data],
      }
      commit('setHaveAllData', true);
      commit("setLiteratureItems", newData);
      commit("setData", data);
    },

    async fetchArchived({ commit }, obj) {

      const { data } = await this.$axios.post(
        `${ process.env.apiUrl }literatures/index`,
        {
          projectSlug: obj.slug,
          archived:1,
          offset:0,
          sort:{
            "field": SORT_BY.AccessedAt,
            "order": "DESC"
          },
        });
      commit("setArchivedData", data.data);
    },
    async fetchAuthors({ commit }, slug){
      const { data } = await this.$axios.get(`/literatures/authors/all/${slug}`);
      commit("setCurrentProjectAuthors", data);
    },
    async fetchTags({ commit }, slug){
      const { data } = await this.$axios.get(`/tags/all/literatures/${slug}`);
      commit("setCurrentProjectTags", data);
    },

    async archive({ commit, state }, inputs) {
      const { data } = await this.$axios.post(`/literatures/archive`, inputs);
      commit("updateArchivedData", data);
      commit("updateData", data);
    },

    async onInvite({ commit }, _data) {
      const data = { ..._data, status: "1", isOwner: 1, isShared:true };
      commit("updateData", data);
    },

    async fetchTitles({ commit }) {
      let projectSlug = this.$router.currentRoute.fullPath.split("/")[2];
      const { data } = await this.$axios.get(
        `/literatures/partial/${projectSlug}`
      );
      commit("setTitleList", data);
    },

    async fetchExceptExits({ commit, state }, obj) {
      const { data } = await this.$axios.get(
        `/reviewer-literatures/${obj.slug}`
      );
      let filterArray = data.filter(item => {
        return !arraySearch(state.data, item.id);
      });
      for (let i = 0; i < filterArray.length; i++) {
        commit("addDataAfter", filterArray[i]);
      }
    },

    async show({ commit, state }, obj) {
      const { data } = await this.$axios.get(
        `/reviewer-literatures/show/${obj.slug}?withTags=true&withContacts=true`
      );
      commit("updateData", data);
      commit("setCurrentData", data);
      return data;
    },

    async update({ commit, state }, inputs) {
      const { data } = await this.$axios.put(
        `/reviewer-literatures?withContacts=true&withTags=true`,
        inputs
      );
      commit("updateData", data);
    },

    async create({ commit }, inputs) {
      const { data } = await this.$axios.post("/reviewer-literatures", inputs);
      commit("addData", data);
    },

    async createUrl({ commit, dispatch }, inputs) {
      const { data } = await this.$axios
        .post(
          `${process.env.serverLessBaseUrl}/literatures-submit/url/import?withContacts=true&withTags=true`,
          inputs
        )
        .catch(error => {
          return Promise.reject(error);
        });
      commit("addData", data);
      commit("setObjData", {
        name: "firstFetching",
        keyName: "literatureId",
        value: data.id
      });
      commit("setObjData", {
        name: "firstFetching",
        keyName: "isFetching",
        value: true
      });
      setTimeout(async () => {
        await dispatch("show", data);
        commit("setObjData", {
          name: "firstFetching",
          keyName: "literatureId",
          value: data.id
        });
        commit("setObjData", {
          name: "firstFetching",
          keyName: "isFetching",
          value: false
        });
      }, 8000);
    },

    updateCurrentPage({ commit, dispatch }, data) {
      commit("setObjData", {
        name: "firstFetching",
        keyName: "literatureId",
        value: data.id
      });
      commit("setObjData", {
        name: "firstFetching",
        keyName: "isFetching",
        value: true
      });
      setTimeout(async () => {
        await dispatch("show", data);
        commit("setObjData", {
          name: "firstFetching",
          keyName: "literatureId",
          value: data.id
        });
        commit("setObjData", {
          name: "firstFetching",
          keyName: "isFetching",
          value: false
        });
      }, 8000);
    },

    async updateFromUrl({ state, commit, dispatch }, inputs) {
      const { data } = await this.$axios
        .post(
          `${process.env.serverLessBaseUrl}/literatures-submit/reviewer-update/url/import`,
          inputs
        )
        .catch(error => {
          return Promise.reject(error);
        });
      commit("updateData", data);
      commit("setObjData", {
        name: "firstFetching",
        keyName: "literatureId",
        value: data.id
      });
      commit("setObjData", {
        name: "firstFetching",
        keyName: "isFetching",
        value: false
      });
      setTimeout(async () => {
        await dispatch("show", data);
        commit("setObjData", {
          name: "firstFetching",
          keyName: "literatureId",
          value: data.id
        });
        commit("setObjData", {
          name: "firstFetching",
          keyName: "isFetching",
          value: false
        });
      }, 8000);
    },

    async createFromResources({ commit, dispatch }, inputs) {
      // const { data } = await this.$axios.post("/reviewer-literatures/resources", inputs);
      // const { data } = await this.$axios.post(`${process.env.serverLessBaseUrl}/literatures-submit/resource/import`, inputs);
      const { data } = await this.$axios.post(`${process.env.serverLessBaseUrl}/literatures-submit/reviewer/resource/import`, inputs);
      commit("addData", data);
    },

    async updateSubmissionFeedback({ commit, dispatch }, inputs) {
      const { data } = await this.$axios.put("/reviewer-literatures/review-feedback", inputs);
      commit('updateFeedbackData', data);
      return data;
    },

    async delete({ commit, state }, inputs) {
      await this.$axios.delete(
        `reviewer-literatures/${inputs.slug}`,
        // `http://localhost:4009/dev/${inputs.slug}`
      );
      commit("deleteData", inputs);
      commit("reminders/deleteData", { id: inputs.id, projectId: inputs.projectId }, { root: true });
    },

    async fetchCitations({ state }, id) {
      const { data } = await this.$axios.post(
        `${ process.env.serverLessBaseUrl }/literature-citations/reviewer-citations/get`,
        {
          id
        }
      );
      Vue.set(state["currentData"], "citations", data);
    }
  },

  getters: {
    getData(state) {
      return state.data;
    },
    getSubmissionData(state) {
      return state.data? state.data.filter(j => j.type === 'Manuscript') : [];
    },
    getRelatedLiteratureData(state) {
      return state.data? state.data.filter(j => j.type !== 'Manuscript') : [];
    },
    getLiteratureList(state) {
      return state.literatureData.data || [];
    },
    hasMoreLiteratureItems(state) {
      return state.literatureData.hasMore;
    },
    getArchivedData(state) {
      return state.archivedData;
    },
    getCurrentData(state) {
      return state.currentData;
    },
    getFirstFetching(state) {
      return state.firstFetching;
    },
    getTags(state) {
      if (Object.keys(state.currentData).length && state.currentData.tags) {
        return state.currentData.tags;
      }
    },
    getIsFetching(state) {
      return state.isFetching;
    },
    currentPdfAction(state) {
      return state.currentPdfAction;
    },
    openCommentsInfo(state) {
      return state.openComments;
    },
    getCurrentPin(state) {
      return state.openPins;
    },
    getCurrentSort(state) {
      return state.currentSort;
    },
    getFilterData(state) {
      return state.filterData;
    },
    getCurrentProjectTags(state) {
      return state.currentProjectMetaData.tags;
    },
    getCurrentProjectAuthors(state) {
      return state.currentProjectMetaData.authors;
    },
    isFilterApplied(state) {
      return state.isFilterApplied;
    },
    getReminderPanelId(state) {
      return state.reminderPanelId;
    },
    showUpdateReviewModal(state) {
      return state.showUpdateReviewModal;
    },
    showReviewDetailsModal(state) {
      return state.showReviewDetailsModal;
    }
  }
};
