import findObjectIndex from "@/assets/js/commonHelper";
import Vue from "vue";

const defaultProjectState = () => {
  return {
    data: [],
    currentData: {},
    offset:0,
    isFetching:false
  };
};
export default {
  namespaced: true,
  state: defaultProjectState,

  mutations: {
    setData(state, data) {
      state["data"] = data;
    },
    addData(state, data) {
      state["data"].unshift(data);
    },
    updateData(state, data) {
      let index = findObjectIndex(state.data, data);
      state.data.splice(index, 1, data);
    },
    updateDataPosition(state, data) {
      let index = findObjectIndex(state.data, data);
      state.data.splice(index, 1);
      state.data.unshift(data);
      Vue.set(state["currentData"], "accessedAt", data.accessedAt);
    },
    deleteData(state, index) {
      state.data.splice(index, 1);
    },
    setCurrentData(state, data) {
      state.currentData = data;
    },
    resetState(state) {
      Object.assign(state, defaultProjectState());
    },
    setIsFetching(state,data) {
      state.isFetching = data;
    }
  },

  actions: {
    async fetch({ commit }) {
      try{
        const { data } = await this.$axios.get(`/projects?withContacts=true`);
        commit("setData", data);
      }
      catch(err){
        if(!err){
          commit("setIsFetching", false);
          commit("setData", []); 
          dispatch('fetch');
        }
      }
    },
    async fetchFirstProject({commit}) {
       try{
        commit("setIsFetching", true);
        const { data } = await this.$axios.get(`/projects/get`);
        commit("setData", data);
        commit("setIsFetching", false);
        return data;
       }
       catch(err){
        if(!err){
          commit("setIsFetching", false);
          commit("setData", []); 
          dispatch('fetch');
        }
        return [];
       }
    },
    async fetchLazy({commit,state}){
      try{
        let limit = 8;
        const {data} = await this.$axios.get(`/projects?withContacts=true&limit=${limit}&offset=${state.offset}`);
        this.offset = offset+limit;
        commit("setData",data);
      }
      catch(err){
        if(!err){
          commit("setData", []); 
          dispatch('fetch');
        }
      }
    },
    async update({ commit, state }, inputs) {
      const { data } = await this.$axios.put(`/project?withContacts=true`, inputs);
      commit("updateData", data[0]);
    },

    async createCopy({ commit }, inputs) {
      const { data } = await this.$axios.post('/project/copy', inputs);
      this.dispatch("subscription/fetchCurrentUsage");
      commit("addData", data);
    },

    async create({ commit, state }, inputs) {
      let index = findObjectIndex(state.data, inputs, "title");
      if (index === -1) {
        const { data } = await this.$axios.post("/project", inputs);
        commit("addData", data);
        this.commit("lira/addProject",[data]);
        this.dispatch("subscription/fetchCurrentUsage");
        return data
      } else {
        throw ("Project with same title already exists.");
      }
    },

    async delete({ commit, state }, inputs) {
      await this.$axios.delete(`/project/${inputs.item.id}`);
      let index = findObjectIndex(state.data, inputs.item);
      commit("deleteData", index);
      this.dispatch("subscription/fetchCurrentUsage");
    },

    async archive({ commit, state }, inputs) {
      const { data } = await this.$axios.post(`/project/archive`, inputs)
      commit("updateData", data);
      this.dispatch("subscription/fetchCurrentUsage");
    }

  },

  getters: {
    getData(state) {
      return state.data;
    },
    getIsFetching(state){
      return state.isFetching;
    }
  }
};
