var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "spinner" }, [
    _c("div", {
      staticClass: "bounce1",
      style: { "background-color": _vm.bounceColor }
    }),
    _vm._v(" "),
    _c("div", {
      staticClass: "bounce2",
      style: { "background-color": _vm.bounceColor }
    }),
    _vm._v(" "),
    _c("div", {
      staticClass: "bounce3",
      style: { "background-color": _vm.bounceColor }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }