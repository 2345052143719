import { EducationalTourType } from "~/shared/constants";

const ip = require("ip");
const defaultUserState = () => {
  return {
    data: {
      flags: {}
    },
    isNewUser: false,
    openFileExplorer: false,
    openPdfByDefault: false,
    showUploadDialog: false,
    showEmailModal: 0,
    timerForEmailModal: 65,
    count: 0,
    country: {
      name: "",
      isoalpha2code: ""
    },
    lastAction: null,
    showReviewOnboarding: false,
    disableStatusReminder: false,
    isPushedToLiteraturePage: false
  };
};
export default {
  namespaced: true,
  state: defaultUserState,

  mutations: {
    setData(state, data) {
      state["data"] = data;
    },
    setIsNewUser(state, data) {
      state["isNewUser"] = data;
    },
    setOpenPdfByDefault(state, data) {
      state["openPdfByDefault"] = data;
    },
    setOpenFileExplorer(state, data) {
      state["openFileExplorer"] = data;
    },
    setTimerForEmailModal(state, data) {
      state["timerForEmailModal"] = data;
    },
    setShowUploadDialog(state, data) {
      state["showUploadDialog"] = data;
    },
    setShowEmailModal(state, data) {
      state["showEmailModal"] = data;
    },
    setCount(state, data) {
      state["count"] = data;
    },
    setCountry(state, data) {
      state["country"] = data;
    },
    setShowReviewOnboarding(state, data) {
      state["showReviewOnboarding"] = data;
    },
    updateFlag(state, data) {
      if (!state.data.flags) {
        state.data["flags"] = {};
      }
      state.data["flags"][data.key] = data.payload;
    },
    updateEduFlag(state, data) {
      if (!state.data.flags) {
        state.data["flags"] = {};
      }

      state.data["flags"]["eduTips"][data.key] = data.payload;
    },
    setLastAction(state, data) {
      state.lastAction = data;
    },
    updateReviewFlag(state) {
      if (state.data.flags) {
        state.data.flags.reviewAssistantAccess = "joined";
      }
    },
    resetState(state) {
      Object.assign(state, defaultUserState());
    },
    setDisableStatusReminder(state, data) {
      state["disableStatusReminder"] = data;
    },
    setIsPushedToLiteraturePage(state, data) {
      state["isPushedToLiteraturePage"] = data;
    },
  },

  actions: {
    async setUser({ commit }) {
      const { data } = await this.$axios.$get("/auth/me");
      commit("setData", data);
    },

    async delete({}) {
      return await this.$axios.$delete("/user/account");
    },

    async setUserCountry({ commit, state }) {
      if (state.country.isoalpha2code) {
        return true;
      }
      let { data } = await this.$axios.get("https://ipinfo.io");
      if (!data) {
        commit("setCountry", { name: "", isoalpha2code: "" });
      } else {
        commit("setCountry", { name: "", isoalpha2code: data.country });
      }
    },
    async doNotShowEduTips({state, commit}, payload){
      // const flags =  state.data && state.data.flags ? state.data.flags.eduTips : {};
      // return flags['contextMenuAddUrl'];

      commit('updateEduFlag', {key:payload.type, payload:payload.data});
      const dataForEduTip = {
        type: payload.tipId,
        doNotShowAgain: true,
      };
      try {
        let { data } = await this.$axios.post(
        "/tips-interaction/create",
        dataForEduTip
      );

      } catch (error) {
        console.log(error);
      }
      // Send event to backend
    },
    async doNotShowTooltip({ state, commit }, payload) {
      let payloadData = {};
      switch (payload.type) {
        case 'viewSummaryTips':
          commit('updateFlag', {key: payload.type, payload: payload.data});
          payloadData = {
            type: 3,
            doNotShowAgain: true,
          };
          try {
            let { data } = await this.$axios.post(
            "/tips-interaction/create",
            payloadData
          );

          } catch (error) {
            console.log(error);
          }

          break;

        case "viewFilterTips":
          commit('updateFlag', {key: payload.type, payload: payload.data});
          payloadData = {
            type: 5,
            doNotShowAgain: true,
          };
          try {
            let { data } = await this.$axios.post(
            "/tips-interaction/create",
            payloadData
          );
          } catch (error) {
            console.log(error);
          }
          break;
        case "viewLiteratureTour":
          commit('updateFlag', {key: payload.type, payload: payload.data});
          payloadData = {
            type: 6,
            doNotShowAgain: true,
          };
          try {
            let { data } = await this.$axios.post(
            "/tips-interaction/create",
            payloadData
          );
          } catch (error) {
            console.log(error);
          }
          break;
        
        case "viewReminderTour":
          commit('updateFlag', {key: payload.type, payload: payload.data});
          payloadData = {
            type: 11,
            doNotShowAgain: true,
          };
          try {
            let { data } = await this.$axios.post(
            "/tips-interaction/create",
            payloadData
          );
          } catch (error) {
            console.log(error);
          }
          break;

        default:
          break;
      }

    },
    async setReviewerActivated({ commit }) {
      await this.$axios.put("/user/set-reviewer-activated");
      commit("updateReviewFlag");
    },
  },

  getters: {
    getCountry(state) {
      return state.country;
    },
    getCount(state) {
      return state.count;
    },
    getData(state) {
      return state.data;
    },
    getIsNewUser(state) {
      return state.isNewUser;
    },
    getOpenPdfByDefault(state) {
      return state.openPdfByDefault;
    },
    getOpenFileExplorer(state) {
      return state.openFileExplorer;
    },
    getShowUploadDialog(state) {
      return state.showUploadDialog;
    },
    getShowEmailModal(state) {
      return state.showEmailModal;
    },
    getTimerForEmailModal(state) {
      return state.timerForEmailModal;
    },
    canShowSummaryFeature(state) {
      return true;
    },
    canShowTipsForSummaryOnLiteratureList(state) {
      const flag = state.data.flags;
      return flag ? flag["viewSummaryTips"] : true;
    },
    canShowFilterTips(state) {
      const flag = state.data.flags;
      return flag ? flag["viewFilterTips"] : true;
    },
    canShowTipsForSummary(state) {
      const flag = state.data.flags;
      return flag ? flag["viewSummaryOnboardingTips"] : true;
    },
    canHighlightReleaseNote(state){
      const flag = state.data && state.data.flags ? state.data.flags : undefined;
      return flag ? flag['highlightReleaseNotes']: true;
    },
    canShowLiteratureTour(state){
      return false;
      // const flag = state.data && state.data.flags ? state.data.flags : undefined;
      // return flag ? flag['viewLiteratureTour']: true;
    },
    canShowReminderTour(state){
      return false;
      // const flag = state.data && state.data.flags ? state.data.flags : undefined;
      // return flag ? flag['viewReminderTour']: true;
    },
    canShowEduTipForContextMenuAddUrl(state){
      const flags =  state.data && state.data.flags ? state.data.flags.eduTips : {};
      return flags[EducationalTourType.ContextMenuAddUrl];
    },
    kiBlogPosition(state) {
      return state.data && state.data.flags ? state.data.flags.kiBlogPosition : 1;
    },
    getLastAction(state) {
      return state.lastAction;
    },
    getShowReviewOnboarding(state) {
      return state["showReviewOnboarding"];
    },
    getDisableStatusReminder(state) {
      return state.disableStatusReminder;
    },
    getIsPushedToLiteraturePage(state) {
      return state.isPushedToLiteraturePage;
    },
  }
};
