const defaultAffState = () => {
  return {
    data: {},
    allowedLibrary: [2981, 8824, 8912, 8943, 8931, 8980, 8957, 9103, 3102, 3112, 9138]
  };
};

export default {
  namespaced: true,
  state: defaultAffState,
  mutations: {
    setData(state, data) {
      state["data"] = data;
    },
    resetState(state) {
      Object.assign(state, defaultAffState());
    }
  },

  actions: {
    async fetch({ commit }) {
      let { data } = await this.$axios.get(`/showusercurrentaffiliations`);
      commit("setData", data);
    },

    async update({ commit, dispatch }, inputs) {
      const { data } = await this.$axios.put(
        `/usercurrentaffiliations`,
        inputs
      );
      commit("setData", data.length ? data[0] : data);
      // update subscription data as per new role
      dispatch('subscription/fetch', {}, { root: true });
      dispatch('subscription/fetchPlanList', {}, { root: true });
    },

    async create({ commit }, inputs) {
      const { data } = await this.$axios.post(
        `/usercurrentaffiliations`,
        inputs
      );
      commit("setData", data.length ? data[0] : data);
    },

    async verifyFacultyManually({ commit }) {
      let { data } = await this.$axios.get(`/verify/faculty/manually`);
    }
  },

  getters: {
    getData(state) {
      return state.data;
    },

    getDiscoverAccess(state) {
      return state.data.institutionEmail &&
        state.data.institution &&
        state.allowedLibrary.indexOf(state.data.institution) !== -1
        ? state.data.isVerified
        : false;
    }
  }
};
