export default function ({ req, redirect }) {
  if(process.server && parseInt(process.env.IS_MAINTAINED)) {
    let allowedIp = ["14.142.231.30"];
    let userIp = (req.headers['x-forwarded-for'] || '').split(',')[0]
      || req.connection.remoteAddress || req.socket.remoteAddress;

    if (allowedIp.indexOf(userIp) === -1) {
      console.log("Outsider");
      return redirect("/maintenance");
    }
  }

}
