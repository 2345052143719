<template>
  <div :class="{'tw-text-sm tw-border-b-2 tw-border-solid tw-border-black-6' : haveContent}">
    <slot ref="hellobarRef" />
  </div>
</template>
<script>
export default {
  data(){
    return {
      haveContent: true
    }
  },
  methods:{
    checkForSlotContent() {
      if (!this.$el.textContent || (this.$el.textContent && !this.$el.textContent.trim())) {
        this.haveContent = false;
        this.$store.commit("core/setData", {
          name: "hellobarInserted",
          data: false
        });
        $('body').removeClass("inserted-hellobar");
      } else {
        this.haveContent = true;
        this.$store.commit("core/setData", {
          name: "hellobarInserted",
          data: true
        });
        $('body').addClass("inserted-hellobar");
      }
    },
  },
  beforeUpdate(){
    const self = this;
    setTimeout(() => {
      self.checkForSlotContent();
    }, 1000);
  },
  beforeMount(){
     $('body').addClass("inserted-hellobar");
  },
  beforeDestroy(){
    $('body').removeClass("inserted-hellobar");
  },
  mounted() {
    this.checkForSlotContent();
  },
}
</script>
