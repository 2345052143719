import findObjectIndex from "@/assets/js/commonHelper";

import Vue from "vue";
import arraySearch from "@/assets/js/arraySearch";
import { isNumber } from "lodash";
import { SORT_BY } from "~/components/literature/constants";
import openFetchDialog from "@/plugins/mixins/openFetchDialog";
import AsyncComponent from "@/assets/js/helper";


import axios from 'axios'
let request = axios.CancelToken.source();

const defaultLiteratureState = () => {
  return {
    data: [],
    literatureData:{
      data: [],
      hasMore: true
    },
    filterData:{},
    currentProjectSlug:'',
    currentProjectMetaData:{
      authors:[],
      tags: []
    },
    selectedLiteratureItem: undefined,
    haveAllData: false,
    isFilterApplied: false,
    archivedData: [],
    currentData: {},
    firstFetching: { literatureId: "", isFetching: false },
    titleList: [],
    currentPdfAction: {
      projectId: "",
      literatureId: "",
      show: false,
      pdfUrl: ""
    },
    isFetching: false,
    openComments: {},
    openPins: null,
    reminderPanelId: null,
    currentSort: SORT_BY.AccessedAt,
    selectedItems: {},
    selectAll: false,
    fetchFavorites: false,
    favoriteCount: null,
    literatureCitaion:null,
    recentlyAddedSuggestion:[],
    newUserLiteratureIds: [],
    archivedIndex:-1,
    recentSlug:undefined,
    recentProjectId:undefined
  };
};

export default {
  namespaced: true,
  state: defaultLiteratureState,

  mutations: {
    setDataByKey(state, obj) {
      state[obj.key] = obj.data;
    },
    setData(state, data) {
      state["data"] = data;
    },
    setSelectedLiteratureItem(state, data) {
      state.selectedLiteratureItem = data;
    },
    setArchivedData(state, data) {
      state["archivedData"] = data;
    },
    addData(state, data) {
      //TODO: check if data is within applied filter then only push
      state.literatureData.data.unshift(data);
    },
    addBulkData(state, data) {
      //TODO: check if data is within applied filter then only push
      state.literatureData.data.unshift(...data);
    },
    addDataAfter(state, data) {
      state["data"].push(data);
    },
    updateData(state, data) {
      this.commit("literatures/updateDataHelper",data);
      if (state["currentData"]) {
        if (data.figures_and_tables_status !== undefined) {
          Vue.set(state["currentData"], "figures_and_tables_status", data.figures_and_tables_status);
        }
        if (data.haveContent) {
          Vue.set(state["currentData"], "haveContent", data.haveContent);
        }
        if(data.isCopyLiterature !== undefined) {
          Vue.set(state["currentData"], "isCopyLiterature", data.isCopyLiterature);
        }
      }
    },
    updateDataHelper(state,data){
      let index = findObjectIndex(state.data, data);
      let indexInLazy = findObjectIndex(state.literatureData.data, data);
      if (indexInLazy !== undefined && isNumber(indexInLazy)) {
        state.literatureData.data.splice(indexInLazy, 1, data)
      }
      index !== undefined && isNumber(index) ? state.data.splice(index, 1, data) : state.data.splice(0, 0, data);
    },
    updateArchivedData(state, data) {
      let index = findObjectIndex(state.archivedData, data);
      state.archivedData.splice(index, 1);
    },
    updateLiteratureKeyInsightFetched(state, literature){
      let indexInLazy = findObjectIndex(state.literatureData.data, literature);
      if (indexInLazy !== undefined && isNumber(indexInLazy) && indexInLazy!=-1) {
        Vue.set(state.literatureData.data[indexInLazy], "hasReadSummary", true);
      }
      let index = findObjectIndex(state.data, literature);
      if (index !== undefined && isNumber(index) && index!=-1) {
        Vue.set(state.data[index], "hasReadSummary", true);
      }
    },
    updateSelectedItems(state, obj) {
      if (obj.val) {
        this.commit(
          "literatures/setDataByKey",
          {
            key: "selectedItems",
            data: { ...state.selectedItems, [obj.id]: true }
          },
          { root: true }
        );
      } else {
        Vue.delete(state.selectedItems, obj.id);
      }
    },
    selectAllLiteratures(state, list) {
      state.selectAll = true;
      list.forEach(item => {
        Vue.set(state.selectedItems, item.id, true);
      });
    },
    unselectAllLiteratures(state) {
      state.selectedItems = {};
      state.selectAll = false;
    },
    updateLiteratureTldrFetched(state, literature){
      let indexInLazy = findObjectIndex(state.literatureData.data, literature);
      if (indexInLazy !== undefined && isNumber(indexInLazy) && indexInLazy!=-1) {
        Vue.set(state.literatureData.data[indexInLazy], "hasReadTldr", true);
      }
      let index = findObjectIndex(state.data, literature);
      if (index !== undefined && isNumber(index) && index !=-1) {
        Vue.set(state.data[index], "hasReadTldr", true);
      }
    },
    updateDataPosition(state, data) {
      let index = findObjectIndex(state.data, data);
      state.data.splice(index, 1);
      state.data.unshift(data);
      Vue.set(state["currentData"], "accessedAt", data.accessedAt);
    },
    deleteData(state, input) {
      let index = findObjectIndex(state.data, input);
      state.data.splice(index, 1);
      let lazyIndex = findObjectIndex(state.literatureData.data, input);
      if (lazyIndex !== undefined && isNumber(lazyIndex)) {
        state.literatureData.data.splice(lazyIndex, 1);
      }

    },
    setCurrentData(state, data) {
      state.currentData = data;
    },
    setIsFetching(state, data) {
      state.isFetching = data;
    },
    setOpenPins(state, pin) {
      state.openPins = pin;
    },
    updateCitation(state, index) {
      state.currentData.citations[index].isAddedLiterature = true;
    },
    setObjData(state, obj) {
      Vue.set(state[obj.name], obj.KeyName, obj.data);
    },
    setCurrentPdfAction(state, data) {
      state.currentPdfAction = data;
    },
    resetState(state) {
      Object.assign(state, defaultLiteratureState());
    },
    setTitleList(state, data) {
      state["titleList"] = data;
    },
    setCurrentSort(state, data) {
      state.currentSort = data;
    },
    setCurrentFilter(state, data){
      state.isFilterApplied = !!data;
      state.filterData = data || {};
    },
    setLiteratureItems(state, data){
      state.literatureData = data;
    },
    updateCurrentProjectSlug(state, data){
      state.currentProjectSlug = data;
    },
    setCurrentProjectAuthors(state, data){
      state.currentProjectMetaData.authors = data;
    },
    setCurrentProjectTags(state, data){
      state.currentProjectMetaData.tags = data;
    },
    setHaveAllData(state, data){
      state.haveAllData = data;
    },
    updateTag(state, {index, data}){
      Vue.set(state.literatureData.data[index], "tags", data);
    },
    addRecentlyAddedLiterature(state,data){
      if(data.length)
      state.recentlyAddedSuggestion=[...data];
      else
      state.recentlyAddedSuggestion=[data];
    },
    clearRecentlyAddedLiterature(state,data){
      state.recentlyAddedSuggestion=[];
    },
    addNewUserLiteratureIds(state, data) {
      state.newUserLiteratureIds.push(data.id);
    },
    deletenewUserLiteratures(state, data) {
      const indexToBeDeleted = state.newUserLiteratureIds.indexOf(data);
      if(indexToBeDeleted > -1) state.newUserLiteratureIds.splice(indexToBeDeleted, 1);
    },
    setArchivedIndex(state, data){
      state.archivedIndex = data;
    },
    setRecentSlug(state,data){
      state.recentSlug=data;
    },
    setRecentProjectId(state,data){
      state.recentProjectId=data;
    },
  },

  actions: {
    setFavoriteCount({ state, commit, dispatch }, data) {
      commit("setDataByKey", {
        key: "favoriteCount",
        data: data < 0 ? 0 : data
      })
      if (!state.favoriteCount && state.fetchFavorites) {
        dispatch("updateFetchFavorites", false)
      }
    },
    async exportLiterature({ state, commit, dispatch },{format,literatureIds,title}){
      try {
        var response = await this.$axios.get(`/export-literature/${format}`, {
          params:{literatureIds}
        });
        let x = new Date();
        let date = ((x.getMonth()<10?"0"+x.getMonth().toString():x.getMonth().toString())+"-"+(x.getDate()<10?"0"+x.getDate().toString():x.getDate().toString())+"-"+(x.getFullYear().toString()));
        if(title){
          title = `${title}_${format}_${date}.${format}`
        }
        else{
          title = `literature_${format}_${date}.${format}`
        }
        if(window.showSaveFilePicker){
          let data = new Blob([response.data]);
          const handle = await window.showSaveFilePicker({suggestedName: title});
          const writable = await handle.createWritable();
          await writable.write( data );
          writable.close();
        }
        else{
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', title); //or any other extension
          link.setAttribute("target", "_blank");
          link.click();
        }

      } catch (e) {
        let message = "";
        if(e.data) message = e.data.message;
        else if(e.message) message = e.message;
        console.log(e);
        throw new Error(message);
      }
    },
    async favoriteItem({ state, commit, dispatch }, item) {
      try {
        Vue.set(item, "isFavorite", !item.isFavorite);
        commit("updateDataHelper", item);
        await this.$axios.post("/literatures/bulk/favorite", {
          itemList: [item.id],
          unFavorite: !item.isFavorite
        });
        dispatch(
          "setFavoriteCount",
          item.isFavorite ? state.favoriteCount + 1 : state.favoriteCount - 1
        );
      } catch (e) {
        Vue.set(item, "isFavorite", !item.isFavorite);
        commit("updateDataHelper", item);
        throw new Error("Something went wrong");
      }
    },
    downloadLiteraturePDF({}, pdfURL) {
      let a = document.createElement("a");
      a.setAttribute("href", pdfURL);
      a.setAttribute("download", "");
      a.setAttribute("target", "_self"); // Downloading literature item on same page
      a.click();
    },
    async getLiteratureCitation({ state, dispatch },{literatureIds,isDownload,target,type,title}){
      try{
        if(!isDownload){
          this.commit(
            "literatures/setDataByKey",
            {
              key: "literatureCitaion",
              data: null
          });
          let citationData = await this.$axios.get("/export-literature/stylise", {
            params:{literatureIds,isDownload,target}
          });
          this.commit(
            "literatures/setDataByKey",
            {
              key: "literatureCitaion",
              data: citationData.data.formatCitation
            });
        }
        else{
          var response = await this.$axios.get(`/export-literature/stylise`, {
            params:{literatureIds,isDownload,target}
          });
          let x = new Date();
          let date = ((x.getMonth()<10?"0"+x.getMonth().toString():x.getMonth().toString())+"-"+(x.getDate()<10?"0"+x.getDate().toString():x.getDate().toString())+"-"+(x.getFullYear().toString()));
          if(title){
            title = `${title}_${type}_${date}.txt`
          }
          else{
            title = `literature_${type}_${date}.txt`
          }
          if(window.showSaveFilePicker){
            let data = new Blob([response.data]);
            const handle = await window.showSaveFilePicker({suggestedName: title});
            const writable = await handle.createWritable();
            await writable.write( data );
            writable.close();
          }
          else{
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', title); //or any other extension
            link.setAttribute("target", "_blank");
            link.click();
          }
        }
      }
      catch(e){
        throw new Error(e.message);
      }
    },
    async bulkArchive({ state, dispatch }, itemList) {
      itemList = itemList || Object.keys(state.selectedItems);
      dispatch('bulkFavorite', { itemList, favoriteFlag: false });
      const { data = [] } = await this.$axios.post("/literatures/bulk/archive", {
        itemList,
        unArchive: false
      });
      data.forEach(id => {
        const index = state.data.findIndex(i => i.id == id);
        const indexInLazy = state.literatureData.data.findIndex(i => i.id == id);
        if (indexInLazy !== undefined && isNumber(indexInLazy) && indexInLazy >= 0) {
          Vue.set(state.literatureData.data[indexInLazy], "status", "3");
        }
        if (index !== undefined && isNumber(index) && index >= 0) {
          Vue.set(state.data[index], "status", "3");
        }
      })
    },

    async bulkDelete({ state, dispatch, commit }, itemList) {
      itemList = itemList || Object.keys(state.selectedItems);
      itemList.forEach((x) => {
        commit('deletenewUserLiteratures', x);
      })
      dispatch('bulkFavorite', { itemList, favoriteFlag: false });
      const { data } = await this.$axios.post("/literatures/bulk/delete", {
        itemList,
      });
      data.isDeletedIds.forEach(id => {
        const index = state.data.findIndex(i => i.id == id);
        const indexInLazy = state.literatureData.data.findIndex(i => i.id == id);
        if (indexInLazy !== undefined && isNumber(indexInLazy) && indexInLazy >= 0) {
          state.literatureData.data.splice(indexInLazy, 1);
          // Vue.set(state.literatureData.data[indexInLazy], "status", "3");
        }
        if (index !== undefined && isNumber(index) && index >= 0) {
          // Vue.set(state.data[index], "status", "3");
          state.data.splice(index, 1);
        }
      })
    },

    async bulkDownload({ state, dispatch }, itemList) {
      try {
        itemList = Object.keys(state.selectedItems);
        await dispatch('bulkFavorite', { itemList, favoriteFlag: false });
        const pdfDownloadParams = [];
        await itemList.forEach(id => {
          const index = state.data.findIndex(i => i.id == id);
          const indexInLazy = state.literatureData.data.findIndex(
            i => i.id == id
          );
          if (indexInLazy !== undefined && isNumber(indexInLazy) && indexInLazy >= 0) {
            const s3Key = state.literatureData.data[indexInLazy].s3Key;
            const title = state.literatureData.data[indexInLazy].title;
            pdfDownloadParams.push({
              "path": s3Key,
              title
            });
          }
        });
        const { data } = await this.$axios.post("/literatures/bulk/download", {
            pdfDownloadParams,
        });
        const myPromise = new Promise((resolve, reject) => {
          resolve({
            resourceUrl: data.resourceUrl,
            size: data.size
          });
        });
        return myPromise;

      } catch (e) {
        throw e;
      }

    },
    async download({ commit }, path) {
      let urls = [path];
      let interval = setInterval(download, 300, urls);

      function download(urls) {
        let url = urls.pop();
        let a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("download", "");
        a.setAttribute("target", "_blank");
        a.click();

        if (urls.length === 0) {
          clearInterval(interval);
        }
      }
    },

    openFetchDialog(dialogPath) {
      dialogPath = "literature/bulk/archiveModal"
      if (!this.asyncComp) {
        this.asyncComp = () => AsyncComponent(dialogPath);
        this.asyncComp()
          .component.then(() => {})
          .catch(() => {
            this.asyncComp = false;
          });
      } else {
        $(`#${this.dialogId}`).MaterialDialog("show");
      }
    },

    async bulkRestore({ state }) {
      const itemList = Object.keys(state.selectedItems);
      const { data = [] } = await this.$axios.post("/literatures/bulk/archive", {
        itemList,
        unArchive: true
      });
      data.forEach(id => {
        const index = state.archivedData.findIndex(i => i.id == id);
        const data = state.archivedData.splice(index, 1);
      })
    },
    async bulkFavorite({ state, commit, dispatch }, { itemList, favoriteFlag }) {
      itemList = itemList || Object.keys(state.selectedItems);
      const { data = [] } = await this.$axios.post(
        "/literatures/bulk/favorite",
        {
          itemList,
          unFavorite: !favoriteFlag
        }
      );
      itemList.forEach(id => {
        const index = state.data.findIndex(i => i.id == id);
        const indexInLazy = state.literatureData.data.findIndex(
          i => i.id == id
        );
        if (indexInLazy !== undefined && isNumber(indexInLazy) && indexInLazy >= 0) {
          Vue.set(
            state.literatureData.data[indexInLazy],
            "isFavorite",
            favoriteFlag
          );
        }
        if (index !== undefined && isNumber(index) && index >= 0) {
          Vue.set(state.data[index], "isFavorite", favoriteFlag);
        }
      });
      dispatch(
        "setFavoriteCount",
        favoriteFlag
          ? state.favoriteCount + data.length
          : state.favoriteCount - data.length
      );
    },
    async fetchLazy({ commit, state, dispatch }, obj) {
      // get filter and sort value
      commit('setRecentProjectId', obj.id);
      commit('setRecentSlug', obj.slug);
      if (state.isFetching && request) {
        request.cancel("delete last fetch call");
        return;
      }
      if (!request) {
        request = axios.CancelToken.source();
      }
      if (obj.slug) {
        commit('updateCurrentProjectSlug', obj.slug);
      }
      commit("setIsFetching", true);
      const appliedFilter = state.filterData;
      const appliedSort = state.currentSort;
      const filterData = {
        authors: appliedFilter.addedAuthors && appliedFilter.addedAuthors.map(author => author.label),
        tagIds: (appliedFilter.addedTags && appliedFilter.addedTags.map(tag=> tag.id)) || [],
        publicationYear: appliedFilter.from && appliedFilter.to && { start: appliedFilter.from, end: appliedFilter.to },
        fetchFavorites: state.fetchFavorites
      }
      if (appliedFilter.citationCountFrom != null && appliedFilter.citationCountTo != null) {
        filterData.citationCount = {
          start: appliedFilter.citationCountFrom,
          end: appliedFilter.citationCountTo
        }
      }
      if (obj.isFresh) {
        commit('setLiteratureItems', {data:[], hasMore: true});
      }
      const offset = obj.isFresh || obj.isRepetitive ? 0 : state.literatureData.data.length;
      try {
        let fetch;
        if(obj.id){
          fetch = await this.$axios.post(
            `${process.env.serverLessBaseUrl}/literatures/index`,
            // `http://localhost:4012/dev/index`,
            {
              "projectId": String(obj.id),
              archived:0,
              limit:10,
              offset,
              sort:{
                "field": appliedSort,
                "order": appliedSort !== SORT_BY.Title ? "DESC" : "ASC"
              },
              ...filterData
            },
            { cancelToken: request.token }
          )
        }
        else{
          fetch = await this.$axios.post(
            `${process.env.serverLessBaseUrl}/literatures/index`,
            {
              archived:0,
              limit:25,
              offset,
              sort:{
                "field": appliedSort,
                "order": appliedSort !== SORT_BY.Title ? "DESC" : "ASC"
              },
              ...filterData
            },
            { cancelToken: request.token }
          )
        }
        const {data} = fetch;
        const tempLiteratureArray = offset ? state.literatureData.data : [];
        const newData = {
          hasMore: data.hasMore,
          data: [...tempLiteratureArray, ...data.data],
        }
        dispatch('setFavoriteCount', data.counts.favorite);
        if (state.selectAll) {
          data.data.forEach(item => {
            Vue.set(state.selectedItems, item.id, true);
          });
        }
        if (!state.isFilterApplied && !state.fetchFavorites && !data.hasMore) {
          commit('setHaveAllData', true);
        }
         commit("setLiteratureItems", newData);
         commit("setIsFetching", false);
         request = null;

      } catch (error) {
        commit("setIsFetching", false);
        if (!error) {
          request = null;
          commit('setLiteratureItems', {data:[], hasMore: true});
          if(obj.slug)
          dispatch('fetchLazy', { slug: state.recentSlug, id: state.recentProjectId });
          else
          dispatch('fetchLazy', {isArchived: 0, slug: state.recentSlug, id: state.recentProjectId});
        }
      }

    },
    async updateFilterData({ commit, dispatch, state }, data){
      // if previously no filter was applied and not hasMore data then no need to kick api. else remove everything and add fresh data
      if ((!state.isFilterApplied && !state.literatureData.hasMore) || state.haveAllData) {
        commit('setCurrentFilter', data);
      }else{
        commit('setCurrentFilter', data);
        commit('setLiteratureItems', {data:[], hasMore: true});
        dispatch('fetchLazy', { slug: state.currentProjectSlug });
      }
      // on update call fetch api
    },
    async updateFetchFavorites({ commit, dispatch, state }, {slug,data}) {
      commit('setDataByKey', {
        key: 'fetchFavorites',
        data
      });
      if (state.literatureData.hasMore || !state.haveAllData) {
        commit('setLiteratureItems', { data:[], hasMore: true });
        if(slug) dispatch('fetchLazy', { slug });
        else dispatch('fetchLazy', {});
      }
    },
    async updateSortData({ commit, dispatch, state }, {type, passProjectSlug}){
      // if previously no filter was applied and not hasMore data then no need to kick api. else remove everything and add fresh data
      if ((!state.isFilterApplied && !state.literatureData.hasMore) || state.haveAllData) {
        commit('setCurrentSort', type);
      }else{
        commit('setCurrentSort', type);
        commit('setLiteratureItems', {data:[], hasMore: true});
        if(passProjectSlug)
        dispatch('fetchLazy', { slug: state.currentProjectSlug });
        else
        dispatch('fetchLazy', {});
      }
    },
    async fetch({ commit, dispatch }, obj) {
      const { data } = await this.$axios.get(
        `/literatures/${obj.slug}`
      );
      const newData = {
        hasMore: false,
        data: [...data],
      }
      commit('setHaveAllData', true);
      commit("setLiteratureItems", newData);
      commit("setData", data);
    },
    async fetchV2({ commit, dispatch }, obj) {
      let params = obj.slug?{slug:obj.slug}:{};
      const { data } = await this.$axios.post(`/v2/literatures/list`,params);
      // const { data } = await this.$axios.get(
      //   `/v2/literatures/${obj.slug}`
      // );
      commit("setData", data);
    },

    async fetchArchived({ commit }) {

      const { data } = await this.$axios.post(
        `${ process.env.serverLessBaseUrl }/literatures/index`,
        {
          archived:1,
          offset:0,
          sort:{
            "field": SORT_BY.AccessedAt,
            "order": "DESC"
          },
        });
      commit("setArchivedData", data.data);
    },
    async fetchAuthors({ commit }, slug){
      const { data } = await this.$axios.get(`/literatures/authors/all/${slug}`);
      commit("setCurrentProjectAuthors", data);
    },
    async fetchTags({ commit }, slug){
      const { data } = await this.$axios.get(`/tags/all/literatures/${slug}`);
      commit("setCurrentProjectTags", data);
    },

    async archive({ commit, state, dispatch }, inputs) {
      dispatch('bulkFavorite', { itemList: [inputs.itemId], favoriteFlag: false });
      const { data } = await this.$axios.post(`/literatures/archive`, inputs);
      if(inputs.unArchive){
        if(state.archivedIndex!=-1){
          state.literatureData.data.splice(state.archivedIndex, 1, data);
          state.data.splice(0, 0, data);
        }
      }
      else{
        const index = state.data.findIndex(i => i.id == inputs.itemId);
        const indexInLazy = state.literatureData.data.findIndex(i => i.id == inputs.itemId);
        commit("setArchivedIndex", indexInLazy);
        if(index!=undefined && index!=-1){
          state.data.splice(index, 1);
        }
        if(indexInLazy!=undefined && indexInLazy!=-1){
          state.literatureData.data.splice(indexInLazy, 1);
        }
      }
      commit("updateArchivedData", data);
    },

    async onInvite({ commit }, _data) {
      const data = { ..._data, status: "1", isOwner: 1, isShared:true };
      commit("updateData", data);
    },

    async fetchTitles({ commit }) {
      let projectSlug = this.$router.currentRoute.fullPath.split("/")[2];
      const { data } = await this.$axios.get(
        `/literatures/partial/${projectSlug}`
      );
      commit("setTitleList", data);
    },

    async fetchExceptExits({ commit, state }, obj) {
      const { data } = await this.$axios.get(
        `/literatures/${obj.slug}`
      );
      let filterArray = data.filter(item => {
        return !arraySearch(state.data, item.id);
      });
      for (let i = 0; i < filterArray.length; i++) {
        commit("addDataAfter", filterArray[i]);
      }
    },

    async show({ commit, state }, obj) {
      try {
        const { data } = await this.$axios.get(
          `/literatures/show/${obj.slug}?withTags=true&withContacts=true`
        );
        commit("updateData", data);
        commit("setCurrentData", data);
        return data;
      } catch (e) {
        console.log(e);
      }
    },

    async update({ commit, state }, inputs) {
      const { data } = await this.$axios.put(
        `/literatures?withContacts=true&withTags=true`,
        inputs
      );
      commit("updateData", data);
    },

    async create({ commit }, inputs) {
      const { data } = await this.$axios.post("/literatures", inputs);
      commit("addData", data);
    },

    async createUrl({ commit, dispatch }, inputs) {
      const { data } = await this.$axios
        .post(
          `${process.env.serverLessBaseUrl}/literatures-submit/url/import?withContacts=true&withTags=true`,
          // `http://localhost:4006/dev/url/import?withContacts=true&withTags=true`,
          inputs
        )
        .catch(error => {
          return Promise.reject(error);
        });
      commit("addData", data);
      commit("setObjData", {
        name: "firstFetching",
        keyName: "literatureId",
        value: data.id
      });
      commit("setObjData", {
        name: "firstFetching",
        keyName: "isFetching",
        value: true
      });
      setTimeout(async () => {
        await dispatch("show", data);
        commit("setObjData", {
          name: "firstFetching",
          keyName: "literatureId",
          value: data.id
        });
        commit("setObjData", {
          name: "firstFetching",
          keyName: "isFetching",
          value: false
        });
      }, 8000);
    },

    updateCurrentPage({ commit, dispatch }, data) {
      commit("setObjData", {
        name: "firstFetching",
        keyName: "literatureId",
        value: data.id
      });
      commit("setObjData", {
        name: "firstFetching",
        keyName: "isFetching",
        value: true
      });
      setTimeout(async () => {
        await dispatch("show", data);
        commit("setObjData", {
          name: "firstFetching",
          keyName: "literatureId",
          value: data.id
        });
        commit("setObjData", {
          name: "firstFetching",
          keyName: "isFetching",
          value: false
        });
      }, 8000);
    },

    async updateFromUrl({ state, commit, dispatch }, inputs) {
      const { data } = await this.$axios
        .post(
          `${process.env.serverLessBaseUrl}/literatures-submit/update/url/import?withContacts=true&withTags=true`,
          // "http://localhost:4006/dev/update/url/import?withContacts=true&withTags=true",
          inputs
        )
        .catch(error => {
          return Promise.reject(error);
        });
      commit("updateData", data);
      commit("setObjData", {
        name: "firstFetching",
        keyName: "literatureId",
        value: data.id
      });
      commit("setObjData", {
        name: "firstFetching",
        keyName: "isFetching",
        value: false
      });
      setTimeout(async () => {
        await dispatch("show", data);
        commit("setObjData", {
          name: "firstFetching",
          keyName: "literatureId",
          value: data.id
        });
        commit("setObjData", {
          name: "firstFetching",
          keyName: "isFetching",
          value: false
        });
      }, 8000);
    },

    async createFromResources({ commit, dispatch }, inputs) {
      function isValidHttpUrl(string) {
        let url;

        try {
          url = new URL(string);
        } catch (_) {
          return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
      }
      if(inputs.pdfUrls&&inputs.pdfUrls.length){
        inputs.pdfUrls = inputs.pdfUrls.filter(url=>isValidHttpUrl(url));
      }

      const { data } = await this.$axios.post(`${process.env.serverLessBaseUrl}/literatures-submit/resource/import`, inputs);
      commit("addData", data);
      commit("addRecentlyAddedLiterature",data);
      return data;
    },

    async bulkCreateFromResources({ commit, dispatch }, inputs) {
      // const requestPayload = [];
      // inputs.forEach((input) => {
      //   requestPayload.push(input)
      // })
      let { data } = await this.$axios.post(`${process.env.serverLessBaseUrl}/literatures-submit/bulk/resource/import`, inputs);
      commit("addBulkData", data);
      commit("addRecentlyAddedLiterature",data);
      return data;
    },

    async createCopy({ commit }, inputs) {
      const { data } = await this.$axios.post("/literature/copy", inputs);
      commit("addData", data);
    },

    async checkDuplicateInProject({ commit }, inputs) {
      const { data } = await this.$axios.post("/literatures/duplicate/check", inputs);
      return data;
    },

    async copyToProjects({ commit }, inputs) {
      const { data } = await this.$axios.post("/literatures/project/copy", inputs);
    },

    async moveToProject({ commit }, inputs) {
      const { data } = await this.$axios.post("/literatures/project/move", inputs);
      for (const id of inputs.ids) {
        commit("deleteData", { id });
      }
    },

    async delete({ commit, state }, inputs) {
      await this.$axios.delete(
        `${process.env.serverLessBaseUrl}/literatures-delete/${inputs.slug}`,
        // `http://localhost:4009/dev/${inputs.slug}`
      );
      commit("deleteData", inputs);
      commit('deletenewUserLiteratures', inputs.id);
      commit("reminders/deleteData", { id: inputs.id, projectId: inputs.projectId }, { root: true });
    },

    async populateLiterature({ state, dispatch, commit }) {
      await dispatch("htmlAvailable");
    },

    async htmlAvailable({ state, dispatch, commit }) {
      try {
        commit("setIsFetching", true);
        await dispatch("getComments");
        commit("setIsFetching", false);
      } catch (e) {
        commit("setIsFetching", false);
      }
    },

    async getComments({ state }) {
      const { data } = await this.$axios.post("/shared/item/comments/fetch", {
        itemId: state.currentData.id,
        type: "literatures"
      });
      let filterArray = data.filter(item => {
        return !arraySearch(this.state.sharedComments.comments, item.id);
      });
      for (let i = 0; i < filterArray.length; i++) {
        this.commit("sharedComments/addData", {
          key: "comments",
          data: filterArray[i]
        });
      }
      this.dispatch("sharedItemUsers/fetch", {
        itemId: state.currentData.id,
        type: "literatures"
      });
    },
    addTags({ commit, state }, obj) {
      this.dispatch("results/addTags",obj);
      let lazyIndex = findObjectIndex(state.literatureData.data, { id: obj.inputs.itemId });
      if (lazyIndex !== undefined && isNumber(lazyIndex) && state.literatureData.data[lazyIndex]) {
        obj.data.forEach(item => {
          const tag = [...state.literatureData.data[lazyIndex].tags, { ...item, id: item.tagId }];
          state.currentProjectMetaData.tags.push({ ...item, id: item.tagId });
          commit('updateTag', {index:lazyIndex, data: tag});
        });
      }
    },

    async updateTags({ commit, state }, obj) {
      this.dispatch("results/updateTags",obj);
      for (let i = 0; state.literatureData.data.length; ++i) {
        const index_ = state.literatureData.data[i].tags.findIndex(tag => tag.id === obj.data.id);
        if (index_ !== -1) {
          await Vue.set(state.literatureData.data[i].tags, index_, obj.data);
        }
      }
    },

    deleteTags({ commit, state }, obj) {
      this.dispatch("results/deleteTags",obj);
      let index = findObjectIndex(state.data, { id: obj.inputs.id });
      if (index !== -1 && state.data[index].tags) {
        const index_ = findObjectIndex(state.data[index].tags, {
          id: obj.data.id
        });
        index_ !== -1 && state.data[index].tags.splice(index_, 1);
      }

      let lazyIndex = findObjectIndex(state.literatureData.data, { id: obj.inputs.id });
      if (lazyIndex !== undefined && isNumber(lazyIndex) && state.literatureData.data[lazyIndex]) {

        const index_ = state.literatureData.data[lazyIndex].tags.findIndex(tag=> tag.id == obj.data.id);
        index_ !== -1 &&  state.literatureData.data[lazyIndex].tags.splice(index_, 1);
        const indexInTag = state.currentProjectMetaData.tags.findIndex(tag=> tag.id == obj.data.id);
        if (indexInTag > -1) {
          state.currentProjectMetaData.tags.splice(indexInTag, 1);
        }
      }
    },
    async fetchCitations({ state }, id) {
      const { data } = await this.$axios.post(
        `${ process.env.serverLessBaseUrl }/literature-citations/citations/get`,
        {
          id
        }
      );
      Vue.set(state["currentData"], "citations", data);
    },
    removeRecentlyAddedSuggestions({state,dispatch}){
      let suggestionList = state.recentlyAddedSuggestion;
      let itemList  = suggestionList.map(item=>item.id);
      dispatch("bulkDelete",itemList);
    }
  },

  getters: {
    getData(state) {
      return state.data;
    },
    getLiteratureList(state) {
      return state.literatureData.data || [];
    },
    hasMoreLiteratureItems(state) {
      return state.literatureData.hasMore;
    },
    getArchivedData(state) {
      return state.archivedData;
    },
    getCurrentData(state) {
      return state.currentData;
    },
    getFirstFetching(state) {
      return state.firstFetching;
    },
    getTags(state) {
      if (Object.keys(state.currentData).length && state.currentData.tags) {
        return state.currentData.tags;
      }
    },
    getIsFetching(state) {
      return state.isFetching;
    },
    currentPdfAction(state) {
      return state.currentPdfAction;
    },
    openCommentsInfo(state) {
      return state.openComments;
    },
    getCurrentPin(state) {
      return state.openPins;
    },
    getCurrentSort(state) {
      return state.currentSort;
    },
    getFilterData(state) {
      return state.filterData;
    },
    getCurrentProjectTags(state) {
      return state.currentProjectMetaData.tags;
    },
    getCurrentProjectAuthors(state) {
      return state.currentProjectMetaData.authors;
    },
    isFilterApplied(state) {
      return state.isFilterApplied;
    },
    getReminderPanelId(state) {
      return state.reminderPanelId;
    },
    getSelectedCount(state) {
      return Object.keys(state.selectedItems).length;
    },
    getSelectedItems(state) {
      return state.selectedItems;
    },
    getSelectedKeys(state) {
      return Object.keys(state.selectedItems);
    },
    getSelectAll(state) {
      return state.selectAll;
    },
    getFetchFavorites(state) {
      return state.fetchFavorites;
    },
    getFavoriteCount(state) {
      return state.favoriteCount;
    },
    getSelectedLiteratureItem(state) {
      return state.selectedLiteratureItem;
    },
    getCitationFormatting(state){
      return state.literatureCitaion;
    },
    getNewUserLiteratureIds(state) {
      return state.newUserLiteratureIds;
    }
  }
};
