import Vue from "vue";
import findObjectIndex from "@/assets/js/commonHelper";
import { SORT_BY } from "~/components/literature/constants";
import { isNumber } from "lodash";

const defaultProjectRefState = () => {
  return {
    data: [],
    fetched: false,
    currentSort: SORT_BY.CreatedAt,
    selectedItems: {},
    selectAll: false,
  };
};
export default {
  namespaced: true,
  state: defaultProjectRefState,

  mutations: {
    setData(state, data) {
      state["data"] = data;
    },
    addData(state, data) {
      state["data"].unshift(data);
    },
    updateData(state, obj) {
      state.data.splice(obj.index, 1, obj.data);
    },
    setFetched(state, data) {
      state["fetched"] = data;
    },
    setDataByKey(state, obj) {
      state[obj.key] = obj.data;
    },
    deleteData(state, index) {
      state.data.splice(index, 1);
    },
    resetState(state) {
      Object.assign(state, defaultProjectRefState());
    },
    setCurrentSort(state, data) {
      state.currentSort = data;
    },
    selectAllLiteratures(state, list) {
      state.selectAll = true;
      list.forEach(item => {
        Vue.set(state.selectedItems, item.id, true);
      });
    },
    unselectAllLiteratures(state) {
      state.selectedItems = {};
      state.selectAll = false;
    },
    updateSelectedItems(state, obj){
      if (obj.val) {
        this.commit(
          "projectReferences/setDataByKey",
          {
            key: "selectedItems",
            data: { ...state.selectedItems, [obj.id]: true }
          },
          { root: true }
        );
      } else {
        Vue.delete(state.selectedItems, obj.id);
      }
    }
  },

  actions: {
    async fetch({ commit, state, dispatch }, project) {
      try {
      const config = {
        headers: {"Access-Control-Allow-Origin": "*"}
      }
      const payload = {
        projectId: project.id
      };
      const { data } = await this.$axios.post(`/project-references/list`, payload, config);

      if (state.data.length) {
        let index;
        data.forEach((item) => {
          index = findObjectIndex(state.data, item);
          if (index === -1) {
            commit("addData", item);
          } else {
            commit("updateData", { data: item, index: index });
          }
        });
      } else {
        commit("setData", data);
      }
      } catch(e) {
        console.log(e);
      } 
    },
    async fetchAll({ commit, state, dispatch }) {
      try {
      const config = {
        headers: {"Access-Control-Allow-Origin": "*"}
      }
      const {data}  = await this.$axios.post(`/project-references/list/?withTags=true`, config); 
      if (state.data.length) {
        let index;
        data.forEach((item) => {
          index = findObjectIndex(state.data, item);
          if (index === -1) {
            commit("addData", item);
          } else {
            commit("updateData", { data: item, index: index });
          }
        });
      } else {
        commit("setData", data);
      }
      } catch(e) {
        console.log(e);
      } 
    },
    async update({ commit, state }, inputs) {
      const { data } = await this.$axios.put(`/project-references?withTags=true`, inputs);
      let index = findObjectIndex(state.data, inputs);
      commit("updateData", { data: data[0], index: index });
    },

    async create({ commit }, inputs) {
      const { data } = await this.$axios.post("/project-references?withTags=true", inputs);
      commit("addData", data);
    },

    async createByUrl({ commit }, inputs) {
      const { data } = await this.$axios.post("/project-references/url/import", inputs);
      commit("addData", data);
    },

    async delete({ commit, state }, inputs) {
      try{
        const { data } = await this.$axios.delete(`/project-references/${inputs.id}`);
        if (data && data.id) {
          let index = findObjectIndex(state.data, inputs);
          if (index != -1) {
            commit("deleteData", index);
          }
        }
      } catch(e) {
        console.log("ERR at projectReferences/delete: ", e)
      }
    },

    addTags({ commit, state }, obj) {
      let index = findObjectIndex(state.data, {id: obj.inputs.itemId})
      obj.data.forEach((item) => {
        state.data[index].tags.push({...item, id: item.tagId})
      })
    },

    updateTags({ commit, state }, obj) {
      let index = findObjectIndex(state.data, {id: obj.inputs.id})
      if (index !== -1) {
        const index_ = findObjectIndex(state.data[index].tags, {id: obj.data.id})
        index_!== -1 && (state.data[index].tags[index_] = obj.data)
      }
    },

    deleteTags({ commit, state }, obj) {
      let index = findObjectIndex(state.data, {id: obj.inputs.id})
      if (index !== -1) {
        const index_ = findObjectIndex(state.data[index].tags, {id: obj.data.id})
        index_!== -1 && state.data[index].tags.splice(index_, 1)
      }
    },
    async updateSortData({ commit, dispatch, state }, {type}){
      commit('setCurrentSort', type);
    },
    async bulkDelete({ state, dispatch, commit }, itemList){
      itemList = itemList || Object.keys(state.selectedItems);
      await this.$axios.post("/project-reference/bulk/delete", {
        itemList,
      });
      itemList.forEach(id => {
        const index = state.data.findIndex(i => i.id == id);
        console.log(index);
        if (index !== undefined && isNumber(index) && index >= 0) {
          state.data.splice(index, 1);
        }
      })
    }
  },

  getters: {
    getData(state) {
      return state.data;
    },

    getCount(state) {
      return state.data.length;
    },
    getFetched(state) {
      return state.fetched;
    },
    getCurrentSort(state) {
      return state.currentSort;
    },
    getSelectedCount(state) {
      return Object.keys(state.selectedItems).length;
    },
    getSelectAll(state) {
      return state.selectAll;
    },
    getSelectedItems(state) {
      return state.selectedItems;
    },
    getSelectedKeys(state) {
      return Object.keys(state.selectedItems);
    },
    getSelectedCount(state) {
      return Object.keys(state.selectedItems).length;
    }
  }
};
