<template>
  <div class="rx-empty">
    <div class="rx-empty__img-wrapper">
      <div
        class="md-layout md-align md-align--center-center"
        :class="{ 'rx-empty__circle': circle }"
      >
        <i v-if="isIcon" class="material-icons no-user-select">
          {{ icon }}
        </i>
        <img v-else-if="img" :src="imgSrc" class="rx-empty__img" />
      </div>
    </div>
    <div class="rx-empty__text-wrapper">
      <div
        v-if="heading"
        class="md-typo--title rx-empty__title my-typo--font-light"
      >
        {{ heading }}
      </div>
      <div v-if="subHeading" class="rx-empty__subtext" v-html="subHeading"/>
      <button
        v-if="showButton"
        :class="buttonClass"
        :disabled="disabled"
        class="layout-m-t-2 md-button"
        @click="$emit('buttonClick', $event)"
      >
        {{ buttonText }}
      </button>
      <slot name="body" />
    </div>
  </div>
</template>
<style>
.rex-color-contrast {
  color: rgba(0, 0, 0, 0.7);
  font-size: 15px;
}

.rx-filter-button {
  color: rgba(0, 0, 0, 0.54) !important;
  text-transform: none;
}

.rx-empty {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 24px;
  margin-right: auto;
  margin-left: auto;
}

.rx-empty__title {
  margin-top: 16px;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: var(--color-dark);
}

.rx-empty__circle {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  font-size: 92px;
  position: relative;
}

.rx-empty__text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rx-empty__circle::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--color-primary);
  opacity: 0.2;
}
.rx-empty__circle > * {
  z-index: 1;
}

.rx-empty__circle .material-icons {
  font-size: inherit;
  color: var(--color-primary);
  z-index: 1;
  position: relative;
}

.rx-empty__subtext {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-dark-secondary);
  margin-top: 16px;
  line-height: 20px;
}

.rx-empty--has-divider {
  position: relative;
}

.rx-empty--has-divider::after {
  position: absolute;
  top: 10%;
  right: -8px;
  height: 80%;
  width: 1px;
  background-color: var(--color-dark-divider);
  content: "";
  z-index: 0;
}
.rx-empty__img {
  max-width: 320px;
  max-height: 190px;
  padding: 16px;
}
.rx-empty--gray .rx-empty__circle::before {
  background-color: var(--color-light-app-bar);
  opacity: 1;
}

.rx-empty--danger .rx-empty__circle::before {
  background-color: var(--color-danger);
}

.rx-empty--danger .material-icons {
  color: var(--color-danger);
}

@media (min-width: 720px) {
  .rx-empty--large .rx-empty__circle {
    width: 204px;
    height: 204px;
    font-size: 120px;
  }
  .rx-empty--horizontal {
    width: 644px;
    flex-direction: row;
    text-align: left;
  }
  .rx-empty--horizontal .rx-empty__title {
    margin-top: 0;
  }
  .rx-empty--horizontal .rx-empty__text-wrapper {
    align-items: flex-start;
    padding-left: 32px;
  }
}
</style>
<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    heading: {
      type: String,
      default: null
    },
    subHeading: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    isIcon: {
      type: Boolean,
      default: true
    },
    showButton: {
      type: Boolean,
      default: false
    },
    buttonText: {
      type: String,
      default: "Try Again"
    },
    buttonClass: {
      type: String,
      default: "md-button--raised md-button--primary"
    },
    img: {
      type: String,
      default: null
    },
    circle: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    imgSrc() {
      return process.env.assetsUrl + this.img;
    }
  }
};
</script>
