import Vue from "vue";

const defaultReminderState = () => {
  return {
    data: {
      literature: {},
      projectsWithReminder: {}
    },
    todaysReminders: []
  };
};

export default {
  namespaced: true,
  state: defaultReminderState,

  mutations: {
    setTodaysReminders(state, data) {
      state.todaysReminders = data;
    },
    setData(state, data) {
      data.forEach(item => {
        const id = item && item.literature && item.literature.id;
        if (id) {
          Vue.set(state.data.literature, id, item);
        }
        const projectId =
          item.status !== "completed" && item.project && item.project.id;
        if (projectId) {
          Vue.set(
            state.data.projectsWithReminder,
            projectId,
            state.data.projectsWithReminder[projectId]
              ? state.data.projectsWithReminder[projectId] + 1
              : 1
          );
        }
      });
    },
    addData(state, data) {
      const id = data && data.literature && data.literature.id;
      if (id) {
        Vue.set(state.data.literature, id, data);
      }
      const projectId = data && data.project && data.project.id;
      if (projectId) {
        Vue.set(
          state.data.projectsWithReminder,
          projectId,
          state.data.projectsWithReminder[projectId]
            ? state.data.projectsWithReminder[projectId] + 1
            : 1
        );
      }
    },
    updateData(state, data) {
      const id = data && data.literature && data.literature.id;
      if (id) {
        Vue.set(state.data.literature, id, data);
      }
    },
    updateProjectsWithReminder(state, { id, isComplete }) {
      if (isComplete) {
        Vue.set(
          state.data.projectsWithReminder,
          id,
          state.data.projectsWithReminder[id] - 1
        );
      } else {
        Vue.set(
          state.data.projectsWithReminder,
          id,
          state.data.projectsWithReminder[id]
            ? state.data.projectsWithReminder[id] + 1
            : 1
        );
      }
    },
    deleteData(state, { id, projectId }) {
      Vue.delete(state.data.literature, id);
      Vue.set(
        state.data.projectsWithReminder,
        projectId,
        state.data.projectsWithReminder[projectId] - 1
      );
    },
    resetState(state) {
      Object.assign(state, defaultReminderState());
    }
  },

  actions: {
    async fetch({ commit }) {
      const { data } = await this.$axios.get(
        `${process.env.serverLessBaseUrl}/reminders/fetch`
      );
      commit("setData", data);
    },
    async fetchByDate({ commit }, inputs) {
      const { data } = await this.$axios.get(
        `${process.env.serverLessBaseUrl}/reminders/fetch?start=${inputs.start
        }&end=${inputs.end}`
      );
      commit("setTodaysReminders", data);
    },
    async create({ commit }, inputs) {
      try {
        commit("addData", inputs.storeData);
        const { data } = await this.$axios.put(
          `${process.env.serverLessBaseUrl}/reminders/create`,
          inputs.reqData
        );
        inputs.storeData.id = data.id;
        commit("updateData", inputs.storeData);
      } catch (e) {
        commit("deleteData", {
          id:
            inputs.storeData &&
            inputs.storeData.literature &&
            inputs.storeData.literature.id,
          projectId:
            inputs.storeData &&
            inputs.storeData.project &&
            inputs.storeData.project.id
        });
        throw new Error(e);
      }
    },
    async update({ commit }, inputs) {
      const { data } = await this.$axios.patch(
        `${process.env.serverLessBaseUrl}/reminders/update`,
        inputs.reqData
      );
      console.log("this is the inputs causing issues ", inputs);
      commit("updateData", inputs.storeData);
      if (inputs.reqData.status === "completed") {
        commit("updateProjectsWithReminder", {
          id: inputs.storeData.project.id,
          isComplete: true
        });
      } else if (inputs.reqData.isUndo) {
        commit("updateProjectsWithReminder", {
          id: inputs.storeData.project.id,
          isComplete: false
        });
      }
    },
    async delete({ commit }, inputs) {
      await this.$axios.patch(
        `${process.env.serverLessBaseUrl}/reminders/update`,
        inputs.reqData
      );
        commit("deleteData", { id: inputs.id, projectId: inputs.projectId });
    }
  },

  getters: {
    getData(state) {
      return state.data.literature;
    },
    getTodaysReminders(state) {
      return state.todaysReminders;
    },
    getProjectsWithReminders(state) {
      return state.data.projectsWithReminder;
    }
  }
};
