var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Sidebar",
    {
      attrs: {
        isPanelOpen: _vm.isMobileDrawerOpen,
        isLeftSidePanel: false,
        hasBackdrop: false,
        sideBarPanelStyle: {
          width: "100%",
          position: "fixed",
          height: "100%",
          overflow: "hidden"
        }
      }
    },
    [
      _c("div", { staticClass: "tw-w-full" }, [
        _vm.isMobileDrawerOpen
          ? _c(
              "div",
              {
                staticClass:
                  "tw-absolute tw-text-black-72 tw-h-full tw-overflow-auto tw-w-full tw-z-20 tw-right-0 tw-bg-white",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                  }
                }
              },
              [
                _c("span", {
                  staticClass:
                    "raxIcon raxIcon-menu tw-right-6 tw-mt-5 tw-absolute raxIcon--white tw-cursor-pointer",
                  on: { click: _vm.hideDrawer }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "tw-pt-6 tw-pb-4 tw-px-6",
                    staticStyle: {
                      background:
                        "linear-gradient(180deg, #007BFB 0%, #1A64E2 100%)"
                    }
                  },
                  [
                    _vm.profile && _vm.profile.profilePic
                      ? [
                          _c("div", {
                            staticClass:
                              "tw-rounded-full tw-h-14 tw-w-14 tw-ml-1",
                            style: {
                              backgroundImage:
                                "url(" +
                                _vm.assetsUrl +
                                _vm.profile.profilePic +
                                ")"
                            }
                          })
                        ]
                      : [
                          _c(
                            "div",
                            {
                              staticClass:
                                "tw-rounded-full tw-h-14 tw-w-14 tw-flex tw-items-center tw-justify-center tw-bg-white tw-ml-1"
                            },
                            [
                              _c(
                                "i",
                                { staticClass: "material-icons tw-text-4xl" },
                                [_vm._v(" person ")]
                              )
                            ]
                          )
                        ],
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tw-mt-2 tw-font-medium tw-text-white" },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.profile && _vm.profile.firstName
                                ? _vm.profile.firstName
                                : ""
                            ) +
                            "\n          " +
                            _vm._s(
                              _vm.profile && _vm.profile.lastName
                                ? _vm.profile.lastName
                                : ""
                            ) +
                            "\n        "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "tw-mt-1 tw-text-xs tw-text-white" },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.user && _vm.user.email) +
                            "\n        "
                        )
                      ]
                    )
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "tw-border-b tw-border-0 tw-border-black-24 tw-border-solid tw-flex tw-flex-col"
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "tw-text-sm tw-ml-6 tw-mt-8 tw-font-medium",
                        attrs: {
                          href: "https://read.enago.com/",
                          target: "_blank"
                        }
                      },
                      [_vm._v("Know more about RAx")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "tw-text-sm tw-ml-6 tw-mt-8 tw-font-medium",
                        attrs: {
                          href: "https://read.enago.com/faq/",
                          target: "_blank"
                        }
                      },
                      [_vm._v("FAQs")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "tw-text-sm tw-ml-6 tw-my-8 tw-font-medium",
                        attrs: {
                          href: "https://read.enago.com/contact-us/",
                          target: "_blank"
                        }
                      },
                      [_vm._v("Contact us")]
                    )
                  ]
                ),
                _vm._v(" "),
                _vm.user && _vm.user.onboardingStatus !== 5
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "tw-ml-6 tw-mt-6 tw-font-medium tw-text-sm",
                        on: { click: _vm.logout }
                      },
                      [_vm._v("\n        Log out\n      ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "tw-flex tw-justify-center tw-absolute tw-left-1/2 tw--translate-x-1/2 tw-transform tw-bottom-2"
                  },
                  [
                    _c("img", {
                      attrs: { src: _vm.assetsUrl + "img/bot-with-bg.png" }
                    })
                  ]
                )
              ]
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }