import { AnnotationTypeMap, AnnotationTypeEnum, AnnotationType, AnnotationSourceType } from "~/components/literature/constants";
import { generateHighlightId, getProperSelection } from "~/components/literature/helper";
import { TourEvents } from "~/shared/constants";

const defaultAnnotationState = () => {
  return {
    currentLiteratureData:null,
    data:[],
    createdCommentData : null,
    highlighter: null,
    selectedAnnotationIdForAction:null,
    selectedActionType: null,
    selectedRange: null,
    selectedText: '',
    isHtmlRendered: false,
    selectedAnnotationPin: null,
    selectedAnnotationAttachment:null,
    selectedAnnotationNote:null,
    selectedAnnotationUrl:null,
    currentPage: 1,
    lastAttachmentHId: null,

    isUpdateHighlight: false,

    selectionHighlighter:null,
    selectionHighlightData: null,
    isTextSelected: false,
    prevNoteId: null
  };
};

export default {
  namespaced: true,
  state: defaultAnnotationState,

  mutations: {
    setData(state, payload) {
      state.data = payload;
    },
    setSelectedText(state, data) {
      state.selectedText = data;
    },
    setSelectedRange(state, data) {
      state.selectedRange = data;
    },
    setCurrentData(state, data) {
      state.currentLiteratureData = data;
    },
    setFlagForHighlightUpdateTo(state, payload){
      state.isUpdateHighlight = payload;
    },
    setCreatedCommentData(state, payload){
      state.createdCommentData = payload;
    },
    setForPinAnnotation(state, item){
      state.selectedAnnotationPin = item;
    },
    setForAttachmentAnnotation(state, item){
      state.selectedAnnotationAttachment = item;
    },
    setLastAttachmentHId(state, id){
      state.lastAttachmentHId = id;
    },
    setForNoteAnnotation(state, item){
      state.selectedAnnotationNote = item;
    },
    setForUrlAnnotation(state, item){
      state.selectedAnnotationUrl = item;
    },
    setCurrentPage(state, payload){
      state.currentPage = payload || 1
    },
    addToHighlightStore(state, payload){
      if (state.data) {
        state.data.push(payload);
      }else{
        state.data = [payload];
      }
    },
    removeFromHighlightStoreWithIndex(state,index){
      if (index !== -1) {
        state.data.splice(index, 1);
      }
    },
    removeComment(state, commentId){
      console.log(commentId);
      const selectedIndex = state.data.findIndex(i=> i.content == commentId);
      if (selectedIndex > -1) {
        const selectedData = state.data[selectedIndex];
        state.highlighter.remove(selectedData.hId);
      }
    },
    setHighlighterInstance(state, payload){
      state.highlighter = payload;
    },
    setSelectionHighlighterInstance(state, payload){
      state.selectionHighlighter = payload;
    },
    setSelectionHighlightData(state, payload){
      state.selectionHighlightData = payload;
    },
    setAnnotationIdForAction(state, payload){
      state.selectedAnnotationIdForAction = payload;
    },
    setSelectedActionType(state, payload){
      state.selectedActionType = payload;
    },
    setHighlighterInstance(state, payload){
      state.highlighter = payload;
    },
    highlightFromRange(state){
      state.highlighter.fromRange(state.selectedRange);
      state.selectedRange = null;
    },
    highlightFromStore(state){
      if (!state.highlighter) {
        return;
      }
      (state.data || []).forEach((hs) => {
        state.highlighter.fromStore(
          hs.startMeta,
          hs.endMeta,
          hs.text,
          hs.hId,
          AnnotationTypeMap[hs.type]
        );
      })
    },
    deleteFromId(state, id){
      const selectedItemIndex = state.data.findIndex(i=>i.id === id);
      const selectedItem = state.data[selectedItemIndex];
      state.highlighter.remove(selectedItem.hId);
    },
    hasHtmlRendered(state, payload){
      state.isHtmlRendered = payload;
    },
    updateRange(state, payload){
      state.selectedRange = payload;
    },
    destroy(state){
      // Merge rather than replace so we don't lose observers
      // https://github.com/vuejs/vuex/issues/1118
      Object.assign(state, defaultAnnotationState())
    },
    setIsTextSelected(state, data) {
      state.isTextSelected = data;
    },
    setPrevNoteId(state, data) {
      state.prevNoteId = data;
    }
  },
  actions: {
    async fetch({ commit, state }) {
      if (!state.currentLiteratureData) return;
      const { data } = await this.$axios.get(
        "/reviewer-literature/" + state.currentLiteratureData.slug + "/annotations"
      );
      commit("setData", data);
      commit('highlightFromStore');
    },
    async create({ commit, state }, inputs) {
      console.log(inputs);
      // creating comment
      if (inputs.type === AnnotationTypeEnum.comment) {
        inputs.content = state.createdCommentData.id;
      }
      const { data } = await this.$axios.post("/reviewer-literature/annotations", inputs);
      commit('addToHighlightStore', { ...data, annotationSource: AnnotationSourceType.Literature});
      return data;
    },
    async delete({ commit, state }, hId){
      const selectedItemIndex = state.data.findIndex(i=>i.hId === hId);
      if (selectedItemIndex !== -1) {
        const selectedItem = state.data[selectedItemIndex];
        await this.$axios.delete(`/reviewer-literature/annotations/${selectedItem.id}`);
        //TODO: Add snackbar for deletion
        commit('removeFromHighlightStoreWithIndex',selectedItemIndex);
      }
    },
    async update({commit, state}, payload){
      const selectedItemIndex = state.data.findIndex(i=>i.hId === payload.hId);
      if (selectedItemIndex !== -1) {
        const selectedItem = state.data[selectedItemIndex];
        const data = await this.$axios.put(`/reviewer-literature/annotations`, {id:selectedItem.id, content:payload.content });
        selectedItem.content = payload.content;
      }

    },
    async createPin({commit, dispatch, state}){
      // create promise for id
      // check if state.selectedRange is available
      if (!(state.selectedRange && state.selectedRange.toString())) {
        return null;
      }
      commit('highlightFromRange');
      return new Promise((resolve, reject) =>{
        let count = 0
        const intervalRef = setInterval(()=>{
          count++;
        if (state.selectedAnnotationPin) {
          commit('setForPinAnnotation', state.selectedAnnotationPin);
          clearInterval(intervalRef);
          resolve(state.selectedAnnotationPin.id);
        }
        if (count > 6) {
          console.log('late response');
          reject('late response');
          clearInterval(intervalRef);
        }
      }, 1000);
    })
  },
    async createAttachment({commit, dispatch, state}){
      // create promise for id
      // check if state.selectedRange is available
      if (!(state.selectedRange && state.selectedRange.toString())) {
        return null;
      }
      commit('highlightFromRange');
      const self = this;
      return new Promise((resolve, reject) =>{
        let count = 0
        const intervalRef = setInterval(()=>{
          count++;
        if (state.selectedAnnotationAttachment) {
          commit('setForAttachmentAnnotation', state.selectedAnnotationAttachment);
          clearInterval(intervalRef);
          resolve(state.selectedAnnotationAttachment.id);
        }
        if (count > 6) {
          console.log('late response');
          reject('late response');
          clearInterval(intervalRef);
        }
      }, 1000);
    })
  },
    createUrlAttachment({ commit, state }) {
      // create promise for id
      // check if state.selectedRange is available
      if (!(state.selectedRange && state.selectedRange.toString())) {
        return null;
      }
      commit('highlightFromRange');

      return new Promise((resolve, reject) => {
        let count = 0
        const intervalRef = setInterval(() => {
          count++;
          if (state.selectedAnnotationUrl) {
            commit("setForUrlAnnotation", state.selectedAnnotationUrl);
            clearInterval(intervalRef);
            resolve(state.selectedAnnotationUrl.id);
          }
          if (count > 6) {
            console.log('late response');
            reject('late response');
            clearInterval(intervalRef);
          }
        }, 1000);
      })
    },
    htmlRendered({commit, dispatch}){
      commit("hasHtmlRendered", true);
      dispatch('fetch');
    }
  },
  getters: {
    getCurrentLiteratureData(state){
      return state.currentLiteratureData;
    },
    getAnnotations(state){
      return state.data;
    },
    highlighter(state){
      return state.highlighter;
    },
    selectionHighlighter(state){
      return state.selectionHighlighter;
    },
    selectedAnnotationIdForAction(state){
      return state.selectedAnnotationIdForAction;
    },
    selectedActionType(state){
      return state.selectedActionType;
    },
    getRange(state){
      return state.selectedRange;
    },
    getSelectedText(state){
      if (state.selectionHighlightData) {
        return state.selectionHighlightData.text;
      }
      return state.selectedRange ? getProperSelection({selectionRange: state.selectedRange}).replace(/\s+/g, ' ') : '';
    },
    getSelectedPinedItem(state){
      if (!state.selectedAnnotationPin) {
        return null;
      }
      return state.selectedAnnotationPin;
    },
    getCurrentNoteAnnotation(state){
      if (state.selectedAnnotationNote) {
        return state.selectedAnnotationNote
      }
      return null
    },
    getUrlAnnotation(state){
      if (state.selectedAnnotationUrl) {
        return state.selectedAnnotationUrl;
      }
      return null;
    },
    getAnnotationAttachment(state){
      if (!state.selectedAnnotationAttachment) {
        return null;
      }
      return state.selectedAnnotationAttachment;
    },
    getLastAttachmentHId(state){
      return state.lastAttachmentHId;
    },
    getIsTextSelected(state) {
      return state.isTextSelected;
    },
    getPrevNoteId(state) {
      return state.prevNoteId;
    }
  }
};
