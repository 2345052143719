var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "rx-empty" }, [
    _c("div", { staticClass: "rx-empty__img-wrapper" }, [
      _c(
        "div",
        {
          staticClass: "md-layout md-align md-align--center-center",
          class: { "rx-empty__circle": _vm.circle }
        },
        [
          _vm.isIcon
            ? _c("i", { staticClass: "material-icons no-user-select" }, [
                _vm._v("\n        " + _vm._s(_vm.icon) + "\n      ")
              ])
            : _vm.img
            ? _c("img", {
                staticClass: "rx-empty__img",
                attrs: { src: _vm.imgSrc }
              })
            : _vm._e()
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "rx-empty__text-wrapper" },
      [
        _vm.heading
          ? _c(
              "div",
              {
                staticClass:
                  "md-typo--title rx-empty__title my-typo--font-light"
              },
              [_vm._v("\n      " + _vm._s(_vm.heading) + "\n    ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.subHeading
          ? _c("div", {
              staticClass: "rx-empty__subtext",
              domProps: { innerHTML: _vm._s(_vm.subHeading) }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.showButton
          ? _c(
              "button",
              {
                staticClass: "layout-m-t-2 md-button",
                class: _vm.buttonClass,
                attrs: { disabled: _vm.disabled },
                on: {
                  click: function($event) {
                    return _vm.$emit("buttonClick", $event)
                  }
                }
              },
              [_vm._v("\n      " + _vm._s(_vm.buttonText) + "\n    ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm._t("body")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }