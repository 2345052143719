<template class="sharedPopupSection">
  <div class="modal-content-container tw-relative" style="max-width: 1024px">
    <div class="header tw-relative tw-py-5">
      <slot name="header" />
    </div>
    <div class="body">
      <slot name="body" />
    </div>
    <div class="footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
