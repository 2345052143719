<template>
  <transition name="td">
    <aside
      v-if="showBanner && reminderList.length"
      class="tw-fixed tw-z-50 tw-pointer-events-auto rm-banner tw-bg-white tw-text-black-72 tw-rounded-lg"
      style="width: 472px"
    >
      <span
        class="raxIcon raxIcon-close tw-absolute tw-right-3 tw-top-3 raxIcon--black72 tw-cursor-pointer"
        @click="hideBanner"
      />
      <div
        class="tw-pl-6 tw-py-3 tw-border-0 tw-font-medium tw-border-solid tw-border-black-24 tw-border-b"
      >
        Today’s Reminders 👇🏼
      </div>
      <div class="tw-overflow-y-auto tw-max-h-80 tw-text-sm">
        <div v-for="(item, index) in reminderList" :key="item.id">
          <reminder-list :item="item" :index="index" tab="td-reminders" />
        </div>
      </div>
    </aside>
  </transition>
</template>

<script>
import * as moment from "moment";
import ReminderList from "./ReminderList";
import { mapGetters } from "vuex";
import { MixpanelEventGroup, MixpanelEvents } from "~/shared/constants";

export default {
  components: {
    ReminderList
  },
  data() {
    return {
      showBanner: false
    };
  },
  computed: {
    ...mapGetters({
      reminderList: "reminders/getTodaysReminders"
    })
  },
  async mounted() {
    const remindersFetchedOn = localStorage.getItem(`reminders_fetched_on`);
    const today = moment().format("YYYY-MM-DD");
    const start = moment().unix();
    const end = moment(`${today} 23:59`, "YYYY-MM-DD H:mm").unix();
    if (remindersFetchedOn !== today) {
      try {
        await this.$store.dispatch("reminders/fetchByDate", { start, end });
        localStorage.setItem(`reminders_fetched_on`, today);
        this.showBanner = true;
        if (this.reminderList.length) {
          this.$mixpanel.track(
            MixpanelEvents[MixpanelEventGroup.Reminders].TodaysRemindersShown
          );
        }
      } catch (e) {
        console.log(e);
      }
    }
  },
  methods: {
    hideBanner() {
      this.showBanner = false;
    }
  }
};
</script>

<style scoped>
.rm-banner {
  top: 6px;
  right: 10px;
  box-shadow: 1px -2px 5px 4px rgba(0, 0, 0, 0.2);
}

.td-enter-active,
.td-leave-active {
  transition: all 300ms ease;
}

.td-enter {
  transform: translateX(100%);
}

.td-leave-to {
  opacity: 0;
}
</style>
