var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 24 24" } },
    [
      _c("defs"),
      _vm._v(" "),
      _c("path", { attrs: { fill: "none", d: "M0 0h24v24H0V0z" } }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          fill: "currentColor",
          d:
            "M20 3h-2V2H3v20h15v-1h2l1-1V4l-1-1zM6 20H5V4h1v16zm10 0H8V4h8v16zm4-1h-2v-2h1v2zm0-4h-2v-2h1v2zm0-4h-2V9h1v2zm0-4h-2V5h1v2zm-6 2H9V7h5v2zm0 3H9v-2h5v2z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }