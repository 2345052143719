import Vue from "vue";

import DraftsIcon from "@/components/icons/draft";
import LiteratureIcon from "@/components/icons/literature";
import SuggestionsIcon from "@/components/icons/suggestion";
import stateEmpty from "@/components/states/empty";
import appLoading from "@/components/states/loading";
import appAlert from "@/components/ui/alert";
import appButton from "@/components/ui/appButton";
import appCheckbox from "@/components/ui/checkbox";
import appDatepicker from "@/components/ui/datepicker";
import appDialog from "@/components/ui/dialog";
import logo from "@/components/ui/logo";
import appMenu from "@/components/ui/menu";
import appPanel from "@/components/ui/panel";
import profileBtn from "@/components/ui/profileBtn";
import appProgress from "@/components/ui/progress";
import appRadio from "@/components/ui/radio";
import appSnackbar from "@/components/ui/snackbar";
import appTextfield from "@/components/ui/textfield";
import tooltip from "@/components/ui/tooltip";
import loader from "@/components/ui/loader";
import * as moment from 'moment'
import summaryItem from "@/components/literature/summary/summaryItem";

Vue.component("appLogo", logo);
Vue.component("appButton", appButton);
Vue.component("appCheckbox", appCheckbox);
Vue.component("appMenu", appMenu);
Vue.component("profileBtn", profileBtn);
Vue.component("appTextfield", appTextfield);
Vue.component("appPanel", appPanel);
Vue.component("appDialog", appDialog);
Vue.component("appRadio", appRadio);
Vue.component("appProgress", appProgress);
Vue.component("appSnackbar", appSnackbar);
Vue.component("appAlert", appAlert);
Vue.component("appLoading", appLoading);
Vue.component("appDatepicker", appDatepicker);
Vue.component("stateEmpty", stateEmpty);
Vue.component("tooltip", tooltip);
Vue.component("loader", loader);

Vue.component("LiteratureIcon", LiteratureIcon);
Vue.component("DraftsIcon", DraftsIcon);
Vue.component("SuggestionsIcon", SuggestionsIcon);
Vue.component("SummaryItem", summaryItem);

Vue.filter("truncate", function(value, n = 100) {
  if (!value) return "";
  value = value.toString();
  return value.length > n ? value.substring(0, n) + "..." : value;
});

Vue.filter("readable-time", function(value) {
  if (!value) return "";
  return moment(value).format('MMM DD, YYYY');
});
