var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ModalContent", {
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function() {
          return [
            _c("section", { staticClass: "tw-flex tw-mx-6 tw-relative" }, [
              _c(
                "span",
                { staticClass: "tw-absolute tw-right-0 tw-text-gray-600" },
                [
                  _c(
                    "i",
                    {
                      staticClass: "material-icons tw-cursor-pointer",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.closeModal()
                        }
                      }
                    },
                    [_vm._v("close")]
                  )
                ]
              )
            ])
          ]
        },
        proxy: true
      },
      {
        key: "body",
        fn: function() {
          return [
            _c(
              "section",
              {
                staticClass:
                  "tw-flex tw-flex-col tw-justify-center tw-items-center tw-mx-6 tw-mb-6 tw-mt-2 tw-relative",
                staticStyle: { "margin-bottom": "0" }
              },
              [
                _c("h1", { staticClass: "tw-text-center tw-text-3xl" }, [
                  _c(
                    "span",
                    {
                      staticClass: "tw-font-bold",
                      staticStyle: { color: "#2E3E4E" }
                    },
                    [_vm._v("Refer Enago Read to your friends")]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "tw-flex tw-justify-evenly tw-text-center",
                    staticStyle: { "margin-top": "15px" }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "tw-flex tw-flex-col tw-items-center tw-px-3"
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "tw-font-bold tw-flex tw-justify-center tw-items-start tw-text-blue-primary tw-text-xl tw-mb-2"
                          },
                          [_vm._v("You get")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "tw-px-2 tw-py-3 tw-bg-white tw-rounded-lg tw-flex tw-flex-col tw-items-center tw-border tw-border-solid tw-border-blue-primary tw-max-w-80"
                          },
                          [
                            _c(
                              "h2",
                              {
                                staticClass:
                                  "tw-text-xl tw-text-center tw-font-medium",
                                staticStyle: { color: "#6D7284" }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "tw-text-blue-primary" },
                                  [_vm._v("$12")]
                                ),
                                _vm._v(" as a token of thanks")
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "tw-text-base tw-mt-3",
                                staticStyle: { color: "#6D7284" }
                              },
                              [
                                _vm._v(
                                  "after your referred friend successfully signs up with Enago Read."
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "tw-flex tw-flex-col tw-items-center tw-px-3"
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "tw-font-bold tw-flex tw-justify-center tw-items-start tw-text-xl tw-mb-2",
                            staticStyle: { color: "#017EE6" }
                          },
                          [_vm._v("They get")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "tw-px-2 tw-py-3 tw-bg-white tw-rounded-lg tw-flex tw-flex-col tw-items-center tw-border tw-border-solid tw-border-blue-primary tw-max-w-80"
                          },
                          [
                            _c(
                              "h2",
                              {
                                staticClass:
                                  "tw-text-xl tw-text-center tw-font-medium",
                                staticStyle: { color: "#6D7284" }
                              },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { color: "#017EE6" } },
                                  [_vm._v("$5")]
                                ),
                                _vm._v(" as a joining bonus")
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "tw-text-base tw-mt-3",
                                staticStyle: { color: "#6D7284" }
                              },
                              [
                                _vm._v(
                                  "in their wallet as a credit after their successful sign up with Enago Read."
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "tw-w-5/6",
                    staticStyle: { "margin-top": "15px" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "tw-font-medium tw-flex",
                        staticStyle: { color: "#2E3E4E" }
                      },
                      [_vm._v("Share your unique referral link")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "tw-py-2 tw-flex" }, [
                      _c(
                        "span",
                        {
                          staticClass: "tw-pb-1 tw-text-sm tw-leading-snug",
                          staticStyle: { color: "#6D7284" }
                        },
                        [_vm._v(_vm._s(_vm.referralLink) + "\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "tw-text-sm tw-font-medium tw-underline tw-px4 tw-ml-9 tw-cursor-pointer tw-text-blue-primary",
                          on: { click: _vm.copyReferral }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.isLinkCopied ? "Copied" : "Copy link")
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "tw-flex tw-justify-start",
                        staticStyle: {
                          "padding-top": "0",
                          "padding-bottom": "10px"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "py-3 a2a_kit a2a_kit_size_32 a2a_default_style large-icons",
                            attrs: {
                              "data-a2a-title":
                                "Hey, I am trying this cool literature review tool called Enago Read. Literature survey is time-consuming and I feel Enago Read might be what I always wanted for “easy-n-quick” critical reading. Check it out, it will be useful for you as well. As they love getting feedback from the research community, you can share yours after using it. Also, you will get a $5 reward as a token of appreciation, if you sign up using the link. Help them get more feedback by sharing Enago Read with others and as a token of appreciation, they will give you credit of $12 as a reward."
                            }
                          },
                          [
                            _vm._l(_vm.socialMediaLinks, function(item) {
                              return _c("a", {
                                key: item,
                                staticClass: "tw-mx-2",
                                class: ["a2a_button_" + item]
                              })
                            }),
                            _vm._v(" "),
                            _c("a", {
                              staticClass: "a2a_dd",
                              attrs: { href: "https://www.addtoany.com/share" }
                            })
                          ],
                          2
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "tw-w-10/12\ttw-rounded tw-mt-8 tw-px-8 tw-py-4 tw-bg-blue-light2",
                    staticStyle: { background: "#F0F7FF" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "tw-font-medium tw-flex",
                        staticStyle: { color: "#2E3E4E" }
                      },
                      [
                        _vm._v("Invite via email\n          "),
                        _c("img", {
                          staticClass: "tw-mx-2",
                          attrs: {
                            loading: "lazy",
                            src:
                              _vm.assetsUrl + "img/referral-program/secure.svg"
                          }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "tw-flex tw-my-4" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.emails,
                            expression: "emails"
                          }
                        ],
                        staticClass:
                          "tw-bg-white tw-w-full tw-text-black-72 tw-p-2 tw-text-sm\ttw-resize-none tw-rounded tw-border-blue-primary",
                        attrs: {
                          rows: "3",
                          placeholder:
                            "use comma(,) to add multiple email addresses"
                        },
                        domProps: { value: _vm.emails },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.emails = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "tw-flex tw-flex-col tw-items-center tw-ml-8"
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "tw-border-blue-primary tw-border tw-border-solid tw-w-36 tw-h-8 tw-text-white tw-bg-blue-primary tw-text-sm tw-font-medium",
                              class: {
                                "tw-opacity-50 tw-cursor-not-allowed": !_vm.isInviteToValid
                              },
                              attrs: { disabled: !_vm.isInviteToValid },
                              on: { click: _vm.inviteUser }
                            },
                            [_vm._v("\n              Send\n            ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass:
                                "tw-text-xs tw-underline tw-w-32 tw-pt-4 tw-cursor-pointer tw-text-blue-primary tw-justify-center",
                              attrs: {
                                href:
                                  _vm.assetsUrl +
                                  "img/referral-program/sample-referral-email-template.png",
                                target: "_blank"
                              }
                            },
                            [
                              _vm._v(
                                "\n              See sample email\n            "
                              )
                            ]
                          )
                        ]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "tw-flex tw-w-5/6 tw-text-xs tw-mt-2 tw-items-center tw-text-black"
                  },
                  [
                    _vm._v("\n        To know how it works,\n        "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "tw-text-xs tw-underline tw-w-16 tw-pl-0.5 tw-cursor-pointer tw-text-blue-primary tw-justify-start",
                        attrs: {
                          href: _vm.landingUrl + "referral",
                          target: "_blank"
                        }
                      },
                      [_vm._v("\n          click here\n        ")]
                    )
                  ]
                )
              ]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }