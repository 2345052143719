const defaultSidebarState = () => {
    return {
      suggestionDropdown: false,
      activeTab:""
    }
  };
  
  export default {
    namespaced: true,
    state: defaultSidebarState,
  
    mutations: {
      setDropdown(state, data) {
        state.suggestionDropdown = data;
      },
      setActiveTab(state,data) {
        state.activeTab = data;
      },
      setInitialState(state){
        state.suggestionDropdown=false;
        state.activeTab="";
      }
    },
  
    actions: {
    },
  
    getters: {
      getSuggestionDropdown(state) {
        return state.suggestionDropdown;
      },
      getActiveTab(state){
        return state.activeTab;
      }
    }
  };
  