import findObjectIndex from "@/assets/js/commonHelper";
const defaultPulicationState = () => {
  return {
    data: []
  }
};

export default {
  namespaced: true,
  state: defaultPulicationState,

  mutations: {
    setData(state, data) {
      state.data = data;
    },
    addData(state, data) {
      state.data.unshift(data);
    },
    updateData(state, data) {
      let index = findObjectIndex(state.data, data);
      state.data.splice(index, 1, data);
    },
    deleteData(state, data, index) {
      state.data.splice(index, 1);
    },
    resetState(state) {
      Object.assign(state, defaultPulicationState());
    }
  },

  actions: {
    async fetch({commit}) {
      let response =  await this.$axios.get(`/userpublications`);
      commit('setData', response.data);
    },

    async update({commit}, inputs) {
      inputs.publishDate = inputs.publishDate? inputs.publishDate + ' 00:00:00' : '';
      const {data} = await this.$axios.put(`/userpublicaitons`, inputs);
      commit("updateData", data[0]);
    },

    async create({commit}, inputs) {
      inputs.publishDate = inputs.publishDate? inputs.publishDate + ' 00:00:00' : '';
      const {data} = await this.$axios.post(`/userpublications`, inputs);
      commit("addData", data);
    },

    async delete({ commit }, inputs) {
      await this.$axios.delete(`/userpublications/${inputs.item.id}`);
      commit("deleteData", inputs.index);
    }
  },

  getters: {
    data(state) {
      return state.data;
    }
  }
};
