<template>
  <div class="spinner">
    <div class="bounce1" :style="{ 'background-color': bounceColor }"/>
    <div class="bounce2" :style="{ 'background-color': bounceColor }"/>
    <div class="bounce3" :style="{ 'background-color': bounceColor }"/>
  </div>
</template>

<script>
export default {
  props:{
    bounceColor: {
      type: String,
      default: '#1A64E2'
    }
  }

}
</script>

<style scoped>
.spinner {
  margin: 100px auto 0;
  text-align: center;
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  height: 100%;
  width: 100%;
  pointer-events: none;
}

.spinner > div {
  width: 18px;
  height: 18px;

  border-radius: 100%;
  display: inline-block;
  animation: animation-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  animation-delay: -0.16s;
}

@keyframes animation-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  } 40% {
    transform: scale(1.0);
  }
}

</style>
