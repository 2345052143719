import { AnnotationTypeMap, AnnotationTypeEnum, AnnotationType, AnnotationSourceType } from "~/components/literature/constants";
import { generateHighlightId, getProperSelection } from "~/components/literature/helper";
import { TourEvents } from "~/shared/constants";

const defaultAnnotationState = () => {
  return {
    currentLiteratureData:null,
    data:[],
    createdCommentData : null,
    highlighter: null,
    selectedAnnotationIdForAction:null,
    selectedActionType: null,
    selectedRange: null,
    selectedText: '',
    isHtmlRendered: false,
    selectedAnnotationPin: null,
    selectedAnnotationAttachment:null,
    selectedAnnotationNote:null,
    selectedAnnotationUrl:null,
    currentPage: 1,
    lastAttachmentHId: null,

    isUpdateHighlight: false,

    selectionHighlighter:null,
    selectionHighlightData: null,
    isTextSelected: false,
    prevNoteId: null,
    showCopilotAnnotationForId: [],
    selectedAnnotationCopilotAnswer: null
  };
};

export default {
  namespaced: true,
  state: defaultAnnotationState,

  mutations: {
    setData(state, payload) {
      state.data = payload;
    },
    setSelectedText(state, data) {
      state.selectedText = data;
    },
    setSelectedRange(state, data) {
      state.selectedRange = data;
    },
    setCurrentData(state, data) {
      state.currentLiteratureData = data;
    },
    setFlagForHighlightUpdateTo(state, payload){
      state.isUpdateHighlight = payload;
    },
    setCreatedCommentData(state, payload){
      state.createdCommentData = payload;
    },
    setForPinAnnotation(state, item){
      state.selectedAnnotationPin = item;
    },
    setForAttachmentAnnotation(state, item){
      state.selectedAnnotationAttachment = item;
    },
    setLastAttachmentHId(state, id){
      state.lastAttachmentHId = id;
    },
    setForNoteAnnotation(state, item){
      state.selectedAnnotationNote = item;
    },
    setForUrlAnnotation(state, item){
      state.selectedAnnotationUrl = item;
    },
    setCurrentPage(state, payload){
      state.currentPage = payload || 1
    },
    setShowCopilotAnnotationForId(state, item) {
      state.showCopilotAnnotationForId.push(item);
    },
    setSelectedAnnotationCopilotAnswer(state, item) {
      state.selectedAnnotationCopilotAnswer = item;
    },
    addToHighlightStore(state, payload){
      if (state.data) {
        state.data.push(payload);
      }else{
        state.data = [payload];
      }
    },
    removeFromHighlightStoreWithIndex(state,index){
      if (index !== -1) {
        state.data.splice(index, 1);
      }
    },
    removeComment(state, commentId){
      console.log(commentId);
      const selectedIndex = state.data.findIndex(i=> i.content == commentId);
      if (selectedIndex > -1) {
        const selectedData = state.data[selectedIndex];
        state.highlighter.remove(selectedData.hId);
      }
    },
    setHighlighterInstance(state, payload){
      state.highlighter = payload;
    },
    setSelectionHighlighterInstance(state, payload){
      state.selectionHighlighter = payload;
    },
    setSelectionHighlightData(state, payload){
      state.selectionHighlightData = payload;
    },
    setAnnotationIdForAction(state, payload){
      state.selectedAnnotationIdForAction = payload;
    },
    setSelectedActionType(state, payload){
      state.selectedActionType = payload;
    },
    setHighlighterInstance(state, payload){
      state.highlighter = payload;
    },
    highlightFromRange(state){
      state.highlighter.fromRange(state.selectedRange);
      state.selectedRange = null;
    },
    highlightFromStore(state) {
      if (!state.highlighter) {
        return;
      }
      (state.data || []).forEach((hs) => {
        if (!hs.hideAnnotation) {
          state.highlighter.fromStore(
            hs.startMeta,
            hs.endMeta,
            hs.text,
            hs.hId,
            AnnotationTypeMap[hs.type]
          );
          document.querySelectorAll(`[data-highlight-id="${hs.hId}"]`).forEach((x) => {
            !hs.hideAnnotation ? x.classList.add('show') : x.classList.remove('show');
          });
        }
      })
    },
    deleteFromId(state, id){
      const selectedItemIndex = state.data.findIndex(i=>i.id === id);
      const selectedItem = state.data[selectedItemIndex];
      state.highlighter.remove(selectedItem.hId);
    },
    hasHtmlRendered(state, payload){
      state.isHtmlRendered = payload;
    },
    updateRange(state, payload){
      state.selectedRange = payload;
    },
    destroy(state){
      // Merge rather than replace so we don't lose observers
      // https://github.com/vuejs/vuex/issues/1118
      Object.assign(state, defaultAnnotationState())
    },
    setIsTextSelected(state, data) {
      state.isTextSelected = data;
    },
    setPrevNoteId(state, data) {
      state.prevNoteId = data;
    }
  },
  actions: {
    async fetch({ commit, state }) {
      if (!state.currentLiteratureData) return;
      const { data } = await this.$axios.get(
        "/literature/" + state.currentLiteratureData.slug + "/annotations"
      );
      commit("setData", data);
      commit('highlightFromStore');
    },
    async create({ commit, state }, inputs) {
      console.log(inputs);
      // creating comment
      if (inputs.type === AnnotationTypeEnum.comment) {
        inputs.content = state.createdCommentData.id;
      }
      const { data } = await this.$axios.post("/literature/annotations", inputs);
      commit('addToHighlightStore', { ...data, annotationSource: AnnotationSourceType.Literature});
      return data;
    },
    async delete({ commit, state }, hId){
      const selectedItemIndex = state.data.findIndex(i=>i.hId === hId);
      if (selectedItemIndex !== -1) {
        const selectedItem = state.data[selectedItemIndex];
        await this.$axios.delete(`/literature/annotations/${selectedItem.id}`);
        //TODO: Add snackbar for deletion
        commit('removeFromHighlightStoreWithIndex',selectedItemIndex);
      }
    },
    async update({ commit, state }, payload) {
      const selectedItemIndex = state.data.findIndex(i=>i.hId === payload.hId);
      if (selectedItemIndex !== -1) {
        const selectedItem = state.data[selectedItemIndex];
        let object = { id: selectedItem.id };
        object[payload.key] = payload.value;
        const data = await this.$axios.put(`/literature/annotations`, object);
        selectedItem[payload.key] = payload.value;
        if (payload.key === 'hideAnnotation') {
          commit('highlightFromStore');
          document.querySelectorAll(`[data-highlight-id="${payload.hId}"]`).forEach((x) => {
            !payload.value ? x.classList.add('show') : x.classList.remove('show');
          });
          if(payload.value) state.highlighter.remove(payload.hId);
        }
      }

    },
    async createComment({ state, commit, getters, dispatch }, text) {
      // check if new creation or conversion from highlight to comment
      let params;
      let hsData= null;
      const selectedIdFroAction = state.isUpdateHighlight && state.selectedAnnotationIdForAction;
      const lastData = state.selectionHighlightData;
      if (!selectedIdFroAction && !lastData) {
        console.log('No selection');
        // TODO: Show warning.
        return;
      }

      if (selectedIdFroAction) {
        const getCurrentItemToBeConverted = state.data.find(i=> i.hId === selectedIdFroAction);
        const id = String(Math.random()).slice(2);
        const newId = generateHighlightId(id+ ''+getCurrentItemToBeConverted.pageNo , AnnotationType.Comment);
        hsData = {
          startMeta: getCurrentItemToBeConverted.startMeta,
          endMeta: getCurrentItemToBeConverted.endMeta,
          text: getCurrentItemToBeConverted.text,
          content: '',
          literatureId: getCurrentItemToBeConverted.literatureId,
          pageNo:getCurrentItemToBeConverted.pageNo,
          type:AnnotationTypeEnum.comment,
          hId: newId
        }
        params = {
          itemId: state.currentLiteratureData.id,
          selection: hsData.text,
          text: text,
          type: "literatures",
          pageNo:hsData.pageNo
        };
      } else {
        params = {
          itemId: state.currentLiteratureData.id,
          selection: lastData.text,
          text: text,
          type: "literatures",
          pageNo:state.currentPage
        };

      }

      const { data } = await this.$axios.post(
        "/shared/item/comments/create",
        params
      );
      commit('setCreatedCommentData', data);
      commit('setAnnotationIdForAction', null);
      commit('setFlagForHighlightUpdateTo', false);
      // if having hs store data
      if (hsData) {
        hsData.content = data.id+ ''
        await dispatch('create', hsData);
         // remove highlightId
        state.highlighter.remove(selectedIdFroAction);
        setTimeout(() => {
          state.highlighter.fromStore(hsData.startMeta,
            hsData.endMeta,
            hsData.text,
            hsData.hId,
            AnnotationTypeMap[hsData.type]);
        }, 10);

      }else{
        const id = String(Math.random()).slice(2);
        const newId = generateHighlightId(id+ '', AnnotationType.Comment);
        const newData = {
          startMeta: lastData.startMeta,
          endMeta: lastData.endMeta,
          text: lastData.text,
          content: data.id+ '',
          literatureId: state.currentLiteratureData.id,
          pageNo:state.currentPage,
          type:AnnotationTypeEnum.comment,
          hId: newId
        }
        await dispatch('create', newData);
        if (state.selectionHighlighter) {
          state.selectionHighlighter.remove('Rax__Selection__Text');
        }
        setTimeout(() => {
          state.highlighter.fromStore( newData.startMeta,
            newData.endMeta,
            newData.text,
            newId,
            AnnotationType.Comment);
        }, 10);
      }

      if (state.selectionHighlighter) {
        state.selectionHighlighter.remove('Rax__Selection__Text');
      }

      data["refItemId"] = state.currentLiteratureData.id;
      data["type"] = "2"; //literature
      this.commit("sharedComments/addData", { key: "comments", data: data });
      // commit("setDataByKey", {
      //   key: "openComments",
      //   data: { id: data.id, type: "literatures", open: true }
      // });
      this.dispatch("core/updateLastAccess", {
        item: state.currentLiteratureData,
        type: "literature",
        storeName: "literatures"
      });
      console.log(data);
    },
    async createPin({commit, dispatch, state}){
      // create promise for id
      // check if state.selectedRange is available
      if (!(state.selectedRange && state.selectedRange.toString())) {
        return null;
      }
      commit('highlightFromRange');
      return new Promise((resolve, reject) =>{
        let count = 0
        const intervalRef = setInterval(()=>{
          count++;
        if (state.selectedAnnotationPin) {
          commit('setForPinAnnotation', state.selectedAnnotationPin);
          clearInterval(intervalRef);
          resolve(state.selectedAnnotationPin.id);
        }
        if (count > 6) {
          console.log('late response');
          reject('late response');
          clearInterval(intervalRef);
        }
      }, 1000);
    })
  },
    async createAttachment({commit, dispatch, state}){
      // create promise for id
      // check if state.selectedRange is available
      if (!(state.selectedRange && state.selectedRange.toString())) {
        return null;
      }
      commit('highlightFromRange');
      const self = this;
      return new Promise((resolve, reject) =>{
        let count = 0
        const intervalRef = setInterval(()=>{
          count++;
        if (state.selectedAnnotationAttachment) {
          commit('setForAttachmentAnnotation', state.selectedAnnotationAttachment);
          clearInterval(intervalRef);
          resolve(state.selectedAnnotationAttachment.id);
        }
        if (count > 6) {
          console.log('late response');
          reject('late response');
          clearInterval(intervalRef);
        }
      }, 1000);
    })
  },
    createUrlAttachment({ commit, state }) {
      // create promise for id
      // check if state.selectedRange is available
      if (!(state.selectedRange && state.selectedRange.toString())) {
        return null;
      }
      commit('highlightFromRange');

      return new Promise((resolve, reject) => {
        let count = 0
        const intervalRef = setInterval(() => {
          count++;
          if (state.selectedAnnotationUrl) {
            commit("setForUrlAnnotation", state.selectedAnnotationUrl);
            clearInterval(intervalRef);
            resolve(state.selectedAnnotationUrl.id);
          }
          if (count > 6) {
            console.log('late response');
            reject('late response');
            clearInterval(intervalRef);
          }
        }, 1000);
      })
    },
    htmlRendered({commit, dispatch}){
      commit("hasHtmlRendered", true);
      dispatch('fetch');
    }
  },
  getters: {
    getCurrentLiteratureData(state){
      return state.currentLiteratureData;
    },
    getAnnotations(state){
      return state.data;
    },
    highlighter(state){
      return state.highlighter;
    },
    selectionHighlighter(state){
      return state.selectionHighlighter;
    },
    selectedAnnotationIdForAction(state){
      return state.selectedAnnotationIdForAction;
    },
    selectedActionType(state){
      return state.selectedActionType;
    },
    getRange(state){
      return state.selectedRange;
    },
    getSelectedText(state){
      if (state.selectionHighlightData) {
        return state.selectionHighlightData.text;
      }
      return state.selectedRange ? getProperSelection({selectionRange: state.selectedRange}).replace(/\s+/g, ' ') : '';
    },
    getSelectedPinedItem(state){
      if (!state.selectedAnnotationPin) {
        return null;
      }
      return state.selectedAnnotationPin;
    },
    getCurrentNoteAnnotation(state){
      if (state.selectedAnnotationNote) {
        return state.selectedAnnotationNote
      }
      return null
    },
    getUrlAnnotation(state){
      if (state.selectedAnnotationUrl) {
        return state.selectedAnnotationUrl;
      }
      return null;
    },
    getAnnotationAttachment(state){
      if (!state.selectedAnnotationAttachment) {
        return null;
      }
      return state.selectedAnnotationAttachment;
    },
    getLastAttachmentHId(state){
      return state.lastAttachmentHId;
    },
    getIsTextSelected(state) {
      return state.isTextSelected;
    },
    getPrevNoteId(state) {
      return state.prevNoteId;
    },
    getShowCopilotAnnotationForId(state) {
      return (id) => {
        return state.showCopilotAnnotationForId.find(x => x.id === id);
      }
    },
    getSelectedAnnotationCopilotAnswer(state) {
      return state.data;
    },
    getCurrentAnnotationById(state) {
      return (id) => {
        return state.data.filter(x => x.id === id)[0];
      }
    }
  }
};
