import Vue from "vue";

import findObjectIndex from "@/assets/js/commonHelper";
import { AnnotationSource } from "~/components/literature/constants";

const defaultCoreState = () => {
  return {
    snackbar: {
      actionText: "",
      message: "",
      config: {
        float: true,
        timeout: 3000,
      },
    },
    alert: {
      title: "",
      defaultTitle: "Something went wrong or unable to connect",
      body: "",
      disableDismissive: false,
      show: false,
      icon:null,
      affirmativeText:null
    },
    appLoading: false,
    feedback: false,
    breadCrumbs: [],
    showUserInfoModal: false,
    currentModalStep: 0,
    showPasswordSentBanner: false,
    onboardingEvents: [],
    increaseSheetWidth: false,
    currentSelectedAnnotation:AnnotationSource.Literature,
    isContextMenuOpen: false,
    showReferralModal:false,
    headerTitle:"",
    licenses: [
      {
        id: "lic-no",
        value: 0,
        title: "No license",
        description:
          "You waive all your copyright and related rights in this work, worldwide.",
        link: "https://creativecommons.org/share-your-work/public-domain/cc0"
      },
      {
        id: "lic-pd",
        value: 2,
        title: "Public Domain",
        description:
          "This work is already in the public domain and free of copyright restrictions.",
        link: "https://creativecommons.org/share-your-work/public-domain/pdm"
      },
      {
        id: "lic-cca",
        value: 3,
        title: "CC : Attribution",
        description:
          "Others can distribute, remix, and build upon your work as long as they credit you.",
        link: "https://creativecommons.org/licenses/by/4.0/"
      },
      {
        id: "lic-ccand",
        value: 4,
        title: "CC : Attribution, no derivatives",
        description:
          "Others can distribute your work as long as they credit you. Others can only distribute non-derivative copies of your work.",
        link: "https://creativecommons.org/licenses/by-nd/4.0/"
      },
      {
        id: "lic-ccasa",
        value: 5,
        title: "CC : Attribution, share alike",
        description:
          "Others can distribute, remix, and build upon your work as long as they credit you. Others must distribute derivatives of your work under the same license.",
        link: "https://creativecommons.org/licenses/by-sa/4.0/"
      },
      {
        id: "lic-ccanc",
        value: 6,
        title: "CC : Attribution, non-commercial",
        description:
          "Others can distribute, remix, and build upon your work as long as they credit you. Others can use your work for non-commercial purposes only.",
        link: "https://creativecommons.org/licenses/by-nc/4.0/"
      },
      {
        id: "lic-ccancnd",
        value: 7,
        title: "CC : Attribution, non-commercial, no derivatives",
        description:
          "Others can distribute your work as long as they credit you. Others can use your work for non-commercial purposes only. Others can only distribute non-derivative copies of your work.",
        link: "https://creativecommons.org/licenses/by-nc-nd/4.0/"
      },
      {
        id: "lic-ccancsa",
        value: 8,
        title: "CC : Attribution, non-commercial, share alike",
        description:
          "Others can distribute, remix, and build upon your work as long as they credit you. Others can use your work for non-commercial purposes only. Others must distribute derivatives of your work under the same license.",
        link: "https://creativecommons.org/licenses/by-nc-sa/4.0/"
      }
    ],
    country: [],
    institution: [],
    libraryId: undefined,
    showHeader: true,
    tours: [],
    currentTip: "1",
    tipsInteraction: [],
    tips: {
      "10": {
        id: 10,
        name: "next-read-mailer",
        imgSource: "https://assets.raxter.io/img/nextread.png",
        title: "Which paper to read next? At your aid is improved <span class='tw-text-blue-primary tw-underline'>Mailer digest</span>",
        points: [
          "Our weekly digest is now more useful with the suggestions of <span class='tw-italic tw-font-semibold'>potential next read from your Literature list</span> on Enago Read. <span class='tw-underline'>Know how to prioritize your reading list using Next Read 1.0.</span>"
        ],
        verticalLayout: true,
        blogLink: "https://medium.com/rax-news/introducing-next-read-1-0-c313ce1b4bfd"
      },
      "9": {
        id: 9,
        name: "key-insights-blog",
        showInTwoPlaces: true,
        imgSource: "https://assets.raxter.io/img/key-insights-blog.png",
        title: "Making reading research papers easy with Enago Read Key Insights",
        points: [
          "Learn how you can use <span class='tw-text-yellow-dark tw-font-bold'> Key Insights </span> feature for reading papers faster without missing on critical information. 5 mins of this time can save you hours later on!"
        ],
        blogLink: "https://medium.com/rax-news/making-research-papers-reading-easy-with-key-insights-85528d82e759"
      },
      "8": {
        id: 8,
        name: "literature-select-n-explore",
        imgSource: "https://assets.raxter.io/img/literature-select-n-explore.png",
        title: "How to understand research papers faster with RAx?",
        points: [
          "Check out our latest blog about how to use <span class='tw-text-yellow-dark tw-font-bold'>select-n-xplore</span> feature for better and faster understanding of papers. Many examples included."
        ],
        blogLink: "https://medium.com/rax-news/how-to-understand-research-papers-faster-with-rax-2f2b611e71d3"
      },
      "2": {
        id: 2,
        name: "related-content",
        videoSources: [
          {
            type: "video/mp4",
            src:
              "https://assets.raxter.io/video/tips/related-content-cropped.mp4"
          }
        ],
        title: "Compare and contrast papers across your projects",
        points: [
          "Based on your selection, RAxBot finds similar problem statements, arguments, methods, results, or conclusions across your literature.",
          "Pin useful content and form a network of similar information among all your papers.",
          "Add remarks and give context to the connection, for ease recall."
        ]
      },
      "1": {
        id: 1,
        name: "selective-ryg",
        videoSources: [
          {
            type: "video/mp4",
            src: "https://assets.raxter.io/video/tips/selective-ryg-cropped.mp4"
          }
        ],
        title: "Understanding papers made easy",
        points: [
          "Select sections or phrases that you need to explore more.",
          "Find research papers or blogs for more clarification, alternate approach or latest updates on the selected topic.",
          "Attach useful results right on the paper for future reference."
        ]
      }
    },
    currentResultTab: "suggested",
    tourActive: false,
    reminderTourActive: false,
    reminderDrawerOpen: false,
    reminderPanelOpen: false,
    hellobarInserted: false,
    isPdfProcessing: false,
    useCustomTextForUploader: false,
    isFileUploading: false,
    mobileDrawerOpen: false,
    allSelected:false
  };
};

export default {
  namespaced: true,

  state: defaultCoreState,

  mutations: {
    setTourStatus(state, val) {
      state.tourActive = val;
    },
    setCurrentAnnotation(state, val){
      state.currentSelectedAnnotation = val;
    },
    setReminderTourStatus(state, val) {
      state.reminderTourActive = val;
    },
    setData(state, obj) {
      state[obj.name] = obj.data;
    },
    addData(state, obj) {
      state[obj.name].unshift(obj.data);
    },
    updateData(state, obj) {
      state[obj.name].splice(obj.index, 1, obj.data);
    },
    setSnackbar(state, obj) {
      if (!obj.config || !obj.config.timeout) {
        const defaultState = defaultCoreState();
        obj.config = defaultState.snackbar.config;
      }
      for (let key in obj) {
        Vue.set(state.snackbar, key, obj[key]);
      }
      state.snackbar.isUndo = obj.isUndo?obj.isUndo:false; 
    },
    setAlert(state, obj) {
      for (let key in obj) {
        Vue.set(state.alert, key, obj[key]);
      }
    },
    setShowReferralModal(state,data){
      state.showReferralModal = data;
    },
    setAppLoading(state, val) {
      state.appLoading = val;
    },
    setBreadCrumbs(state, val) {
      state.breadCrumbs = val;
    },
    setHeaderTitle(state, val) {
      state.headerTitle = val;
    },
    resetState(state) {
      Object.assign(state, defaultCoreState());
    },
    setCurrentResultTab(state, val) {
      state.currentResultTab = val;
    },
    setOnboardingEvents(state, data) {
      state.onboardingEvents = data;
    },
    setIsContextMenuOpen(state, data) {
      state.isContextMenuOpen = data;
    },
    setAllSelected(state,data){
      state.allSelected=data;
    }
  },

  actions: {
    async fetchTipsData({ commit }) {
      let { data } = await this.$axios.get(`/tips-interaction`);
      commit("setData", { name: "tipsInteraction", data: data });
    },
    async fetchCountry({ commit }) {
      let { data } = await this.$axios.get(`/common/country`);
      commit("setData", { name: "country", data: data });
    },
    async fetchInstitution({ commit }, params) {
      let { data } = await this.$axios.get(`/common/institute/${params}`);
      commit("setData", { name: "institution", data: data });
    },
    async fetchLibraryInfo({ commit }) {
      let { data } = await this.$axios.get(`/library/info-for-user`);
      commit("setData", { name: "libraryId", data: data.id });
    },
    async updateLastAccess({}, inputs) {
      try {
        let data = await this.$axios.put("/accessed", inputs);
        if(inputs.storeName!='projects')
        this.commit(`${inputs.storeName}/updateDataPosition`, data["data"]);
      } catch (e) {
        console.log(e);
      }
    },
    async fetchOnboardingEvents({ commit }) {
      try {
        let { data } = await this.$axios.get(`onboarding-events/show`);
        commit("setOnboardingEvents", data);
      } catch (e) {
        console.log(e);
      }
    },
    async fetchTours({ commit }) {
      let { data } = await this.$axios.get(`/tour`);
      commit("setData", { name: "tours", data: data });
    },
    async createTour({ commit }, obj) {
      let { data } = await this.$axios.post(`/tour/create`, obj);
      commit("addData", { name: "tours", data: data });
    },
    async updateTours({ commit, state }, obj) {
      let { data } = await this.$axios.put(`/tour`, obj);
      let index = findObjectIndex(state.tours, obj);
      commit("updateData", { data: data[0], index: index, name: "tours" });
    },
    async download({ commit }, path) {
      let urls = [path];
      let interval = setInterval(download, 300, urls);

      function download(urls) {
        let url = urls.pop();
        let a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("download", "");
        a.setAttribute("target", "_blank");
        a.click();

        if (urls.length === 0) {
          clearInterval(interval);
        }
      }
    },
    async fetchDownloadLink({commit}, pdfUrl){
      try{
        return await this.$axios.get("",pdfUrl);
      }
      catch(e){
        console.log(e);
      }
    },
    async fetchReleaseNotesData({ commit }) {
      try {
        let { data } = await this.$axios.get(`/release-notes/latest`);
        if (!data.success) throw 'No release note found';
        commit("setData", { name: "releaseNotes", data: data });
      } catch (e) {
        commit("setData", {
          name: "releaseNotes",
          data: {
            version: '0.0.0',
            url: 'https://www.notion.so/RAx-release-notes-fc57e2aa31e049fb81441653cafc3f99?test=test'
          }
        });
      }
    },
    async upsertReleaseNoteDataLog({}, inputs) {
      try {
        await this.$axios.put(`/release-notes/log/${inputs.version}`);
      } catch (e) {
        console.log(e);
      }
    }
  },

  getters: {
    getTipsStatus(state) {
      return { tips: state.tips, tipsInteraction: state.tipsInteraction };
    },
    getCurrentAnnotation(state){
      return state.currentSelectedAnnotation;
    },
    getCurrentTip(state) {
      return state.currentTip;
    },
    getTipsData(state) {
      return state.tips[state.currentTip];
    },
    snackbar(state) {
      return state.snackbar;
    },
    getcurrentModalStep(state) {
      return state.currentModalStep;
    },
    alert(state) {
      return state.alert;
    },
    appLoading(state) {
      return state.appLoading;
    },
    breadCrumbs(state) {
      return state.breadCrumbs;
    },
    institution(state) {
      return state.institution;
    },
    verifiedInstitution(state) {
      return state.institution.filter(item => {
        return item.isVerified;
      });
    },
    country(state) {
      return state.country;
    },
    feedback(state) {
      return state.feedback;
    },
    showUserInfoModal(state) {
      return state.showUserInfoModal;
    },
    getLibraryId(state) {
      return state.libraryId;
    },
    getShowHeader(state) {
      return state.showHeader;
    },
    getShowPasswordSentBanner(state) {
      return state.showPasswordSentBanner;
    },
    getTours(state) {
      return state.tours;
    },
    getCurrentResultTab(state) {
      return state.currentResultTab;
    },
    getCurrentModalStep(state) {
      return state.currentModalStep;
    },
    getOnboardingEvents(state) {
      return state.onboardingEvents;
    },
    getIncreaseSheetWidth(state) {
      return state.increaseSheetWidth;
    },
    getReleaseNotes(state) {
      return state.releaseNotes;
    },
    isTourActive(state) {
      return state.tourActive;
    },
    isReminderTourActive(state) {
      return state.reminderTourActive;
    },
    isReminderDrawerOpen(state) {
      return state.reminderDrawerOpen;
    },
    isReminderPanelOpen(state) {
      return state.reminderPanelOpen;
    },
    getHellobarInserted(state) {
      return state.hellobarInserted;
    },
    getIsPdfProcessing(state) {
      return state.isPdfProcessing;
    },
    getUseCustomTextForUploader(state) {
      return state.useCustomTextForUploader;
    },
    getIsFileUploading(state) {
      return state.isFileUploading;
    },
    getIsContextMenuOpen(state) {
      return state.isContextMenuOpen;
    },
    isMobileDrawerOpen(state) {
      return state.mobileDrawerOpen;
    },
    getHeaderTitle(state){
      return state.headerTitle;
    },
    getAllSelected(state){
      return state.allSelected;
    },
    getShowReferralModal(state){
      return state.showReferralModal;
    }
  }
};
