var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { cursor: "pointer" },
      attrs: {
        "data-autofocus": "false",
        "data-location": "bottom",
        "data-target": "#app-account-menu",
        "data-toggle": ![
          _vm.UserStatus.KIDemoOld,
          _vm.UserStatus.KIDemoNew,
          _vm.UserStatus.ReadingDemo,
          _vm.UserStatus.RedditDemo
        ].includes(_vm.user.onboardingStatus)
          ? "menu"
          : ""
      },
      on: { click: _vm.checkUserEmail }
    },
    [
      _c(
        "button",
        {
          staticClass: "md-button md-button--profile tw-mx-1",
          class: { "md-button--active": _vm.icon },
          style: _vm.backgroundImage
        },
        [
          !_vm.isImg && _vm.icon
            ? [
                _c("img", {
                  staticClass: "avatar-icon",
                  attrs: { src: _vm.assetsUrl + "img/icon/avatar.svg" }
                })
              ]
            : [_vm._v("\n      " + _vm._s(_vm.letter) + "\n    ")]
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }