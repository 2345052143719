<template>
  <div
    class="summary-sub-item tw-relative tw-px-2"
    :class="{
      pinned: isPinned,
    }"
  >
    <!-- <i v-if="!isPinned" class="more-icon raxIcon raxIcon-more tw-cursor-pointer" @click="openPinOption(data, $el)"/> -->
    <div @mousedown="onStatementClick" class="tw-my-1 tw-flex tw-flex-col">
      <div
        class="tw-font-normal tw-text-base tw-text-black-72 tw-py-2 tw-relative"
        :class="{ 'tw-bg-blue-light2 tw-px-2 tw-rounded-md': isPinned }"
        :statement-key="data.key"
      >
        <!-- <span
          v-if="isPinned"
          class="tw-flex tw-text-sm tw-text-black-72 tw-cursor-pointer tw-my-2 tw-absolute pinned-statement RAx_highlight_wrap"
          :class="{ 'active-pin': isPinned }"
          style="left: -28px; top: -12px;"
        >
          <i class="raxIcon raxIcon-bookmark_outline" />
        </span> -->
        <div :statement-key="data.key">{{ data.text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    store: {
      type: String,
      default: "summarizer"
    }
  },
  data() {
    return {
      isMouseDown: false,
    };
  },
  methods: {
    onStatementClick(evt) {
      this.$emit("onStatementClick", evt);
    },
    openPinOption(data, el) {
      this.$emit("openPinOption", data, el.firstChild);
    },
  },
  computed: {
    ...mapState({
      getPinnedData(state, getters) {
        return getters[`${this.store}/getPinnedData`];
      },
      getSelectedStatementInView(state, getters) {
        return getters[`${this.store}/getSelectedStatementInView`];
      }
    }),
    isPinned() {
      return (
        this.getPinnedData &&
        this.getPinnedData.some((j) =>
          !j.hasSub ? j.statementKey == this.data.key : false
        )
      );
    },
    texts() {
      return this.data.text.split(" ");
    },
  },
};
</script>
<style scoped>
.summary-sub-item {
  transition: 0.2s;
}
</style>
