import { UserStatus } from "~/shared/constants";

export default async function({ redirect, store, route }) {
  try {
    let user = store.getters["user/getData"];
    if (!user || !user.onboardingStatus) {
      await store.dispatch("user/setUser");
      user = store.getters["user/getData"];
    }

    const lastAction = store.getters["user/getLastAction"];
    if (
      user &&
      user.onboardingStatus === UserStatus.RedditDemo &&
      lastAction !== "Preparing RAx assistant"
    ) {
      return redirect("/auth/signup?logout=true");
    }

    if (
      user &&
      user.onboardingStatus &&
      [UserStatus.KIDemoOld, UserStatus.ReadingDemo].includes(
        user.onboardingStatus
      )
    ) {
      let project = store.getters["projects/getData"];
      if (!project.length) {
        await store.dispatch("projects/fetch");
        project = store.getters["projects/getData"];
      }
      let literatureSlug;
      let literature = store.getters["literatures/getData"];
      if (!literature.length && project && project.length) {
        await store.dispatch("literatures/fetch", {
          slug: project[0].slug
        });
        literature = store.getters["literatures/getData"];
      }
      literatureSlug = literature[0] && literature[0].slug;

      const currentUrl = route.fullPath;
      if (project && project.length && literatureSlug) {
        const redirectUrl = `/projects/${project[0].slug}/
      literatures/${literatureSlug}`;
        if (currentUrl !== redirectUrl) {
          return redirect(
            `/projects/${project[0].slug}/literatures/${literatureSlug}`
          );
        }
      }
    } else if (
      user &&
      user.onboardingStatus &&
      user.onboardingStatus === UserStatus.KIDemoNew
    ) {
      let project = store.getters["projects/getData"];
      if (!project.length) {
        await store.dispatch("projects/fetch");
        project = store.getters["projects/getData"];
      }
      const currentUrl = route.fullPath;
      if (project && project.length) {
        const redirectUrl = `/projects/${project[0].slug}/literatures`;
        if (currentUrl !== redirectUrl) {
          return redirect(redirectUrl);
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}
