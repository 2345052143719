import findObjectIndex from "@/assets/js/commonHelper"

const defaultTagState = () => ({
  data: []
});

export default {
  namespaced: true,
  state: defaultTagState,


  mutations: {
    setData(state, data) {
      state.data = data;
    },
    updateData(state, data) {
      let index = findObjectIndex(state.data, data);
      state.data.splice(index, 1, data);
    },
    deleteData(state, data) {
      let index = findObjectIndex(state.data, data);
      state.data.splice(index, 1);
    },
    resetState(state) {
      Object.assign(state, defaultTagState());
    },
    addData(state, data) {
      state.data = [...data, ...state.data];
    },
  },

  actions: {
    async attachTag({ commit }, inputs) {
      const { data } = await this.$axios.post(`/tags/add`, inputs);
      this.dispatch(`${inputs.storeName}/addTags`, { data: data, inputs: inputs, id: inputs.itemId })
    },
    async fetch({ commit }) {
      let { data } = await this.$axios.get(`/tags`);
      commit("setData", data);
    },
    async create({commit}, inputs) {
      const { data } = await this.$axios.post(`/tags/create`, inputs);
      commit("addData", data);
      // add tags in associated item
      this.dispatch(`${inputs.storeName}/addTags`, { data: data, inputs: inputs })
      return data;
    },
    async update({ commit }, obj) {
      const updates = {
        name: obj.data.name,
        color: obj.data.color
      };
      const { data } = await this.$axios.patch(`/tags/${obj.data.id}`, updates);
      commit("updateData", data);
      // update tags in associated item
      this.dispatch(`${obj.storeName}/updateTags`, { data: data, inputs: obj.formData })
    },
    async delete({commit}, obj) {
      const { data } = await this.$axios.delete(`/tag/remove/${obj.data.id}/${obj.formData.id}/${obj.itemType}`);
      commit("deleteData", data);
      // delete tags in associated item
      this.dispatch(`${obj.storeName}/deleteTags`, { inputs: obj.formData, data: obj.data })
    }
  },

  getters: {
    getData(state) {
      const allTagsList = [];
      state.data.forEach((tagItem) => {
        const index = allTagsList.findIndex(({ name }) => name.toLowerCase() == tagItem.name.toLowerCase());
        if (index <= -1) allTagsList.push(tagItem);
      });
      return allTagsList;
    },
    getTagsByKey: state => key => state[key],
  }

}