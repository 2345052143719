var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal-content-container tw-relative",
      staticStyle: { "max-width": "1024px" }
    },
    [
      _c(
        "div",
        { staticClass: "header tw-relative tw-py-5" },
        [_vm._t("header")],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "body" }, [_vm._t("body")], 2),
      _vm._v(" "),
      _c("div", { staticClass: "footer" }, [_vm._t("footer")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }