import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _66f5b14a = () => interopDefault(import('../pages/autocomplete/index.vue' /* webpackChunkName: "pages/autocomplete/index" */))
const _1e4bb0ea = () => interopDefault(import('../pages/discover/index.vue' /* webpackChunkName: "pages/discover/index" */))
const _47002ec8 = () => interopDefault(import('../pages/gettingstarted.vue' /* webpackChunkName: "pages/gettingstarted" */))
const _16c25864 = () => interopDefault(import('../pages/lira/index.vue' /* webpackChunkName: "pages/lira/index" */))
const _c5acc572 = () => interopDefault(import('../pages/maintenance/index.vue' /* webpackChunkName: "pages/maintenance/index" */))
const _7a81144c = () => interopDefault(import('../pages/newOnboarding.vue' /* webpackChunkName: "pages/newOnboarding" */))
const _b86c29ac = () => interopDefault(import('../pages/preparingRAxAssistant.vue' /* webpackChunkName: "pages/preparingRAxAssistant" */))
const _78594977 = () => interopDefault(import('../pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _2a3c7646 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _6e4c06dc = () => interopDefault(import('../pages/projects/index.vue' /* webpackChunkName: "pages/projects/index" */))
const _1d036e62 = () => interopDefault(import('../pages/reviews/index.vue' /* webpackChunkName: "pages/reviews/index" */))
const _9318410e = () => interopDefault(import('../pages/shared-with-me/index.vue' /* webpackChunkName: "pages/shared-with-me/index" */))
const _522fc4bc = () => interopDefault(import('../pages/student-monitoring/index.vue' /* webpackChunkName: "pages/student-monitoring/index" */))
const _94655a88 = () => interopDefault(import('../pages/subscription.vue' /* webpackChunkName: "pages/subscription" */))
const _13e7dc3f = () => interopDefault(import('../pages/subscription/index.vue' /* webpackChunkName: "pages/subscription/index" */))
const _4c720a33 = () => interopDefault(import('../pages/subscription/payment.vue' /* webpackChunkName: "pages/subscription/payment" */))
const _51d8512e = () => interopDefault(import('../pages/subscription/payment-success.vue' /* webpackChunkName: "pages/subscription/payment-success" */))
const _1ff606a7 = () => interopDefault(import('../pages/subscription/payment-success-duplicate.vue' /* webpackChunkName: "pages/subscription/payment-success-duplicate" */))
const _c04c5486 = () => interopDefault(import('../pages/auth/forgot-password.vue' /* webpackChunkName: "pages/auth/forgot-password" */))
const _0f25719a = () => interopDefault(import('../pages/auth/signin.vue' /* webpackChunkName: "pages/auth/signin" */))
const _032648e5 = () => interopDefault(import('../pages/auth/signin-mobile.vue' /* webpackChunkName: "pages/auth/signin-mobile" */))
const _23bbc810 = () => interopDefault(import('../pages/auth/signup.vue' /* webpackChunkName: "pages/auth/signup" */))
const _43c9e040 = () => interopDefault(import('../pages/auth/verify-email.vue' /* webpackChunkName: "pages/auth/verify-email" */))
const _2b4d337f = () => interopDefault(import('../pages/auth/verify-email-mobile.vue' /* webpackChunkName: "pages/auth/verify-email-mobile" */))
const _9a3be75c = () => interopDefault(import('../pages/discover/acs-collections.vue' /* webpackChunkName: "pages/discover/acs-collections" */))
const _31dbfab6 = () => interopDefault(import('../pages/discover/bookmarks.vue' /* webpackChunkName: "pages/discover/bookmarks" */))
const _7fe59864 = () => interopDefault(import('../pages/discover/feeds.vue' /* webpackChunkName: "pages/discover/feeds" */))
const _250f7a98 = () => interopDefault(import('../pages/discover/library.vue' /* webpackChunkName: "pages/discover/library" */))
const _24a2502a = () => interopDefault(import('../pages/faculty/email-change.vue' /* webpackChunkName: "pages/faculty/email-change" */))
const _cf4ab5fa = () => interopDefault(import('../pages/faculty/invitation.vue' /* webpackChunkName: "pages/faculty/invitation" */))
const _f384e062 = () => interopDefault(import('../pages/projects/archive/index.vue' /* webpackChunkName: "pages/projects/archive/index" */))
const _12c637f8 = () => interopDefault(import('../pages/quick-view/related-papers.vue' /* webpackChunkName: "pages/quick-view/related-papers" */))
const _40495a0a = () => interopDefault(import('../pages/quick-view/summary.vue' /* webpackChunkName: "pages/quick-view/summary" */))
const _75c20769 = () => interopDefault(import('../pages/record-result-action/platform.vue' /* webpackChunkName: "pages/record-result-action/platform" */))
const _e54340c4 = () => interopDefault(import('../pages/shared-with-me/drafts/index.vue' /* webpackChunkName: "pages/shared-with-me/drafts/index" */))
const _5cadb92a = () => interopDefault(import('../pages/shared-with-me/email-change.vue' /* webpackChunkName: "pages/shared-with-me/email-change" */))
const _13ceca02 = () => interopDefault(import('../pages/shared-with-me/invitation.vue' /* webpackChunkName: "pages/shared-with-me/invitation" */))
const _04f3306c = () => interopDefault(import('../pages/shared-with-me/literatures/index.vue' /* webpackChunkName: "pages/shared-with-me/literatures/index" */))
const _1c677af6 = () => interopDefault(import('../pages/shared-with-me/request.vue' /* webpackChunkName: "pages/shared-with-me/request" */))
const _67ebedf4 = () => interopDefault(import('../pages/shared-with-me/result.vue' /* webpackChunkName: "pages/shared-with-me/result" */))
const _7298b7a5 = () => interopDefault(import('../pages/verify/email.vue' /* webpackChunkName: "pages/verify/email" */))
const _53a15524 = () => interopDefault(import('../pages/projects/all/advance-recommendations/index.vue' /* webpackChunkName: "pages/projects/all/advance-recommendations/index" */))
const _92f20aee = () => interopDefault(import('../pages/projects/all/drafts/index.vue' /* webpackChunkName: "pages/projects/all/drafts/index" */))
const _9fc49f3e = () => interopDefault(import('../pages/projects/all/literatures/index.vue' /* webpackChunkName: "pages/projects/all/literatures/index" */))
const _7c4a7380 = () => interopDefault(import('../pages/projects/all/project-feeds/index.vue' /* webpackChunkName: "pages/projects/all/project-feeds/index" */))
const _8d2aa23e = () => interopDefault(import('../pages/projects/all/repository/index.vue' /* webpackChunkName: "pages/projects/all/repository/index" */))
const _618bee7a = () => interopDefault(import('../pages/auth/resetpassword/_token/index.vue' /* webpackChunkName: "pages/auth/resetpassword/_token/index" */))
const _68a27b89 = () => interopDefault(import('../pages/shared-with-me/drafts/_id/index.vue' /* webpackChunkName: "pages/shared-with-me/drafts/_id/index" */))
const _74a63557 = () => interopDefault(import('../pages/shared-with-me/literatures/_id/index.vue' /* webpackChunkName: "pages/shared-with-me/literatures/_id/index" */))
const _671c6d17 = () => interopDefault(import('../pages/projects/_slug/index.vue' /* webpackChunkName: "pages/projects/_slug/index" */))
const _2f37a66c = () => interopDefault(import('../pages/reviews/_slug/index.vue' /* webpackChunkName: "pages/reviews/_slug/index" */))
const _243fbcb2 = () => interopDefault(import('../pages/student-monitoring/_id/index.vue' /* webpackChunkName: "pages/student-monitoring/_id/index" */))
const _420e6dd8 = () => interopDefault(import('../pages/projects/_slug/advance-recommendations.vue' /* webpackChunkName: "pages/projects/_slug/advance-recommendations" */))
const _1f0d8b0a = () => interopDefault(import('../pages/projects/_slug/archive/index.vue' /* webpackChunkName: "pages/projects/_slug/archive/index" */))
const _90c88816 = () => interopDefault(import('../pages/projects/_slug/collaborators/index.vue' /* webpackChunkName: "pages/projects/_slug/collaborators/index" */))
const _7266e440 = () => interopDefault(import('../pages/projects/_slug/drafts/index.vue' /* webpackChunkName: "pages/projects/_slug/drafts/index" */))
const _590e410a = () => interopDefault(import('../pages/projects/_slug/literatures/index.vue' /* webpackChunkName: "pages/projects/_slug/literatures/index" */))
const _26d15ea6 = () => interopDefault(import('../pages/projects/_slug/project-feeds.vue' /* webpackChunkName: "pages/projects/_slug/project-feeds" */))
const _4b40e518 = () => interopDefault(import('../pages/projects/_slug/repository/index.vue' /* webpackChunkName: "pages/projects/_slug/repository/index" */))
const _e9222218 = () => interopDefault(import('../pages/projects/_slug/suggestions.vue' /* webpackChunkName: "pages/projects/_slug/suggestions" */))
const _23ffe7bd = () => interopDefault(import('../pages/reviews/_slug/archive/index.vue' /* webpackChunkName: "pages/reviews/_slug/archive/index" */))
const _08d5d41b = () => interopDefault(import('../pages/reviews/_slug/related-literatures/index.vue' /* webpackChunkName: "pages/reviews/_slug/related-literatures/index" */))
const _199b30f6 = () => interopDefault(import('../pages/reviews/_slug/repository/index.vue' /* webpackChunkName: "pages/reviews/_slug/repository/index" */))
const _2163ab62 = () => interopDefault(import('../pages/reviews/_slug/submissions/index.vue' /* webpackChunkName: "pages/reviews/_slug/submissions/index" */))
const _7ea6dc67 = () => interopDefault(import('../pages/reviews/_slug/suggestions.vue' /* webpackChunkName: "pages/reviews/_slug/suggestions" */))
const _670b8460 = () => interopDefault(import('../pages/reviews/_slug/working-notes/index.vue' /* webpackChunkName: "pages/reviews/_slug/working-notes/index" */))
const _419bd460 = () => interopDefault(import('../pages/projects/_slug/collaborators/_id/index.vue' /* webpackChunkName: "pages/projects/_slug/collaborators/_id/index" */))
const _529841aa = () => interopDefault(import('../pages/projects/_slug/drafts/_id/index.vue' /* webpackChunkName: "pages/projects/_slug/drafts/_id/index" */))
const _2d6bc6f5 = () => interopDefault(import('../pages/projects/_slug/literatures/_id/index.vue' /* webpackChunkName: "pages/projects/_slug/literatures/_id/index" */))
const _8bbd2cf4 = () => interopDefault(import('../pages/reviews/_slug/related-literatures/_id/index.vue' /* webpackChunkName: "pages/reviews/_slug/related-literatures/_id/index" */))
const _c4577566 = () => interopDefault(import('../pages/reviews/_slug/submissions/_id/index.vue' /* webpackChunkName: "pages/reviews/_slug/submissions/_id/index" */))
const _ac69216a = () => interopDefault(import('../pages/reviews/_slug/working-notes/_id/index.vue' /* webpackChunkName: "pages/reviews/_slug/working-notes/_id/index" */))
const _04b3c27a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'is-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
      path: "/autocomplete",
      component: _66f5b14a,
      name: "autocomplete"
    }, {
      path: "/discover",
      component: _1e4bb0ea,
      name: "discover"
    }, {
      path: "/gettingstarted",
      component: _47002ec8,
      name: "gettingstarted"
    }, {
      path: "/lira",
      component: _16c25864,
      name: "lira"
    }, {
      path: "/maintenance",
      component: _c5acc572,
      name: "maintenance"
    }, {
      path: "/newOnboarding",
      component: _7a81144c,
      name: "newOnboarding"
    }, {
      path: "/preparingRAxAssistant",
      component: _b86c29ac,
      name: "preparingRAxAssistant"
    }, {
      path: "/pricing",
      component: _78594977,
      name: "pricing"
    }, {
      path: "/profile",
      component: _2a3c7646,
      name: "profile"
    }, {
      path: "/projects",
      component: _6e4c06dc,
      name: "projects"
    }, {
      path: "/reviews",
      component: _1d036e62,
      name: "reviews"
    }, {
      path: "/shared-with-me",
      component: _9318410e,
      name: "shared-with-me"
    }, {
      path: "/student-monitoring",
      component: _522fc4bc,
      name: "student-monitoring"
    }, {
      path: "/subscription",
      component: _94655a88,
      children: [{
        path: "",
        component: _13e7dc3f,
        name: "subscription"
      }, {
        path: "payment",
        component: _4c720a33,
        name: "subscription-payment"
      }, {
        path: "payment-success",
        component: _51d8512e,
        name: "subscription-payment-success"
      }, {
        path: "payment-success-duplicate",
        component: _1ff606a7,
        name: "subscription-payment-success-duplicate"
      }]
    }, {
      path: "/auth/forgot-password",
      component: _c04c5486,
      name: "auth-forgot-password"
    }, {
      path: "/auth/signin",
      component: _0f25719a,
      name: "auth-signin"
    }, {
      path: "/auth/signin-mobile",
      component: _032648e5,
      name: "auth-signin-mobile"
    }, {
      path: "/auth/signup",
      component: _23bbc810,
      name: "auth-signup"
    }, {
      path: "/auth/verify-email",
      component: _43c9e040,
      name: "auth-verify-email"
    }, {
      path: "/auth/verify-email-mobile",
      component: _2b4d337f,
      name: "auth-verify-email-mobile"
    }, {
      path: "/discover/acs-collections",
      component: _9a3be75c,
      name: "discover-acs-collections"
    }, {
      path: "/discover/bookmarks",
      component: _31dbfab6,
      name: "discover-bookmarks"
    }, {
      path: "/discover/feeds",
      component: _7fe59864,
      name: "discover-feeds"
    }, {
      path: "/discover/library",
      component: _250f7a98,
      name: "discover-library"
    }, {
      path: "/faculty/email-change",
      component: _24a2502a,
      name: "faculty-email-change"
    }, {
      path: "/faculty/invitation",
      component: _cf4ab5fa,
      name: "faculty-invitation"
    }, {
      path: "/projects/archive",
      component: _f384e062,
      name: "projects-archive"
    }, {
      path: "/quick-view/related-papers",
      component: _12c637f8,
      name: "quick-view-related-papers"
    }, {
      path: "/quick-view/summary",
      component: _40495a0a,
      name: "quick-view-summary"
    }, {
      path: "/record-result-action/platform",
      component: _75c20769,
      name: "record-result-action-platform"
    }, {
      path: "/shared-with-me/drafts",
      component: _e54340c4,
      name: "shared-with-me-drafts"
    }, {
      path: "/shared-with-me/email-change",
      component: _5cadb92a,
      name: "shared-with-me-email-change"
    }, {
      path: "/shared-with-me/invitation",
      component: _13ceca02,
      name: "shared-with-me-invitation"
    }, {
      path: "/shared-with-me/literatures",
      component: _04f3306c,
      name: "shared-with-me-literatures"
    }, {
      path: "/shared-with-me/request",
      component: _1c677af6,
      name: "shared-with-me-request"
    }, {
      path: "/shared-with-me/result",
      component: _67ebedf4,
      name: "shared-with-me-result"
    }, {
      path: "/verify/email",
      component: _7298b7a5,
      name: "verify-email"
    }, {
      path: "/projects/all/advance-recommendations",
      component: _53a15524,
      name: "projects-all-advance-recommendations"
    }, {
      path: "/projects/all/drafts",
      component: _92f20aee,
      name: "projects-all-drafts"
    }, {
      path: "/projects/all/literatures",
      component: _9fc49f3e,
      name: "projects-all-literatures"
    }, {
      path: "/projects/all/project-feeds",
      component: _7c4a7380,
      name: "projects-all-project-feeds"
    }, {
      path: "/projects/all/repository",
      component: _8d2aa23e,
      name: "projects-all-repository"
    }, {
      path: "/auth/resetpassword/:token?",
      component: _618bee7a,
      name: "auth-resetpassword-token"
    }, {
      path: "/shared-with-me/drafts/:id?",
      component: _68a27b89,
      name: "shared-with-me-drafts-id"
    }, {
      path: "/shared-with-me/literatures/:id?",
      component: _74a63557,
      name: "shared-with-me-literatures-id"
    }, {
      path: "/projects/:slug",
      component: _671c6d17,
      name: "projects-slug"
    }, {
      path: "/reviews/:slug",
      component: _2f37a66c,
      name: "reviews-slug"
    }, {
      path: "/student-monitoring/:id?",
      component: _243fbcb2,
      name: "student-monitoring-id"
    }, {
      path: "/projects/:slug/advance-recommendations",
      component: _420e6dd8,
      name: "projects-slug-advance-recommendations"
    }, {
      path: "/projects/:slug/archive",
      component: _1f0d8b0a,
      name: "projects-slug-archive"
    }, {
      path: "/projects/:slug/collaborators",
      component: _90c88816,
      name: "projects-slug-collaborators"
    }, {
      path: "/projects/:slug/drafts",
      component: _7266e440,
      name: "projects-slug-drafts"
    }, {
      path: "/projects/:slug/literatures",
      component: _590e410a,
      name: "projects-slug-literatures"
    }, {
      path: "/projects/:slug/project-feeds",
      component: _26d15ea6,
      name: "projects-slug-project-feeds"
    }, {
      path: "/projects/:slug/repository",
      component: _4b40e518,
      name: "projects-slug-repository"
    }, {
      path: "/projects/:slug/suggestions",
      component: _e9222218,
      name: "projects-slug-suggestions"
    }, {
      path: "/reviews/:slug/archive",
      component: _23ffe7bd,
      name: "reviews-slug-archive"
    }, {
      path: "/reviews/:slug/related-literatures",
      component: _08d5d41b,
      name: "reviews-slug-related-literatures"
    }, {
      path: "/reviews/:slug/repository",
      component: _199b30f6,
      name: "reviews-slug-repository"
    }, {
      path: "/reviews/:slug/submissions",
      component: _2163ab62,
      name: "reviews-slug-submissions"
    }, {
      path: "/reviews/:slug/suggestions",
      component: _7ea6dc67,
      name: "reviews-slug-suggestions"
    }, {
      path: "/reviews/:slug/working-notes",
      component: _670b8460,
      name: "reviews-slug-working-notes"
    }, {
      path: "/projects/:slug/collaborators/:id",
      component: _419bd460,
      name: "projects-slug-collaborators-id"
    }, {
      path: "/projects/:slug/drafts/:id",
      component: _529841aa,
      name: "projects-slug-drafts-id"
    }, {
      path: "/projects/:slug/literatures/:id",
      component: _2d6bc6f5,
      name: "projects-slug-literatures-id"
    }, {
      path: "/reviews/:slug/related-literatures/:id?",
      component: _8bbd2cf4,
      name: "reviews-slug-related-literatures-id"
    }, {
      path: "/reviews/:slug/submissions/:id",
      component: _c4577566,
      name: "reviews-slug-submissions-id"
    }, {
      path: "/reviews/:slug/working-notes/:id?",
      component: _ac69216a,
      name: "reviews-slug-working-notes-id"
    }, {
      path: "/",
      component: _04b3c27a,
      name: "index"
    }],

  fallback: false
}

export function createRouter() {
  return new Router(routerOptions)
}
