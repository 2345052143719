<template>
  <ModalContent>
    <template v-slot:header>
      <section class="tw-flex tw-mx-6 tw-relative">
        <span class="tw-absolute tw-right-0 tw-text-gray-600">
          <i
            class="material-icons tw-cursor-pointer"
            @click.prevent="closeModal()"
          >close</i>
        </span>
      </section>
    </template>
    <template v-slot:body>
      <section style="margin-bottom: 0" class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-mx-6 tw-mb-6 tw-mt-2 tw-relative">
        <h1 class="tw-text-center tw-text-3xl">
          <span class="tw-font-bold" style="color: #2E3E4E">Refer Enago Read to your friends</span>
        </h1>
        <div class="tw-flex tw-justify-evenly tw-text-center" style="margin-top: 15px">
          <div class="tw-flex tw-flex-col tw-items-center tw-px-3">
            <span class="tw-font-bold tw-flex tw-justify-center tw-items-start tw-text-blue-primary tw-text-xl tw-mb-2">You get</span>
            <div class="tw-px-2 tw-py-3 tw-bg-white tw-rounded-lg tw-flex tw-flex-col tw-items-center tw-border tw-border-solid tw-border-blue-primary tw-max-w-80">
              <h2 style="color: #6D7284" class="tw-text-xl tw-text-center tw-font-medium"><span class="tw-text-blue-primary">$12</span> as a token of thanks</h2>
              <span style="color: #6D7284" class="tw-text-base tw-mt-3">after your referred friend successfully signs up with Enago Read.</span>
            </div>
          </div>
          <div class="tw-flex tw-flex-col tw-items-center tw-px-3">
            <span style="color: #017EE6" class="tw-font-bold tw-flex tw-justify-center tw-items-start tw-text-xl tw-mb-2">They get</span>
            <div class="tw-px-2 tw-py-3 tw-bg-white tw-rounded-lg tw-flex tw-flex-col tw-items-center tw-border tw-border-solid tw-border-blue-primary tw-max-w-80">
              <h2 style="color: #6D7284" class="tw-text-xl tw-text-center tw-font-medium"><span style="color: #017EE6;">$5</span> as a joining bonus</h2>
              <span style="color: #6D7284" class="tw-text-base tw-mt-3">in their wallet as a credit after their successful sign up with Enago Read.</span>
            </div>
          </div>
        </div>
        <div class="tw-w-5/6" style="margin-top: 15px">
          <span style="color: #2E3E4E;" class="tw-font-medium tw-flex">Share your unique referral link</span>
          <div class="tw-py-2 tw-flex">
            <span
              style="color: #6D7284"
              class="tw-pb-1 tw-text-sm tw-leading-snug"
            >{{ referralLink }}
            </span>
            <span
              class="tw-text-sm tw-font-medium tw-underline tw-px4 tw-ml-9 tw-cursor-pointer tw-text-blue-primary"
              @click="copyReferral"
            >{{ isLinkCopied ? 'Copied' : 'Copy link' }}</span>
          </div>

          <div class="tw-flex tw-justify-start" style="padding-top: 0;padding-bottom: 10px;">
            <div
              data-a2a-title="Hey, I am trying this cool literature review tool called Enago Read. Literature survey is time-consuming and I feel Enago Read might be what I always wanted for “easy-n-quick” critical reading. Check it out, it will be useful for you as well. As they love getting feedback from the research community, you can share yours after using it. Also, you will get a $5 reward as a token of appreciation, if you sign up using the link. Help them get more feedback by sharing Enago Read with others and as a token of appreciation, they will give you credit of $12 as a reward."
              class="py-3 a2a_kit a2a_kit_size_32 a2a_default_style large-icons"
            >
              <a
                v-for="item in socialMediaLinks"
                :key="item"
                class="tw-mx-2"
                :class="['a2a_button_' + item]"
              />
              <a class="a2a_dd" href="https://www.addtoany.com/share" />
            </div> 
          </div>
        </div>
        <div style="background: #F0F7FF" class="tw-w-10/12	tw-rounded tw-mt-8 tw-px-8 tw-py-4 tw-bg-blue-light2">
          <span style="color: #2E3E4E;" class="tw-font-medium tw-flex">Invite via email
            <img
              class="tw-mx-2"
              loading="lazy"
              :src="assetsUrl + 'img/referral-program/secure.svg'"
            />
          </span>
          <div class="tw-flex tw-my-4">
            <textarea
              v-model="emails"
              class="tw-bg-white tw-w-full tw-text-black-72 tw-p-2 tw-text-sm	tw-resize-none tw-rounded tw-border-blue-primary"
              rows="3"
              placeholder="use comma(,) to add multiple email addresses"
            />
            <div class="tw-flex tw-flex-col tw-items-center tw-ml-8">
              <button
                :disabled="!isInviteToValid"
                class="tw-border-blue-primary tw-border tw-border-solid tw-w-36 tw-h-8 tw-text-white tw-bg-blue-primary tw-text-sm tw-font-medium"
                :class="{
                  'tw-opacity-50 tw-cursor-not-allowed':
                    !isInviteToValid
                }"
                @click="inviteUser"
              >
                Send
              </button>
              <a
                :href="
                  assetsUrl +
                    'img/referral-program/sample-referral-email-template.png'
                "
                target="_blank"
                class="tw-text-xs tw-underline tw-w-32 tw-pt-4 tw-cursor-pointer tw-text-blue-primary tw-justify-center"
              >
                See sample email
              </a>
            </div>
          </div>
        </div>
        <div class="tw-flex tw-w-5/6 tw-text-xs tw-mt-2 tw-items-center tw-text-black">
          To know how it works,
          <a
            :href="landingUrl + 'referral'"
            target="_blank"
            class="tw-text-xs tw-underline tw-w-16 tw-pl-0.5 tw-cursor-pointer tw-text-blue-primary tw-justify-start"
          >
            click here
          </a>
        </div>
      </section>
    </template>
  </ModalContent>
</template>

<style>
.large-icons .a2a_svg {
  padding: 8px;
  width: 48px;
  height: 48px;
}
</style>

<script>
import { mapGetters } from "vuex";
import ModalContent from "~/components/ui/modalContent";
import { SOCIAL_SHARE_CONTENT } from "~/shared/constants";

export default {
  components: {
    ModalContent
  },
   props: {
    closeModal: {
      type: Function,
      required: false,
      default: () => null
    }
   },
  data() {
    return {
      emails: '',
      assistantUrl: process.env.assistantUrl,
      referralLink: '',
      remarks: '',
      assetsUrl: process.env.assetsUrl,
      landingUrl: process.env.landingUrl,
      isValidInput: false,
      EMAIL_RGX: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      isLinkCopied: false,
    }
  },
  computed: {
    ...mapGetters({
      user: "user/getData",
    }),
    isInviteToValid() {
      const inviteTo = this.emails;
      if (!inviteTo) {
        return false;
      }

      let invitationEmails = inviteTo.split(',');
      invitationEmails = invitationEmails.map(e => e.trim());

      if (!invitationEmails || !invitationEmails.length) {
        return false;
      }
      const checker = email => email.every(e => this.EMAIL_RGX.test(e));
      return checker(invitationEmails);
    },
    socialMediaLinks() {
      const { WHATSAPP, TWITTER, GMAIL, LINKEDIN, REDDIT, TELEGRAM } = SOCIAL_SHARE_CONTENT;
      return [
        WHATSAPP.TYPE,
        TWITTER.TYPE,
        GMAIL.TYPE,
        LINKEDIN.TYPE,
        REDDIT.TYPE,
        TELEGRAM.TYPE
      ];
    },
  },
  mounted() {
    this.getReferralLink();
    const addToAnyScript = document.createElement('script');
    addToAnyScript.setAttribute(
      'src',
      'https://static.addtoany.com/menu/page.js'
    );
    document.head.appendChild(addToAnyScript);
  },
  methods: {
    resetInviteTo() {
      this.emails = '';
    },
    setUrlOnShare() {
      return {
        url: this.referralLink
      };
    },
    async getReferralLink () {
      const userEmail = this.user.email;
      const { data } = await this.$axios.get(`/referral-program/referral-code?email=${userEmail}&&name=''`);
      const { referralCode } = data;
      this.referralLink = `${process.env.assistantUrl}auth/signup?referral-code=${referralCode}`;
      const a2aConfig = window.a2a_config || {};
      a2aConfig.callbacks = a2aConfig.callbacks || [];
      a2aConfig.exclude_services = ['facebook'];
      a2aConfig.prioritize = [
        'blogger',
        'email',
        'viber',
        'line',
        'facebook_messenger',
        'mendeley',
        'sms',
        'trello',
        'wechat'
      ];
      a2aConfig.callbacks.push({
        share: this.setUrlOnShare.bind(this)
      });

      a2a_config.templates = a2a_config.templates || {};
      a2a_config.templates.email = {
        subject: SOCIAL_SHARE_CONTENT.GMAIL.SUBJECT,
        body: SOCIAL_SHARE_CONTENT.GMAIL.BODY,
      };
      a2a_config.templates.whatsapp = {
        text: SOCIAL_SHARE_CONTENT.WHATSAPP.TEXT,
      };
      a2a_config.templates.linkedin = {
        text: SOCIAL_SHARE_CONTENT.LINKEDIN.TEXT,
      };
      a2a_config.templates.twitter = {
        text: SOCIAL_SHARE_CONTENT.TWITTER.TEXT,
      };
      a2a_config.templates.facebook = {
        text: SOCIAL_SHARE_CONTENT.FACEBOOK.TEXT,
      };
      a2aConfig.templates.reddit = {
        title: SOCIAL_SHARE_CONTENT.REDDIT.SUBJECT,
        text: SOCIAL_SHARE_CONTENT.REDDIT.TEXT
      };
      a2aConfig.templates.telegram = {
        content: SOCIAL_SHARE_CONTENT.TELEGRAM.CONTENT
      };
    },
    async inviteUser() {
      this.$mixpanel.track("Refer and Earn");
      try {
        if (!this.isInviteToValid) {
          return false;
        }

        let emails = (this.emails).split(',');
        emails = emails.map(e => e.trim());
        await this.$axios.post("/referral-program/refer", {
          emails
        });
        this.resetInviteTo();
        setTimeout(() => {
          this.$store.commit("core/setSnackbar", {
            message: "Referral link sent successfully"
          });
          $("#snackbar").MaterialSnackbar("show");
        }, 300);
        this.$emit("startPolling");
      } catch (e) {
        setTimeout(() => {
          this.$store.commit("core/setSnackbar", {
            message: "Something went wrong"
          });
          $("#snackbar").MaterialSnackbar("show");
        }, 300);
      }
    },
    async copyReferral() {
      this.$mixpanel.track("Copy Referral Link");
      this.isLinkCopied = true;
      const el = document.createElement("textarea");
      el.value = this.referralLink;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      setTimeout(() => {
        this.$store.commit("core/setSnackbar", {
          message: "Referral link copied to the clipboard"
        });
        $("#snackbar").MaterialSnackbar("show");
      }, 300);
      setTimeout(() => {
         this.isLinkCopied = false;
      }, 3000);
    },
  }
};
</script>
