var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar" },
    [
      _vm.hasBackdrop && _vm.isPanelOpen
        ? _c("div", {
            staticClass: "sidebar-backdrop",
            style: _vm.backDropStyle,
            on: { click: _vm.closeSidebarPanel }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("transition", { attrs: { name: _vm.shouldAnimate ? "slide" : "" } }, [
        _vm.isPanelOpen
          ? _c(
              "div",
              {
                staticClass: "sidebar-panel",
                class: {
                  left: _vm.isLeftSidePanel,
                  right: !_vm.isLeftSidePanel
                },
                style: _vm.sidePanelStyle
              },
              [_vm._t("default")],
              2
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }