var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("app-header", { attrs: { "display-landing-nav": true } }),
      _vm._v(" "),
      _c("app-drawer", {
        attrs: { unid: "drawer" },
        on: { openFeedback: _vm.openFeedback }
      }),
      _vm._v(" "),
      _c(_vm.asyncComp, {
        tag: "feedback",
        attrs: { "dialog-id": _vm.dialogId }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "md-container md-container--box md-container--gutter md-container--gutter-40-md",
          attrs: { "data-ready": "true" }
        },
        [
          _c(
            "main",
            {
              staticClass:
                "nt-active md-layout md-align md-align--center-center rx-full-height"
            },
            [_c("nuxt", { staticClass: "md-flex--grow" })],
            1
          ),
          _vm._v(" "),
          _c("app-snackbar", {
            staticClass: "md-snackbar--primary",
            attrs: {
              "action-text": _vm.snackbar.actionText,
              message: _vm.snackbar.message,
              unid: "snackbar"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.isAppLoading
        ? _c(
            "div",
            {
              staticClass: "layout-block layout-block--fixed",
              attrs: { id: _vm.unid + "-block" }
            },
            [
              _c("app-progress", {
                attrs: {
                  unid: _vm.unid + "-progress",
                  config: {
                    circle: true,
                    center: true,
                    overlay: true,
                    parent: true,
                    target: "#" + _vm.unid + "-block"
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }