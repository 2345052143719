<template>
  <div class="result-card-container">
    <img
      :src="assetsUrl + 'img/icon/pdf.svg'"
      class="result-card-icon"
    />
    <div class="result-card-detail">
      <p class="tw-inline-flex tw-items-center">
        <span
          class="result-card-literature-title overflow-ellipsis tw-cursor-pointer"
          v-tooltip="{
            content: searchItem.literatureTitle,
            delay: {
              show: 300,
              hide: 100
            },
            placement: 'bottom'
          }"
          v-html="literatureTitle"
          @click.prevent="openLiteratureInReadingRoom('Title')"
        />
        <span class="result-card-literature-title-divider">|</span>
        <span class="tw-inline-flex tw-items-center">
          <svg class="tw-mx-1" width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.33301 1.33919C2.1562 1.33919 1.98663 1.40943 1.8616 1.53445C1.73658 1.65948 1.66634 1.82905 1.66634 2.00586V9.33919C1.66634 9.516 1.73658 9.68557 1.8616 9.8106C1.98663 9.93562 2.1562 10.0059 2.33301 10.0059H11.6663C11.8432 10.0059 12.0127 9.93562 12.1377 9.8106C12.2628 9.68557 12.333 9.516 12.333 9.33919V4.00586C12.333 3.82905 12.2628 3.65948 12.1377 3.53445C12.0127 3.40943 11.8432 3.33919 11.6663 3.33919H6.99967C6.82286 3.33919 6.65329 3.26895 6.52827 3.14393L4.72353 1.33919H2.33301ZM0.918794 0.591646C1.29387 0.216573 1.80257 0.00585938 2.33301 0.00585938H4.99967C5.17649 0.00585938 5.34606 0.0760972 5.47108 0.201122L7.27582 2.00586H11.6663C12.1968 2.00586 12.7055 2.21657 13.0806 2.59165C13.4556 2.96672 13.6663 3.47543 13.6663 4.00586V9.33919C13.6663 9.86963 13.4556 10.3783 13.0806 10.7534C12.7055 11.1285 12.1968 11.3392 11.6663 11.3392H2.33301C1.80257 11.3392 1.29387 11.1285 0.918794 10.7534C0.543721 10.3783 0.333008 9.86963 0.333008 9.33919V2.00586C0.333008 1.47543 0.543721 0.966719 0.918794 0.591646Z" fill="#414E62"/>
          </svg>
          <span class="result-card-project-title overflow-ellipsis">{{ searchItem.projectTitle }}</span>
        </span>
      </p>
      <div class="result-card-matching-detail">
        <p class="result-card-matching">
          <span class="result-card-matching-section">
            Related to the section
          </span>
          <span class="result-card-matching-section-title">{{ searchItem.sectionHeader }}</span>
        </p>
        <span class="result-card-matching-highlight" v-html="highlightedText" />
        <span
          class="result-card-view-more"
          @click.prevent="openLiteratureInReadingRoom('ReadMore')"
        >
          Read more
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { MixpanelEventGroup, MixpanelEvents } from '~/shared/constants';
export default {
  props: {
    searchItem: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      assetsUrl: process.env.assetsUrl,
      assistantUrl: process.env.assistantUrl,
    };
  },
  computed: {
    highlightedText() {
      return this.searchItem.highlightedTexts && this.searchItem.highlightedTexts.rawText || "";
    },
    literatureTitle() {
      const { title } = this.searchItem.highlightedTexts;
      return title || this.searchItem.literatureTitle || "";
    }
  },
  methods: {
    openLiteratureInReadingRoom(sourceMode) {
      const { projectSlug, literatureSlug, literatureTitle } = this.searchItem;
      const mixpanelEventGroup = MixpanelEvents[MixpanelEventGroup.Search];
      const mixpanelEventTitle = sourceMode === 'Title' ? mixpanelEventGroup.Literature_Title : mixpanelEventGroup.Search_Read_More;
      const mixpanelEventPayload = { title: literatureTitle };
      if (sourceMode === 'Title') mixpanelEventPayload['source'] = 'Search';
      this.$mixpanel.track(mixpanelEventTitle, mixpanelEventPayload);
      this.$emit("closeSearch");
      window.open(
        `${this.assistantUrl}projects/${projectSlug}/literatures/${literatureSlug}`,
        "_blank"
      );
    }
  }
};
</script>

<style>
.result-card-container {
  display: flex;
  padding: 12px 0px;
  width: 100%;
}
.result-card-icon {
  height: 20px;
  width: 20px;
  margin: 3px;
}
.result-card-detail {
  border-radius: 8px;
  width: 100%;
}
.result-card-title {
  margin-bottom: 8px;
}
.result-card-literature-title-divider { 
  font-size: 18px;
  color: #DDE2E9;
  margin: 0px 2px;
}
.result-card-literature-title {
  color: #111A27;
  font-size: 14px;
  font-weight: bold;
  margin-right: 2px;
  max-width: 450px;
}
.result-card-project-title {
  font-size: 12px;
  max-width: 200px;
}
.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.result-card-matching-detail {
  background: #EFF6FF;
  padding: 10px;
  border-radius: 8px;
  width: 100%;
}
.result-card-matching-section {
  background: #D1E0F9;
  margin-right: 5px;
  font-size: 12px;
  color: #1F2937;
  padding: 2px;
}
.result-card-matching-section-title {
  font-weight: bold;
  color: #111A27;
}
.result-card-matching-highlight {
  color: #414E62;
}
.search-highlighted-text {
  background: #FEF3C7;
  color: #111A27;
}
.result-card-view-more {
  text-decoration: underline;
  color: #1A64E2;
  cursor: pointer;
}
</style>