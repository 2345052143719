const defaultProfileState = () => {
  return {
    data: {},
    searchResults: []
  };
};

export default {
  namespaced: true,
  state: defaultProfileState,

  mutations: {
    setData(state, data) {
      state["data"] = data;
    },
    setAutoLinkup(state, data) {
      state["data"]["autoLinkPdfFlag"] = data;
    },
    setFeatureIntroAutoLinkup(state, data) {
      if(state["data"]["featureIntroduction"]) {
        state["data"]["featureIntroduction"]["autoLinkPdf"] = data;
      } else {
        state["data"] = {
          "featureIntroduction": { "autoLinkPdf": data }
        };
      }
    },
    modifyUserPinnedTags(state, data) {
      state["data"]["pinnedTags"] = data;
    },
    setSearchResults(state, data) {
      state["searchResults"] = data;
    },
    resetState(state) {
      Object.assign(state, defaultProfileState());
    }
  },

  actions: {
    async fetchProfile({ commit }) {
      const data = await this.$axios.$get("/showgeneralprofile");
      commit("setData", data);
    },
    async update({ commit }, inputs) {
      const { data } = await this.$axios.put(`/generalprofile`, inputs);
      commit("setData", data[0]);
    },
    async availDiscount({ commit }, params) {
      const data = await this.$axios.$get(`/plan-details/avail/discount?discountCode=${params.discountCode}`);
      console.log(data);
    },
    async modifyAutoLinkup({ commit }, payload) {
      try {
        let { autoLinkPdfFlag } = await this.$axios.$patch(
        `user/update-auto-link-switch`,
        {autoLinkPdfFlag: payload ? 1 : 0}
      );
      commit("setAutoLinkup", Boolean(autoLinkPdfFlag))
      } catch (error) {
        console.log(error);
      }
    },
    async updateFeatureIntroductionAutoLinkup({ commit }) {
      try {
        await this.$axios.$patch(
          `user/update-feature-introduction`,
          {
            feature: 'autoLinkPdf',
            setting: true,
          }
        );
        commit("setFeatureIntroAutoLinkup", true)
      } catch (error) {
        console.log(error);
      }
    },
    async modifyUserPinnedTags({ commit, state }, pinnedTags) {
      const originalPinnedList = state["data"]["pinnedTags"];
      commit("modifyUserPinnedTags", pinnedTags);
      try {
        const resp = await this.$axios.$patch("/generalprofile/tags", { pinnedTags });
        if (!resp.success) throw new Error('Unable to update pinned tags');
      } catch (err) {
        commit("modifyUserPinnedTags", originalPinnedList);
      }
    },
    async togglePinnedTag({ commit, state }, { tagItem, action }) {
      const originalPinnedList = state["data"]["pinnedTags"];
      const modifiedPinnedList = originalPinnedList.map((pinnedTag) => ({
        ...pinnedTag,
        isPinned: pinnedTag.id === tagItem.id && action === 'activate'
      }));
      commit("modifyUserPinnedTags", modifiedPinnedList);
      try {
        const resp = await this.$axios.$patch("/generalprofile/tags", { pinnedTags: modifiedPinnedList });
        if (!resp.success) throw new Error('Unable to activate pinned tag');
      } catch (err) {
        commit("modifyUserPinnedTags", originalPinnedList);
      }
    },
    async removeTagFromPinnedList({ commit, state }, tagSelected) {
      const originalPinnedList = state["data"]["pinnedTags"];
      const filteredPinnedList = originalPinnedList.filter((pinnedTag) => pinnedTag.id !== tagSelected.id);
      commit("modifyUserPinnedTags", filteredPinnedList);
      try {
        const resp = await this.$axios.$patch("/generalprofile/tags", { pinnedTags: filteredPinnedList });
        if (!resp.success) throw new Error('Unable to removed selected pinned tag from list');
      } catch (err) {
        commit("modifyUserPinnedTags", originalPinnedList);
      }
    },
    async initateFullTextSearch({ commit }, text) {
      try {
        const { filteredLiteraturesList } = await this.$axios.$post("/search", { text });
        commit("setSearchResults", filteredLiteraturesList);
      } catch (err) {
        commit("setSearchResults", []);
        throw new Error(`Unable to find search results`);
      }
    }
  },

  getters: {
    getData(state) {
      return state.data;
    },
    getAutoLinkup(state) {
      return Boolean(state.data.autoLinkPdfFlag);
    },
    getFeatureIntroAutoLinkup(state) {
      return state.data.featureIntroduction && state.data.featureIntroduction.autoLinkPdf;
    },
    getPlan(state) {
      return state.data.isTeamPlan;
    },
    getDisableStatusReminder(state){
      console.log("this is the profile ", state.data);
      return state.data.disableStatusReminder;
    },
    isNewFeatureShownFlag(state) {
      return state.data.IsNewFeatureShownFlag;
    },
    getUserPinnedTags(state) {
      return (state["data"] && state["data"]["pinnedTags"] || []);
    },
    getActivatedPinnedTags(state) {
      return (state["data"] && state["data"]["pinnedTags"] || []).filter(({ isPinned }) => isPinned);
    },
    getSearchResults(state) {
      return state["searchResults"] || [];
    }
  }
};
