<template>
  <div :id="unid" class="md-snackbar">
    <div class="md-snackbar__surface">
      <div class="md-snackbar__text" style="display: flex;justify-content: space-between;">
        {{message}}
      </div>
      <div>
        <button v-if="isUndo" @click="emitUndoEvent" style="font-size:14px;color:#93C5FC" >
          Undo
        </button>
        <button
          :class="{ 'md-button--icon': actionText === '' }"
          class="md-button md-snackbar__action md-button--dense md-button--accent md-snackbar__button"
          style="margin:0px;"
        >
          <template v-if="!actionText">
            <i class="material-icons md-button__icon">close</i>
          </template>
          <template v-else>
            {{ actionText }}
          </template>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    unid: {
      type: String,
      required: true
    },
    actionText: {
      type: String,
      default: ""
    },
    message: {
      type: String,
      required: true
    },
    config: {
      type: Object,
      default: function() {
        return { float: true };
      },
    },
    isUndo:{
      type:Boolean,
      default:false 
    },
    eventName:{
      type:String,
      default:""
    },
    id:{
      type:Number,
      default:0
    }
  },
  data() {
    return {
      defaultConfig: {
        show: false,
        update: false,
        theme: "theme",
        timeout: 3000
      }
    };
  },
  watch: {
    config(newConf) {
      $(`#${this.unid}`).MaterialSnackbar("destroy");
      const config = {
        ...this.defaultConfig,
        ...newConf
      };
      this.prepareSnackbar(config);
    }
  },
  mounted() {
    const config = {
      ...this.defaultConfig,
      ...this.config
    };
    this.prepareSnackbar(config);
  },
  methods: {
    prepareSnackbar(config) {
      const snackbar = $(`#${this.unid}`);
      snackbar.MaterialSnackbar(config);
      snackbar
        .on("ca.snackbar.hidden", () => {
          this.$emit("hidden", {});
        })
        .on("ca.snackbar.shown", () => {
          this.$emit("shown", {});
        });
    },
    emitUndoEvent(){
      if(this.eventName=="unarchived"){
      this.$raxEventBus.$emit(this.eventName,this.id);
      }
      else
      this.$raxEventBus.$emit(this.eventName);
    }
  },
  beforeDestroy() {
    $(`#${this.unid}`).MaterialSnackbar("destroy");
    this.$store.commit("literatures/clearRecentlyAddedLiterature");
  }
};
</script>

<style scoped>
.md-snackbar__surface {
  padding: 30px 24px;
  border-radius: 6px;
}
</style>
