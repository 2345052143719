<template>
  <div
    id="app-global-loader"
    class="layout-block layout-block--center layout-block--overlay layout-block--fixed"
  >
    <app-progress
      :config="{
        fixed: true,
        circle: true,
        overlay: true,
        parent: true,
        target: '#app-global-loader'
      }"
      unid="app-loading"
      data-type="indeterminate"
    />
  </div>
</template>
<script>
export default {};
</script>
