const defaultReferralProgramState = () => {
  return {
    kiReferralReward: {},
    rewardRedeemResponse: {}
  }
};

export default {
  namespaced: true,
  state: defaultReferralProgramState,

  mutations: {
    updateKIReferralData(state, payload){
      state.kiReferralReward = payload;
    },
    updateRewardRedeemResponse(state, payload) {
      state.rewardRedeemResponse = payload;
    },
    resetState(state, _) {
      state.kiReferralReward = {}
      state.rewardRedeemResponse = {}
    }
  },

  actions: {
    async fetch({ commit }) {
      let { data } = await this.$axios.get(`/referral-program/get-ki-referral`);
      commit('updateKIReferralData', data);
    },
    async checkReferralRewardStatus({ commit }, payload) {
      let { data } = await this.$axios.get(`/referral-program/redeem-incentive/${payload.referralCode}`);
      commit('updateRewardRedeemResponse', data);
    }
   },

  getters: {
    getKIReferralReward(state){
      return state.kiReferralReward;
    },
    getRewardRedeemResponse(state) {
      return state.rewardRedeemResponse;
    }
  },
}
