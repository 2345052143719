<template>
  <Sidebar
    :isPanelOpen="isMobileDrawerOpen"
    :isLeftSidePanel="false"
    :hasBackdrop="false"
    :sideBarPanelStyle="{
      width: '100%',
      position: 'fixed',
      height: '100%',
      overflow: 'hidden'
    }"
  >
    <div class="tw-w-full">
      <div
        v-if="isMobileDrawerOpen"
        class="tw-absolute tw-text-black-72 tw-h-full tw-overflow-auto tw-w-full tw-z-20 tw-right-0 tw-bg-white"
        @click.stop=""
      >
        <span
          class="raxIcon raxIcon-menu tw-right-6 tw-mt-5 tw-absolute raxIcon--white tw-cursor-pointer"
          @click="hideDrawer"
        />
        <div
          class="tw-pt-6 tw-pb-4 tw-px-6"
          style="background: linear-gradient(180deg, #007BFB 0%, #1A64E2 100%);"
        >
          <template v-if="profile && profile.profilePic">
            <div
              :style="{
                backgroundImage: 'url(' + assetsUrl + profile.profilePic + ')'
              }"
              class="tw-rounded-full tw-h-14 tw-w-14 tw-ml-1"
            />
          </template>
          <template v-else>
            <div
              class="tw-rounded-full tw-h-14 tw-w-14 tw-flex tw-items-center tw-justify-center tw-bg-white tw-ml-1"
            >
              <i class="material-icons tw-text-4xl"> person </i>
            </div>
          </template>
          <div class="tw-mt-2 tw-font-medium tw-text-white">
            {{ profile && profile.firstName ? profile.firstName : "" }}
            {{ profile && profile.lastName ? profile.lastName : "" }}
          </div>
          <div class="tw-mt-1 tw-text-xs tw-text-white">
            {{ user && user.email }}
          </div>
        </div>
        <div
          class="tw-border-b tw-border-0 tw-border-black-24 tw-border-solid tw-flex tw-flex-col"
        >
          <a
            href="https://read.enago.com/"
            target="_blank"
            class="tw-text-sm tw-ml-6 tw-mt-8 tw-font-medium"
            >Know more about RAx</a
          >
          <a
            href="https://read.enago.com/faq/"
            target="_blank"
            class="tw-text-sm tw-ml-6 tw-mt-8 tw-font-medium"
            >FAQs</a
          >
          <a
            href="https://read.enago.com/contact-us/"
            target="_blank"
            class="tw-text-sm tw-ml-6 tw-my-8 tw-font-medium"
            >Contact us</a
          >
        </div>
        <button
          v-if="user && user.onboardingStatus !== 5"
          class="tw-ml-6 tw-mt-6 tw-font-medium tw-text-sm"
          @click="logout"
        >
          Log out
        </button>
        <div
          class="tw-flex tw-justify-center tw-absolute tw-left-1/2 tw--translate-x-1/2 tw-transform tw-bottom-2"
        >
          <img :src="assetsUrl + 'img/bot-with-bg.png'" />
        </div>
      </div>
    </div>
  </Sidebar>
</template>

<script>
import Sidebar from "@/components/newUI/sideBar";
import openFetchDialog from "@/plugins/mixins/openFetchDialog";
import { mapGetters } from "vuex";

export default {
  components: {
    Sidebar
  },
  data() {
    return {
      assetsUrl: process.env.assetsUrl,
      landingUrl: process.env.landingUrl
    };
  },
  mixins: [openFetchDialog],
  computed: {
    ...mapGetters({
      profile: "profile/getData",
      user: "user/getData",
      isMobileDrawerOpen: "core/isMobileDrawerOpen"
    })
  },
  methods: {
    logout() {
      this.$router.push("/auth/signin");
      this.$store.dispatch("auth/logout");
    },
    hideDrawer() {
      this.$store.commit("core/setData", {
        name: "mobileDrawerOpen",
        data: false
      });
    }
  }
};
</script>
