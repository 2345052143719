<template>
  <div
    :id="unid"
    data-type="indeterminate"
    class="md-progress md-progress--origin-top"
  />
</template>

<script>
export default {
  props: {
    unid: {
      type: String,
      required: true
    },
    config: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      defaultConfig: {
        show: true,
        theme: "primary"
      }
    };
  },
  mounted() {
    const config = {
      ...this.defaultConfig,
      ...this.config
    };
    $(`#${this.unid}`).MaterialProgress(config);
    if (config.type === "indeterminate") {
      $(`#${this.unid}`).addClass("md-progress--indeterminate");
    }
  },
  beforeDestroy() {
    $(`#${this.unid}`).MaterialProgress("destroy");
  }
};
</script>
