import Vue from "vue";
const defaultSharedReviewState = () => {
  return {
    data: {},
    currentIndex: 0
  }
};

// let headingId;

export default {
  namespaced: true,
  state: defaultSharedReviewState,

  mutations: {
    setData(state, data) {
      state["data"] = data;
    },

    updateData(state, data) {
      state.data = data;
    },
    resetState(state) {
      Object.assign(state, defaultSharedReviewState());
    }
  },

  actions: {
    async update({ commit, state, dispatch }, inputs) {
      const { data } = await this.$axios.put(`/reviews`, inputs);
      commit("updateData", data[0]);
    }
  },

  getters: {
    getData(state) {
      return state.data;
    }
  }
};
