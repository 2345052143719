export default async function({ redirect, store, route }) {
  if (!route.fullPath.startsWith("/reviews")) {
    return;
  }

  let user = store.getters["user/getData"];
  if (!(user && user.id)) {
    await store.dispatch("user/setUser");
    user = store.getters["user/getData"];
  }

  //   check for flag
  if (!user.flags.reviewAssistantAccess) {
    return redirect("/projects");
  }
}
