<template>
    <header
      v-if="getShowHeader"
      role="banner"
      :class="{ 'rx-nav-hide-mobile': !isAuthenticated }"
      class="md-navbar md-navbar--dense md-navbar--has-menu-button rx-header-animate md-navbar--fixed md-navbar--light md-navbar--casting-border tw-p-0"
      @click="hideAppSheet"
    >
      <hellobar>
        <template v-if="showPasswordSentBanner">
          <div class="tw-flex tw-py-4">
            <div class="tw-flex-grow tw-text-center tw-ml-8">
              <span class="raxIcon raxIcon-mail tw-align-bottom tw-text-blue-primary tw-mr-2" />We have sent a link to
              <span class="tw-font-bold">set your password</span> on your registered email id.
              {{ canShowSpecialOffer ? 'Set your password and avail' : 'Please set the password to secure your work on Enago Read.'}}
              <span v-if="canShowSpecialOffer"><strong>Christmas offer!</strong></span>
            </div>
            <div class="tw-justify-self-end tw-ml-auto">
              <i
                @click="onClosePasswordSentBanner"
                class="material-icons tw-align-bottom tw-cursor-pointer"
              >close</i>
            </div>
          </div>
        </template>
        <template v-if="isAllowedToSeeOfferBanner && isValidPageToShow && !showPasswordSentBanner">
          <div
            class="deals tw-bg-cover tw-p-4 tw-w-full tw-text-center tw-flex tw-justify-center"
            :style="{ backgroundImage: `url(${dealsBackgroundUrl})`, height:'48px', }"
          >
            <div
              class="tw-flex tw-flex-col tw-items-center sm:tw-flex-col md:tw-flex-col lg:tw-flex-row lg:tw-justify-center tw-text-white"
            >
              <div>
                <span class="tw-text-base tw-font-bold">Christmas offer</span>
                <span class="tw-text-base tw-font-normal">(24 Dec - 25 Dec)</span>
              </div>
              <span class="tw-font-bold tw-mt-2 lg:tw-ml-4 lg:tw-mt-0"
                >Get 50% flat off on Enago Read annual subscription.</span
              >
              <span
                class="tw-cursor-pointer tw-bg-white tw-text-blue-primary tw-rounded-sm tw-py-1 tw-px-4 tw-font-bold tw-text-sm tw-mt-4 lg:tw-ml-4 lg:tw-mt-0"
                @click="gotoSubscribePageFromDeals"
                >Subscribe now</span
              >
              <i class="material-icons tw-absolute tw-cursor-pointer" @click="closeOfferBar" style="right:24px;">close</i>
            </div>
          </div>
        </template>
        <template v-if="(isAuthenticated && subscriptionData && (!haveFullAccess || trialDaysRemain <= 10 || !user.emailVerified)) && isValidPageToShow && !showPasswordSentBanner && !isAllowedToSeeOfferBanner">
          <div>
          <div v-if="!user.emailVerified">
            <!-- if Email not verified -->
            <div class="tw-bg-red-lightest tw-text-black-72 tw-flex tw-justify-center tw-items-center tw-h-12">
              <i class="material-icons tw-mr-1">info</i>
              Please <span class="tw-underline tw-mx-1 tw-cursor-pointer" @click="verifyEmail">verify your email</span> for uninterrupted and secure usage of Enago Read.
            </div>
          </div>
          <div v-else>
            <div v-if="haveNoPreviousPaidPlan && subscriptionData.isPayer">
              <!-- if trial ended -->
              <div v-if="!isTrial" class="tw-bg-black-6 tw-text-black-72 tw-flex tw-justify-center tw-items-center tw-h-12">
                <i class="material-icons tw-mr-1">info</i>
                Your free trial has ended. Please <span @click="gotoSubscribePage" class="tw-text-black-72 tw-mx-1 tw-underline tw-cursor-pointer" id="subscribe-trial-end"> Subscribe</span> to continue using all features of Enago Read.
              </div>
              <!-- if trial is about to expire -->
              <div v-if="isTrial && trialDaysRemain <= 10" class="tw-text-black-72 tw-flex tw-justify-center tw-items-center tw-h-12">
                <i class="material-icons tw-mr-1">calendar_today</i>
                Your free trial of Enago Read ends {{ trialDaysRemain > 0 ? 'in ' + trialDaysRemain + ' days.' : 'today.' }}  <span @click="gotoSubscribePage" class="tw-text-blue-primary tw-font-bold tw-mx-1 tw-underline tw-cursor-pointer"  id="subscribe-trial-days-left"> Subscribe now</span> for uninterrupted use.
              </div>
            </div>
            <div v-if="subscriptionData.isPaymentAttached || !haveNoPreviousPaidPlan">
              <!-- if payment is failing -->
              <div v-if="subscriptionData.isPaymentFailing && !(hasPlanExpired && !haveNoPreviousPaidPlan)" class="tw-bg-yellow-tag-yellow tw-text-black-72 tw-flex tw-justify-center tw-items-center tw-h-12 ">
                <div class="tw-flex tw-mr-2">
                  <i class="material-icons tw-mr-1">info</i>
                  We could not process bill amount. Please update your payment method soon. Account will be freezed after 2 failed attempts within 7 days.
                </div>
                <span class="tw-cursor-pointer tw-underline" @click="$modal.show('payment-update')">Update your payment method</span>
              </div>
              <!-- if subscription ended -->
              <div v-if="hasPlanExpired && !haveNoPreviousPaidPlan" class="tw-bg-black-6 tw-text-black-72 tw-flex tw-justify-center tw-items-center tw-h-12">
                <i class="material-icons tw-mr-1">info</i>
                Your subscription has ended. Please <span @click="gotoSubscribePage" class="tw-text-black-72 tw-mx-1 tw-underline tw-cursor-pointer" id="subscribe-plan-end"> Subscribe</span> to continue using all features of Enago Read.
              </div>
            </div>
          </div>
          </div>
        </template>
        <template v-if="showReferralModal" >
          <div class="tw-flex tw-text-white tw-font-normal tw-justify-center tw-bg-cover tw-items-center tw-bg-no-repeat tw-bg-center tw-h-12"
               :style="{
                 'background-image': 'url(' + assetsUrl + 'img/referral-program/refer-banner.svg?external' + ')'
               }"
          >
            <span>Refer Enago Read to your friends and get $12 for every successful sign-up.</span><span class="tw-mx-6">Your friends get $5.</span>
            <ReferUser class="tw-mx-4">
              <template v-slot:referUserButton>
                <span
                  class="tw-px-12 tw-py-1 tw-border-2 tw-border-white tw-text-white tw-cursor-pointer tw-border-solid tw-rounded"
                >
                  Refer now
                </span>
              </template>
            </ReferUser>
            <span class="tw-flow-right tw-text-white tw-cursor-pointer tw-absolute tw-right-8 tw-text-base" @click="closeReferBanner">x</span>
          </div>
        </template>
      </hellobar>
      <div class="md-container topHeaderContainer">
        <div class="md-navbar__row" style="margin-bottom: -1px" 
        :class="{ 'tw-justify-between': displayLandingNav, 'menuOuterSection': !isAuthenticated }"
        >
          <bread-crumb />
          <div v-if="!displayLandingNav" class="md-layout-spacer" />
          <div
            v-if="
              isLibraryAccess &&
                !isAuthenticated &&
                routerName !== 'discover-library'
            "
            class="md-nav md-nav--compact hidden--lt-sm md-nav--color-dark-primary md-align md-align--center-center"
          >
            <nuxt-link
              to="/discover/library"
              class="md-nav__link md-text-primary"
              tag="a"
            >RC Resources</nuxt-link>
          </div>
          <div
            v-if="displayLandingNav"
            class="md-nav hidden--lt-sm md-nav--compact md-nav--color-dark-primary md-align md-align--center-center"
          >
            <a
              v-for="item in navItems"
              :key="item.title"
              :href="landingUrl + item.value"
              class="md-nav__link tw-relative"
              style="font-weight: 500"
            >
              <span
                v-if="item.value === 'review-assistant'"
                class="tw-absolute tw-font-medium tw-border tw-border-solid tw-border-green-primary tw-rounded tw-text-green-primary tw-px-1"
                style="top: 5px; width: max-content; font-size: 10px; margin-left: 3px"
              >
                Get early access
              </span>
              {{ item.title }}
            </a>
            <div class="resources-parent tw-cursor-pointer">
              <div
               class="md-nav__link resources-tab tw-flex tw-items-center tw-font-bold"
              >
                Resources
                <span class="raxIcon raxIcon-arrow-drop-down raxIcon--black56 tw-transition-transform tw-duration-200" />
              </div>
              <ul
                class="tw-items-center tw-ml-2 tw-justify-between tw-fixed tw-bg-white tw-text-sm tw-text-gray-500 tw-flex resources-list"
              >
                <li
                  class="hover:tw-bg-black-4 tw-transition-colors tw-duration-300"
                >
                  <a
                    class="tw-block tw-py-3 tw-px-4 tw-text-black-56 tw-text-sm tw-font-medium tw-border-b-2 tw-border-transparent"
                    href="https://blog.raxter.io/"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Blog
                  </a>
                </li>
                <li
                  class="hover:tw-bg-black-4 tw-transition-colors tw-duration-300"
                >
                  <a
                    class="tw-block tw-py-3 tw-text-black-56 tw-px-4 tw-text-sm tw-font-medium tw-border-b-2 tw-border-transparent"
                    :href="assetsUrl + 'rax-guide.pdf'"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    User Guide
                  </a>
                </li>
                <li
                  class="hover:tw-bg-black-4 tw-transition-colors tw-duration-300"
                >
                  <a
                    class="tw-block tw-py-3 tw-text-black-56 tw-px-4 tw-text-sm tw-font-medium tw-border-b-2 tw-border-transparent"
                    href="https://www.youtube.com/playlist?list=PLeQNr1XWrB0xaDgRN3zgn0ihY9Q1k33p5"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Tutorials
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <!-- Mobile menu -->
          <div v-show="showMobileMenu"
            class="mobileMenuSection md-nav--color-dark-primary md-align md-align--center-center"
          >
            <a
              v-for="item in navItems"
              :key="item.title"
              :href="landingUrl + item.value"
              class="md-nav__link tw-relative"
              style="font-weight: 500"
            >
              <span
                v-if="item.value === 'review-assistant'"
                class="tw-absolute tw-font-medium tw-border tw-border-solid tw-border-green-primary tw-rounded tw-text-green-primary tw-px-1"
                style="top: 5px; width: max-content; font-size: 10px; margin-left: 3px"
              >
                Get early access
              </span>
              {{ item.title }}
            </a>
            <div class="resources-parent tw-cursor-pointer">
              <div style="position: relative;"
               class="md-nav__link resources-tab tw-flex tw-items-center tw-font-bold"
              >
                Resources
                <span class="raxIcon raxIcon-arrow-drop-down raxIcon--black56 tw-transition-transform tw-duration-200" />
              </div>
              <ul
                class="tw-items-center tw-ml-2 tw-justify-between tw-fixed tw-bg-white tw-text-sm tw-text-gray-500 tw-flex resources-list"
              >
                <li
                  class="hover:tw-bg-black-4 tw-transition-colors tw-duration-300"
                >
                  <a
                    class="tw-block tw-py-3 tw-px-4 tw-text-black-56 tw-text-sm tw-font-medium tw-border-b-2 tw-border-transparent"
                    href="https://blog.raxter.io/"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Blog
                  </a>
                </li>
                <li
                  class="hover:tw-bg-black-4 tw-transition-colors tw-duration-300"
                >
                  <a
                    class="tw-block tw-py-3 tw-text-black-56 tw-px-4 tw-text-sm tw-font-medium tw-border-b-2 tw-border-transparent"
                    :href="assetsUrl + 'rax-guide.pdf'"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    User Guide
                  </a>
                </li>
                <li
                  class="hover:tw-bg-black-4 tw-transition-colors tw-duration-300"
                >
                  <a
                    class="tw-block tw-py-3 tw-text-black-56 tw-px-4 tw-text-sm tw-font-medium tw-border-b-2 tw-border-transparent"
                    href="https://www.youtube.com/playlist?list=PLeQNr1XWrB0xaDgRN3zgn0ihY9Q1k33p5"
                    rel="noreferrer noopener"
                    target="_blank"
                  >
                    Tutorials
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <!-- Mobile meun end -->
          <ReferUser v-if="shouldShowReferAndEarnBtn" class="tw-mx-4">
            <template class="" v-slot:referUserButton>
              <button style="height: 36px" class="tw-flex tw-w-max-content tw-justify-center tw-items-center tw-bg-blue-primary tw-rounded-full tw-text-white tw-px-7 tw-py-2">
                <img style="transform: scale(0.8)" :src="assetsUrl + 'img/referral-program/announcement-ref.svg'" />
                <span class="tw-text-sm tw-ml-2">Refer & Earn $12</span>
              </button>
            </template>
          </ReferUser>
          <button
            v-if="!shouldShowReferAndEarnBtn && isAuthenticated && isMounted && !reviewAssistantAccess && !disableBtn"
            class="tw-text-pink tw-mr-4 tw-normal-case tw-flex tw-items-center tw-border-2 tw-font-medium tw-h-9 tw-px-4 tw-border-solid tw-border-pink md-button hover:tw-text-white hover:tw-bg-pink tw-transition-colors tw-duration-200 tw-cursor-pointer"
            style="border-radius: 20px;"
            @click="$emit('tryReview')"
          >
            Try Review Assistant
          </button>
          <button
            v-else-if="!shouldShowReferAndEarnBtn && isAuthenticated && reviewAssistantAccess === 'eligible' && !disableBtn"
            class="tw-text-pink tw-mr-4 tw-normal-case tw-flex tw-items-center tw-border-2 tw-font-medium tw-h-9 tw-px-4 tw-border-solid tw-border-pink md-button hover:tw-text-white hover:tw-bg-pink tw-transition-colors tw-duration-200 tw-cursor-pointer"
            style="border-radius: 20px;"
            @click="activateReview"
          >
            Activate Review Assistant
          </button>
          <div
            v-if="isAuthenticated"
            class="md-navbar__toolbar layout-m-r-1 md-nav--color-dark-primary"
          >
            <span
              @click="onReleaseNoteClicked"
              :class="{
                'active-release-notes': canHighlightReleaseNote,
                'md-button': true,
                'release-notes': true,
              }"
            >
              <span v-if="canHighlightReleaseNote">
                <i class="material-icons md-button__icon">star_rate</i>
              </span>
              New updates
            </span>
          </div>
          <div
            v-if="isAuthenticated && !disableBtn"
            class="md-navbar__toolbar layout-m-r-1 md-nav--color-dark-primary"
          >
            <tooltip title="Help">
              <button class="md-button md-button--icon" @click="onHelpClicked">
                <i class="material-icons md-button__icon">help_outline</i>
              </button>
            </tooltip>
            <tooltip id="reminder-header" title="Reminders">
              <button class="reminder-header-icon md-button md-button--icon tw-ml-4" @click="openReminderDrawer">
                <i class="material-icons md-button__icon">alarm</i>
              </button>
            </tooltip>
            <button
              class="hidden md-button md-button--icon"
              onclick="$('.md-sheet-layer').toggleClass('md-sheet-layer--visible')"
            >
              <i class="material-icons">notifications</i>
            </button>
          </div>
          <div v-if="!isAuthenticated" class="tw-flex tw-items-center tw-mr-4 md:tw-mr-16">
            <nuxt-link
              to="/auth/signup"
              class="mobileSignUpForFree tw-h-8 tw-px-4 tw-font-medium tw-text-white tw-text-sm tw-rounded tw-bg-blue-primary"
              tag="button"
            >
              Sign up for free
            </nuxt-link>
            <nuxt-link
              to="/auth/signin"
              class="mobileSignInButton tw-py-1 tw-h-8 tw-px-4 tw-ml-6 tw-text-sm tw-text-blue-primary tw-rounded-sm tw-border tw-border-solid tw-border-blue-primary"
              tag="button"
            >
              Sign in
            </nuxt-link>
            <button @click="showMobileMenu = !showMobileMenu"
             class="MobileMenuButton tw-h-8 tw-px-4 tw-font-medium tw-text-white tw-text-sm tw-rounded tw-bg-blue-primary">Menu</button>
          </div>
          <profile-btn
            v-if="isAuthenticated"
            :icon="icon"
            :is-img="isImg"
            :img-src="profileImage"
            class="layout-m-l-1"
          />
        </div>
      </div>
      <modal
        v-if="subscriptionData && subscriptionData.isPaymentFailing"
        name="payment-update"
        transition="pop-out"
        :adaptive="true"
        :focus-trap="true"
        :scrollable="true"
        :height="'auto'"
      >
        <PaymentMethodUpdate />
      </modal>
    </header>
  </template>
  
  <style>
  @media (min-width: 769px) {
  body {
    padding-top: 56px;
  }
  /* @media (min-width: 769px) {
    .rx-nav-hide-mobile .md-navbar__row {
      padding-left: 0;
    }*/
  }
  </style>
  
  <style scoped>
  .resources-list {
    display: none;
    top: 3.5rem;
    width: 9rem;
    filter: drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.1));
  }
  
  .resources-tab {
    font-weight: 500;
    text-transform: none;
  }
  
  .resources-parent:hover .resources-tab,
  .resources-parent:hover .raxIcon-arrow-drop-down::before {
    color: #1a64e2 !important;
    transition-property: color;
    transition-duration: 300ms;
  }
  
  .resources-parent:hover .raxIcon-arrow-drop-down {
    transform: rotate(180deg);
  }
  
  .resources-tab:hover ~ .resources-list,
  .resources-list:hover {
    display: block;
  }
  </style>
  
  <script>
  import warningModal from '@/plugins/mixins/warningModal';
import * as moment from 'moment';
import { mapGetters } from "vuex";
import { AppSheet, MixpanelEventGroup, MixpanelEvents, PlanType, UserStatus } from '~/shared/constants';
import breadCrumb from "../common/child/breadCrumb";
import hellobar from "../common/hellobar";
import { RAxReferBanner, RaxSpecialOffer } from '../literature/constants';
import PaymentMethodUpdate from '../pricing/paymentMethodUpdate.vue';
import ReferUser from "../refer-user/referUser.vue";
  
  const delay = ms => new Promise(res => setTimeout(res, ms))
  
  export default {
    components: {
      breadCrumb,
      hellobar,
      PaymentMethodUpdate,
      ReferUser
    },
    mixins:[warningModal],
    props: {
      displayLandingNav: {
        type: Boolean,
        default: false,
      },
      disableBtn: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        UserStatus,
        displaySidenav: false,
        isMounted: false,
        isAllowedToSeeOfferBanner:false,
        dealsBackgroundUrl: process.env.assetsUrl + 'img/pricing/offer.png',
        navItems: [
          { title: "Features", value: "features", },
          { title: "Pricing", value: "pricing" },
          { title: "For Teams", value: "teams" },
          { title: "For Reviewers", value: "review-assistant" },
          { title: "For Librarians", value: "for-librarians" }
        ],
        assetsUrl: process.env.assetsUrl,
        landingUrl: process.env.landingUrl,
        isAllowedToSeeReferralBanner:false,
        showMobileMenu: false
      };
    },
    computed: {
      ...mapGetters({
        user: "user/getData",
        isAuthenticated: "auth/isAuthenticated",
        haveFullAccess: "subscription/haveFullAccess",
        isTrial: "subscription/isTrial",
        haveNoPreviousPaidPlan: "subscription/haveNoPreviousPaidPlan",
        hasPlanExpired: 'subscription/hasPlanExpired',
        trialDaysRemain: "subscription/trialDaysRemain",
        subscriptionData:'subscription/getSubscriptionData',
        canShowSpecialOffer:'subscription/canShowSpecialOffer',
        profile: "profile/getData",
        affData: "aff/getData",
        isLibraryAccess: "library/getDiscoverAccess",
        haveLibraryId: "core/getLibraryId",
        getShowHeader: "core/getShowHeader",
        showPasswordSentBanner: "core/getShowPasswordSentBanner",
        releaseNoteData: "core/getReleaseNotes",
        canHighlightReleaseNote: "user/canHighlightReleaseNote"
      }),
      routerName() {
        return this.$route.name;
      },
      icon() {
        return this.profile.profilePic ? "" : "person";
      },
      isImg() {
        return !this.icon;
      },
      profileImage() {
        return this.profile.profilePic
          ? this.assetsUrl + this.profile.profilePic
          : "";
      },
      isValidPageToShow(){
        const lists = ['auth-verify-email', 'auth', 'auth-signin', 'auth-signup', 'subscription' , 'subscription-payment', 'subscription-payment-success', 'newOnboarding'];
        if ((lists.findIndex(i=> i === this.$route.name) !== -1) || [UserStatus.KIDemoOld, UserStatus.KIDemoNew, UserStatus.ReadingDemo, UserStatus.RedditDemo].includes(this.user.onboardingStatus)) {
          return false;
        }
        return true;
      },
      showReferralModal() {
        return this.isAuthenticated && this.user.emailVerified && this.subscriptionData &&
          this.isAllowedToSeeReferralBanner && !this.isAllowedToSeeOfferBanner && !this.showPasswordSentBanner &&
          ((this.isTrial && this.trialDaysRemain > 10) || (!this.isTrial && this.isReferralAllowed) ||
          (this.subscriptionData.isPaymentAttached && this.haveNoPreviousPaidPlan));
      },
      isReferralAllowed(){
        return this.$route.name == 'subscription';
      },
      shouldShowReferAndEarnBtn() {
        return !this.isAllowedToSeeReferralBanner && this.subscriptionData.planType === PlanType.Individual;
      },
      reviewAssistantAccess() {
        return this.isAuthenticated && this.user && this.user.flags.reviewAssistantAccess;
      }
    },
    methods: {
      async activateReview() {
        try {
          await this.$store.dispatch("reviewerProjects/fetch");
          const payload = {
            title: "Sample Conference",
            description: "This is a same conference to give you a feel.",
            year: new Date().getFullYear(),
            deadline: moment().add(90, "d").unix(),
            type: "conference"
          };
          await this.$store.dispatch("reviewerProjects/create", payload).catch();
        } catch (e) {
          console.log(e);
        }
        this.$emit('activateReview');
        this.$store.commit("user/setShowReviewOnboarding", true);
      },
      hideAppSheet() {
        this.$raxEventBus.$emit(AppSheet.Close);
      },
      async openReminderDrawer() {
  
        this.$mixpanel.track(
          MixpanelEvents[MixpanelEventGroup.Bot].Desk_Reminders
        );
        if (!this.botUserSignupModal('ReminderDrawer Clicked')) {
          return;
        }
        this.$store.commit("core/setData", {
          name: "reminderPanelOpen",
          data: false
        });
        await delay(100);
        this.$store.commit("core/setData", {
          name: "reminderDrawerOpen",
          data: true
        })
      },
      isOfferBannerClosed(){
        if (!localStorage) {
          return true;
        }
        const timeClosedAt = localStorage.getItem(RaxSpecialOffer);
        if (timeClosedAt) {
          try {
            const isSameDay = moment(timeClosedAt).isSame(moment().format('YYYY-MM-DD'), 'day');
            return isSameDay;
          } catch (error) {
            console.log(error);
          }
          return false;
        }
        return false;
      },
      closeOfferBar(){
        // set time stamp  mm-dd-yyyy
        localStorage.setItem(RaxSpecialOffer, moment().format('YYYY-MM-DD') );
        this.isAllowedToSeeOfferBanner = false;
        this.$mixpanel.track('Hellobar Closed', {
          reason: 'special-deal'
        });
      },
      logMixpanelForSubscriptionLink(){
        let reason = ''
         if (this.isTrial) {
           reason = `${this.trialDaysRemain} days left in trial end`;
         }else{
          reason = this.hasPlanExpired && !this.haveNoPreviousPaidPlan ? 'plan ended' : 'trial ended';
         }
        this.$mixpanel.track('Click - Subscribe From Hellobar', {
          reason
        });
      },
      gotoSubscribePage(){
        this.logMixpanelForSubscriptionLink();
        this.$router.push({ name: "subscription" });
      },
      gotoSubscribePageFromDeals(){
        this.$mixpanel.track('Click - Subscribe From Hellobar', {
          reason: 'special-deal'
        });
        this.$router.push({ name: "subscription" });
      },
      checkUserEmail(){
        this.$mixpanel.track('(Desk) Menu');
        if([UserStatus.KIDemoOld, UserStatus.KIDemoNew, UserStatus.ReadingDemo, UserStatus.RedditDemo].includes(this.user.onboardingStatus)){
          this.$store.commit("user/setLastAction", "Side drawer open from header");
          this.$store.commit("user/setShowEmailModal", 1);
        }
      },
      onHelpClicked(e) {
  
        if (!this.botUserSignupModal('Help Clicked')) {
          return;
        }
        this.$mixpanel.track('Help Clicked');
        $(`#help-rax`).MaterialMenu(
          {
            show: true,
            location: "left",
            position: "bottom left",
          },
          e.target
        );
      },
      async onClosePasswordSentBanner() {
        this.$store.commit("core/setData", {
          name: "showPasswordSentBanner",
          data: false,
        });
        await this.$axios.$post("/onboarding-events/createsignupevent", {
          step: 4,
          skip: 0,
        });
      },
      onReleaseNoteClicked(e) {
        this.$mixpanel.track('Open Release Notes');
        if (this.user.onboardingStatus === UserStatus.RedditDemo) {
          this.$mixpanel.track(
            MixpanelEvents[MixpanelEventGroup.Bot].Desk_Help
          );
        }
         if (!this.botUserSignupModal('NewUpdate Clicked')) {
          return;
        }
        
        if (this.canHighlightReleaseNote) {
          this.$store.dispatch('core/upsertReleaseNoteDataLog', this.releaseNoteData);
          this.$store.commit('user/updateFlag', {key: 'highlightReleaseNotes', payload: false});
        }
        window.open(this.releaseNoteData.url, '_blank');
      },
      closeReferBanner() {
        this.isAllowedToSeeReferralBanner = false;
        localStorage.setItem(RAxReferBanner, false);
      },
      shouldShowReferralModal() {
        if (process.client) {
          const shouldShow = localStorage.getItem(RAxReferBanner);
          if(shouldShow == undefined || shouldShow == null) {
            return true;
          }
          return shouldShow == 'true' ? true: false;
        }
        return false
      },
    },
    watch:{
      $route() {
        this.isAllowedToSeeOfferBanner = this.canShowSpecialOffer && !this.isOfferBannerClosed();
      },
    },
    mounted(){
      this.isMounted = true;
      this.isAllowedToSeeOfferBanner = this.canShowSpecialOffer && !this.isOfferBannerClosed();
      this.isAllowedToSeeReferralBanner = this.shouldShowReferralModal();
    },
  };
  </script>
  <style scoped>
  
  .raxIcon-mail:before {
    color: #1a64e2;
  }
  
  .fix-padding {
    margin-left: -16px !important;
    border: #2E3E4E;
    opacity: 0.1;
  }
  
  .active-release-notes {
    color: var(--color-primary);
    background-color: #D7F9FC;
    border-radius: 18px;
  }
  
  .release-notes {
    font-size: 14px;
    text-transform: none;
  }
  </style>