var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return (_vm.data.hasSub
  ? _vm.data.subBucketData &&
    _vm.data.subBucketData.length &&
    _vm.data.subBucketData[0].data &&
    _vm.data.subBucketData[0].data.length
  : _vm.data.data && _vm.data.data.length)
    ? _c(
        "div",
        {
          staticClass: "summary-item-container",
          class: { "tw-mb-4 summary-root": _vm.selectedLevel === 0 },
          attrs: {
            "section-key": _vm.selectedLevel === 0 && _vm.data.sectionKey
          },
          on: { mouseup: _vm.onMouseUp }
        },
        [
          _c(
            "h1",
            {
              staticClass:
                "section-name tw-flex tw-items-center tw-relative tw-pr-4 tw-select-none",
              class: {
                "tw-text-base tw-text-black-72 tw-font-semibold tw-px-2":
                  _vm.selectedLevel !== 0,
                "tw-text-lg tw-font-semibold tw-text-black-80 sticky-header tw-pt-4":
                  _vm.selectedLevel === 0,
                "tw-cursor-pointer tw-py-2 hover:tw-bg-black-4":
                  _vm.isCollapsible,
                "tw-mb-4": !_vm.isCollapsed
              },
              on: { click: _vm.toggleCollapse }
            },
            [
              _vm.isCollapsible
                ? _c("i", {
                    staticClass: "raxIcon tw-px-1",
                    class: {
                      "raxIcon-chevron_right": _vm.isCollapsed,
                      "raxIcon-chevron_down": !_vm.isCollapsed
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("span", { style: _vm.isInvalidHeading }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.store === "tldr"
                        ? _vm.initCap(
                            _vm.updateInvalidHeading(_vm.data.sectionName)
                          )
                        : _vm.data.sectionName
                    ) +
                    "\n    "
                )
              ]),
              _vm._v(" "),
              _vm.selectedLevel === 0 &&
              !_vm.noQuickReadData &&
              !_vm.isCollapsed &&
              _vm.showHideDetails
                ? _c(
                    "span",
                    {
                      staticClass:
                        "action-btn tw-cursor-pointer tw-text-sm tw-font-normal tw-absolute tw-right-0 tw-mr-4 tw-py-1 tw-px-4 tw-rounded",
                      class: {
                        "hover:tw-bg-blue-light tw-text-blue-primary":
                          _vm.showQuickRead,
                        "hover:tw-bg-black-12 tw-text-black-56": !_vm.showQuickRead
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.toggleView()
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          !_vm.showQuickRead ? "Hide details" : "Show details"
                        )
                      )
                    ]
                  )
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "summary-selectable-content" }, [
            _c(
              "div",
              { ref: "summaryContainerDetail" },
              [
                _c("transition", { attrs: { name: "fade" } }, [
                  !_vm.showQuickRead ||
                  _vm.selectedLevel === 1 ||
                  _vm.noQuickReadData
                    ? _c("div", [
                        _vm.data.hasSub &&
                        (_vm.isCollapsible ? !_vm.isCollapsed : true)
                          ? _c("ul", { staticClass: "tw-ml-6 overview" }, [
                              _c(
                                "li",
                                _vm._l(_vm.data.subBucketData, function(item) {
                                  return _c("summary-item", {
                                    key: item.sectionKey,
                                    attrs: {
                                      data: item,
                                      id: "summary-subitem-" + item.sectionKey,
                                      parent: _vm.data.sectionKey,
                                      selectedLevel: 1,
                                      isCollapsible: false,
                                      summaryType: _vm.summaryType,
                                      source: _vm.source
                                    },
                                    on: {
                                      onPinRequest: function($event) {
                                        return _vm.$emit("onPinRequest", $event)
                                      },
                                      openMoreOption: _vm.onNestedOpen
                                    }
                                  })
                                }),
                                1
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.data.hasSub &&
                        (_vm.isCollapsible ? !_vm.isCollapsed : true)
                          ? _c(
                              "div",
                              {
                                staticClass: "sub-item-statement",
                                class: {
                                  "tw-ml-8":
                                    _vm.isCollapsible &&
                                    _vm.selectedLevel === 1,
                                  "tw-ml-6": _vm.selectedLevel === 0
                                }
                              },
                              _vm._l(_vm.data.data, function(item) {
                                return _c("SubItem", {
                                  key: item.key,
                                  ref: "subItem" + item.key,
                                  refInFor: true,
                                  attrs: {
                                    id: "statement-" + item.key,
                                    data: item,
                                    store: _vm.store
                                  },
                                  on: { openPinOption: _vm.openPinOption }
                                })
                              }),
                              1
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { ref: "summaryContainerQuick" },
              [
                _c("transition", { attrs: { name: "fade" } }, [
                  _vm.showQuickRead || _vm.selectedLevel === 1
                    ? _c("div", [
                        _vm.quickReadData &&
                        _vm.quickReadData.hasSub &&
                        (_vm.isCollapsible ? !_vm.isCollapsed : true)
                          ? _c("ul", { staticClass: "tw-ml-6 detailed" }, [
                              _c(
                                "li",
                                _vm._l(
                                  _vm.quickReadData.subBucketData,
                                  function(item) {
                                    return _c("summary-item", {
                                      key: item.sectionKey,
                                      ref: item.sectionKey,
                                      refInFor: true,
                                      attrs: {
                                        data: item,
                                        selectedLevel: 1,
                                        isCollapsible: false,
                                        summaryType: _vm.summaryType
                                      },
                                      on: {
                                        onPinRequest: function($event) {
                                          return _vm.$emit(
                                            "onPinRequest",
                                            $event
                                          )
                                        },
                                        openMoreOption: _vm.onNestedOpen
                                      }
                                    })
                                  }
                                ),
                                1
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.quickReadData &&
                        !_vm.quickReadData.hasSub &&
                        (_vm.isCollapsible ? !_vm.isCollapsed : true)
                          ? _c(
                              "div",
                              {
                                staticClass: "sub-item-statement",
                                class: {
                                  "tw-ml-8":
                                    _vm.isCollapsible &&
                                    _vm.selectedLevel === 1,
                                  "tw-ml-6": _vm.selectedLevel === 0
                                }
                              },
                              _vm._l(_vm.quickReadData.data, function(item) {
                                return _c("SubItem", {
                                  key: item.key,
                                  ref: "subItem" + item.key,
                                  refInFor: true,
                                  attrs: { data: item, store: _vm.store },
                                  on: { openPinOption: _vm.openPinOption }
                                })
                              }),
                              1
                            )
                          : _vm._e()
                      ])
                    : _vm._e()
                ])
              ],
              1
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }