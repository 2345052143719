<template>
  <div>
    <notifications group="message-note" position="top center" />
    <app-header :display-landing-nav="!isAuthenticated" />
    <app-drawer
      v-if="isAuthenticated"
      unid="drawer"
      @openFeedback="openFeedback"
    />
    <nuxt />
    <app-snackbar
      :action-text="snackbar.actionText"
      :message="snackbar.message"
      unid="snackbar"
    />
    <app-alert
      :title="alert.title"
      :body="alert.body"
      :disable-dismissive="alert.disableDismissive"
      :affirmative-action-text="alert.affirmativeText || `OK`"
      unid="app-alert"
      @hidden="onAlertHidden"
    />
    <app-menu
      :unid="menuId"
      :dense="true"
      data-width="4"
      @selected="onSelected"
    >
      <template slot="body">
        <button
          data-value="/projects"
          data-option="''"
          style="text-transform: none"
          class="md-button md-menu__item md-button--dense rx-button-allow-event"
        >
          See all projects
          <a href="/projects/" class="rx-click__area" />
        </button>
        <template v-if="projectList.length">
          <div
            style="margin-top: 8px;margin-bottom: 8px;"
            class="md-menu__divider"
          />
          <div
            style="color: rgba(0,0,0,0.54);font-size: 12px;line-height: 16px;padding: 8px 16px;"
            class="md-typo--caption-color-contrast"
          >
            Recent
          </div>
          <button
            v-for="(item, index) in projectList"
            :key="index"
            :data-value="'/projects/' + item.slug + '/literatures'"
            :data-option="''"
            class="md-menu__item md-button md-button--block rx-button-allow-event"
          >
            {{ item.title | truncate(20) }}
            <a
              :href="'/projects/' + item.slug + '/literatures'"
              class="rx-click__area"
            />
          </button>
        </template>
      </template>
    </app-menu>
    <feedback :is="asyncComp" :dialog-id="dialogId" />
    <div
      v-if="isAppLoading"
      :id="unid + '-block'"
      class="layout-block layout-block--fixed"
    >
      <app-progress
        :unid="unid + '-progress'"
        :config="{
          circle: true,
          center: true,
          overlay: true,
          parent: true,
          target: `#${unid}-block`
        }"
      />
    </div>
    <no-pdf-action
      :is="pdfAsyncComp"
      ref="requestDialog"
      :project-id="currentPdfAction.projectId"
      :literature-id="currentPdfAction.literatureId"
      :pdf-url="currentPdfAction.pdfUrl"
      :unid="noPdfDialogId"
      dialog-title="Add Pdf"
      @requestLibrary="onRequestLibrary"
    />
    <!-- <rax-free-view/> -->
    <app-menu unid="help-rax" data-width="5" @selected="onHelpSelected">
      <template slot="body">
        <div class="md-list__subhead">
          <span class="md-typo--caption-color-contrast">
            Need help with using Enago Read?
          </span>
        </div>
        <!-- <button
          data-value="guide"
          data-option="guide"
          style="text-transform: none"
          class="md-button md-menu__item"
        >
          <i class="material-icons md-button__icon">chrome_reader_mode</i>
          <span class="">
            User guide
          </span>
          <span class="md-layout-spacer" />
          <i class="material-icons md-button__icon">open_in_new</i>
        </button> -->
        <div class="md-menu__divider" />
        <div class="md-list__subhead">
          <span class="md-typo--caption-color-contrast">
            Need to talk with our support team?
          </span>
        </div>
        <button
          data-value="contact"
          data-option="contact"
          style="text-transform: none"
          class="md-button md-menu__item"
        >
          <i class="material-icons md-button__icon">announcement</i> Contact us
        </button>
      </template>
    </app-menu>
    <app-menu
      unid="app-account-menu"
      class="layout-m-t-1"
      @selected="onMyAccount"
    >
      <template slot="body">
        <button
          v-for="item in filterMyAccountLinks"
          :key="item.value"
          :data-value="item.value"
          type="button"
          class="md-menu__item md-button rx-button-allow-event"
        >
          {{ item.title }}
          <a
            v-if="item.title != 'Logout'"
            :href="'/' + item.value"
            class="rx-click__area"
          />
        </button>
      </template>
    </app-menu>
  </div>
</template>

<style>
.annotation-menu {
  z-index: 1505;
}
.md-tooltip__surface {
  line-height: 1;
  font-size: 14px;
}
.rx-hidden-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  pointer-events: none;
}
.editor-wrapper.vb-visible .editor-inner-wrapper {
  overflow-x: auto !important;
}
.rx-policy-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(100%);
  transition-property: transform, opacity;
  transition-duration: 0.3s;
  padding: 8px 0;
  transition-timing-function: ease-in-out;
  z-index: 24;
  background-color: rgba(0, 0, 0, 0.84);
  pointer-events: none;
  opacity: 0;
}
.rx-policy-wrapper--accent {
  background-color: var(--color-accent);
  color: var(--color-accent-contrast);
}
.rx-policy__text {
  font-size: 16px;
}
.rx-policy-wrapper--visible {
  transform: none;
  opacity: 1;
  pointer-events: auto;
}
.rx-policy-wrapper--vertical .rx-policy__surface {
  flex-direction: column;
}
.rx-policy-wrapper--vertical .rx-policy__text {
  width: 100%;
}
.rx-policy-wrapper--abs {
  position: absolute;
}
@media (max-width: 767px) {
  .rx-policy__surface {
    flex-direction: column;
  }
  .rx-policy__text {
    width: 100%;
  }
  .rx-policy__actions {
    width: 100%;
  }
}
.rx-policy-wrapper--vertical .rx-policy__actions {
  width: 100%;
  margin-left: auto !important;
  justify-content: center;
}
</style>

<script>
import appHeader from "@/components/navigation/header";
import appDrawer from "@/components/navigation/drawer";
import AsyncComponent from "@/assets/js/helper";
import { mapGetters } from "vuex";

export default {
  components: {
    appHeader,
    appDrawer,
    // raxFreeView: () => import('../components/common/raxFreeView'),
  },
  data() {
    return {
      asyncComp: false,
      menuId: "breadCrumb-project-menu",
      dialogId: "feedback-dialog",
      noPdfDialogId: "no-pdf-action",
      unid: "app-universal",
      pdfAsyncComp: false,
      assetsUrl: process.env.assetsUrl,
      baseUrl: process.env.baseUrl,
      consoleUrl: process.env.consoleUrl,
      myAccountLinks: [
        { title: "Library console", value: "console" },
        { title: "Projects", value: "projects" },
        { title: "Profile", value: "profile" },
        { title: "Logout", value: "logout" }
      ]
    };
  },
  computed: {
    ...mapGetters({
      snackbar: "core/snackbar",
      alert: "core/alert",
      feedback: "core/feedback",
      isAppLoading: "core/appLoading",
      isAuthenticated: "auth/isAuthenticated",
      projectList: "projects/getData",
      user: "user/getData",
      currentPdfAction: "literatures/currentPdfAction"
    }),
    isLibrarian() {
      return this.isAuthenticated
        ? this.user.roles
          ? this.user.roles.includes("LIBRARY_ADMIN")
          : false
        : false;
    },
    filterMyAccountLinks() {
      return this.myAccountLinks.filter(item => {
        return item.value === "console" ? this.isLibrarian : true;
      });
    }
  },
  watch: {
    feedback: function(val) {
      if (val) {
        this.openFetchDialog("common/child/feedback");
      }
    },
    currentPdfAction: function(val) {
      if (val.show) {
        this.openNoPdfFetchDialog("literature/child/noPdfAction");
      }
    }
  },
  beforeDestroy() {
    this.$store.commit("core/setAppLoading", false);
  },
  async mounted() {
    setTimeout(() => {
      let element = document.querySelector("#scispace-extension-root");
      if (element) {
        element.style.display = "none";
      }
    }, 3000);
    this.$nextTick(() => {
      if (
        (navigator.userAgent.indexOf("Safari") !== -1 ||
          navigator.userAgent.indexOf("MSIE") !== -1) &&
        navigator.userAgent.indexOf("Chrome") === -1
      ) {
        document.getElementById("app-body").classList.add("safari");
      }
    });
    if (this.projectList.length < 2 && this.isAuthenticated) {
      this.$store.dispatch("projects/fetch");
    }
    await this.$store.dispatch("user/setUserCountry");
  },
  methods: {
    onAlertHidden() {
      this.$store.commit("core/setAlert", {
        title: this.alert.defaultTitle,
        show: false,
        body: "",
        disableDismissive: false
      });
    },
    onSelected(data) {
      this.$router.push(data.value);
    },
    onHelpSelected(obj) {
      if (obj.value === "guide") {
        let link = this.assetsUrl + "rax-guide.pdf";
        window.open(link);
      } else {
        this.openFeedback();
      }
    },
    openFetchDialog(dialogPath) {
      if (!this.asyncComp) {
        this.asyncComp = () => AsyncComponent(dialogPath);
        this.$store.commit("core/setAppLoading", true);
        this.asyncComp()
          .component.then(() => {
            this.$store.commit("core/setAppLoading", false);
          })
          .catch(() => {
            this.asyncComp = false;
            this.$store.commit("core/setAppLoading", false);
          });
      } else {
        $(`#${this.dialogId}`).MaterialDialog("show");
      }
    },
    openFeedback() {
      this.$store.commit("core/setData", { name: "feedback", data: true });
    },
    openNoPdfFetchDialog(dialogPath) {
      if (!this.pdfAsyncComp) {
        this.pdfAsyncComp = () => AsyncComponent(dialogPath);
        this.$store.commit("core/setAppLoading", true);
        this.pdfAsyncComp()
          .component.then(() => {
            this.$store.commit("core/setAppLoading", false);
          })
          .catch(() => {
            this.pdfAsyncComp = false;
            this.$store.commit("core/setAppLoading", false);
          });
      } else {
        $(`#${this.noPdfDialogId}`).MaterialDialog("show");
      }
    },
    onRequestLibrary() {
      console.log("onRequestLibrary");
    },
    onMyAccount(e) {
      switch (e.value) {
        case "projects":
          this.$router.push("/projects");
          break;
        case "console":
          window.location.href = this.consoleUrl;
          break;
        case "profile":
          this.$router.push("/profile");
          break;
        case "logout":
          this.$store.dispatch("auth/logout");
          this.$router.push("/auth/signin");
          this.$store.commit("core/setSnackbar", {
            message: "You have been successfully logged out"
          });
          $("#snackbar").MaterialSnackbar("show");
          break;
      }
    }
  }
};
</script>
