var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "md-checkbox md-checkbox--primary",
      class: { "md-checkbox--disabled": _vm.disabled }
    },
    [
      _c("input", {
        staticClass: "md-checkbox__input",
        attrs: { id: _vm.inputId, type: "checkbox" },
        domProps: { checked: _vm.isChecked, value: _vm.value }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "md-checkbox__background" }, [
        _c(
          "svg",
          {
            staticClass: "md-checkbox__checkmark",
            attrs: { viewBox: "0 0 24 24" }
          },
          [
            _c("path", {
              staticClass: "md-checkbox__checkmark__path",
              attrs: {
                fill: "none",
                stroke: "white",
                d: "M1.73,12.91 8.1,19.28 22.79,4.59"
              }
            })
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "md-checkbox__mixedmark" })
      ]),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "md-checkbox__label",
          attrs: { id: _vm.unid, for: _vm.inputId }
        },
        [_vm._v("\n    " + _vm._s(_vm.label) + "\n  ")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }