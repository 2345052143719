import Vue from "vue";
import findObjectIndex from "@/assets/js/commonHelper";

const defaultRelatedItemsState = () => {
  return {
    data: [],
    fetched: false
  };
};
export default {
  namespaced: true,
  state: defaultRelatedItemsState,

  mutations: {
    setData(state, data) {
      state["data"] = data;
    },
    addData(state, data) {
      state["data"].unshift(data);
    },
    updateData(state, obj) {
      state.data.splice(obj.index, 1, obj.data);
    },
    setFetched(state, data) {
      state["fetched"] = data;
    },
    deleteData(state, index) {
      state.data.splice(index, 1);
    },
    resetState(state) {
      Object.assign(state, defaultRelatedItemsState());
    }
  },

  actions: {
    async fetch({ commit, state, dispatch }, params) {
      const { data } = await this.$axios.get(`/related/items/${params.itemId}/${params.type}`);
      let itemTypeMap = { 1 : 'drafts', 2 : 'literatures', 3 : 'reviews', 4 : 'projects' };
      data.forEach(item => {
        item["type"] = itemTypeMap[item.type];
        item["suggestedItemType"] = itemTypeMap[item.suggestedItemType];
      });
      commit("setData", data);
    },

    async update({ commit, state }, inputs) {
      const { data } = await this.$axios.put(`/related/items`, inputs);
      let index = findObjectIndex(state.data, inputs);
      commit("updateData", { data: data[0], index: index });
      return data;
    },

    async create({ commit }, inputs) {
      const { data } = await this.$axios.post("/related/items/", inputs);
      let itemTypeMap = { 1 : 'drafts', 2 : 'literatures', 3 : 'reviews', 4 : 'projects' };
      data["type"] = itemTypeMap[data.type];
      data["suggestedItemType"] = itemTypeMap[data.suggestedItemType];
      commit("addData", data);
      return data;
    },

    async delete({ commit, state }, inputs) {
      await this.$axios.delete(`/related/items/${inputs.id}?activeTab=${inputs.activeTab}`);
      let index = findObjectIndex(state.data, inputs);
      commit("deleteData", index);
    }
  },

  getters: {
    getData(state) {
      return state.data;
    },

    getCount(state) {
      return state.data.length;
    },

    getFetched(state) {
      return state.fetched;
    }
  }
};
