import processImage from "@/assets/js/processImage";
import Vue from "vue";
const defaultAcsCollectionState = () => {
  return {
    literatures: []
  }
};
export default {
  namespaced: true,
  state: defaultAcsCollectionState,

  mutations: {
    setData(state, obj) {
      state[obj.key] = obj.data;
    },
    addData(state, obj) {
      state[obj.key].push(obj.data);
    },
    setObjData(state, obj) {
      Vue.set(state['literatures'], obj.key, obj.data);
    },
    resetState(state) {
      Object.assign(state, defaultAcsCollectionState());
    }
  },

  actions: {

    async literature({ commit, state }, params, loadMore = false) {
      let data, axiosData;
      axiosData = await this.$axios.post(`/feed/acs/literature`, params);
      data = axiosData["data"]["results"];
      data.forEach((item) => {
        item.type = "paper";
      });
      if (loadMore) {
        data.forEach((item) => {
          commit("addData", { key: "literatures", data: item });
        });
      } else {
        commit("setData", { key: "literatures", data: data });
      }
    },

    async recordUserAction({state}, params) {
      params["resultType"] = "feeds";
      try {
        await this.$axios.post(`/data-analytics/record-action`, params);
      } catch (e) {
        console.log(e);
      }
    }

  },

  getters: {
    getLiteratures(state) {
      return state.literatures;
    }
  }
};
