import { UserStatus } from "~/shared/constants";

export default async function ({ redirect, store }) {
  try{
  await store.dispatch("user/setUser");
  let userData = store.getters["user/getData"];
  const haveFullAccess = store.getters["subscription/haveFullAccess"];

  if (userData.onboardingStatus === UserStatus.EmailGiven) {
    // data has all onboarding events
    await store.dispatch("core/fetchOnboardingEvents");
    let data = store.getters["core/getOnboardingEvents"];
    // Event CODES :
    // 0 => PDF UPLOADED
    // 1 => Name/Role modal step
    // 2 => Affiliation modal step
    // 3 => Areas of interest step
    // 4 => Set your password link header dismissed

    let bannerData = data.filter(ev => [0, 4].includes(ev.step));
    bannerData.sort(
      (a, b) => new Date(b["createdAt"]) - new Date(a["createdAt"])
    );
    let isDismissed = bannerData.filter(ev => ev.step == 4).length > 0;

    // No event of this type recorded. Old user
    if (bannerData.length == 0) {
      store.commit("core/setData", {
        name: "showPasswordSentBanner",
        data: false
      });
    } else if (
      isDismissed ||
      (new Date() - new Date(bannerData[0].createdAt)) / (1000 * 60 * 60) > 24
    ) {
      //Either banner was dismissed, or 24 hours passed since pdf upload event
      store.commit("core/setData", {
        name: "showPasswordSentBanner",
        data: false
      });
    } else {
      store.commit("core/setData", {
        name: "showPasswordSentBanner",
        data: true
      });
    }

    data = data.filter(ev => [0, 1, 2, 3].includes(ev.step));
    data.sort((a, b) => new Date(b["createdAt"]) - new Date(a["createdAt"]));

    let isOnboarded =
      data.filter(ev => ev.step == 3 && ev.skip == 0).length > 0;

    // Don't show modal conditions. Just after sign up, OR  < 24hours elapsed since last event recorded OR all info recorded
    if (
      data.length == 0 ||
      (new Date() - new Date(data[0]["createdAt"])) / (1000 * 60 * 60) < 24 ||
      isOnboarded ||
      (userData.onboardingStatus && parseInt(userData.onboardingStatus) === UserStatus.Onboarded)
    ) {
      store.commit("core/setData", { name: "showUserInfoModal", data: false });
    } else {
      let modalStep = data[0].skip == 0 ? data[0].step + 1 : data[0].step;
      modalStep = Math.min(3, Math.max(1, modalStep));
      store.commit("core/setData", {
        name: "currentModalStep",
        data: modalStep
      });
      store.commit("core/setData", { name: "showUserInfoModal", data: true });
    }

    // Set modal step in case race condition occurs and modal needs to be shown
    if (data.length > 0) {
      let modalStep = data[0].skip == 0 ? data[0].step + 1 : data[0].step;
      modalStep = Math.min(3, Math.max(1, modalStep));
      store.commit("core/setData", {
        name: "currentModalStep",
        data: modalStep
      });
    }

    let uploadEventData = data.filter(ev => ev.step == 0);

    let project = store.getters["projects/getData"];
    if (!project || project.length === 0) {
      await store.dispatch("projects/fetch");
      project = store.getters["projects/getData"];
    }
    let literature = store.getters["literatures/getData"];
    if ((!literature || literature.length === 0) && project && project.length) {
      await store.dispatch("literatures/fetch", {
        slug: project[0].slug
      });
      literature = store.getters["literatures/getData"];
    }


    if ((literature && literature.length) || (uploadEventData && uploadEventData.length > 0)) {
      store.commit("user/setIsNewUser", false);
    } else store.commit("user/setIsNewUser", true);

    const isNewUser = store.getters["user/getIsNewUser"];
    let userCreatedAt = new Date(userData.createdAt);

    if (isNewUser && haveFullAccess && userCreatedAt > new Date("09/27/2020") && !userData.flags.reviewAssistantAccess) {
      return redirect("/newOnboarding");
    }
  } else {
    store.commit("core/setData", {
      name: "showUserInfoModal",
      data: false
    });
  }
  }
  catch(err){
    console.log("this is the error", err);
  }
}
