var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("notifications", {
        attrs: { group: "message-note", position: "top center" }
      }),
      _vm._v(" "),
      _c("app-header", {
        attrs: { "display-landing-nav": !_vm.isAuthenticated }
      }),
      _vm._v(" "),
      _vm.isAuthenticated
        ? _c("app-drawer", {
            attrs: { unid: "drawer" },
            on: { openFeedback: _vm.openFeedback }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("nuxt"),
      _vm._v(" "),
      _c("app-snackbar", {
        attrs: {
          "action-text": _vm.snackbar.actionText,
          message: _vm.snackbar.message,
          unid: "snackbar"
        }
      }),
      _vm._v(" "),
      _c("app-alert", {
        attrs: {
          title: _vm.alert.title,
          body: _vm.alert.body,
          "disable-dismissive": _vm.alert.disableDismissive,
          "affirmative-action-text": _vm.alert.affirmativeText || "OK",
          unid: "app-alert"
        },
        on: { hidden: _vm.onAlertHidden }
      }),
      _vm._v(" "),
      _c(
        "app-menu",
        {
          attrs: { unid: _vm.menuId, dense: true, "data-width": "4" },
          on: { selected: _vm.onSelected }
        },
        [
          _c(
            "template",
            { slot: "body" },
            [
              _c(
                "button",
                {
                  staticClass:
                    "md-button md-menu__item md-button--dense rx-button-allow-event",
                  staticStyle: { "text-transform": "none" },
                  attrs: { "data-value": "/projects", "data-option": "''" }
                },
                [
                  _vm._v("\n        See all projects\n        "),
                  _c("a", {
                    staticClass: "rx-click__area",
                    attrs: { href: "/projects/" }
                  })
                ]
              ),
              _vm._v(" "),
              _vm.projectList.length
                ? [
                    _c("div", {
                      staticClass: "md-menu__divider",
                      staticStyle: {
                        "margin-top": "8px",
                        "margin-bottom": "8px"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "md-typo--caption-color-contrast",
                        staticStyle: {
                          color: "rgba(0,0,0,0.54)",
                          "font-size": "12px",
                          "line-height": "16px",
                          padding: "8px 16px"
                        }
                      },
                      [_vm._v("\n          Recent\n        ")]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.projectList, function(item, index) {
                      return _c(
                        "button",
                        {
                          key: index,
                          staticClass:
                            "md-menu__item md-button md-button--block rx-button-allow-event",
                          attrs: {
                            "data-value":
                              "/projects/" + item.slug + "/literatures",
                            "data-option": ""
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm._f("truncate")(item.title, 20)) +
                              "\n          "
                          ),
                          _c("a", {
                            staticClass: "rx-click__area",
                            attrs: {
                              href: "/projects/" + item.slug + "/literatures"
                            }
                          })
                        ]
                      )
                    })
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        2
      ),
      _vm._v(" "),
      _c(_vm.asyncComp, {
        tag: "feedback",
        attrs: { "dialog-id": _vm.dialogId }
      }),
      _vm._v(" "),
      _vm.isAppLoading
        ? _c(
            "div",
            {
              staticClass: "layout-block layout-block--fixed",
              attrs: { id: _vm.unid + "-block" }
            },
            [
              _c("app-progress", {
                attrs: {
                  unid: _vm.unid + "-progress",
                  config: {
                    circle: true,
                    center: true,
                    overlay: true,
                    parent: true,
                    target: "#" + _vm.unid + "-block"
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(_vm.pdfAsyncComp, {
        ref: "requestDialog",
        tag: "no-pdf-action",
        attrs: {
          "project-id": _vm.currentPdfAction.projectId,
          "literature-id": _vm.currentPdfAction.literatureId,
          "pdf-url": _vm.currentPdfAction.pdfUrl,
          unid: _vm.noPdfDialogId,
          "dialog-title": "Add Pdf"
        },
        on: { requestLibrary: _vm.onRequestLibrary }
      }),
      _vm._v(" "),
      _c(
        "app-menu",
        {
          attrs: { unid: "help-rax", "data-width": "5" },
          on: { selected: _vm.onHelpSelected }
        },
        [
          _c("template", { slot: "body" }, [
            _c("div", { staticClass: "md-list__subhead" }, [
              _c("span", { staticClass: "md-typo--caption-color-contrast" }, [
                _vm._v("\n          Need help with using Enago Read?\n        ")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "md-menu__divider" }),
            _vm._v(" "),
            _c("div", { staticClass: "md-list__subhead" }, [
              _c("span", { staticClass: "md-typo--caption-color-contrast" }, [
                _vm._v(
                  "\n          Need to talk with our support team?\n        "
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "md-button md-menu__item",
                staticStyle: { "text-transform": "none" },
                attrs: { "data-value": "contact", "data-option": "contact" }
              },
              [
                _c("i", { staticClass: "material-icons md-button__icon" }, [
                  _vm._v("announcement")
                ]),
                _vm._v(" Contact us\n      ")
              ]
            )
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "app-menu",
        {
          staticClass: "layout-m-t-1",
          attrs: { unid: "app-account-menu" },
          on: { selected: _vm.onMyAccount }
        },
        [
          _c(
            "template",
            { slot: "body" },
            _vm._l(_vm.filterMyAccountLinks, function(item) {
              return _c(
                "button",
                {
                  key: item.value,
                  staticClass: "md-menu__item md-button rx-button-allow-event",
                  attrs: { "data-value": item.value, type: "button" }
                },
                [
                  _vm._v("\n        " + _vm._s(item.title) + "\n        "),
                  item.title != "Logout"
                    ? _c("a", {
                        staticClass: "rx-click__area",
                        attrs: { href: "/" + item.value }
                      })
                    : _vm._e()
                ]
              )
            }),
            0
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }