<template>
  <div
    :addClass="addClass"
    :class="{ 'md-textfield--outline': outline }"
    class="md-textfield"
  >
    <slot name="surface" />
    <template v-if="type !== 'textarea'">
      <input
        :id="unid"
        :type="type"
        :minlength="minlength"
        :maxlength="maxlength"
        :name="name"
        :readonly="readonly"
        :autofocus="autofocus"
        :placeholder="placeholder"
        :value="value"
        :max="max"
        :min="min"
        :required="required"
        :disabled="disabled"
        :pattern="pattern"
        :autocomplete="autocomplete"
        class="md-textfield__input"
        :class="{ 'italicsPlaceholder': borderBottom }"
        @keydown.enter="$emit('enter', $event)"
        @keydown.tab="$emit('tab')"
        @keydown.up="$emit('up')"
        @keydown.down="$emit('down')"
        @keydown.esc="$emit('esc')"
        @input="$emit('input', $event.target.value), customPDFValidation($event)"
        @click="clearInput"
      />
    </template>
    <template v-else>
      <textarea
        :id="unid"
        :name="name"
        :disabled="disabled"
        :required="required === true"
        :placeholder="placeholder"
        :value="value"
        :rows="rows"
        :autofocus="autofocus"
        class="md-textfield__input"
        @input="$emit('input', $event.target.value)"
      />
    </template>
    <label :for="unid" class="md-textfield__label" :class="labelClass">
      {{ label }}
    </label>
    <span class="md-textfield__help-text">{{ helperText }}</span>
    <span class="md-textfield__error">{{ errorText }}</span>
    <div v-if="outline" class="md-notched" >
      <svg>
        <path class="md-notched__path" :class="{ 'no-stroke': borderBottom }"/>
      </svg>
    </div>
    <div 
      v-if="outline" 
      class="md-notched__outline"
      :class="{ 'border-bottom': borderBottom }"
      />
    <div
      v-if="icon"
      :class="{ 'md-button md-button--icon': renderButton }"
      class="md-textfield__icon"
      @click="$emit('iconClick')"
    >
      <i class="material-icons">{{ icon }}</i>
    </div>
  </div>
</template>
<style>
.md-textfield__icon:not(.md-button) {
  border: none;
  background: none;
  outline: inherit;
  padding: 0;
}
.md-textfield--outline .md-textfield__error,
.md-textfield--outline .md-textfield__help-text {
  left: 12px;
  margin-left: 1px;
  transform: translateY(100%);
}
.md-textfield--invalid .md-textfield__help-text {
  opacity: 0;
}
.md-textfield--show-label .md-textfield__label {
  font-size: 12px;
  top: 6px;
  visibility: visible;
}
.no-stroke{
  stroke-width: 0 !important;
}
.border-bottom{
  border:0px;
  border-bottom: 1px solid gray;
}
.italicsPlaceholder{
  font-style: italic;
}
</style>
<script>
export default {
  props: {
    unid: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    addClass: {
      type: String,
      default: null
    },
    labelClass: {
      type: String,
      default: null
    },
    helperText: {
      type: String,
      default: null
    },
    errorText: {
      type: String,
      default: null
    },
    focusOnMount: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    },
    renderButton: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "text"
    },
    name: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number],
      default: null
    },
    maxlength: {
      type: Number,
      default: null
    },
    max: {
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: null
    },
    minlength: {
      type: Number,
      default: null
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    outline: {
      type: Boolean,
      default: true
    },
    autocomplete: {
      type: String,
      default: "on"
    },
    placeholder: {
      type: String,
      default: ""
    },
    borderBottom: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default: function() {
        return { float: true };
      }
    },
    pattern: {
      type: String,
      default: null
    },
    rows: {
      type: Number,
      default: 4
    },
    addPDFValidation: {
      type: Boolean,
      default: false
    },
    from:{
      type:String,
      default:""
    }
  },
  watch: {
    value(val) {
      if(!this.borderBottom){
        if (val && !$(this.$el).hasClass("md-textfield--dirty")) {
          $(this.$el).addClass("md-textfield--dirty");
        } else if (!val && $(this.$el).hasClass("md-textfield--dirty")) {
          $(this.$el).removeClass("md-textfield--dirty");
        }
      }
    }
  },
  mounted() {
    if (this.focusOnMount) {
      $("#" + this.unid).focus();
    }
    $(this.$el).MaterialTextfield(this.config);
    $(this.$el)
      .on("focused.ca.textfield", () => {
        this.$emit("focused");
      })
      .on("blured.ca.textfield", () => {
        this.$emit("blured");
      });
  },
  beforeDestroy() {
    $(this.$el)
      .off("focused.ca.textfield")
      .off("blured.ca.textfield");
    $(this.$el).MaterialTextfield("destroy");
  },
  methods: {
    //custom validation for PDF files
    customPDFValidation (event) {
      if (this.addPDFValidation) {
        const regexp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/
        if (regexp.test(event.target.value) && event.target.value.includes('.pdf')) event.target.setCustomValidity('')
        else event.target.setCustomValidity('Invalid PDF url');
      }
    },
    clearInput(){
      if(this.from==='autoComplete'){
        this.$emit('input', "");
      }
    }
  }
}
</script>
