<template>
  <label :id="unid" :for="inputId" class="md-radio">
    <input
      :id="inputId"
      :name="name"
      :value="value"
      :checked="isChecked"
      :required="required"
      type="radio"
      class="md-radio__button md-radio__input"
    />
    <span class="md-radio__label">{{ label }}</span>
  </label>
</template>
<script>
export default {
  props: {
    unid: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    },
    isChecked: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      required: true
    },
    label: {
      type: String,
      required: true
    }
  },
  computed: {
    inputId: function() {
      return "input-" + this.unid;
    }
  },
  mounted() {
    const radio = $(`#${this.unid}`);
    radio.MaterialRadio();
    radio.on("changed.ca.radio", () => {
      this.$emit("changed", $(`#${this.inputId}`).val());
    });
  },
  destroy() {
    $(`#${this.unid}`)
      .off("changed.ca.radio")
      .MaterialRadio("destroy");
  }
};
</script>
