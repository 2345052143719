<script>
export default {
  name: "ResizableTextarea",
  props: {
    allowOverflow: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    resizeTextarea(event) {
      event.target.style.height = "auto";
      event.target.style.height = event.target.scrollHeight + "px";
    }
  },
  mounted() {
    this.$nextTick(() => {
      // DOM updated
      this.$el.setAttribute(
        "style",
        "height:" + this.$el.scrollHeight + "px;overflow-y:hidden;"
      );
      if (this.allowOverflow) {
        this.$el.setAttribute("style", "overflow-y: auto;");
      }
      this.$el.addEventListener("input", this.resizeTextarea);
    });
  },
  beforeDestroy() {
    this.$el.removeEventListener("input", this.resizeTextarea);
  },
  render() {
    return this.$slots.default[0];
  }
};
</script>
