<template>
  <div class="breadcrumb md-layout md-align md-align--start-center topHeaderProjectNameSection">
    <template v-for="(item, index) in breadCrumbs">
      <template v-if="item.type === 'button'">
        <button
          :key="index"
          :data-target="'#' + menuId"
          :data-toggle="
            ![
              UserStatus.KIDemoOld,
              UserStatus.KIDemoNew,
              UserStatus.ReadingDemo,
              UserStatus.RedditDemo
            ].includes(user.onboardingStatus)
              ? 'menu'
              : ''
          "
          @click="checkUserEmail"
          style="text-transform: none;padding-right: 2px"
          class="md-button md-button--primary md-button--dense rx-button--project"
        >
          {{ item.title | truncate(20) }}
          <i
            style="margin-left: 0"
            class="md-button__icon md-button__icon--right material-icons"
          >
            <span class="hide-on-affix">keyboard_arrow_right</span>
            <span class="show-on-affix">arrow_drop_down</span>
          </i>
        </button>
      </template>
      <template v-else-if="item.type === 'link'">
        <i
          :key="item.title"
          style="margin-left: 0"
          class="material-icons md-button__icon--right md-button__icon tw-text-sm tw-text-black-72"
        >
          chevron_right
        </i>
        <nuxt-link
          :key="item.title + 'link'"
          :title="item.title"
          :to="item.link"
          :event="
            ![
              UserStatus.KIDemoOld,
              UserStatus.KIDemoNew,
              UserStatus.ReadingDemo,
              UserStatus.RedditDemo
            ].includes(user.onboardingStatus)
              ? 'click'
              : ''
          "
          style="text-transform: none;min-width: inherit;margin-left: 20px;font-size: 16px; padding: 0px 8px;"
          class="md-button md-button-link md-button--dense rx-button--project tw-max-w-44 xl:tw-max-w-60 tw-truncate breadcrumb__item"
        >
          {{ item.title }}
        </nuxt-link>
      </template>
      <template v-else-if="item.type == 'header'">
        <div
          :key="item.title"
          class="md-header__sub-title md-page-crumb__item"
          style="font-size:16px;"
        >
        <img :src="`${assetsUrl}img/icon/${item.icon}.svg`"/>
        <nuxt-link
          :key="item.title + 'link'"
          :to="item.link"
          style="text-transform: none;min-width: inherit;margin-left: 20px;font-size: 16px; padding: 0px 8px;"
          class="md-button md-button--dense rx-button--project tw-max-w-44 xl:tw-max-w-60 tw-truncate breadcrumb__item"
        >
          <span class="tw-truncate">
            {{ item.title }}
          </span>
        </nuxt-link>
        </div>
      </template>
      <template v-else>
        <div :key="item.title + 'other'" class="md-page-crumb crumbOuterSection" style="width: fit-content;">
          <div
            class="md-page-crumb__wrapper tw-max-w-64 xl:tw-max-w-184 tw-truncate"
            style="display: flex;align-items: center; height: 100%;"
          >
            <i
            :key="item.title"
            style="margin-left: 0"
            class="material-icons md-button__icon--right md-button__icon tw-text-sm tw-text-black-72"
            >
              chevron_right
            </i>
            <div
              v-if="!item.persistent"
              class="md-header__title md-page-crumb__item"
            >
              &nbsp;
            </div>
            <div
              v-else
              :title="item.title"
              class="md-header__sub-title md-page-crumb__item"
              style="font-size:16px;margin-left: 20px;padding: 0px 8px;"
            >
              <span class="tw-truncate">
                {{ item.title }}
              </span>
            </div>
            <!-- <div class="md-header__sub-title md-page-crumb__item">
              {{ item.title | truncate(30) }}
            </div> -->
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UserStatus } from "~/shared/constants";

export default {
  data: () => {
    return {
      menuId: "breadCrumb-project-menu",
      UserStatus,
      assetsUrl: process.env.assetsUrl,
    };
  },
  computed: {
    ...mapGetters({
      breadCrumbs: "core/breadCrumbs",
      projectList: "projects/getData",
      user: "user/getData"
    })
  },
  methods: {
    checkUserEmail() {
      if (this.menuId == 'breadCrumb-project-menu') {
        this.$mixpanel.track('BreadCrumb Projects');
      }
      if (
        [
          UserStatus.KIDemoOld,
          UserStatus.KIDemoNew,
          UserStatus.ReadingDemo,
          UserStatus.RedditDemo
        ].includes(this.user.onboardingStatus)
      ) {
        this.$store.commit("user/setLastAction", "Breadcrumb click");
        this.$store.commit("user/setShowEmailModal", 1);
      }
    }
  }
};
</script>
<style scoped>
.md-button-link:hover{
  color:#1B64E2;
}
</style>
