export const SubscriptionStatus = {
  Active: 'active',
  Cancelled: 'canceled',
  UnPaid: 'unpaid',
  pastDue:'past_due',
  trial: 'trialing',
  Incomplete: 'incomplete',
  IncompleteExp: 'incomplete_expired',
};

export const PaymentIntentStatus = {
  Null: null,
  Processing: 'processing',
  RequiresAction: 'requires_action',
  RequiresPaymentMethod: 'requires_payment_method',
  Succeeded: 'succeeded',
}

export const PlanType = {
  Individual : '1',
  Group : '2',
  Institution: '3'
}
export const PlanCategory = {
  Essential : '1',
  Pro : '2',
  Premium: '3'
}

export const PlanNames = {
  [PlanCategory.Essential]: 'Enago Read Basic',
  [PlanCategory.Pro]: 'Enago Read Premium',
  [PlanCategory.Premium]: 'Enago Read Premium with Copilot'
}

export const ShortPlanNames = {
  [PlanCategory.Essential]: 'Basic',
  [PlanCategory.Pro]: 'Premium',
  [PlanCategory.Premium]: 'Premium with Copilot'
}

export const PlanPrice = {
  [PlanCategory.Essential]: '$0',
  [PlanCategory.Pro]: '$3',
  [PlanCategory.Premium]: '$4'
}

export const PlanCycle = {
  Annual: 'annual',
  Monthly: 'monthly'
}

export const AcademicRole = {
  Student: '1',
  PostDoc: '2',
  FacultyStaff: '3'
}

export const  CancelQuestionsType = {
  NoLongerNeeded: '1',
  WantedToTry: '2',
  SwitchingToBetter: '3',
  TooExpensive: '4',
  ResearchIsOver: '5',
  TechIssue: '6',
  Others: '7'
}

export const ReminderDrawer = {
  Click: 'clicked-on-drawer',
  Opened: 'reminder-drawer-opened'
}

export const AppSheet = {
  Close: 'close-sheet',
  SwitchToCitedBy: 'switch-to-cited-by'
}

export const RygResult = {
  Attach: 'attach-ryg-result'
}

export const TourV2 = {
  Events: {
    StartTour: 'startTour'
  },
  TourTypes: {
    SETTOPICTAGS: 'setTopicTags',
    LITERATURELIST: 'literatureList',
    SUMMARYMODAL: 'summaryModal',
  }
}

export const TourEvents = {
  Started: 'started',
  Change: 'change',
  FirstTourOnLiteratureClicked: 'first_tour_on_literature',
  LiteratureContextMenuClicked: 'literature_context_menu_clicked',
  TourWaveClicked: 'tour_wave_clicked',
  ResultLoaded: 'ryg-result-loaded',
  AttachmentAddedToPaper: 'attachment-added-on-paper',
  ReminderDrawerOpened: 'reminder-drawer-opened',
  ReminderDrawerClosed: 'reminder-drawer-closed',
  ReminderPanelOpended: 'reminder-panel-opened'
}
export const MixpanelEventGroup = {
  Tour: 'tour',
  TourContextMenu : 'context-menu-tour',
  LiteratureTour: 'literature-tour',
  EduTour: 'educational-tour',
  CompareAndContrastResultCard: 'compare-n-contrast-result-tab',
  ResultCards: 'result-cards',
  Reminders: 'reminders',
  PaperMoreInfo:'paper-more-info',
  Demo: 'Demo',
  RygMenu: 'Ryg menu',
  PillTabResultSources: 'Pill tab',
  KeyInsightRaxification: 'KeyInsight Raxification',
  TLDR: 'TLDR',
  TryReviewer: 'Try-reviewer-assistant',
  MultiSelect: 'multi-select',
  Bot: 'Bot',
  Export:'Export',
  Copy: 'Copy',
  Move: 'Move',
  Suggestions:'Suggestions',
  SuggestionModal:'SuggestionModal',
  PinnedTag: 'PinnedTag',
  Search: 'Search'
}
export const MixpanelEvents = {
  [MixpanelEventGroup.LiteratureTour]:{
    Start: 'Tour/Literature Start',
    Visited: 'Tour/Literature Visited',
    Skip: 'Tour/Literature Skip',
    Complete: 'Tour/Literature Complete'
  },
  [MixpanelEventGroup.EduTour]:{
    ContextMenuAddUrl : 'EduTour/ContextMenu AddUrl',
  },
  [MixpanelEventGroup.TourContextMenu] :{
    Open: 'Tour/Context Menu Open',
    Click: 'Tour/Context Menu Click'
  },
  [MixpanelEventGroup.Tour] :{
    SkipTour: 'Tour Skip'
  },
  [MixpanelEventGroup.CompareAndContrastResultCard] :{
    ChangeTab: 'Change Compare-n-Contrast Tab',
    OpenLiterature: 'Click Compare-n-Contrast Result Title',
    OpenKeyInsight: 'Click Compare-n-Contrast Key Insight',
  },
  [MixpanelEventGroup.ResultCards] :{
    ViewMoreText: 'View More Result Card Text',
    ViewLessText: 'View Less Result Card Text',
    MobileViewMore: 'View More_Explore Related',
    MobileViewLess: 'View Less_Explore Related',
    Attach: 'Attach ryg result',
    Staple: 'Staple ryg result',
    AddToLiterature: 'Add to literature',
    StapleAndAddToLit: 'Staple & add to literature',
  },
  [MixpanelEventGroup.Reminders]: {
    OpenPanel: 'Open reminders panel',
    CreateReminder: 'New reminder created',
    UpdateReminder: 'Reminder updated',
    UndoReminder: 'Undo reminder',
    SnoozeReminder: 'Reminder snoozed',
    DeleteReminder: 'Reminder deleted',
    CompleteReminder: 'Reminder marked as complete',
    OpenCalender: 'Calender opened',
    ReminderTourStarted: 'Reminder tour started',
    ReminderTourClosed: 'Reminders tour closed',
    ReminderTourCompleted: 'Reminders tour completed',
    ReminderDrawerOpened: 'Reminder drawer opened',
    TodaysRemindersShown: 'Todays reminders shown',
    UpcomingTabOpen: 'Upcoming tab opened',
    OverdueTabOpen: 'Overdue tab opened',
    CompletedTabOpen: 'Completed tab opened',
    ClickReminder: 'Clicked on reminder link from drawer'
  },
  [MixpanelEventGroup.PaperMoreInfo]:{
    OpenPanel:'PaperInfoPanel/Open',
    ClosePanel:'PaperInfoPanel/Close',
    PanelTabChange:'PaperInfoPanel/TabSwitch',
    UploadPDF:'PaperInfoPanel/UploadPDF',
    OpenKeyInsight:'PaperInfoPanel/OpenKeyInsight'
  },
  [MixpanelEventGroup.Demo] :{
    AnonymousSignup: 'User signed up from test site - anonymous signup',
    ChooseOption: 'User chose the option for demo',
    Role: 'User provided the role',
    ShowEmailModal: 'Email modal shown',
    NewEmailProvided: 'User provided new email',
    ExistingEmailProvided: 'User provided existing email'
  },
  [MixpanelEventGroup.RygMenu]: {
    SourceSelectedFromRygMenu: 'Ryg menu option selected',
  },
  [MixpanelEventGroup.PillTabResultSources]: {
    MoreClickedPillTab: 'View all',
  },
  [MixpanelEventGroup.KeyInsightRaxification]:{
    OpenContextMenu:'KeyInsight/OpenContextMenu',
    ContextMenuAddUrl : 'KeyInsight/ContextMenu AddUrl',
    ClickOnKeyInsightAnnotation:'KeyInsight/Annotation Click'
  },
  [MixpanelEventGroup.TLDR]: {
    OpenContextMenu: 'TLDR/OpenContextMenu',
    ContextMenuAddUrl : 'TLDR/ContextMenu AddUrl',
    ClickOnTldrAnnotation: 'TLDR/Annotation Click'
  },
  [MixpanelEventGroup.TryReviewer]: {
    TryClick: 'Click on try reviewer assistant from assistant header',
    ModalNextClick: 'Next button click from assistant site reviewer modal',
    FinishClick: 'Finish/skip button click from assistant site reviewer modal',
    EmailGiven: 'Email info given from assistant site reviewer modal',
    DataGiven: 'Personal info given from assistant site reviewer modal',
  },
  [MixpanelEventGroup.MultiSelect]: {
    SelectAll: 'Select all literatures',
    BulkFavorite: 'Bulk Favorite',
    BulkArchive: 'Bulk Archive',
    BulkUnarchive: 'Archive list: Bulk Unarchive',
    BulkDownload: 'Bulk Download',
    BulkDelete: 'Bulk Delete',
    BulkExportBibliography: 'Bulk Export Bibliography',
    BulkAddToLiterature: 'Bulk Add To Literature',
    Favorite: 'Mark Literature: Favorite',
    Unfavorite: 'Mark Literature: Unfavorite',
    FavoritePanelClick: 'Click Favorite on Left-hand panel',
    FavSelectAll: 'Favorite List: Select all',
    BulkUnfavorite: 'Favorite List: Bulk Unfavorite',
    UnfavoriteFromFavlist: 'Favorite List: Mark Literature: Unfavorite'
  },
  [MixpanelEventGroup.Bot]: {
    Mobile_UserProfile: 'Mobile_UserProfile',
    Mobile_SaveSummary: 'Mobile_SaveSummary',
    Mobile_VerificationLink: 'Mobile_VerificationLink',
    Mobile_signin_submit: 'Mobile_signin Submit',
    Mobile_signin: 'Mobile_SignIn',
    Mobile_Okgotit: 'Mobile_Okgot it',
    Mobile_VerificationLink2: 'Mobile _VerificationLink2',
    Mobile_IWDL: 'Mobile _IWDL',
    Mobile_IWDL2: 'Mobile _IWDL2',
    Mobile_WatchTutorial1: 'Mobile_WatchTutorial1',
    Mobile_VerificationLink3: 'Mobile _VerificationLink3',
    Mobile_IWDL3: 'Mobile _IWDL3',
    Mobile_WatchTutorial2: 'Mobile_WatchTutorial2',
    Mobile_Yes: 'Mobile_Yes',
    Mobile_No: 'Mobile_No',
    Mobile_ExploreRelated: 'Mobile_Explore Related',
    Mobile_Open_Saved_Summary: 'Mobile_Open Saved Summary',
    Mobile_Attachsummary: 'Mobile_Attachsummary',
    Mobile_Attachsummary2: 'Attach with Summary_Explore Related',
    Mobile_WatchTutorial3: 'Mobile_WatchTutorial3',
    Desk_SaveSummary: 'Desk_Save Summary',
    Desk_Keyinsights: 'Desk_Keyinsights',
    Desk_LiteratureTab: 'TwitterBoT (Desk) Literature',
    Desk_Generate_keyinsights: 'Desk_Generate_keyinsights',
    Desk_Critique: 'Desk_Critique',
    Desk_ExploreRelated: 'Desk_ExploreRelated',
    Desk_Signin: 'Desk_Signin',
    Desk_Signup: 'Desk_ Signup',
    Desk_Team: 'Desk_Team',
    Desk_Splitview: 'Desk_Splitview',
    Desk_NewUpdates: 'Desk_NewUpdates',
    Desk_Help: 'Desk_Help',
    Desk_Reminders: 'Desk_Reminders',
    Desk_Download: 'Desk_Download',
    Desk_Share: 'Desk_Share',
    Desk_Signin: 'Desk_Signin',
    Desk_Signup: 'Desk_ Signup',
    Desk_keyInsi_Signup: 'Desk_ keyInsi_Signup',
  },
  [MixpanelEventGroup.Export]: {
    Export: 'Export',
    Stylize: 'Stylize bibliography',
    Export_Bib: 'Exp_bib',
    Export_Ris: 'Exp_ris',
    Export_Enw: 'Exp_Endnotexml',
    Export_Csv: 'Exp_csv',
    Export_Xls: 'Exp_xls',
    Previous:'Bib_previous',
    CopyText:"Bib_copytext",
    Export_Cit:"Bib_Exported"
  },
  [MixpanelEventGroup.Copy]: {
    Copy: 'Copy',
  },
  [MixpanelEventGroup.Move]: {
    Move: 'Move',
  },
  [MixpanelEventGroup.Suggestions]: {
    Suggestions: 'Suggestions',
    Project_Feed: 'Project Feeds',
    Advance_Recommendation: 'Surprise Me',
    Add_Literature: 'Add Literature',
    Abstract: 'Abstract',
    Title: 'Title',
    Open_Literature: 'Open Literature',
  },
  [MixpanelEventGroup.SuggestionModal]: {
    Abstract: 'Abstract',
    Summary: 'Summary',
    Key_Insights: 'Key Insights',
    Tables: 'Tables',
    Figures: 'Figures',
    Info: 'Info',
    Add_Literature:"Add Literature",
    Open_Literature: 'Open Literature',
    Mark_Favourite:'Mark Favorite',
    Set_Tags:"Set Tags",
    Download:"Download",
    Export:"Export",
    Share:"Share",
    Archive:"Archive",
    Delete:"Delete"
  },
  [MixpanelEventGroup.PinnedTag]: {
    Pinned_tag_CTA: 'Pinned_tag_CTA',
    Pin: 'Pin',
    Unpin: 'Unpin',
    Cancel_Pinned_Tag: 'Cancel_Pinned_Tag',
    Save_Pinned_Tag: 'Save_Pinned_Tag',
    View_Pinned_Tag: 'View_Pinned_Tag'
  },
  [MixpanelEventGroup.Search]: {
    Search_Text: 'Search_Text',
    Literature_Title: 'Literature Title',
    Search_Read_More: 'Search_Read more'
  }
}

export const RygSources = {
  LiteratureReadingView: 'Literature reading view',
  LiteratureListView: 'Literature list view',
  QuickView :'Related Literature Quick view',
  SharedLiteratureReadingView: 'Shared literature reading view',
  DraftReadingView: 'Draft reading view',
  SharedDraftReadingView: 'Shared draft reading view',
  ExploreRelated: 'Explore Related'
}

export const LiteratureTourSteps = {
  0 : 'ContextMenu',
  1 : 'RygResult',
  2 : 'AttachOnPaper',
  3 : 'Attachments',
  4 : 'KeyInsight',
  5 : 'Critique'
}

export const TipId = {
  ContextMenuAddUrl: 7
}

export const EducationalTourType = {
  ContextMenuAddUrl : 'contextMenuAddUrl'
}

export const ContactStatus = {
 // 0: pending, 1: member, 2: revoked, 3: archived, 4: left, 5: requested, 6: re-invite
 Pending: 0,
 Member: 1,
 Revoked: 2,
 Archived: 3,
 Left: 4,
 Requested: 5,
 ReInvited: 6
}

export const TipsData = {
  ABKeyInsights: "9",
  ShowInReadingView: ["1", "2", "8", "9"],
  ShowInListView: ["9", "10"]
}

export const BlogPosition = {
  ShowInListView: 0,
  ShowInReadingView: 1
}

export const ReviewAssistant = {
  Try: "try-review-assistant"
}

export const UserStatus = {
  EmailGiven: 0,
  Onboarded: 1,
  KIDemoOld: 2,
  KIDemoNew: 3,
  ReadingDemo: 4,
  RedditDemo: 5
}

export const Demo = {
  UploadPaper: 'upload-paper',
  FileSelected: 'file-selected',
  UploadFailed: 'upload-failed',
  RetryUpload: 'retry-upload'
}

export const EmailModal = {
  UserAlreadyExists: 'user-already-exists'
}

// Recommendation types(for rygging)
export const ResultTypes = {
  Literatures: 'literatures',
  SurveyPapers: 'surveyPapers',
  Wikis: 'wikis',
  Lectures: 'lectures',
  LightReads: 'lightreads',
  BooksInstitute: 'books',
  EbooksInstitute: 'e-books',
  ThesisInstitute: 'thesis',
  CitedBy: 'cited-by',
  Review: 'review'
}

export const ReadingRoomTypes = {
  Copilot: 'copilot',
  ExploreRelated: 'result',
  Discussion: 'discussion',
  Attached: 'attached',
  Info: 'info',
  InstituteResources: 'institute-resources'
}

// Data for rendering and animating pill tab(result type)
export const resultTypeTabDataOld = {
  [ResultTypes.Literatures]: {
    label: 'Research papers',
    type: ResultTypes.Literatures,
    iconClass: 'raxIcon-microscope',
    selectedClass: 'tw-w-40' // Class applied when type selected in pill tab
  },
  [ResultTypes.SurveyPapers]: {
    label: 'Survey papers',
    type: ResultTypes.SurveyPapers,
    iconClass: 'raxIcon-light_reads',
    selectedClass: 'tw-w-36'
  },
  [ResultTypes.Review]: {
    label: 'Review articles',
    type: ResultTypes.Review,
    iconClass: 'raxIcon-review-articles',
    selectedClass: 'tw-w-40',
  },
  [ResultTypes.Wikis]: {
    label: 'Wiki',
    type: ResultTypes.Wikis,
    iconClass: 'raxIcon-wiki',
    selectedClass: 'tw-w-20'
  },
  [ResultTypes.Lectures]: {
    label: 'Lectures and videos',
    type: ResultTypes.Lectures,
    iconClass: 'raxIcon-videos',
    selectedClass: 'tw-w-44'
  },
  [ResultTypes.LightReads]: {
    label: 'Light reads',
    type: ResultTypes.LightReads,
    iconClass: 'raxIcon-web',
    selectedClass: 'tw-w-32'
  },
  [ResultTypes.BooksInstitute]: {
    label: 'Institute Resources',
    type: ResultTypes.BooksInstitute,
    iconClass: 'raxIcon-institute',
    selectedClass: 'tw-w-44',
  },
  [ResultTypes.EbooksInstitute]: {
    label: 'Institute Resources',
    type: ResultTypes.EbooksInstitute,
    iconClass: 'raxIcon-institute',
    selectedClass: 'tw-w-44',
  },
  [ResultTypes.ThesisInstitute]: {
    label: 'Institute Resources',
    type: ResultTypes.ThesisInstitute,
    iconClass: 'raxIcon-institute',
    selectedClass: 'tw-w-44',
  },
  [ResultTypes.CitedBy]: {
    label: 'Cited By',
    type: ResultTypes.CitedBy,
    iconClass: 'raxIcon-quote_2',
    selectedClass: 'tw-w-28',
  }
}

export const resultTypeTabData = {
  [ResultTypes.Literatures]: {
    label: 'Research papers',
    type: ResultTypes.Literatures,
    iconClass: 'rax-icon-v2-research-paper-blue',
    selectedClass: 'tw-w-40' // Class applied when type selected in pill tab
  },
  [ResultTypes.SurveyPapers]: {
    label: 'Survey papers',
    type: ResultTypes.SurveyPapers,
    iconClass: 'rax-icon-v2-survey-paper-blue',
    selectedClass: 'tw-w-36'
  },
  [ResultTypes.Review]: {
    label: 'Review articles',
    type: ResultTypes.Review,
    iconClass: 'rax-icon-v2-review-article-blue',
    selectedClass: 'tw-w-40',
  },
  // [ResultTypes.Wikis]: {
  //   label: 'Wikipedia',
  //   type: ResultTypes.Wikis,
  //   iconClass: 'rax-icon-v2-wikipedia-blue',
  //   selectedClass: 'tw-w-28'
  // },
  // [ResultTypes.Lectures]: {
  //   label: 'Lectures and videos',
  //   type: ResultTypes.Lectures,
  //   iconClass: 'rax-icon-v2-video-blue',
  //   selectedClass: 'tw-w-44'
  // },
  // [ResultTypes.LightReads]: {
  //   label: 'Light reads',
  //   type: ResultTypes.LightReads,
  //   iconClass: 'rax-icon-v2-light-reads-blue',
  //   selectedClass: 'tw-w-32'
  // },
  [ResultTypes.BooksInstitute]: {
    label: 'Institute Resources',
    type: ResultTypes.BooksInstitute,
    iconClass: 'rax-icon-v2-institute-blue',
    selectedClass: 'tw-w-44',
  },
  [ResultTypes.EbooksInstitute]: {
    label: 'Institute Resources',
    type: ResultTypes.EbooksInstitute,
    iconClass: 'rax-icon-v2-institute-blue',
    selectedClass: 'tw-w-44',
  },
  [ResultTypes.ThesisInstitute]: {
    label: 'Institute Resources',
    type: ResultTypes.ThesisInstitute,
    iconClass: 'rax-icon-v2-institute-blue',
    selectedClass: 'tw-w-44',
  },
  [ResultTypes.CitedBy]: {
    label: 'Cited By',
    type: ResultTypes.CitedBy,
    iconClass: 'rax-icon-v2-cited-by-blue',
    selectedClass: 'tw-w-28',
  }
}

export const readingRoomTabData = {
  [ReadingRoomTypes.Copilot]: {
    label: 'Copilot',
    type: ReadingRoomTypes.Copilot,
    iconClass: '--copilot'
  },
  [ReadingRoomTypes.ExploreRelated]: {
    label: 'Explore',
    type: ReadingRoomTypes.ExploreRelated,
    iconClass: '--explore-related'
  },
  // [ReadingRoomTypes.Discussion]: {
  //   label: 'Discussion',
  //   type: ReadingRoomTypes.Discussion,
  //   iconClass: '--discussion'
  // },
  [ReadingRoomTypes.Attached]: {
    label: 'Attached',
    type: ReadingRoomTypes.Attached,
    iconClass: '--attached'
  },
  [ReadingRoomTypes.Info]: {
    label: 'Info',
    type: ReadingRoomTypes.Info,
    iconClass: '--info'
  }
}

export const readingRoomSplitPageOptions = {
  [ReadingRoomTypes.Copilot]: {
    label: 'Copilot',
    type: ReadingRoomTypes.Copilot,
    iconClass: '--copilot'
  },
  // [ReadingRoomTypes.Discussion]: {
  //   label: 'Discussion',
  //   type: ReadingRoomTypes.Discussion,
  //   iconClass: '--discussion'
  // },
  [ReadingRoomTypes.ExploreRelated]: {
    label: 'Related Papers',
    type: 'literatures',
    iconClass: '--explore-related'
  },
  [ReadingRoomTypes.InstituteResources]: {
    label: 'Institute Resources',
    type: 'books',
    iconClass: '--institute-resources'
  },
  [ReadingRoomTypes.Attached]: {
    label: 'Attached',
    type: ReadingRoomTypes.Attached,
    iconClass: '--attached'
  },
  [ReadingRoomTypes.Info]: {
    label: 'Info',
    type: ReadingRoomTypes.Info,
    iconClass: '--info'
  }
}

export const CopilotInputs = {
  InitialQueries: [
    'Summarize this paper',
    'Research goal of this paper',
    'Research outcome of this paper'
  ],
  InitialBackendQueries:{
    'Summarize this paper':'Summarize this paper',
    'Research goal of this paper':'What is the research goal?',
    'Research outcome of this paper':'What is the research outcome?'
  },
  TranslationLanguages: [
    'English (en)',
    'Chinese (zh)',
    'Japanese (ja)',
    'Afrikaans (af)',
    'Albanian (Sq)',
    'Amharik (am)',
    'Arabic (ar)',
    'Armenian (hy)'
  ]
};

export const FetchingPromptText = {
  [ResultTypes.Literatures]: `Finding related papers from more than<br /> 200 million+ research papers of top-class journals and proceedings...`,
  [ResultTypes.SurveyPapers]: "Finding related survey papers...",
  [ResultTypes.Wikis]: "Finding related wikis...",
  [ResultTypes.Lectures]: "Finding related lectures and videos...",
  [ResultTypes.LightReads]: "Finding related light reads...",
  [ResultTypes.BooksInstitute]: "Finding related books...",
  [ResultTypes.EbooksInstitute]: "Finding related e-books...",
  [ResultTypes.ThesisInstitute]: "Finding related theses...",
  [ResultTypes.Review]: "Finding related review articles...",
}

export const ResultTypeAnalytics = {
  Default: undefined,
  SimilarProblem: 1,
  SimilarApproach: 2,
  SimilarApproachAllProblems: 3,
  SurveyPapers: 4,
  Review: 5
}

export const AdvancedMinerFields = {
  SimilarResearchQuestion: 'Similar research question',
  SimilarApproach: 'Similar approach',
}

export const Draft = {
  Delete: 'Delete draft'
}

export const TLDR = {
  Switch: 'Switch TLDR',
  UploadPaper: 'Upload paper'
}

export const META_DATA_REFERRAL = [
  {
    name: 'og:url',
    content: `https://read.enago.com/referral`
  },
  {
    name: 'og:type',
    content: 'Website'
  },
  {
    name: 'og:title',
    content:
      'RAx- a tool to speed up your literature review, critical reading, and analysis.'
  },
  {
    name: 'og:description',
    content:
      'Hey, I found this tool called Enago Read very helpful for “quick-n-easy” literature review and critical reading. Check it out and get a $5 credit reward when you sign up.'
  },
  {
    name: "og:site_name",
    content: "RAx"
  },
  {
    name: "twitter:title",
    content: "RAx- a tool to speed up your literature review, critical reading, and analysis."
  },
  {
    name: "twitter:description",
    content:
      "Hey, I found this tool called Enago Read very helpful for “quick-n-easy” literature review and critical reading. Check it out and get a $5 credit reward when you sign up."
  },
  {
    name: "twitter:site",
    content: `https://read.enago.com/referral`
  },
  {
    name: "twitter:card",
    content: "summary"
  },
  {
    name: "twitter:creator",
    content: "@raxter_io"
  },
  {
    name: "twitter:image",
    content: `${process.env.assetsUrl}img/referral-program/referral-og.png`
  },
  {
    name: "twitter:image:alt",
    content: "Speed up literature analysis and comprehension with RAx"
  }
];

export const META_DATA_DEFAULT =
  [
    {
      name: "og:type",
      content: "Website"
    },
    {
      name: "og:title",
      content: "Speed up literature analysis and comprehension with RAx"
    },
    {
      name: "og:description",
      content:
        "Smart assistant to explore, collate insights and organize your research easily"
    },
    {
      name: "og:url",
      content: "https://raxter.io"
    },
    {
      name: "og:site_name",
      content: "RAx"
    },
    {
      name: "twitter:title",
      content: "Speed up literature analysis and comprehension with RAx"
    },
    {
      name: "twitter:description",
      content:
      "Smart assistant to explore, collate insights and organize your research easily"
    },
    { name: "twitter:site", content: "http://raxter.io" },
    { name: "twitter:card", content: "summary" },
    { name: "twitter:creator", content: "@raxter_io" },
    {
      name: "twitter:image",
      content: "https://read.enago.com/img/summary_image.png"
    },
    {
      name: "twitter:image:alt",
      content: "Speed up literature analysis and comprehension with RAx"
    },
];

export const SOCIAL_SHARE_CONTENT = {
  DEFAULT: {
    TITLE: '',
    CONTENT:
      'Hey, I am trying this cool literature review tool called Enago Read. Literature survey is time-consuming and I feel Enago Read might be what I always wanted for “easy-n-quick” critical reading. Check it out, it will be useful for you as well. As they love getting feedback from the research community, you can share yours after using it. Also, you will get a $5 reward as a token of appreciation, if you sign up using the link. Help them get more feedback by sharing Enago Read with others and as a token of appreciation, they will give you credit of $12 as a reward.'
  },
  GMAIL: {
    TYPE: 'google_gmail',
    SUBJECT: 'Found Enago Read - a cool tool that helps speed up a literature review',
    BODY:
      'Hey,\n\nI am trying this cool literature review tool called Enago Read. Literature survey is time-consuming and I feel Enago Read might be what I always wanted for “easy-n-quick” critical reading.\n\nCheck it out, it will be useful for you as well.\n\nAs they love getting feedback from the research community, you can share yours after using it. Also, you will get a $5 reward as a token of appreciation, if you sign up using the link below.\n\n${link}\n\nHelp them get more feedback by sharing Enago Read with others and as a token of appreciation, they will give you credit of $12 as a reward.'
  },
  WHATSAPP: {
    TYPE: 'whatsapp',
    TEXT:
      'Hey,\n\nI am trying this cool literature review tool called Enago Read. Literature survey is time-consuming and I feel Enago Read might be what I always wanted for “easy-n-quick” critical reading.\n\nCheck it out, it will be useful for you as well.\n\nAs they love getting feedback from the research community, you can share yours after using it. Also, you will get a $5 reward as a token of appreciation, if you sign up using the link below.\n\n${link}\n\nHelp them get more feedback by sharing Enago Read with others and as a token of appreciation, they will give you credit of $12 as a reward.'
  },
  LINKEDIN: {
    TYPE: 'linkedin',
    TEXT:
      'Hey,\n\nI am trying this cool literature review tool called Enago Read. Literature survey is time-consuming and I feel Enago Read might be what I always wanted for “easy-n-quick” critical reading.\n\nCheck it out, it will be useful for you as well.\n\nAs they love getting feedback from the research community, you can share yours after using it. Also, you will get a $5 reward as a token of appreciation, if you sign up using the link below.\n\n${link}\n\nHelp them get more feedback by sharing Enago Read with others and as a token of appreciation, they will give you credit of $12 as a reward.'
  },
  TWITTER: {
    TYPE: 'twitter',
    TEXT:
      'I found this cool #literaturereview tool called RAx, @Raxter_io.\nCheck it out guys & share your experience in the comments.\n\nYou can share your feedback about Enago Read and as a return gift, you get a $5 credit reward when you sign up for free using the link.\n${link}'
  },
  FACEBOOK: {
    TYPE: 'facebook',
    TEXT:
      'I’m trying this cool literature review tool called Enago Read.\nCheck it out guys & comment your experience below.\n\nFeedback is appreciated & as a return gift, you get $5 worth wallet credit when you sign up for free.\n\n${link}'
  },
  REDDIT: {
    TYPE: 'reddit',
    SUBJECT: 'Found Enago Read - a cool tool that helps speed up a literature review',
    TEXT:
      'Hey,\n\nI am trying this cool literature review tool called Enago Read. Literature survey is time-consuming and I feel Enago Read might be what I always wanted for “easy-n-quick” critical reading.\n\nCheck it out, it will be useful for you as well.\n\nAs they love getting feedback from the research community, you can share yours after using it. Also, you will get a $5 reward as a token of appreciation, if you sign up using the link below.\n\n${link}\n\nHelp them get more feedback by sharing Enago Read with others and as a token of appreciation, they will give you credit of $12 as a reward.'
  },
  TELEGRAM: {
    TYPE: 'telegram',
    CONTENT:
      'Hey, I am trying this cool literature review tool called Enago Read. Literature survey is time-consuming and I feel Enago Read might be what I always wanted for “easy-n-quick” critical reading. Check it out, it will be useful for you as well. As they love getting feedback from the research community, you can share yours after using it. Also, you will get a $5 reward as a token of appreciation, if you sign up using the link. Help them get more feedback by sharing Enago Read with others and as a token of appreciation, they will give you credit of $12 as a reward.'
  }
}

export const SampleContent = "1. Is the research question within the scope of the conference?\n\n\n2. What are the primary claims of the manuscript?\n\n\n3. To what extent are the claims original?\n\n\n4. What is the extent of the rigor and soundness of the approach?\n\n\n5. To what extent the approach is reproducible?\n\n\n6. Have the authors included the key citations and positioned their approach comprehensively w.r.t previous key research works?\n\n\n7. How much impactful do you think is the contribution of the manuscript?\n\n\n8. Please give your comment on the clarity of the key ideas and the overall structural flow of the manuscript.\n\n\n9. Other remarks\n\n";

export const SampleHtmlContent = "<h2><span style=\"color: rgb(0, 102, 204);\">1. Is the research question within the scope of the conference?</span></h2><h2><br></h2><p><br></p><h2><span style=\"color: rgb(0, 102, 204);\">2. What are the primary claims of the manuscript?</span></h2><p><br></p><h2><br></h2><h2><span style=\"color: rgb(0, 102, 204);\">3. To what extent are the claims original?</span></h2><p><br></p><h2><br></h2><h2><span style=\"color: rgb(0, 102, 204);\">4. What is the extent of the rigor and soundness of the approach?</span></h2><p><br></p><h2><br></h2><h2><span style=\"color: rgb(0, 102, 204);\">5. To what extent the approach is reproducible?</span></h2><p><br></p><h2><br></h2><h2><span style=\"color: rgb(0, 102, 204);\">6. Have the authors included the key citations and positioned their approach comprehensively w.r.t previous key research works?</span></h2><p><br></p><h2><br></h2><h2><span style=\"color: rgb(0, 102, 204);\">7. How much impactful do you think is the contribution of the manuscript?</span></h2><p><br></p><h2><br></h2><h2><span style=\"color: rgb(0, 102, 204);\">8.&nbsp;Please give your comment on the clarity of the key ideas and the overall structural flow of the manuscript.</span></h2><p><br></p><h2><br></h2><h2><span style=\"color: rgb(0, 102, 204);\">9. Other remarks</span></h2><p><br></p>";


export const RANDOM_TWEET_MSG = [
  `Hey! Check this section-wise summary via @rax_bot of the paper:
  <title>
  We can also write our notes as we read the paper - think this will come handy when revisiting.`,
  `Hey! Check this section-wise summary via @rax_bot of the paper:
  <title>
  Might just help you guys to investigate good papers.`,
  `Hey! Check this section-wise summary via @rax_bot of the paper: <title>
  One of the awesome things - it helps to draw out attention on the often missed yet important aspects of the paper.`,
  `Hey! Check this section-wise summary via @rax_bot of the paper: <title>
  Might just help you guys to cut the noise, and focus quality time on papers.`
];

export const Rax_Crypto_Pwd = 'rax_secret_key';

export const Upload_Error_Msg = {
  typeError: "Unsupported File Format",
  sizeError: "File size limit exceeded",
  defaultError: "Error while uploading"
}

export const routesConfiguration = {
  "discover": { isLeftPanelOpen: false, isTopMenulOpen: false, currentRoute: "discover", headerTitle: "Discover" },
  "lira": { isLeftPanelOpen: false, isTopMenulOpen: false, headerTitle: "LIRA", currentRoute: "lira" },
  "newOnboarding": {},
  "preparingRAxAssistant": {},
  "pricing": {},
  "profile": {},
  "projects": { isLeftPanelOpen: true, isTopMenulOpen: true, headerTitle: "Knowledge Manager", currentRoute: "projects" },
  "reviews": { isLeftPanelOpen: false, isTopMenulOpen: false, currentRoute: "discover", headerTitle: "Reviews" },
  "shared-with-me": { isLeftPanelOpen: true, isTopMenulOpen: true, headerTitle: "Knowledge Manager / Shared-with-me", currentRoute: "projects" },
  "student-monitoring": {},
  "subscription": {},
  "subscription-payment": {},
  "subscription-payment-success": {},
  "auth-forgot-password": {},
  "auth-signin": {},
  "auth-signin-mobile": {},
  "auth-signup": {},
  "auth-verify-email": {},
  "auth-verify-email-mobile": {},
  "discover-acs-collections": { isLeftPanelOpen: false, isTopMenulOpen: false, currentRoute: "discover", headerTitle: "Discover" },
  "discover-bookmarks": { isLeftPanelOpen: false, isTopMenulOpen: false, currentRoute: "discover", headerTitle: "Discover" },
  "discover-feeds": { isLeftPanelOpen: false, isTopMenulOpen: false, currentRoute: "discover", headerTitle: "Discover" },
  "discover-library": { isLeftPanelOpen: false, isTopMenulOpen: false, currentRoute: "discover", headerTitle: "Discover" },
  "faculty-email-change": {},
  "faculty-invitation": {},
  "projects-archive": {},
  "quick-view-related-papers": {},
  "quick-view-summary": {},
  "record-result-action-platform": {},
  "shared-with-me-drafts": { isLeftPanelOpen: true, isTopMenulOpen: true, headerTitle: "Knowledge Manager / Shared-with-me", currentRoute: "projects" },
  "shared-with-me-email-change": { isLeftPanelOpen: true, isTopMenulOpen: true, headerTitle: "Knowledge Manager / Shared-with-me", currentRoute: "projects" },
  "shared-with-me-invitation": { isLeftPanelOpen: true, isTopMenulOpen: true, headerTitle: "Knowledge Manager / Shared-with-me", currentRoute: "projects" },
  "shared-with-me-literatures": { isLeftPanelOpen: true, isTopMenulOpen: true, headerTitle: "Knowledge Manager / Shared-with-me", currentRoute: "projects" },
  "shared-with-me-request": { isLeftPanelOpen: true, isTopMenulOpen: true, headerTitle: "Knowledge Manager / Shared-with-me", currentRoute: "projects" },
  "shared-with-me-result": { isLeftPanelOpen: true, isTopMenulOpen: true, headerTitle: "Knowledge Manager / Shared-with-me", currentRoute: "projects" },
  "verify-email": {},
  "projects-all-advance-recommendations": { isLeftPanelOpen: true, isTopMenulOpen: true, headerTitle: "Knowledge Manager / All", currentRoute: "projects" },
  "projects-all-drafts": { isLeftPanelOpen: true, isTopMenulOpen: true, headerTitle: "Knowledge Manager / All", currentRoute: "projects" },
  "projects-all-literatures": { isLeftPanelOpen: true, isTopMenulOpen: true, headerTitle: "Knowledge Manager / All", currentRoute: "projects" },
  "projects-all-project-feeds": { isLeftPanelOpen: true, isTopMenulOpen: true, headerTitle: "Knowledge Manager / All", currentRoute: "projects" },
  "projects-all-repository": { isLeftPanelOpen: true, isTopMenulOpen: true, headerTitle: "Knowledge Manager / All", currentRoute: "projects" },
  "shared-with-me-drafts-id": { isLeftPanelOpen: true, isTopMenulOpen: true, headerTitle: "Knowledge Manager / Shared-with-me", currentRoute: "projects" },
  "shared-with-me-literatures-id": { isLeftPanelOpen: true, isTopMenulOpen: true, headerTitle: "Knowledge Manager / Shared-with-me", currentRoute: "projects" },
  "projects-slug": { isLeftPanelOpen: true, isTopMenulOpen: true, headerTitle: "Knowledge Manager", currentRoute: "projects" },
  "reviews-slug": { isLeftPanelOpen: false, isTopMenulOpen: false, currentRoute: "discover", headerTitle: "Reviews" },
  "student-monitoring-id": {},
  "projects-slug-advance-recommendations": { isLeftPanelOpen: true, isTopMenulOpen: true, headerTitle: "Knowledge Manager", currentRoute: "projects" },
  "projects-slug-archive": { isLeftPanelOpen: true, isTopMenulOpen: true, headerTitle: "Knowledge Manager", currentRoute: "projects" },
  "projects-slug-collaborators": { isLeftPanelOpen: true, isTopMenulOpen: true, headerTitle: "Knowledge Manager", currentRoute: "projects" },
  "projects-slug-drafts": { isLeftPanelOpen: true, isTopMenulOpen: true, headerTitle: "Knowledge Manager", currentRoute: "projects" },
  "projects-slug-literatures": { isLeftPanelOpen: true, isTopMenulOpen: true, headerTitle: "Knowledge Manager", currentRoute: "projects" },
  "projects-slug-project-feeds": { isLeftPanelOpen: true, isTopMenulOpen: true, headerTitle: "Knowledge Manager", currentRoute: "projects" },
  "projects-slug-repository": { isLeftPanelOpen: true, isTopMenulOpen: true, headerTitle: "Knowledge Manager", currentRoute: "projects" },
  "projects-slug-suggestions": { isLeftPanelOpen: true, isTopMenulOpen: true, headerTitle: "Knowledge Manager", currentRoute: "projects" },
  "reviews-slug-archive": { isLeftPanelOpen: false, isTopMenulOpen: false, currentRoute: "discover", headerTitle: "Reviews" },
  "reviews-slug-related-literatures": { isLeftPanelOpen: false, isTopMenulOpen: false, currentRoute: "discover", headerTitle: "Reviews" },
  "reviews-slug-repository": { isLeftPanelOpen: false, isTopMenulOpen: false, currentRoute: "discover", headerTitle: "Reviews" },
  "reviews-slug-submissions": { isLeftPanelOpen: false, isTopMenulOpen: false, currentRoute: "discover", headerTitle: "Reviews" },
  "reviews-slug-suggestions": { isLeftPanelOpen: false, isTopMenulOpen: false, currentRoute: "discover", headerTitle: "Reviews" },
  "reviews-slug-working-notes": { isLeftPanelOpen: false, isTopMenulOpen: false, currentRoute: "discover", headerTitle: "Reviews" },
  "projects-slug-collaborators-id": {},
  "projects-slug-drafts-id": {},
  "projects-slug-literatures-id": {},
  "reviews-slug-related-literatures-id": { isLeftPanelOpen: false, isTopMenulOpen: false, currentRoute: "discover", headerTitle: "Reviews" },
  "reviews-slug-submissions-id": { isLeftPanelOpen: false, isTopMenulOpen: false, currentRoute: "discover", headerTitle: "Reviews" },
  "reviews-slug-working-notes-id": { isLeftPanelOpen: false, isTopMenulOpen: false, currentRoute: "discover", headerTitle: "Reviews" },
  "index": {}
};


export const StatusArray = [{
  text: 'Needs reading',
  iconClass: 'cautionIcon',
  bgColor: '#F5E0E9'
}, {
  text: 'Needs further reading',
  iconClass: 'findTextIcon',
  bgColor: '#FFE2DD'
}, {
  text: 'Needs discussion',
  iconClass: 'messagingIcon',
  bgColor: '#FADEC9'
}, {
  text: 'In progress',
  iconClass: 'loaderIcon',
  bgColor: '#E8DEEE'
}, {
  text: 'Done',
  iconClass: 'doneIcon',
  bgColor: '#DBEDDB'
}];

export const LANGUAGE_CODES = [
  {
      "language": "English",
      "code": "en"
  },
  {
      "language": "Japanese",
      "code": "ja"
  },
  {
      "language": "Afrikaans",
      "code": "af"
  },
  {
      "language": "Albanian",
      "code": "sq"
  },
  {
      "language": "Amharic",
      "code": "am"
  },
  {
      "language": "Arabic",
      "code": "ar"
  },
  {
      "language": "Armenian",
      "code": "hy"
  },
  {
      "language": "Azerbaijani",
      "code": "az"
  },
  {
      "language": "Bengali",
      "code": "bn"
  },
  {
      "language": "Bosnian",
      "code": "bs"
  },
  {
      "language": "Bulgarian",
      "code": "bg"
  },
  {
      "language": "Catalan",
      "code": "ca"
  },
  {
      "language": "Chinese",
      "code": "zh"
  },
  {
      "language": "Croatian",
      "code": "hr"
  },
  {
      "language": "Czech",
      "code": "cs"
  },
  {
      "language": "Danish",
      "code": "da"
  },
  {
      "language": "Dutch",
      "code": "nl"
  },
  {
      "language": "Filipino",
      "code": "tl"
  },
  {
      "language": "French",
      "code": "fr"
  },
  {
      "language": "Georgian",
      "code": "ka"
  },
  {
      "language": "German",
      "code": "de"
  },
  {
      "language": "Greek",
      "code": "el"
  },
  {
      "language": "Gujarati",
      "code": "gu"
  },
  {
      "language": "Hebrew",
      "code": "he"
  },
  {
      "language": "Hindi",
      "code": "hi"
  },
  {
      "language": "Hungarian",
      "code": "hu"
  },
  {
      "language": "Icelandic",
      "code": "is"
  },
  {
      "language": "Indonesian",
      "code": "id"
  },
  {
      "language": "Irish",
      "code": "ga"
  },
  {
      "language": "Italian",
      "code": "it"
  },
  {
      "language": "Kannada",
      "code": "kn"
  },
  {
      "language": "Kazakh",
      "code": "kk"
  },
  {
      "language": "Korean",
      "code": "ko"
  },
  {
      "language": "Latvian",
      "code": "lv"
  },
  {
      "language": "Lithuanian",
      "code": "lt"
  },
  {
      "language": "Macedonian",
      "code": "mk"
  },
  {
      "language": "Malay",
      "code": "ms"
  },
  {
      "language": "Malayalam",
      "code": "ml"
  },
  {
      "language": "Maltese",
      "code": "mt"
  },
  {
      "language": "Marathi",
      "code": "mr"
  },
  {
      "language": "Mongolian",
      "code": "mn"
  },
  {
      "language": "Nepali",
      "code": "ne"
  },
  {
      "language": "Norwegian",
      "code": "no"
  },
  {
      "language": "Persian",
      "code": "fa"
  },
  {
      "language": "Polish",
      "code": "pl"
  },
  {
      "language": "Portuguese",
      "code": "pt"
  },
  {
      "language": "Punjabi",
      "code": "pa"
  },
  {
      "language": "Romanian",
      "code": "ro"
  },
  {
      "language": "Russian",
      "code": "ru"
  },
  {
      "language": "Serbian",
      "code": "sr"
  },
  {
      "language": "Sinhalese",
      "code": "si"
  },
  {
      "language": "Slovak",
      "code": "sk"
  },
  {
      "language": "Slovenian",
      "code": "sl"
  },
  {
      "language": "Somali",
      "code": "so"
  },
  {
      "language": "Spanish",
      "code": "es"
  },
  {
      "language": "Sundanese",
      "code": "su"
  },
  {
      "language": "Swahili",
      "code": "sw"
  },
  {
      "language": "Swedish",
      "code": "sv"
  },
  {
      "language": "Tamil",
      "code": "ta"
  },
  {
      "language": "Telugu",
      "code": "te"
  },
  {
      "language": "Thai",
      "code": "th"
  },
  {
      "language": "Turkish",
      "code": "tr"
  },
  {
      "language": "Ukrainian",
      "code": "uk"
  },
  {
      "language": "Urdu",
      "code": "ur"
  },
  {
      "language": "Uzbek",
      "code": "uz"
  }
]
