var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.getShowHeader
    ? _c(
        "header",
        {
          staticClass:
            "md-navbar md-navbar--dense md-navbar--has-menu-button rx-header-animate md-navbar--fixed md-navbar--light md-navbar--casting-border tw-p-0",
          class: { "rx-nav-hide-mobile": !_vm.isAuthenticated },
          attrs: { role: "banner" },
          on: { click: _vm.hideAppSheet }
        },
        [
          _c(
            "hellobar",
            [
              _vm.showPasswordSentBanner
                ? [
                    _c("div", { staticClass: "tw-flex tw-py-4" }, [
                      _c(
                        "div",
                        { staticClass: "tw-flex-grow tw-text-center tw-ml-8" },
                        [
                          _c("span", {
                            staticClass:
                              "raxIcon raxIcon-mail tw-align-bottom tw-text-blue-primary tw-mr-2"
                          }),
                          _vm._v("We have sent a link to\n          "),
                          _c("span", { staticClass: "tw-font-bold" }, [
                            _vm._v("set your password")
                          ]),
                          _vm._v(
                            " on your registered email id.\n          " +
                              _vm._s(
                                _vm.canShowSpecialOffer
                                  ? "Set your password and avail"
                                  : "Please set the password to secure your work on Enago Read."
                              ) +
                              "\n          "
                          ),
                          _vm.canShowSpecialOffer
                            ? _c("span", [
                                _c("strong", [_vm._v("Christmas offer!")])
                              ])
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "tw-justify-self-end tw-ml-auto" },
                        [
                          _c(
                            "i",
                            {
                              staticClass:
                                "material-icons tw-align-bottom tw-cursor-pointer",
                              on: { click: _vm.onClosePasswordSentBanner }
                            },
                            [_vm._v("close")]
                          )
                        ]
                      )
                    ])
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.isAllowedToSeeOfferBanner &&
              _vm.isValidPageToShow &&
              !_vm.showPasswordSentBanner
                ? [
                    _c(
                      "div",
                      {
                        staticClass:
                          "deals tw-bg-cover tw-p-4 tw-w-full tw-text-center tw-flex tw-justify-center",
                        style: {
                          backgroundImage:
                            "url(" + _vm.dealsBackgroundUrl + ")",
                          height: "48px"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "tw-flex tw-flex-col tw-items-center sm:tw-flex-col md:tw-flex-col lg:tw-flex-row lg:tw-justify-center tw-text-white"
                          },
                          [
                            _c("div", [
                              _c(
                                "span",
                                { staticClass: "tw-text-base tw-font-bold" },
                                [_vm._v("Christmas offer")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "tw-text-base tw-font-normal" },
                                [_vm._v("(24 Dec - 25 Dec)")]
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "tw-font-bold tw-mt-2 lg:tw-ml-4 lg:tw-mt-0"
                              },
                              [
                                _vm._v(
                                  "Get 50% flat off on Enago Read annual subscription."
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "tw-cursor-pointer tw-bg-white tw-text-blue-primary tw-rounded-sm tw-py-1 tw-px-4 tw-font-bold tw-text-sm tw-mt-4 lg:tw-ml-4 lg:tw-mt-0",
                                on: { click: _vm.gotoSubscribePageFromDeals }
                              },
                              [_vm._v("Subscribe now")]
                            ),
                            _vm._v(" "),
                            _c(
                              "i",
                              {
                                staticClass:
                                  "material-icons tw-absolute tw-cursor-pointer",
                                staticStyle: { right: "24px" },
                                on: { click: _vm.closeOfferBar }
                              },
                              [_vm._v("close")]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.isAuthenticated &&
              _vm.subscriptionData &&
              (!_vm.haveFullAccess ||
                _vm.trialDaysRemain <= 10 ||
                !_vm.user.emailVerified) &&
              _vm.isValidPageToShow &&
              !_vm.showPasswordSentBanner &&
              !_vm.isAllowedToSeeOfferBanner
                ? [
                    _c("div", [
                      !_vm.user.emailVerified
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "tw-bg-red-lightest tw-text-black-72 tw-flex tw-justify-center tw-items-center tw-h-12"
                              },
                              [
                                _c(
                                  "i",
                                  { staticClass: "material-icons tw-mr-1" },
                                  [_vm._v("info")]
                                ),
                                _vm._v("\n          Please "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "tw-underline tw-mx-1 tw-cursor-pointer",
                                    on: { click: _vm.verifyEmail }
                                  },
                                  [_vm._v("verify your email")]
                                ),
                                _vm._v(
                                  " for uninterrupted and secure usage of Enago Read.\n        "
                                )
                              ]
                            )
                          ])
                        : _c("div", [
                            _vm.haveNoPreviousPaidPlan &&
                            _vm.subscriptionData.isPayer
                              ? _c("div", [
                                  !_vm.isTrial
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "tw-bg-black-6 tw-text-black-72 tw-flex tw-justify-center tw-items-center tw-h-12"
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "material-icons tw-mr-1"
                                            },
                                            [_vm._v("info")]
                                          ),
                                          _vm._v(
                                            "\n            Your free trial has ended. Please "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "tw-text-black-72 tw-mx-1 tw-underline tw-cursor-pointer",
                                              attrs: {
                                                id: "subscribe-trial-end"
                                              },
                                              on: {
                                                click: _vm.gotoSubscribePage
                                              }
                                            },
                                            [_vm._v(" Subscribe")]
                                          ),
                                          _vm._v(
                                            " to continue using all features of Enago Read.\n          "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.isTrial && _vm.trialDaysRemain <= 10
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "tw-text-black-72 tw-flex tw-justify-center tw-items-center tw-h-12"
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "material-icons tw-mr-1"
                                            },
                                            [_vm._v("calendar_today")]
                                          ),
                                          _vm._v(
                                            "\n            Your free trial of Enago Read ends " +
                                              _vm._s(
                                                _vm.trialDaysRemain > 0
                                                  ? "in " +
                                                      _vm.trialDaysRemain +
                                                      " days."
                                                  : "today."
                                              ) +
                                              "  "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "tw-text-blue-primary tw-font-bold tw-mx-1 tw-underline tw-cursor-pointer",
                                              attrs: {
                                                id: "subscribe-trial-days-left"
                                              },
                                              on: {
                                                click: _vm.gotoSubscribePage
                                              }
                                            },
                                            [_vm._v(" Subscribe now")]
                                          ),
                                          _vm._v(
                                            " for uninterrupted use.\n          "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.subscriptionData.isPaymentAttached ||
                            !_vm.haveNoPreviousPaidPlan
                              ? _c("div", [
                                  _vm.subscriptionData.isPaymentFailing &&
                                  !(
                                    _vm.hasPlanExpired &&
                                    !_vm.haveNoPreviousPaidPlan
                                  )
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "tw-bg-yellow-tag-yellow tw-text-black-72 tw-flex tw-justify-center tw-items-center tw-h-12 "
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "tw-flex tw-mr-2" },
                                            [
                                              _c(
                                                "i",
                                                {
                                                  staticClass:
                                                    "material-icons tw-mr-1"
                                                },
                                                [_vm._v("info")]
                                              ),
                                              _vm._v(
                                                "\n              We could not process bill amount. Please update your payment method soon. Account will be freezed after 2 failed attempts within 7 days.\n            "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "tw-cursor-pointer tw-underline",
                                              on: {
                                                click: function($event) {
                                                  return _vm.$modal.show(
                                                    "payment-update"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "Update your payment method"
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.hasPlanExpired &&
                                  !_vm.haveNoPreviousPaidPlan
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "tw-bg-black-6 tw-text-black-72 tw-flex tw-justify-center tw-items-center tw-h-12"
                                        },
                                        [
                                          _c(
                                            "i",
                                            {
                                              staticClass:
                                                "material-icons tw-mr-1"
                                            },
                                            [_vm._v("info")]
                                          ),
                                          _vm._v(
                                            "\n            Your subscription has ended. Please "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "tw-text-black-72 tw-mx-1 tw-underline tw-cursor-pointer",
                                              attrs: {
                                                id: "subscribe-plan-end"
                                              },
                                              on: {
                                                click: _vm.gotoSubscribePage
                                              }
                                            },
                                            [_vm._v(" Subscribe")]
                                          ),
                                          _vm._v(
                                            " to continue using all features of Enago Read.\n          "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ])
                              : _vm._e()
                          ])
                    ])
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.showReferralModal
                ? [
                    _c(
                      "div",
                      {
                        staticClass:
                          "tw-flex tw-text-white tw-font-normal tw-justify-center tw-bg-cover tw-items-center tw-bg-no-repeat tw-bg-center tw-h-12",
                        style: {
                          "background-image":
                            "url(" +
                            _vm.assetsUrl +
                            "img/referral-program/refer-banner.svg?external" +
                            ")"
                        }
                      },
                      [
                        _c("span", [
                          _vm._v(
                            "Refer Enago Read to your friends and get $12 for every successful sign-up."
                          )
                        ]),
                        _c("span", { staticClass: "tw-mx-6" }, [
                          _vm._v("Your friends get $5.")
                        ]),
                        _vm._v(" "),
                        _c("ReferUser", {
                          staticClass: "tw-mx-4",
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "referUserButton",
                                fn: function() {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "tw-px-12 tw-py-1 tw-border-2 tw-border-white tw-text-white tw-cursor-pointer tw-border-solid tw-rounded"
                                      },
                                      [
                                        _vm._v(
                                          "\n              Refer now\n            "
                                        )
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            3922690147
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "tw-flow-right tw-text-white tw-cursor-pointer tw-absolute tw-right-8 tw-text-base",
                            on: { click: _vm.closeReferBanner }
                          },
                          [_vm._v("x")]
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "md-container topHeaderContainer" }, [
            _c(
              "div",
              {
                staticClass: "md-navbar__row",
                class: {
                  "tw-justify-between": _vm.displayLandingNav,
                  menuOuterSection: !_vm.isAuthenticated
                },
                staticStyle: { "margin-bottom": "-1px" }
              },
              [
                _c("bread-crumb"),
                _vm._v(" "),
                !_vm.displayLandingNav
                  ? _c("div", { staticClass: "md-layout-spacer" })
                  : _vm._e(),
                _vm._v(" "),
                _vm.isLibraryAccess &&
                !_vm.isAuthenticated &&
                _vm.routerName !== "discover-library"
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "md-nav md-nav--compact hidden--lt-sm md-nav--color-dark-primary md-align md-align--center-center"
                      },
                      [
                        _c(
                          "nuxt-link",
                          {
                            staticClass: "md-nav__link md-text-primary",
                            attrs: { to: "/discover/library", tag: "a" }
                          },
                          [_vm._v("RC Resources")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.displayLandingNav
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "md-nav hidden--lt-sm md-nav--compact md-nav--color-dark-primary md-align md-align--center-center"
                      },
                      [
                        _vm._l(_vm.navItems, function(item) {
                          return _c(
                            "a",
                            {
                              key: item.title,
                              staticClass: "md-nav__link tw-relative",
                              staticStyle: { "font-weight": "500" },
                              attrs: { href: _vm.landingUrl + item.value }
                            },
                            [
                              item.value === "review-assistant"
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "tw-absolute tw-font-medium tw-border tw-border-solid tw-border-green-primary tw-rounded tw-text-green-primary tw-px-1",
                                      staticStyle: {
                                        top: "5px",
                                        width: "max-content",
                                        "font-size": "10px",
                                        "margin-left": "3px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n            Get early access\n          "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(
                                "\n          " +
                                  _vm._s(item.title) +
                                  "\n        "
                              )
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "resources-parent tw-cursor-pointer" },
                          [
                            _vm._m(0),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                staticClass:
                                  "tw-items-center tw-ml-2 tw-justify-between tw-fixed tw-bg-white tw-text-sm tw-text-gray-500 tw-flex resources-list"
                              },
                              [
                                _vm._m(1),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  {
                                    staticClass:
                                      "hover:tw-bg-black-4 tw-transition-colors tw-duration-300"
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "tw-block tw-py-3 tw-text-black-56 tw-px-4 tw-text-sm tw-font-medium tw-border-b-2 tw-border-transparent",
                                        attrs: {
                                          href: _vm.assetsUrl + "rax-guide.pdf",
                                          rel: "noreferrer noopener",
                                          target: "_blank"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                User Guide\n              "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._m(2)
                              ]
                            )
                          ]
                        )
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showMobileMenu,
                        expression: "showMobileMenu"
                      }
                    ],
                    staticClass:
                      "mobileMenuSection md-nav--color-dark-primary md-align md-align--center-center"
                  },
                  [
                    _vm._l(_vm.navItems, function(item) {
                      return _c(
                        "a",
                        {
                          key: item.title,
                          staticClass: "md-nav__link tw-relative",
                          staticStyle: { "font-weight": "500" },
                          attrs: { href: _vm.landingUrl + item.value }
                        },
                        [
                          item.value === "review-assistant"
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "tw-absolute tw-font-medium tw-border tw-border-solid tw-border-green-primary tw-rounded tw-text-green-primary tw-px-1",
                                  staticStyle: {
                                    top: "5px",
                                    width: "max-content",
                                    "font-size": "10px",
                                    "margin-left": "3px"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            Get early access\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(
                            "\n          " + _vm._s(item.title) + "\n        "
                          )
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "resources-parent tw-cursor-pointer" },
                      [
                        _vm._m(3),
                        _vm._v(" "),
                        _c(
                          "ul",
                          {
                            staticClass:
                              "tw-items-center tw-ml-2 tw-justify-between tw-fixed tw-bg-white tw-text-sm tw-text-gray-500 tw-flex resources-list"
                          },
                          [
                            _vm._m(4),
                            _vm._v(" "),
                            _c(
                              "li",
                              {
                                staticClass:
                                  "hover:tw-bg-black-4 tw-transition-colors tw-duration-300"
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "tw-block tw-py-3 tw-text-black-56 tw-px-4 tw-text-sm tw-font-medium tw-border-b-2 tw-border-transparent",
                                    attrs: {
                                      href: _vm.assetsUrl + "rax-guide.pdf",
                                      rel: "noreferrer noopener",
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                User Guide\n              "
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm._m(5)
                          ]
                        )
                      ]
                    )
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.shouldShowReferAndEarnBtn
                  ? _c("ReferUser", {
                      staticClass: "tw-mx-4",
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "referUserButton",
                            fn: function() {
                              return [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "tw-flex tw-w-max-content tw-justify-center tw-items-center tw-bg-blue-primary tw-rounded-full tw-text-white tw-px-7 tw-py-2",
                                    staticStyle: { height: "36px" }
                                  },
                                  [
                                    _c("img", {
                                      staticStyle: { transform: "scale(0.8)" },
                                      attrs: {
                                        src:
                                          _vm.assetsUrl +
                                          "img/referral-program/announcement-ref.svg"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "tw-text-sm tw-ml-2" },
                                      [_vm._v("Refer & Earn $12")]
                                    )
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        1527971267
                      )
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.shouldShowReferAndEarnBtn &&
                _vm.isAuthenticated &&
                _vm.isMounted &&
                !_vm.reviewAssistantAccess &&
                !_vm.disableBtn
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "tw-text-pink tw-mr-4 tw-normal-case tw-flex tw-items-center tw-border-2 tw-font-medium tw-h-9 tw-px-4 tw-border-solid tw-border-pink md-button hover:tw-text-white hover:tw-bg-pink tw-transition-colors tw-duration-200 tw-cursor-pointer",
                        staticStyle: { "border-radius": "20px" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("tryReview")
                          }
                        }
                      },
                      [_vm._v("\n        Try Review Assistant\n      ")]
                    )
                  : !_vm.shouldShowReferAndEarnBtn &&
                    _vm.isAuthenticated &&
                    _vm.reviewAssistantAccess === "eligible" &&
                    !_vm.disableBtn
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "tw-text-pink tw-mr-4 tw-normal-case tw-flex tw-items-center tw-border-2 tw-font-medium tw-h-9 tw-px-4 tw-border-solid tw-border-pink md-button hover:tw-text-white hover:tw-bg-pink tw-transition-colors tw-duration-200 tw-cursor-pointer",
                        staticStyle: { "border-radius": "20px" },
                        on: { click: _vm.activateReview }
                      },
                      [_vm._v("\n        Activate Review Assistant\n      ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isAuthenticated
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "md-navbar__toolbar layout-m-r-1 md-nav--color-dark-primary"
                      },
                      [
                        _c(
                          "span",
                          {
                            class: {
                              "active-release-notes":
                                _vm.canHighlightReleaseNote,
                              "md-button": true,
                              "release-notes": true
                            },
                            on: { click: _vm.onReleaseNoteClicked }
                          },
                          [
                            _vm.canHighlightReleaseNote
                              ? _c("span", [
                                  _c(
                                    "i",
                                    {
                                      staticClass:
                                        "material-icons md-button__icon"
                                    },
                                    [_vm._v("star_rate")]
                                  )
                                ])
                              : _vm._e(),
                            _vm._v("\n          New updates\n        ")
                          ]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isAuthenticated && !_vm.disableBtn
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "md-navbar__toolbar layout-m-r-1 md-nav--color-dark-primary"
                      },
                      [
                        _c("tooltip", { attrs: { title: "Help" } }, [
                          _c(
                            "button",
                            {
                              staticClass: "md-button md-button--icon",
                              on: { click: _vm.onHelpClicked }
                            },
                            [
                              _c(
                                "i",
                                {
                                  staticClass: "material-icons md-button__icon"
                                },
                                [_vm._v("help_outline")]
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "tooltip",
                          {
                            attrs: { id: "reminder-header", title: "Reminders" }
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "reminder-header-icon md-button md-button--icon tw-ml-4",
                                on: { click: _vm.openReminderDrawer }
                              },
                              [
                                _c(
                                  "i",
                                  {
                                    staticClass:
                                      "material-icons md-button__icon"
                                  },
                                  [_vm._v("alarm")]
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm._m(6)
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isAuthenticated
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "tw-flex tw-items-center tw-mr-4 md:tw-mr-16"
                      },
                      [
                        _c(
                          "nuxt-link",
                          {
                            staticClass:
                              "mobileSignUpForFree tw-h-8 tw-px-4 tw-font-medium tw-text-white tw-text-sm tw-rounded tw-bg-blue-primary",
                            attrs: { to: "/auth/signup", tag: "button" }
                          },
                          [_vm._v("\n          Sign up for free\n        ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "nuxt-link",
                          {
                            staticClass:
                              "mobileSignInButton tw-py-1 tw-h-8 tw-px-4 tw-ml-6 tw-text-sm tw-text-blue-primary tw-rounded-sm tw-border tw-border-solid tw-border-blue-primary",
                            attrs: { to: "/auth/signin", tag: "button" }
                          },
                          [_vm._v("\n          Sign in\n        ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "MobileMenuButton tw-h-8 tw-px-4 tw-font-medium tw-text-white tw-text-sm tw-rounded tw-bg-blue-primary",
                            on: {
                              click: function($event) {
                                _vm.showMobileMenu = !_vm.showMobileMenu
                              }
                            }
                          },
                          [_vm._v("Menu")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isAuthenticated
                  ? _c("profile-btn", {
                      staticClass: "layout-m-l-1",
                      attrs: {
                        icon: _vm.icon,
                        "is-img": _vm.isImg,
                        "img-src": _vm.profileImage
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]),
          _vm._v(" "),
          _vm.subscriptionData && _vm.subscriptionData.isPaymentFailing
            ? _c(
                "modal",
                {
                  attrs: {
                    name: "payment-update",
                    transition: "pop-out",
                    adaptive: true,
                    "focus-trap": true,
                    scrollable: true,
                    height: "auto"
                  }
                },
                [_c("PaymentMethodUpdate")],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "md-nav__link resources-tab tw-flex tw-items-center tw-font-bold"
      },
      [
        _vm._v("\n            Resources\n            "),
        _c("span", {
          staticClass:
            "raxIcon raxIcon-arrow-drop-down raxIcon--black56 tw-transition-transform tw-duration-200"
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "li",
      {
        staticClass: "hover:tw-bg-black-4 tw-transition-colors tw-duration-300"
      },
      [
        _c(
          "a",
          {
            staticClass:
              "tw-block tw-py-3 tw-px-4 tw-text-black-56 tw-text-sm tw-font-medium tw-border-b-2 tw-border-transparent",
            attrs: {
              href: "https://blog.raxter.io/",
              rel: "noreferrer noopener",
              target: "_blank"
            }
          },
          [_vm._v("\n                Blog\n              ")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "li",
      {
        staticClass: "hover:tw-bg-black-4 tw-transition-colors tw-duration-300"
      },
      [
        _c(
          "a",
          {
            staticClass:
              "tw-block tw-py-3 tw-text-black-56 tw-px-4 tw-text-sm tw-font-medium tw-border-b-2 tw-border-transparent",
            attrs: {
              href:
                "https://www.youtube.com/playlist?list=PLeQNr1XWrB0xaDgRN3zgn0ihY9Q1k33p5",
              rel: "noreferrer noopener",
              target: "_blank"
            }
          },
          [_vm._v("\n                Tutorials\n              ")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "md-nav__link resources-tab tw-flex tw-items-center tw-font-bold",
        staticStyle: { position: "relative" }
      },
      [
        _vm._v("\n            Resources\n            "),
        _c("span", {
          staticClass:
            "raxIcon raxIcon-arrow-drop-down raxIcon--black56 tw-transition-transform tw-duration-200"
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "li",
      {
        staticClass: "hover:tw-bg-black-4 tw-transition-colors tw-duration-300"
      },
      [
        _c(
          "a",
          {
            staticClass:
              "tw-block tw-py-3 tw-px-4 tw-text-black-56 tw-text-sm tw-font-medium tw-border-b-2 tw-border-transparent",
            attrs: {
              href: "https://blog.raxter.io/",
              rel: "noreferrer noopener",
              target: "_blank"
            }
          },
          [_vm._v("\n                Blog\n              ")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "li",
      {
        staticClass: "hover:tw-bg-black-4 tw-transition-colors tw-duration-300"
      },
      [
        _c(
          "a",
          {
            staticClass:
              "tw-block tw-py-3 tw-text-black-56 tw-px-4 tw-text-sm tw-font-medium tw-border-b-2 tw-border-transparent",
            attrs: {
              href:
                "https://www.youtube.com/playlist?list=PLeQNr1XWrB0xaDgRN3zgn0ihY9Q1k33p5",
              rel: "noreferrer noopener",
              target: "_blank"
            }
          },
          [_vm._v("\n                Tutorials\n              ")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "hidden md-button md-button--icon",
        attrs: {
          onclick: "$('.md-sheet-layer').toggleClass('md-sheet-layer--visible')"
        }
      },
      [_c("i", { staticClass: "material-icons" }, [_vm._v("notifications")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }