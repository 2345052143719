/*
To use websocket in a component:
1. Create an empty websocket variable in the data

    data() {
        return {
            ...,
            websocket: null,
        }
    }

2. Get the websocket object in the mounted method:

    async mounted() {
        this.websocket = await this.$store.dispatch('websocket/getWebsocket');
    }

3. Add event listeners as follows:

    this.websocket.on(
        eventName: <NAME_OF_EVENT_YOU_WANT_TO_LISTEN_TO>,
        cb: <FUNCTION_CALLED_WHEN_EVENT_RECEIVED>
    )
*/

const defaultWebsocketState = () => {
    return {
        websocket: null
    };
};
export default {
    namespaced: true,
    state: defaultWebsocketState,

    mutations: {
        setSocket(state, data) {
            state["websocket"] = data;
        },
        resetState(state) {
            Object.assign(state, defaultWebsocketState());
        }
    },

    actions: {
        async getWebsocket({ commit, state }) {
            let websocket = state.websocket;
            if (!websocket) {
                let token,rax_token;
                if (this.getters["auth/isAuthenticated"]) {
                    token = this.getters["auth/token"];
                    rax_token = this.getters["auth/raxToken"]
                }
                websocket = new WebSocket(`${process.env.websocketEndpoint}?x-access-token=${token}&x-rax-token=${rax_token}`);
                // websocket = new WebSocket('ws://localhost:4004'); // local

                websocket.onopen = () => {
                    commit("setSocket", websocket);
                    onWebsocketConnect();
                };
                websocket.onerror = onWebsocketError;
                websocket.onmessage = onWebsocketMessage;
                websocket.onclose = (params) => {
                    commit("resetState");
                    onWebsocketDisconnect(params);
                };
                websocket.on = onAddEventListener;
            }
            return websocket;
        },
    },
};

const eventListenerCallbacksMap = new Map();
const eventsWithoutListenersMap = new Map();

function onAddEventListener(eventName, cb) {
    const payload = eventsWithoutListenersMap.get(eventName);
    if (payload) cb(payload);
    else eventListenerCallbacksMap.set(eventName, cb);
}

function onWebsocketConnect() {
    console.log('onopen: Connected successfully.');
}

function onWebsocketError(error) {
    console.log(
        'onerror: An error has occurred. See console for details.',
        error
    );
}

function onWebsocketMessage({ data }) {
    let eventName, payload;
    try {
        const jsonData = JSON.parse(data);
        eventName = jsonData.eventName;
        payload = jsonData.payload;
    } catch (error) {
        console.error('Cannot parse socket response', data);
        return;
    }
    const cb = eventListenerCallbacksMap.get(eventName);

    if (cb) {
        cb(payload);
        eventListenerCallbacksMap.delete(eventName);
    } else {
        eventsWithoutListenersMap.set(eventName, payload)
    }
}

function onWebsocketDisconnect({ wasClean, code, reason }) {
    console.log(
        `onclose: ${JSON.stringify({ wasClean, code, reason })}`
    );
}