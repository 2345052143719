import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']
let store = {}

void (function updateModules() {
  store = normalizeRoot(require('../store/index.js'), 'store/index.js')

  // If store is an exported method = classic mode (deprecated)

  if (typeof store === 'function') {
    return console.warn('Classic mode for store/ is deprecated and will be removed in Nuxt 3.')
  }

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/acsCollections.js'), 'acsCollections.js')
  resolveStoreModules(require('../store/advanceRecommendations.js'), 'advanceRecommendations.js')
  resolveStoreModules(require('../store/aff.js'), 'aff.js')
  resolveStoreModules(require('../store/annotations.js'), 'annotations.js')
  resolveStoreModules(require('../store/auth.js'), 'auth.js')
  resolveStoreModules(require('../store/bookmarks.js'), 'bookmarks.js')
  resolveStoreModules(require('../store/contacts.js'), 'contacts.js')
  resolveStoreModules(require('../store/copilot.js'), 'copilot.js')
  resolveStoreModules(require('../store/core.js'), 'core.js')
  resolveStoreModules(require('../store/dataAnalytics.js'), 'dataAnalytics.js')
  resolveStoreModules(require('../store/draftReferences.js'), 'draftReferences.js')
  resolveStoreModules(require('../store/drafts.js'), 'drafts.js')
  resolveStoreModules(require('../store/experience.js'), 'experience.js')
  resolveStoreModules(require('../store/faculty.js'), 'faculty.js')
  resolveStoreModules(require('../store/feeds.js'), 'feeds.js')
  resolveStoreModules(require('../store/kiAnnotations.js'), 'kiAnnotations.js')
  resolveStoreModules(require('../store/library.js'), 'library.js')
  resolveStoreModules(require('../store/links.js'), 'links.js')
  resolveStoreModules(require('../store/lira.js'), 'lira.js')
  resolveStoreModules(require('../store/literatureReferences.js'), 'literatureReferences.js')
  resolveStoreModules(require('../store/literatures.js'), 'literatures.js')
  resolveStoreModules(require('../store/mixpanel.js'), 'mixpanel.js')
  resolveStoreModules(require('../store/profile.js'), 'profile.js')
  resolveStoreModules(require('../store/profileProjects.js'), 'profileProjects.js')
  resolveStoreModules(require('../store/projectFeeds.js'), 'projectFeeds.js')
  resolveStoreModules(require('../store/projectReferences.js'), 'projectReferences.js')
  resolveStoreModules(require('../store/projects.js'), 'projects.js')
  resolveStoreModules(require('../store/publications.js'), 'publications.js')
  resolveStoreModules(require('../store/referralProgram.js'), 'referralProgram.js')
  resolveStoreModules(require('../store/relatedItems.js'), 'relatedItems.js')
  resolveStoreModules(require('../store/reminders.js'), 'reminders.js')
  resolveStoreModules(require('../store/researchAreas.js'), 'researchAreas.js')
  resolveStoreModules(require('../store/results.js'), 'results.js')
  resolveStoreModules(require('../store/review.js'), 'review.js')
  resolveStoreModules(require('../store/reviewerAnnotations.js'), 'reviewerAnnotations.js')
  resolveStoreModules(require('../store/reviewerDrafts.js'), 'reviewerDrafts.js')
  resolveStoreModules(require('../store/reviewerFeeds.js'), 'reviewerFeeds.js')
  resolveStoreModules(require('../store/reviewerKiAnnotations.js'), 'reviewerKiAnnotations.js')
  resolveStoreModules(require('../store/reviewerLiteratureReferences.js'), 'reviewerLiteratureReferences.js')
  resolveStoreModules(require('../store/reviewerLiteratures.js'), 'reviewerLiteratures.js')
  resolveStoreModules(require('../store/reviewerProjectFeeds.js'), 'reviewerProjectFeeds.js')
  resolveStoreModules(require('../store/reviewerProjects.js'), 'reviewerProjects.js')
  resolveStoreModules(require('../store/reviewerRelatedItems.js'), 'reviewerRelatedItems.js')
  resolveStoreModules(require('../store/reviewerResults.js'), 'reviewerResults.js')
  resolveStoreModules(require('../store/reviewerReview.js'), 'reviewerReview.js')
  resolveStoreModules(require('../store/reviewerSummarizer.js'), 'reviewerSummarizer.js')
  resolveStoreModules(require('../store/reviewerTldr.js'), 'reviewerTldr.js')
  resolveStoreModules(require('../store/reviewerTldrAnnotations.js'), 'reviewerTldrAnnotations.js')
  resolveStoreModules(require('../store/sharedComments.js'), 'sharedComments.js')
  resolveStoreModules(require('../store/sharedDrafts.js'), 'sharedDrafts.js')
  resolveStoreModules(require('../store/sharedItemUsers.js'), 'sharedItemUsers.js')
  resolveStoreModules(require('../store/sharedLiteratures.js'), 'sharedLiteratures.js')
  resolveStoreModules(require('../store/sharedReview.js'), 'sharedReview.js')
  resolveStoreModules(require('../store/sidebar.js'), 'sidebar.js')
  resolveStoreModules(require('../store/skills.js'), 'skills.js')
  resolveStoreModules(require('../store/subscription.js'), 'subscription.js')
  resolveStoreModules(require('../store/suggestions.js'), 'suggestions.js')
  resolveStoreModules(require('../store/summarizer.js'), 'summarizer.js')
  resolveStoreModules(require('../store/tags.js'), 'tags.js')
  resolveStoreModules(require('../store/tldr.js'), 'tldr.js')
  resolveStoreModules(require('../store/tldrAnnotations.js'), 'tldrAnnotations.js')
  resolveStoreModules(require('../store/tourV2.js'), 'tourV2.js')
  resolveStoreModules(require('../store/user.js'), 'user.js')
  resolveStoreModules(require('../store/userSession.js'), 'userSession.js')
  resolveStoreModules(require('../store/websocket.js'), 'websocket.js')

  // If the environment supports hot reloading...

  if (process.client && module.hot) {
    // Whenever any Vuex module is updated...
    module.hot.accept([
      '../store/acsCollections.js',
      '../store/advanceRecommendations.js',
      '../store/aff.js',
      '../store/annotations.js',
      '../store/auth.js',
      '../store/bookmarks.js',
      '../store/contacts.js',
      '../store/copilot.js',
      '../store/core.js',
      '../store/dataAnalytics.js',
      '../store/draftReferences.js',
      '../store/drafts.js',
      '../store/experience.js',
      '../store/faculty.js',
      '../store/feeds.js',
      '../store/index.js',
      '../store/kiAnnotations.js',
      '../store/library.js',
      '../store/links.js',
      '../store/lira.js',
      '../store/literatureReferences.js',
      '../store/literatures.js',
      '../store/mixpanel.js',
      '../store/profile.js',
      '../store/profileProjects.js',
      '../store/projectFeeds.js',
      '../store/projectReferences.js',
      '../store/projects.js',
      '../store/publications.js',
      '../store/referralProgram.js',
      '../store/relatedItems.js',
      '../store/reminders.js',
      '../store/researchAreas.js',
      '../store/results.js',
      '../store/review.js',
      '../store/reviewerAnnotations.js',
      '../store/reviewerDrafts.js',
      '../store/reviewerFeeds.js',
      '../store/reviewerKiAnnotations.js',
      '../store/reviewerLiteratureReferences.js',
      '../store/reviewerLiteratures.js',
      '../store/reviewerProjectFeeds.js',
      '../store/reviewerProjects.js',
      '../store/reviewerRelatedItems.js',
      '../store/reviewerResults.js',
      '../store/reviewerReview.js',
      '../store/reviewerSummarizer.js',
      '../store/reviewerTldr.js',
      '../store/reviewerTldrAnnotations.js',
      '../store/sharedComments.js',
      '../store/sharedDrafts.js',
      '../store/sharedItemUsers.js',
      '../store/sharedLiteratures.js',
      '../store/sharedReview.js',
      '../store/sidebar.js',
      '../store/skills.js',
      '../store/subscription.js',
      '../store/suggestions.js',
      '../store/summarizer.js',
      '../store/tags.js',
      '../store/tldr.js',
      '../store/tldrAnnotations.js',
      '../store/tourV2.js',
      '../store/user.js',
      '../store/userSession.js',
      '../store/websocket.js',
    ], () => {
      // Update `root.modules` with the latest definitions.
      updateModules()
      // Trigger a hot update in the store.
      window.$nuxt.$store.hotUpdate(store)
    })
  }
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function resolveStoreModules(moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const storeModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(storeModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeRoot(moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeState(moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule(moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)
    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function getStoreModule(storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty(storeModule, moduleData, property) {
  if (!moduleData) return

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
