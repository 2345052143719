var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: { height: _vm.pinTagLimitReached ? "400px" : "370px" },
      attrs: { id: "pinned-tags-menu" }
    },
    [
      _c("div", { staticClass: "tw-flex" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.searchKey,
              expression: "searchKey"
            }
          ],
          style: {
            border: "1px solid #" + (_vm.searchKey.length ? "1A64E2" : "DDE2E9")
          },
          attrs: {
            id: "search-pinned-tag",
            type: "text",
            placeholder: "Search Tags"
          },
          domProps: { value: _vm.searchKey },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.searchKey = $event.target.value
              },
              _vm.searchTags
            ],
            change: _vm.searchTags
          }
        }),
        _vm._v(" "),
        _vm.searchKey.length > 0
          ? _c(
              "button",
              {
                staticClass: "md-chip__remove md-button md-button--icon",
                style: { background: "transparent" },
                attrs: { id: "reset-search-button" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.resetSearchKey($event)
                  }
                }
              },
              [
                _c(
                  "i",
                  {
                    staticClass: "md-icon material-icons",
                    style: { color: "#000" },
                    attrs: { id: "reset-search-icon" }
                  },
                  [_vm._v("close")]
                )
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.filteredTagsList.length !== 0
        ? _c(
            "div",
            {
              style: { height: _vm.pinTagLimitReached ? "77%" : "75%" },
              attrs: { id: "search-suggested-tags" }
            },
            [
              _vm.pinTagLimitReached
                ? _c("p", { attrs: { id: "pin-limit-message" } }, [
                    _vm._v(
                      "\n      Pinning limit reached, please remove few pins to add some new.\n    "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("p", { staticClass: "suggested-tags-header tw-my-2" }, [
                _vm._v("\n      Suggested Tags\n    ")
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "tw-flex tw-flex-col tw-m-1",
                  attrs: { id: "search-pinned-tags-list" }
                },
                _vm._l(_vm.filteredTagsList.slice(0, 5), function(tagItem) {
                  return _c(
                    "div",
                    {
                      key: tagItem.name + tagItem.color,
                      staticClass:
                        "tw-flex tw-justify-between tw-items-center tw-w-full search-pin-tag-row",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.togglePinTag(tagItem)
                        }
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: {
                                content: tagItem.name,
                                delay: {
                                  show: 300,
                                  hide: 100
                                },
                                placement: "bottom-start"
                              },
                              expression:
                                "{\n            content: tagItem.name,\n            delay: {\n              show: 300,\n              hide: 100\n            },\n            placement: 'bottom-start'\n          }"
                            }
                          ],
                          staticClass:
                            "tw-items-center tw-justify-center search-pin-tag-item",
                          style: {
                            border:
                              "2px solid " + _vm.chipColors[tagItem.color - 1]
                          }
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(tagItem.name) + "\n        "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", {
                        staticClass:
                          "rax-icon rax-icon-phase2-pin-search-tag tw-mr-2",
                        class: {
                          "rax-icon-phase2-pin-tag --active": _vm.isTagPresent(
                            tagItem
                          )
                        }
                      })
                    ]
                  )
                }),
                0
              )
            ]
          )
        : _c("p", { attrs: { id: "no-suggested-tags-text" } }, [
            _vm._v("\n    No tags found, please search for different tags.\n  ")
          ]),
      _vm._v(" "),
      _c("hr", { staticClass: "tw-mb-2" }),
      _vm._v(" "),
      _c("div", { staticClass: "search-pinned-tags-buttons" }, [
        _c(
          "button",
          {
            staticClass: "search-pinned-tags-button",
            attrs: { id: "pinned-tag-cancel" },
            on: { click: _vm.hideTagsList }
          },
          [_vm._v("\n      Cancel\n    ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "search-pinned-tags-button",
            attrs: { id: "pinned-tag-save" },
            on: { click: _vm.saveSelectedPinnedTags }
          },
          [_vm._v("\n      Save\n    ")]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }