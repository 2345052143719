var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "md-textfield",
      class: { "md-textfield--outline": _vm.outline },
      attrs: { addClass: _vm.addClass }
    },
    [
      _vm._t("surface"),
      _vm._v(" "),
      _vm.type !== "textarea"
        ? [
            _c("input", {
              staticClass: "md-textfield__input",
              class: { italicsPlaceholder: _vm.borderBottom },
              attrs: {
                id: _vm.unid,
                type: _vm.type,
                minlength: _vm.minlength,
                maxlength: _vm.maxlength,
                name: _vm.name,
                readonly: _vm.readonly,
                autofocus: _vm.autofocus,
                placeholder: _vm.placeholder,
                max: _vm.max,
                min: _vm.min,
                required: _vm.required,
                disabled: _vm.disabled,
                pattern: _vm.pattern,
                autocomplete: _vm.autocomplete
              },
              domProps: { value: _vm.value },
              on: {
                keydown: [
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.$emit("enter", $event)
                  },
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                    ) {
                      return null
                    }
                    return _vm.$emit("tab")
                  },
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "up", 38, $event.key, [
                        "Up",
                        "ArrowUp"
                      ])
                    ) {
                      return null
                    }
                    return _vm.$emit("up")
                  },
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "down", 40, $event.key, [
                        "Down",
                        "ArrowDown"
                      ])
                    ) {
                      return null
                    }
                    return _vm.$emit("down")
                  },
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "esc", 27, $event.key, [
                        "Esc",
                        "Escape"
                      ])
                    ) {
                      return null
                    }
                    return _vm.$emit("esc")
                  }
                ],
                input: function($event) {
                  _vm.$emit("input", $event.target.value),
                    _vm.customPDFValidation($event)
                },
                click: _vm.clearInput
              }
            })
          ]
        : [
            _c("textarea", {
              staticClass: "md-textfield__input",
              attrs: {
                id: _vm.unid,
                name: _vm.name,
                disabled: _vm.disabled,
                required: _vm.required === true,
                placeholder: _vm.placeholder,
                rows: _vm.rows,
                autofocus: _vm.autofocus
              },
              domProps: { value: _vm.value },
              on: {
                input: function($event) {
                  return _vm.$emit("input", $event.target.value)
                }
              }
            })
          ],
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "md-textfield__label",
          class: _vm.labelClass,
          attrs: { for: _vm.unid }
        },
        [_vm._v("\n    " + _vm._s(_vm.label) + "\n  ")]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "md-textfield__help-text" }, [
        _vm._v(_vm._s(_vm.helperText))
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "md-textfield__error" }, [
        _vm._v(_vm._s(_vm.errorText))
      ]),
      _vm._v(" "),
      _vm.outline
        ? _c("div", { staticClass: "md-notched" }, [
            _c("svg", [
              _c("path", {
                staticClass: "md-notched__path",
                class: { "no-stroke": _vm.borderBottom }
              })
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.outline
        ? _c("div", {
            staticClass: "md-notched__outline",
            class: { "border-bottom": _vm.borderBottom }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.icon
        ? _c(
            "div",
            {
              staticClass: "md-textfield__icon",
              class: { "md-button md-button--icon": _vm.renderButton },
              on: {
                click: function($event) {
                  return _vm.$emit("iconClick")
                }
              }
            },
            [
              _c("i", { staticClass: "material-icons" }, [
                _vm._v(_vm._s(_vm.icon))
              ])
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }