const defaultBookmarkState = () => {
  return {
    data: []
  };
};

export default {
  namespaced: true,
  state: defaultBookmarkState,

  mutations: {
    setData(state, data) {
      state["data"] = data;
    },
    addData(state, data) {
      state["data"].unshift(data);
    },
    deleteData(state, index) {
      state.data.splice(index, 1);
    },
    resetState(state) {
      Object.assign(state, defaultBookmarkState());
    }
  },

  actions: {

    async changeBookmarksNom({}, data) {
      data.forEach((item) => {
        switch (parseInt(item.type)) {
          case 5:
            item.raw_text = item.text;
            item.newsfeed_source = item.source;
            item.fetch_date = item.date;
            item.source_url = item.url;
            item.type = "news";
            item.doc_id = item.docId;
            item.img = item.media;
            break;
          case 3:
            item.raw_text = item.text;
            item.mooc_source = item.source;
            item.reference_link = item.url;
            item.doc_id = item.docId;
            item.type = "course";
            break;
          case 2:
            item.year = item.published;
            item.raw_text = item.text;
            item.googlescholar_title_link = item.url;
            item.doc_id = item.docId;
            item.type = "paper";
            break;
          case 4:
            item.raw_text = item.text;
            item.newsfeed_source = item.source;
            item.fetch_date = item.date;
            item.source_url = item.url;
            item.doc_id = item.docId;
            item.img = item.media;
            item.type = "lightreads";
            break;
          case 10:
            item.copyright_date = item.published;
            item.date_accessioned = item.date;
            item.source_url = item.url;
            item.doc_id = item.docId;
            item.type = "books";
            break;
          case 11:
            item.copyright_year = item.published;
            item.source_url = item.url;
            item.doc_id = item.docId;
            item.type = "ebooks";
            break;
          case 12:
            item.issued_year = item.date;
            item.source_url = item.url;
            item.raw_text = item.text;
            item.doc_id = item.docId;
            item.type = "thesis";
            break;
          default:
            console.log("default");
        }
      });
      return data;
    },

    async fetch({ commit, dispatch }) {
      const { data } = await this.$axios.get(`/bookmarks`);
      let processedData = await dispatch("changeBookmarksNom", data);
      commit("setData", processedData);
    },

    async literature({ dispatch, state }) {
      if (!state.data.length) {
        await dispatch("fetch");
      }
    },

    async courses({ dispatch, state }) {
      if (!state.data.length) {
        await dispatch("fetch");
      }
    },

    async news({ dispatch, state }) {
      if (!state.data.length) {
        await dispatch("fetch");
      }
    },

    async lightReads({ dispatch, state }) {
      if (!state.data.length) {
        await dispatch("fetch");
      }
    },

    async books({ dispatch, state }) {
      if (!state.data.length) {
        await dispatch("fetch");
      }
    },

    async thesis({ dispatch, state }) {
      if (!state.data.length) {
        await dispatch("fetch");
      }
    },

    async eBooks({ dispatch, state }) {
      if (!state.data.length) {
        await dispatch("fetch");
      }
    },

    async create({ commit, dispatch }, inputs) {
      if (inputs.isbn) {
        inputs.isbn = typeof(inputs.isbn) !== "string"? inputs.isbn[0] : inputs.isbn;
      }
      inputs.raw_text = inputs.raw_text? inputs.raw_text : inputs.text;
      const { data } = await this.$axios.post("/bookmarks", inputs);
      let processedData = await dispatch("changeBookmarksNom", [data]);
      commit("addData", processedData[0]);
    },

    async delete({ commit }, inputs) {
      await this.$axios.delete(`/bookmarks/${inputs["item"].id}`);
      commit("deleteData", inputs.index);
    }

  },

  getters: {
    getData(state) {
      return state.data;
    },
    getLiteratures(state) {
      return state.data.filter((item) => {
        return item.type === "paper";
      });
    },
    getCourses(state) {
      return state.data.filter((item) => {
        return item.type === "course";
      });
    },
    getLightReads(state) {
      return state.data.filter((item) => {
        return item.type === "lightreads";
      });
    },
    getNews(state) {
      return state.data.filter((item) => {
        return item.type === "news";
      });
    },
    getBooks(state) {
      return state.data.filter((item) => {
        return item.type === "books";
      });
    },
    getEBooks(state) {
      return state.data.filter((item) => {
        return item.type === "ebooks";
      });
    },
    getThesis(state) {
      return state.data.filter((item) => {
        return item.type === "thesis";
      });
    },
    getURA(state) {
      return state.userResearchAreas;
    }
  }
};
