var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "md-dialog md-dialog--center-origin",
      class: { "md-dialog--scrollable": _vm.scrollable },
      attrs: { id: _vm.unid }
    },
    [
      _c("div", { staticClass: "md-dialog__shadow" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "md-dialog__surface",
          class: [
            _vm.customClass,
            { "rx-overflow-hidden": !_vm.allowOverflow }
          ],
          style: _vm.customModalStyle
        },
        [
          _vm.header
            ? _c(
                "header",
                {
                  staticClass: "md-dialog__header",
                  style: _vm.customModalHeaderStyle
                },
                [
                  _vm._t("header", [
                    _vm.fullScreen && !_vm.closeOnRightSide
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "md-button md-button--accent md-button--icon md-dialog__header-action-dismissive",
                            attrs: {
                              "data-target": "#" + _vm.unid,
                              type: "button",
                              "data-dismissive": "true",
                              "data-action": "dialog"
                            }
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v(_vm._s(_vm.closeIcon))
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.alert
                      ? _c("div", {
                          staticClass:
                            "md-dialog__header-title md-dialog__header-title-v2",
                          style:
                            "color:" +
                            (_vm.isWhiteHeader ? "white" : "black") +
                            ";",
                          domProps: { innerHTML: _vm._s(_vm.title) }
                        })
                      : _c(
                          "div",
                          { staticClass: "md-dialog__header-subtitle tw-flex" },
                          [
                            _vm.icon
                              ? _c("span", {
                                  staticClass: "tw-block tw-mr-2",
                                  class: _vm.icon
                                })
                              : _vm._e(),
                            _vm._v(
                              "\n          " + _vm._s(_vm.title) + "\n        "
                            )
                          ]
                        ),
                    _vm._v(" "),
                    _vm.fullScreen && _vm.closeOnRightSide
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "md-button md-button--accent tw-justify-end md-button--icon",
                            attrs: {
                              "data-target": "#" + _vm.unid,
                              type: "button",
                              "data-dismissive": "true",
                              "data-action": "dialog"
                            }
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v(_vm._s(_vm.closeIcon))
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "md-layout-spacer" }),
                    _vm._v(" "),
                    _vm.showHeaderButton && !_vm.fullScreen
                      ? _c(
                          "button",
                          {
                            staticClass: "md-button md-button--icon",
                            staticStyle: {
                              "margin-right": "-10px",
                              "margin-top": "-8px"
                            },
                            attrs: {
                              "data-target": "#" + _vm.unid,
                              type: "button",
                              "data-dismissive": "true",
                              "data-action": "dialog"
                            }
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v(_vm._s(_vm.closeIcon))
                            ])
                          ]
                        )
                      : _vm._e()
                  ])
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.body
            ? _c(
                "div",
                {
                  staticClass: "md-dialog__body tw-rounded tw-bg-white",
                  class: { "max-height-25": _vm.modifyMaxHeight },
                  style: _vm.customModalBodyStyle
                },
                [_vm._t("body")],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showConfirm
            ? _c("p", { staticClass: "md-dialog__confirm-text" }, [
                _vm._v(
                  "\n      Are you sure you want to discard your changes?\n    "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.footer
            ? _c(
                "footer",
                { staticClass: "tw-flex tw-items-center" },
                [
                  _vm._t("left-footer"),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "md-dialog__footer tw-w-full",
                      staticStyle: { padding: "16px" }
                    },
                    [
                      _vm._t("footer", [
                        !_vm.disableDismissive
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "md-dialog__action md-button md-button--primary md-button--compact",
                                class: _vm.dismissiveActionClass,
                                staticStyle: {
                                  padding: "5px 16px",
                                  "text-transform": "capitalize",
                                  "font-size": "16px",
                                  height: "auto"
                                },
                                attrs: {
                                  "data-target": "#" + _vm.unid,
                                  "data-dismissive": "true",
                                  "data-action": "dialog"
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.dismissiveActionText) +
                                    "\n          "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("app-button", {
                          staticClass:
                            "md-dialog__action md-button--primary md-button md-button--compact",
                          class: _vm.affirmativeActionClass,
                          attrs: {
                            disabled: _vm.disableAffirmative,
                            "is-loading": _vm.isLoading && _vm.isButtonEnabled,
                            text: _vm.affirmativeActionText,
                            unid: _vm.unid + "-affirmative",
                            "data-target": "#" + _vm.unid,
                            type: "button",
                            "data-affirmative": "true",
                            "data-action": "dialog"
                          },
                          on: { click: function() {} }
                        })
                      ])
                    ],
                    2
                  )
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._t("surface")
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }