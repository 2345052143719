<template>
  <div class="rx-tooltip">
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    position: {
      type: String,
      default: "bottom"
    },
    title: {
      type: String,
      required: true
    },
    autoClose: {
      type: Boolean,
      default: true
    },
    enable: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      $tooltip: null
    };
  },
  watch: {
    enable(newValue) {
      if(newValue) {
        $(this.$el).MaterialTooltip({
          title: this.title,
          position: this.position,
          autoClose: this.autoClose
        });
      } else {
        $(this.$el).MaterialTooltip("destroy");
      }
    }
  },
  mounted() {
    if(this.enable) {
      $(this.$el).MaterialTooltip({
        title: this.title,
        position: this.position,
        autoClose: this.autoClose
      });
    }
  },
  beforeDestroy() {
    $(this.$el).MaterialTooltip("destroy");
  }
};
</script>
