<template>
  <div class="md-panel">
    <header v-if="header" class="md-panel__header">
      <div class="md-layout md-flex--grow md-align md-align--start-center">
        <slot name="header">
          <h3 class="md-panel__header-title">{{ title }}</h3>
        </slot>
        <div class="md-layout-spacer" />
        <slot name="button" />
      </div>
    </header>
    <div class="md-panel__body">
      <slot name="body" />
    </div>
    <slot name="surface" />
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    header: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: null
    }
  }
};
</script>
