import Vuex from "vuex";
import socket from '@/plugins/socket.io';
import mixpanel from "./mixpanel";
import acsCollections from "./acsCollections";
import aff from "./aff";
import auth from "./auth";
import bookmarks from "./bookmarks";
import contacts from "./contacts";
import core from "./core";
import dataAnalytics from "./dataAnalytics";
import draftReferences from "./draftReferences";
import drafts from "./drafts";
import reviewerDrafts from "./reviewerDrafts";
import experience from "./experience";
import faculty from "./faculty";
import feeds from "./feeds";
import reviewerFeeds from "./reviewerFeeds";
import library from "./library";
import links from "./links";
import literatureReferences from "./literatureReferences";
import reviewerLiteratureReferences from "./reviewerLiteratureReferences";
import literatures from "./literatures";
import reviewerLiteratures from "./reviewerLiteratures";
import reviewerTldr from "./reviewerTldr";
import profile from "./profile";
import profileProjects from "./profileProjects";
import projectFeeds from "./projectFeeds";
import reviewerProjectFeeds from "./reviewerProjectFeeds";
import projectReferences from "./projectReferences";
import projects from "./projects";
import reviewerProjects from "./reviewerProjects";
import publications from "./publications";
import relatedItems from "./relatedItems";
import reviewerRelatedItems from "./reviewerRelatedItems";
import researchAreas from "./researchAreas";
import results from "./results";
import reviewerResults from "./reviewerResults";
import review from "./review";
import reviewerReview from "./reviewerReview";
import sharedComments from "./sharedComments";
import sharedDrafts from "./sharedDrafts";
import sharedItemUsers from "./sharedItemUsers";
import sharedLiteratures from "./sharedLiteratures";
import sharedReview from "./sharedReview";
import skills from "./skills";
import tags from "./tags";
import summarizer from "./summarizer";
import reviewerSummarizer from "./reviewerSummarizer";
import tldr from "./tldr";
import user from "./user";
import subscription from "./subscription";
import annotations from "./annotations";
import reviewerAnnotations from "./reviewerAnnotations";
import kiAnnotations from "./kiAnnotations";
import tldrAnnotations from "./tldrAnnotations";
import reviewerKiAnnotations from "./reviewerKiAnnotations";
import reviewerTldrAnnotations from "./reviewerTldrAnnotations";
import reminders from "./reminders";
import websocket from "./websocket";
import referralProgram from "./referralProgram";
import userSession from "./userSession";
import suggestions from "./suggestions";
import tourV2 from "./tourV2";
import advanceRecommendations from "./advanceRecommendations";
import sidebar from "./sidebar";
import lira from "./lira";
import copilot from "./copilot";
const store = () =>
  new Vuex.Store({
    modules: {
      auth,
      bookmarks,
      contacts,
      core,
      user,
      researchAreas,
      profileProjects,
      profile,
      aff,
      links,
      skills,
      experience,
      faculty,
      feeds,
      library,
      literatures,
      publications,
      draftReferences,
      literatureReferences,
      projects,
      reviewerProjects,
      reviewerLiteratures,
      reviewerTldr,
      reviewerReview,
      reviewerSummarizer,
      reviewerResults,
      reviewerFeeds,
      reviewerAnnotations,
      reviewerDrafts,
      reviewerRelatedItems,
      reviewerKiAnnotations,
      reviewerTldrAnnotations,
      reviewerProjectFeeds,
      reviewerLiteratureReferences,
      results,
      sharedDrafts,
      sharedItemUsers,
      sharedLiteratures,
      sharedReview,
      drafts,
      review,
      sharedComments,
      acsCollections,
      dataAnalytics,
      projectFeeds,
      projectReferences,
      tags,
      summarizer,
      tldr,
      relatedItems,
      subscription,
      annotations,
      kiAnnotations,
      tldrAnnotations,
      reminders,
      websocket,
      referralProgram,
      userSession,
      suggestions,
      tourV2,
      advanceRecommendations,
      sidebar,
      lira,
      mixpanel,
      copilot
    },
    actions: {
      async nuxtServerInit({ getters, dispatch }, { req, $sentry }) {
        try {
          dispatch("auth/initAuth", req);
          socket.disconnect();
          let userIp =
            req.headers["x-forwarded-for"].split(",")[0] ||
            req.connection.remoteAddress ||
            req.socket.remoteAddress;
          await dispatch("library/setUserIP", userIp);
          await dispatch("core/fetchReleaseNotesData");
          if (getters["auth/isAuthenticated"]) {
            await dispatch("subscription/fetch");
            await dispatch("subscription/fetchPlanList");
            await dispatch("profile/fetchProfile");
            await dispatch("user/setUser");
            await dispatch("aff/fetch");
            await dispatch("researchAreas/fetch");
            await dispatch("core/fetchLibraryInfo");
            await dispatch("core/fetchTours");
          }
        } catch (error) {
          $sentry.captureException(error);
        }

      }
    }
  });

export default store;
