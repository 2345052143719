var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("notifications", {
        staticClass: "mb-not html-snackbar",
        attrs: { group: "message-note" }
      }),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "tw-overflow-hidden tw-h-screen tw-relative" },
        [
          _c(
            "div",
            {
              staticClass:
                "tw-flex tw-justify-between tw-items-end tw-px-6 tw-py-2 tw-z-20 tw-bg-white"
            },
            [
              _c(
                "a",
                { attrs: { href: "https://read.enago.com/" } },
                [_c("appLogo", { staticStyle: { height: "28px" } })],
                1
              ),
              _vm._v(" "),
              _c("span", {
                staticClass: "raxIcon raxIcon-menu tw-cursor-pointer",
                on: { click: _vm.openMobileDrawer }
              })
            ]
          ),
          _vm._v(" "),
          _c("mobile-drawer"),
          _vm._v(" "),
          _c("section", [_c("nuxt")], 1)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }