export default ({ app }) => {
  app.router.afterEach((to, from) => {
    if (process.client) {
      const staticTrackedRoutes = ['/auth/signin', '/auth/signup'];
      const dynamicRoutePattern = /^\/?projects\/[^/]+\/literatures$/;

      if (staticTrackedRoutes.includes(to.path) || (from.path === '/auth/signup' && dynamicRoutePattern.test(to.path))) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'customPageview',
          page: to.path,
        });
      }
    }
  });
};
