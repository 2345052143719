var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "eof-left-panel", class: { open: _vm.isLeftPanelOpen } },
    [
      _c(
        "div",
        { staticClass: "EOFLeftPanelContents" },
        [
          _c("div", { staticClass: "allProjectText" }, [
            _c(
              "a",
              {
                class: {
                  active: _vm.selectedTab == "all"
                },
                on: { click: _vm.onAllClicked }
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "16",
                      height: "16",
                      viewBox: "0 0 16 16",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        "fill-rule": "evenodd",
                        "clip-rule": "evenodd",
                        d:
                          "M2.16667 0.506348C1.24619 0.506348 0.5 1.25254 0.5 2.17301V5.50635C0.5 6.42682 1.24619 7.17302 2.16667 7.17302H5.5C6.42047 7.17302 7.16667 6.42682 7.16667 5.50635V2.17301C7.16667 1.25254 6.42047 0.506348 5.5 0.506348H2.16667ZM2.16667 2.17301H5.5V5.50635H2.16667V2.17301Z",
                        fill: "#414E62"
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        "fill-rule": "evenodd",
                        "clip-rule": "evenodd",
                        d:
                          "M10.5 0.506348C9.57952 0.506348 8.83333 1.25254 8.83333 2.17301V5.50635C8.83333 6.42682 9.57952 7.17302 10.5 7.17302H13.8333C14.7538 7.17302 15.5 6.42682 15.5 5.50635V2.17301C15.5 1.25254 14.7538 0.506348 13.8333 0.506348H10.5ZM10.5 2.17301H13.8333V5.50635H10.5V2.17301Z",
                        fill: "#414E62"
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        "fill-rule": "evenodd",
                        "clip-rule": "evenodd",
                        d:
                          "M0.5 10.4938C0.5 9.57336 1.24619 8.82717 2.16667 8.82717H5.5C6.42047 8.82717 7.16667 9.57336 7.16667 10.4938V13.8272C7.16667 14.7476 6.42047 15.4938 5.5 15.4938H2.16667C1.24619 15.4938 0.5 14.7476 0.5 13.8272V10.4938ZM5.5 10.4938H2.16667V13.8272H5.5V10.4938Z",
                        fill: "#414E62"
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        "fill-rule": "evenodd",
                        "clip-rule": "evenodd",
                        d:
                          "M10.5 8.82717C9.57952 8.82717 8.83333 9.57336 8.83333 10.4938V13.8272C8.83333 14.7476 9.57952 15.4938 10.5 15.4938H13.8333C14.7538 15.4938 15.5 14.7476 15.5 13.8272V10.4938C15.5 9.57336 14.7538 8.82717 13.8333 8.82717H10.5ZM10.5 10.4938H13.8333V13.8272H10.5V10.4938Z",
                        fill: "#414E62"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  { staticStyle: { position: "relative", top: "2px" } },
                  [_vm._v("All")]
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("hr", { attrs: { id: "projects-divider" } }),
          _vm._v(" "),
          _c("div", { staticClass: "createNewProjectSection" }, [
            _c(
              "a",
              {
                attrs: { href: "javascript:void(0)" },
                on: { click: _vm.openCreateProject }
              },
              [
                _c(
                  "svg",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: {
                          content: "Create New Project",
                          placement: "bottom"
                        },
                        expression:
                          "{content: 'Create New Project', placement:'bottom'}"
                      }
                    ],
                    attrs: {
                      width: "12",
                      height: "12",
                      viewBox: "0 0 12 12",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M6.66699 1.32878C6.66699 0.960586 6.36852 0.662109 6.00033 0.662109C5.63214 0.662109 5.33366 0.960586 5.33366 1.32878V5.33378H1.33366C0.965469 5.33378 0.666992 5.63226 0.666992 6.00045C0.666992 6.36864 0.965469 6.66711 1.33366 6.66711H5.33366V10.6621C5.33366 11.0303 5.63214 11.3288 6.00033 11.3288C6.36852 11.3288 6.66699 11.0303 6.66699 10.6621V6.66711H10.667C11.0352 6.66711 11.3337 6.36864 11.3337 6.00045C11.3337 5.63226 11.0352 5.33378 10.667 5.33378H6.66699V1.32878Z",
                        fill: "#414E62"
                      }
                    })
                  ]
                ),
                _vm._v(" "),
                _c("span", [_vm._v("Projects")])
              ]
            )
          ]),
          _vm._v(" "),
          !_vm.isProjectFetching
            ? _c(
                "ul",
                _vm._l(_vm.filterdItemList, function(item, index) {
                  return _c(
                    "li",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: {
                            content: _vm.getProjectTooltip(item),
                            html: true,
                            placement: "bottom-start"
                          },
                          expression:
                            "{\n          content: getProjectTooltip(item),\n          html:true,\n          placement: 'bottom-start'\n        }"
                        }
                      ],
                      key: "project-picker-" + index,
                      class: {
                        active: _vm.selectedTab == item.title
                      },
                      on: {
                        click: function($event) {
                          return _vm.onClicked(item)
                        }
                      }
                    },
                    [
                      _c("span", [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "20",
                              height: "18",
                              viewBox: "0 0 20 18",
                              fill: "none",
                              xmlns: "http://www.w3.org/2000/svg"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                "fill-rule": "evenodd",
                                "clip-rule": "evenodd",
                                d:
                                  "M3 2.00781C2.73478 2.00781 2.48043 2.11317 2.29289 2.30071C2.10536 2.48824 2 2.7426 2 3.00781V14.0078C2 14.273 2.10536 14.5274 2.29289 14.7149C2.48043 14.9025 2.73478 15.0078 3 15.0078H17C17.2652 15.0078 17.5196 14.9025 17.7071 14.7149C17.8946 14.5274 18 14.273 18 14.0078V6.00781C18 5.7426 17.8946 5.48824 17.7071 5.30071C17.5196 5.11317 17.2652 5.00781 17 5.00781H10C9.73478 5.00781 9.48043 4.90246 9.29289 4.71492L6.58579 2.00781H3ZM0.87868 0.886492C1.44129 0.323883 2.20435 0.0078125 3 0.0078125H7C7.26522 0.0078125 7.51957 0.113169 7.70711 0.300706L10.4142 3.00781H17C17.7957 3.00781 18.5587 3.32388 19.1213 3.88649C19.6839 4.4491 20 5.21216 20 6.00781V14.0078C20 14.8035 19.6839 15.5665 19.1213 16.1291C18.5587 16.6917 17.7957 17.0078 17 17.0078H3C2.20435 17.0078 1.44129 16.6917 0.87868 16.1291C0.31607 15.5665 0 14.8035 0 14.0078V3.00781C0 2.21216 0.31607 1.4491 0.87868 0.886492Z",
                                fill: "#414E62"
                              }
                            })
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("a", [
                        _vm._v(
                          "\n          " + _vm._s(item.title) + "\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "md-button md-button--icon",
                          attrs: { "data-autofocus": "false" },
                          on: {
                            click: function($event) {
                              return _vm.openMenu($event, item)
                            }
                          }
                        },
                        [
                          _c(
                            "i",
                            { staticClass: "material-icons no-user-select" },
                            [_vm._v("more_vertical")]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "app-menu",
                        {
                          attrs: { unid: item.id.toString() },
                          on: {
                            selected: function($event) {
                              return _vm.onSelectedProjectMenu($event, item)
                            }
                          }
                        },
                        [
                          _c(
                            "template",
                            { slot: "body" },
                            _vm._l(_vm.menu, function(m) {
                              return _c(
                                "button",
                                {
                                  key: m.title,
                                  staticClass: "md-menu__item md-button",
                                  attrs: {
                                    type: "button",
                                    "data-value": m.value,
                                    disabled: m.disabled
                                  }
                                },
                                [
                                  _c("span", {
                                    class:
                                      "rax-icon rax-icon-v2-" +
                                      (m.icon || m.value) +
                                      " rax-icon-phase1-" +
                                      (m.icon || m.value) +
                                      " --hover project-menu-option"
                                  }),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(m.title) +
                                      "\n            "
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                }),
                0
              )
            : _c("shimmerList")
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "EOF_newProjectSection" }, [
        _c("ul", [
          _c(
            "li",
            {
              on: {
                click: function($event) {
                  return _vm.closeTopMenu("Project_Archives")
                }
              }
            },
            [
              _c(
                "nuxt-link",
                {
                  class: { active: _vm.selectedTab == "archive" },
                  attrs: { to: "/projects/archive" }
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "12",
                        height: "12",
                        viewBox: "0 0 12 12",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M1.33333 12C0.966667 12 0.652667 11.8696 0.391333 11.6087C0.130444 11.3473 0 11.0333 0 10.6667V2.33333C0 2.16667 0.0277778 2.01667 0.0833333 1.88333C0.138889 1.75 0.211111 1.62222 0.3 1.5L1.23333 0.366667C1.32222 0.244444 1.43333 0.152667 1.56667 0.0913334C1.7 0.0304445 1.84444 0 2 0H10C10.1556 0 10.3 0.0304445 10.4333 0.0913334C10.5667 0.152667 10.6778 0.244444 10.7667 0.366667L11.7 1.5C11.7889 1.62222 11.8611 1.75 11.9167 1.88333C11.9722 2.01667 12 2.16667 12 2.33333V10.6667C12 11.0333 11.8696 11.3473 11.6087 11.6087C11.3473 11.8696 11.0333 12 10.6667 12H1.33333ZM1.6 2H10.4L9.83333 1.33333H2.16667L1.6 2ZM1.33333 10.6667H10.6667V3.33333H1.33333V10.6667ZM6 9.71667C6.08889 9.71667 6.17222 9.70267 6.25 9.67467C6.32778 9.64711 6.4 9.6 6.46667 9.53333L8.2 7.8C8.32222 7.67778 8.38333 7.52222 8.38333 7.33333C8.38333 7.14444 8.32222 6.98889 8.2 6.86667C8.07778 6.74444 7.92222 6.68333 7.73333 6.68333C7.54444 6.68333 7.38889 6.74444 7.26667 6.86667L6.66667 7.46667V5.33333C6.66667 5.14444 6.60289 4.986 6.47533 4.858C6.34733 4.73044 6.18889 4.66667 6 4.66667C5.81111 4.66667 5.65289 4.73044 5.52533 4.858C5.39733 4.986 5.33333 5.14444 5.33333 5.33333V7.46667L4.73333 6.86667C4.61111 6.74444 4.45556 6.68333 4.26667 6.68333C4.07778 6.68333 3.92222 6.74444 3.8 6.86667C3.67778 6.98889 3.61667 7.14444 3.61667 7.33333C3.61667 7.52222 3.67778 7.67778 3.8 7.8L5.53333 9.53333C5.6 9.6 5.67222 9.64711 5.75 9.67467C5.82778 9.70267 5.91111 9.71667 6 9.71667ZM1.33333 10.6667V3.33333V10.6667Z",
                          fill: "#414E62"
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Archived")])
                ]
              )
            ],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { id: "pinned-tag-list-menu" } },
        [
          _vm.searchTagsVisible
            ? _c("search-pinned-tags", {
                on: { hideTagsList: _vm.hideTagsList }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }