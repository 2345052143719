<template>
  <app-dialog
    :unid="unid"
    :alert="alert"
    :title="title"
    :body="isShowBody"
    :disable-dismissive="disableDismissive"
    :config="{ button: false }"
    :affirmative-action-text="affirmativeActionText"
    :dismissive-action-text="dismissiveText"
    :icon="icon"
    class="md-dialog--alert"
    @dismissive="onDismissive"
    @affirmative="onAffirmative"
  >
    <template slot="body">
      {{ body }}
    </template>
  </app-dialog>
</template>
<script>
export default {
  props: {
    alert: {
      type: Boolean,
      default: true
    },
    disableDismissive: {
      type: Boolean,
      default: true
    },
    body: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: "Something went wrong or unable to connect"
    },
    unid: {
      type: String,
      required: true
    },
    affirmativeActionText:{
      type: String,
      default: "ok"
    },
    icon:{
      type: String,
      default: null
    }
  },
  data() {
    return {
      affirmativeText: "ok",
      dismissiveText: "cancel"
    };
  },
  computed: {
    isShowBody() {
      return this.body !== "";
    }
  },
  mounted() {
    $(`#${this.unid}`).on("hidden.ca.dialog", function() {
      $(this).off("onAffirmative");
      $(this).off("onDismissive");
    });
  },
  methods: {
    onAffirmative() {
      $(`#${this.unid}`).MaterialDialog("hide");
    },
    onDismissive() {
      $(`#${this.unid}`).MaterialDialog("hide");
    }
  }
};
</script>
