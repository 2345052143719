import findObjectIndex from "@/assets/js/commonHelper";
import arraySearch from "@/assets/js/arraySearch";
import Vue from "vue";
import { SORT_BY } from "~/components/literature/constants";

const defaultDraftState = () => {
  return {
    data: [],
    currentIndex: 0,
    tabs: [],
    headingId: {},
    outlineHeadings: [],
    currentSort: SORT_BY.AccessedAt
  };
};
export default {
  namespaced: true,
  state: defaultDraftState,

  mutations: {
    setData(state, data) {
      state["data"] = data;
    },
    addData(state, data) {
      state["data"].unshift(data);
    },
    addDataAfter(state, data) {
      state["data"].push(data);
    },
    updateData(state, obj) {
      state.data.splice(obj.index, 1, obj.data);
    },
    updateDataPosition(state, data) {
      let index = findObjectIndex(state.data, data);
      state.data.splice(index, 1);
      state.data.unshift(data);
      Vue.set(state["data"][state.currentIndex], "accessedAt", data.accessedAt);
    },
    deleteData(state, index) {
      state.data.splice(index, 1);
    },
    setCurrentData(state, data) {
      state.currentData = data;
    },
    setTab(state, data) {
      state["tabs"] = data;
    },
    addTab(state, data) {
      state["tabs"].push(data);
    },
    updateTab(state, obj) {
      let index = findObjectIndex(state.data, { id: obj.data.id });
      state.tabs.splice(obj.index, 1, state.data[index]);
    },
    deleteTab(state, index) {
      state.tabs.splice(index, 1);
    },
    setParchmentHeadingId(state, data) {
      state["headingId"] = data;
    },
    updateOutlineHeading(state, obj) {
      Vue.set(state.outlineHeadings[obj.index], obj.key, obj.value);
    },
    updateOutlineSubHeading(state, obj) {
      Vue.set(state.outlineHeadings[obj.outlineIndex]["subHeads"][obj.index], obj.key, obj.value);
    },
    resetState(state) {
      Object.assign(state, defaultDraftState());
    },
    setCurrentSort(state, data) {
      state.currentSort = data;
    }
  },

  actions: {
    async fetch({ commit }, obj) {
      const { data } = await this.$axios.get(`/reviewer-drafts/${obj.slug}?withTags=true&withContacts=true`);
      commit("setData", data);
    },

    async fetchArchived({ commit }, obj) {
      const { data } = await this.$axios.get(`/reviewer-drafts/${obj.slug}?withTags=true&archived=true&withContacts=true`);
      commit("setData", data);
    },

    async archive({ commit, state }, inputs) {
      const { data } = await this.$axios.post(`/reviewer-drafts/archive`, inputs)
      let index = findObjectIndex(state.data, data);
      commit("updateData", { data, index: index });
    },

    async onInvite({ commit, state }, _data) {
      const data = { ..._data, status: "1", isOwner: 1 };
      let index = findObjectIndex(state.data, data);
      commit("updateData", { data, index: index });
    },

    async fetchExceptExits({ commit, state }, obj) {
      const { data } = await this.$axios.get(`/reviewer-drafts/${obj.slug}?withTags=true&withContacts=true`);
      let filterArray = data.filter((item) => {
        return !arraySearch(state.data, item.id);
      });
      for (let i = 0; i < filterArray.length; i++) {
        commit("addDataAfter", filterArray[i]);
      }
    },

    async update({ commit, state }, inputs) {
      if (!inputs.title) {
        inputs.title = inputs.content.substring(0,64) + '...';
      }
      delete inputs.references;
      const { data } = await this.$axios.put(`/reviewer-drafts?withTags=true&withContacts=true`, inputs);
      let index = findObjectIndex(state.data, inputs);
      commit("updateData", { data: data[0], index: index });
    },

    async create({ commit }, inputs) {
      const { data } = await this.$axios.post("/reviewer-drafts", inputs);
      commit("addData", data);
      return data; 
    },

    async delete({ commit, state }, inputs) {
      await this.$axios.delete(`/reviewer-drafts/${inputs.id}`);
      let index = findObjectIndex(state.data, inputs);
      commit("deleteData", index);
    },

    closeTabs({ commit, state }, item) {
      let index = findObjectIndex(state.tabs, item);
      commit("deleteTab", index);
    },

    async updateOutline({ commit, dispatch, state }) {
      let unusedElements = $(".ql-editor p, .ql-editor h6, .ql-editor h5, .ql-editor h4, .ql-editor h3");
      unusedElements.map((m) => {
        if (unusedElements[m].id) {
          state.headingId.remove(unusedElements[m]);
        }
      });
      let el = $(".ql-editor h1, .ql-editor h2");
      let headNumbers = 0;
      state.outlineHeadings.forEach(function(i) {
        headNumbers += i.subHeads.length;
      });
      headNumbers += this.state.drafts.outlineHeadings.length;
      if (headNumbers !== el.length) {
        dispatch("registerNewHeadings");
      } else {
        el.map((m) => {
          let currentText, outlineText, outlineButton;
          if (el[m].localName === "h1") {
            currentText = el[m].innerText;
            outlineButton = $("[data-heading='" + el[m].getAttribute("id") + "']");
            outlineText = outlineButton.find(".rx-outline__content")[0].innerText;
            if (currentText !== outlineText) {
              commit("updateOutlineHeading", {
                key: "heading",
                value: currentText,
                index: outlineButton.attr("data-index")
              });
            }
          }
          if (el[m].localName === "h2") {
            currentText = el[m].innerText;
            outlineButton = $("[data-subheading='" + el[m].getAttribute("id") + "']");
            outlineText = outlineButton.find(".rx-outline__content")[0].innerText;
            if (currentText !== outlineText) {
              commit("updateOutlineSubHeading", {
                key: "subHeading",
                value: currentText,
                outlineIndex: outlineButton.attr("data-index"),
                index: outlineButton.attr("data-subindex")
              });
            }
          }
        });
      }
    },

    async registerNewHeadings({ state, dispatch }) {
      let c = $(".ql-editor h1, .ql-editor h2");
      let y = c.filter(function(f) {
        return c[f].id === "";
      });

      function setHeadingId() {
        return new Promise(resolve => {
          if (!y.length) {
            resolve();
          }
          y.each(async (index) => {
            let newId = null;
            if (y[index].localName === "h1") {
              newId = await dispatch("idGenerator");
              state.headingId.add(y[index], newId);
            } else {
              newId = await dispatch("idGenerator");
              state.headingId.add(y[index], newId);
            }
            if (y.length - 1 === index) {
              resolve();
            }
          });
        });
      }

      await setHeadingId();
      if ($(".ql-editor [id]").length) {
        dispatch("assignNavHeadings");
      }
    },

    async idGenerator() {
      let x = (new Date()).toISOString().replace(/[:,.,-]/g, "");
      return x.substr(0, 9) + (parseInt(x.substr(9, 9)) + (Math.floor(Math.random() * (100000 - 20 + 1)) + 25));
    },

    assignNavHeadings({ state, dispatch }) {
      state.outlineHeadings = [];
      let q = $(".ql-editor [id]");
      if (!q.length) {
        setTimeout(() => {
          dispatch("registerNewHeadings");
        }, 3000);
        return false;
      }
      let c = 0;
      q.map((m) => {
        if (q[m].innerText.replace(/\s/g, "") !== "" && q[m].innerHTML !== "<br>" && q[m].id !== "") {
          if (c === 0 && q[m].localName !== "h1") {
            return true;
          } else {
            c = 1;
          }
          if (q[m].localName === "h1") {
            state.outlineHeadings.push({
              "heading": q[m].innerText,
              "headNavId": m,
              "subHeadHolderId": m,
              "headingId": q[m].id,
              "subHeads": []
            });
          } else if (q[m].localName === "h2") {
            state.outlineHeadings[state.outlineHeadings.length - 1].subHeads.push({
              "subHeading": q[m].innerText,
              "subHeadingId": q[m].id
            });
          }
        }
      });
    },

    async addHeading({ commit, state, dispatch }, obj) {
      let end = obj.editorInstance.getLength();
      let newId = await dispatch("idGenerator");
      obj.editorInstance.clipboard.dangerouslyPasteHTML(end, "<h1 id='" + newId + "'>" + obj.value + "</h1>");
      let q = $(".ql-editor h1");
      state.headingId.add(q[q.length - 1], newId);
    },

    addSubHeading({ commit, state, dispatch }, obj) {
      let outline = obj.outline;
      let editorInstance = obj.editorInstance;
      let allHeadings = $(".ql-editor h1");
      allHeadings.map(async (item) => {
        if (allHeadings[item].id === (outline.headingId)) {
          let newId = await dispatch("idGenerator");
          if (typeof(allHeadings[item + 1]) !== "undefined") {
            $("#" + allHeadings[item + 1].id).before("<h2 id='" + newId + "'>" + obj.value + "</h2>");
          } else {
            let end = editorInstance.getLength();
            editorInstance.clipboard.dangerouslyPasteHTML(end, "<h2 id='" + newId + "'>" + obj.value + "</h2>");
          }
        }
      });
      let allSubHeading = $(".ql-editor h2");
      allSubHeading.map(async (item) => {
        if (allSubHeading[item].id === "") {
          let newId = await dispatch("idGenerator");
          state.headingId.add(allSubHeading[item], newId);
        }
      });
    },

    addTags({ commit, state }, obj) {
      let index = findObjectIndex(state.data, {id: obj.inputs.itemId})
      obj.data.forEach((item) => {
        state.data[index].tags.push({...item, id: item.tagId})
      })
    },

    updateTags({ commit, state }, obj) {
      let index = findObjectIndex(state.data, {id: obj.inputs.id})
      if (index !== -1) {
        const index_ = findObjectIndex(state.data[index].tags, {id: obj.data.id})
        index_!== -1 && (state.data[index].tags[index_] = obj.data)
      }
    },

    deleteTags({ commit, state }, obj) {
      let index = findObjectIndex(state.data, {id: obj.inputs.id})
      if (index !== -1) {
        const index_ = findObjectIndex(state.data[index].tags, {id: obj.data.id})
        index_!== -1 && state.data[index].tags.splice(index_, 1)
      }
    },

    async createCopy({ commit }, inputs) {
      const { data } = await this.$axios.post('/reviewer-draft/copy', inputs)
      commit("addData", data);
    },
    async updateSortData({ commit, dispatch, state }, data){
      commit('setCurrentSort', data);
    },
  },

  getters: {
    getData(state) {
      return state.data;
    },
    getCurrentData(state) {
      return state.currentData;
    },
    getTabs(state) {
      return state.tabs;
    },
    getOutlineHeadings(state) {
      return state.outlineHeadings;
    },
    getCurrentSort(state) {
      return state.currentSort;
    }
  }
};
