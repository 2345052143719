import Vue from "vue";

Vue.mixin({
  data() {
    return {
      my_global_config: "This"
    };
  },
  methods: {
    foo: function() {
      console.log("foo");
    },
    conflicting: function() {
      console.log("from mixin");
    }
  }
});
