var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    { staticClass: "md-radio", attrs: { id: _vm.unid, for: _vm.inputId } },
    [
      _c("input", {
        staticClass: "md-radio__button md-radio__input",
        attrs: {
          id: _vm.inputId,
          name: _vm.name,
          required: _vm.required,
          type: "radio"
        },
        domProps: { value: _vm.value, checked: _vm.isChecked }
      }),
      _vm._v(" "),
      _c("span", { staticClass: "md-radio__label" }, [
        _vm._v(_vm._s(_vm.label))
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }