<template>
  <div
    :id="unid"
    :class="{ 'md-dialog--scrollable': scrollable }"
    class="md-dialog md-dialog--center-origin"
  >
    <div class="md-dialog__shadow" />
    <div
      class="md-dialog__surface"
      :class="[customClass, {'rx-overflow-hidden': !allowOverflow}]"
      :style="customModalStyle"
    >
      <header
        v-if="header"
        class="md-dialog__header"
        :style="customModalHeaderStyle"
      >
        <slot name="header">
          <button
            v-if="fullScreen && !closeOnRightSide"
            :data-target="`#${unid}`"
            type="button"
            data-dismissive="true"
            data-action="dialog"
            class="md-button md-button--accent md-button--icon md-dialog__header-action-dismissive"
          >
            <i class="material-icons">{{ closeIcon }}</i>
          </button>
          <div
            v-if="!alert"
            class="md-dialog__header-title md-dialog__header-title-v2"
            :style="`color:${isWhiteHeader?'white':'black'};`"
            v-html="title"
          />
          <div v-else class="md-dialog__header-subtitle tw-flex" >
            <span v-if="icon" :class="icon" class="tw-block tw-mr-2"/>
            {{ title }}
          </div>
          <button
            v-if="fullScreen && closeOnRightSide"
            :data-target="`#${unid}`"
            type="button"
            data-dismissive="true"
            data-action="dialog"
            class="md-button md-button--accent tw-justify-end md-button--icon"
          >
            <i class="material-icons">{{ closeIcon }}</i>
          </button>
          <div class="md-layout-spacer" />
          <button
            v-if="showHeaderButton && !fullScreen"
            :data-target="`#${unid}`"
            type="button"
            style="margin-right: -10px;margin-top: -8px;"
            data-dismissive="true"
            data-action="dialog"
            class="md-button md-button--icon"
          >
            <i class="material-icons">{{ closeIcon }}</i>
          </button>
        </slot>
      </header>
      <div
        v-if="body" class="md-dialog__body tw-rounded tw-bg-white"
        :class="{'max-height-25' : modifyMaxHeight}"
        :style="customModalBodyStyle"
      >
        <slot name="body" />
      </div>
      <p v-if="showConfirm" class="md-dialog__confirm-text">
        Are you sure you want to discard your changes?
      </p>
      <footer v-if="footer" class="tw-flex tw-items-center">
        <slot name="left-footer" />
        <div class="md-dialog__footer tw-w-full" style="padding: 16px;">
          <slot name="footer">
            <button
              v-if="!disableDismissive"
              :class="dismissiveActionClass"
              :data-target="`#${unid}`"
              class="md-dialog__action md-button md-button--primary md-button--compact"
              data-dismissive="true"
              data-action="dialog"
              style="padding: 5px 16px; text-transform: capitalize;font-size: 16px;height: auto; "
            >
              {{ dismissiveActionText }}
            </button>
            <app-button
              :disabled="disableAffirmative"
              :class="affirmativeActionClass"
              :is-loading="isLoading && isButtonEnabled"
              :text="affirmativeActionText"
              :unid="unid + '-affirmative'"
              :data-target="`#${unid}`"
              class="md-dialog__action md-button--primary md-button md-button--compact"
              type="button"
              data-affirmative="true"
              data-action="dialog"
              @click="() => {}"
            />
          </slot>
        </div>
      </footer>
      <slot name="surface" />
    </div>
  </div>
</template>

<style>
.body-dialog-open {
  transition-property: none !important;
  overflow-y: hidden;
}

.md-dialog__confirm-text {
  padding: 8px 24px 0;
  text-align: right;
}

.tw-text-blue-primary > .md-textfield__input {
  color: #1A64E2;
}
.md-dialog__header-title-v2 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #111A27;
}
</style>

<script>
export default {
  props: {
    fullScreen: {
      type: Boolean,
      default: false
    },
    showHeaderButton: {
      type: Boolean,
      default: false
    },
    alert: {
      type: Boolean,
      default: false
    },
    isButtonEnabled: {
      type: Boolean,
      default: false
    },
    header: {
      type: Boolean,
      default: true
    },
    body: {
      type: Boolean,
      default: true
    },
    footer: {
      type: Boolean,
      default: true
    },
    closeOnRightSide: {
      type: Boolean,
      default: false
    },
    disableDismissive: {
      type: Boolean,
      default: false
    },
    disableAffirmative: {
      type: Boolean,
      default: false
    },
    showConfirm: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    unid: {
      type: String,
      required: true
    },
    affirmativeActionClass: {
      type: String,
      default: ""
    },
    dismissiveActionClass: {
      type: String,
      default: ""
    },
    affirmativeActionText: {
      type: String,
      default: "CONTINUE"
    },
    allowOverflow: {
      type: Boolean,
      default: false
    },
    dismissiveActionText: {
      type: String,
      default: "CANCEL"
    },
    title: {
      type: String,
      default: null
    },
    scrollable: {
      type: Boolean,
      default: false
    },
    modifyMaxHeight: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default: function() {
        return {};
      }
    },
    closeIcon: {
      type: String,
      default: "close"
    },
    customClass: {
      type: String,
      default: ""
    },
    customModalStyle: {
      type: String,
      default: ""
    },
    customModalBodyStyle: {
      type: String,
      default: ""
    },
    customModalHeaderStyle: {
      type: String,
      default: ""
    },
    isWhiteHeader:{
      type:Boolean,
      default:false
   },
    icon:{
      type:String,
      default:null
    }
  },
  data() {
    return {
      defaultConfig: {
        show: false,
        backdrop: false
      }
    };
  },
  mounted() {
    const config = {
      ...this.defaultConfig,
      ...this.config
    };
    const dialog = $(`#${this.unid}`);
    dialog.MaterialDialog(config);
    dialog
      .on("affirmative.ca.dialog", () => {
        this.$emit("affirmative", {});
      })
      .on("dismissive.ca.dialog", () => {
        this.$emit("dismissive", {});
      });
  },
  beforeDestroy() {
    $(`#${this.unid}`)
      .off("affirmative.ca.dialog")
      .off("dismissive.ca.dialog")
      .MaterialDialog("destroy");
  }
};
</script>

<style scoped>
.max-height-25 {
  max-height: 25rem;
}
</style>
