import { mapState, mapMutations } from "vuex";
import { ActionItems, AnnotationType, AnnotationTypeEnum, AnnotationTypeMap, SummaryTypes } from "~/components/literature/constants";
import { copyToClipboard, createAnnotationTag, generateHighlightId, selectionFromDoms } from "~/components/literature/helper";

import { delay } from '~/assets/js/commonHelper';
const summaryAnnotations = {
  data() {
    return {
      highlighterQuick: null,
      highlighterDetail: null,
      noteContent: '',
      currentNoteCreatedAt: null,
      resetHighlighterInstance:false
    };
  },
  computed: {
    ...mapState({
      getSectionToAnnotateFromRange(state, getters) {
        return getters[`${this.annotationStore}/getSectionToAnnotateFromRange`];
      },
      getSectionToNoteFromRange(state, getters) {
        return getters[`${this.annotationStore}/getSectionToNoteFromRange`];
      },
      getAnnotationToRemove(state, getters) {
        return getters[`${this.annotationStore}/getAnnotationToRemove`];
      },
      selectedActionType(state, getters) {
        return getters[`${this.annotationStore}/selectedActionType`];
      },
      getContextActionClick(state, getters) {
        return getters[`${this.annotationStore}/getContextActionClick`];
      },
      selectedAnnotationIdForAction(state, getters) {
        return getters[`${this.annotationStore}/selectedAnnotationIdForAction`];
      },
      getCurrentNoteAnnotation(state, getters) {
        return getters[`${this.annotationStore}/getCurrentNoteAnnotation`];
      },
      highlightData(state, getters) {
        return getters[`${this.annotationStore}/getAnnotations`];
      },
      range(state, getters) {
        return getters[`${this.annotationStore}/getRange`];
      },
    }),
    selectedHighlighter() {
      if (this.annotationStore == 'tldrAnnotations') {
        return this.highlighterQuick;
      }
      return this.showQuickRead && !this.noQuickReadData ? this.highlighterQuick : this.highlighterDetail;
    },
    sectionAnnotationData() {
      const sectionKey = this.data.sectionKey;
      return this.highlightData
        ? this.highlightData.filter(
            (j) => j.sectionKey === sectionKey
          )
        : [];
    },
  },
  watch: {
    sectionAnnotationData(val) {
      if (val && this.selectedLevel === 0) {
        this.highlightFromStore(val);
      }
    },
    getContextActionClick(val) {
      if (val && this.selectedLevel === 0) {
        const selectedHighlightData = this.highlightData.find(k => k.hId == val.annotationId);
        if (
          selectedHighlightData &&
          selectedHighlightData.sectionKey === this.data.sectionKey
        ) {
          this.onActionItemClick(val.actionType);
        }
      }
    },
    getSectionToAnnotateFromRange(val) {
      // {
      //   range: data
      //   sectionKey
      // }
      if (
        val &&
        this.selectedLevel === 0 &&
        val.sectionKey === this.data.sectionKey
      ) {
        if (this.selectedHighlighter) {
          this.selectedHighlighter.fromRange(val.range);
        }
      }
    },
    getAnnotationToRemove(val) {
      if (
        val &&
        this.selectedLevel === 0 &&
        val.sectionKey === this.data.sectionKey
      ) {
        this.resetHighlighterInstance = false;
        this.selectedHighlighter.remove(val.hId);
      }
    },
  },
  methods: {
    ...mapMutations({
      setAnnotationIdForAction(commit, payload) {
        return commit(`${this.annotationStore}/setAnnotationIdForAction`, payload)
      },
      setSelectedActionType(commit, payload) {
        return commit(`${this.annotationStore}/setSelectedActionType`, payload)
      },
      setSelectionHighlightData(commit, payload) {
        return commit(`${this.annotationStore}/setSelectionHighlightData`, payload)
      },
      updateRange(commit, payload) {
        return commit(`${this.annotationStore}/updateRange`, payload)
      },
      setForPinAnnotation(commit, payload) {
        return commit(`${this.annotationStore}/setForPinAnnotation`, payload)
      },
      setForAttachmentAnnotation(commit, payload) {
        return commit(`${this.annotationStore}/setForAttachmentAnnotation`, payload)
      },
      setForUrlAnnotation(commit, payload) {
        return commit(`${this.annotationStore}/setForUrlAnnotation`, payload)
      },
      setFlagForHighlightUpdateTo(commit, payload) {
        return commit(`${this.annotationStore}/setFlagForHighlightUpdateTo`, payload)
      },
      setForNoteAnnotation(commit, payload) {
        return commit(`${this.annotationStore}/setForNoteAnnotation`, payload)
      },
      setCreatedCommentData(commit, payload) {
        return commit(`${this.annotationStore}/setCreatedCommentData`, payload)
      }
    }),
    async highlightFromStore(val) {
      if (!this.selectedHighlighter) {
        this.initHighlighter();
      }
      await delay(300);
      val.forEach(item => {
        const existingHighlight = this.selectedHighlighter.getDoms(item.hId) || [];
        if (this.annotationStore == 'tldrAnnotations' && existingHighlight.length === 0) {
          this.selectedHighlighter.fromStore(item.startMeta,
            item.endMeta,
            item.text,
            item.hId,
            AnnotationTypeMap[item.type]);
            return;
        }

        if (this.showQuickRead && !this.noQuickReadData ? item.summaryType === SummaryTypes.Overview : item.summaryType === SummaryTypes.Detailed && this.selectedHighlighter &&
          existingHighlight.length === 0) {

          this.selectedHighlighter.fromStore(item.startMeta,
            item.endMeta,
            item.text,
            item.hId,
            AnnotationTypeMap[item.type]);
        }
      });
    },
    async reRenderAnnotations() {
      const data = this.showQuickRead && !this.noQuickReadData ? this.quickReadData : this.data;
      if (data.hasSub) {
        // check in subBucketData
        const statements = data.subBucketData.reduce((acm, item) => {
          console.log(item);
          return [...acm, ...item.data];
        }, []);
        const statementKeys = statements.map((k) => k.key +'');
        const dataToBeAnnotated = this.highlightData.filter((j) => {
          return statementKeys.includes(j.statementKey);
        });
        const self = this;
        setTimeout(() => {
          self.highlightFromStore(dataToBeAnnotated);
        }, 200);
      } else {
        const statementKeys = data.data.map((k) => k.key + '');
        const dataToBeAnnotated = this.highlightData.filter((j) => {
          return statementKeys.includes(j.statementKey);
        });
        const self = this;
        setTimeout(() => {
          self.highlightFromStore(dataToBeAnnotated);
        }, 100);
      }
    },
    async initHighlighter() {
      this.resetHighlighterInstance = true;
      if (this.highlighterQuick) {
        this.highlighterQuick.removeAll();

      }
      if (this.highlighterDetail) {
        this.highlighterDetail.removeAll();
      }
      var Highlighter = await require("web-highlighter");

      const highlighterQuick = new Highlighter({
        $root: this.annotationStore == 'tldrAnnotations' ? this.$refs.summaryContainerDetail:this.$refs.summaryContainerQuick,
        exceptSelectors: [
          "#context-menu-container.summary",
          "#context-action-container.summary",
          "#context-menu-note-container.summary",
          ".pdf-viewer-header",
          ".pinned-statement",
          `.quick_read`,
        ],
        wrapTag: "span",
        style: {
          className: 'RAx_KI_highlight_wrap',
        },
      });
      this.attachEvents(highlighterQuick);
      this.highlighterQuick = highlighterQuick;
      if (this.annotationStore == 'tldrAnnotations') {
        setTimeout(() => {
          this.resetHighlighterInstance = false;
        }, 300);
        return;
      }

      const highlighterDetail = new Highlighter({
        $root: this.$refs.summaryContainerDetail,
        exceptSelectors: [
          "#context-menu-container.summary",
          "#context-action-container.summary",
          "#context-menu-note-container.summary",
          ".pdf-viewer-header",
          ".pinned-statement",
          `.detail_read`,
        ],
        wrapTag: "span",
        style: {
          className: 'RAx_KI_highlight_wrap',
        },
      });
      this.attachEvents(highlighterDetail);
      this.highlighterDetail = highlighterDetail;
      setTimeout(() => {
        this.resetHighlighterInstance = false;
      }, 300);
    },
    attachEvents(highlighter) {
      highlighter.on("selection:error", (d) => {
        alert(JSON.stringify(d));
      });
      highlighter
        .on("selection:click", ({ id }) => {
          // Add delay to avoid opening two context menu and action menu when there are selection on highlighted node.
          setTimeout(() => {
            this.handleClickEventOnAnnotation(id);
          }, 420);
        })
        .on("selection:hover", ({ id }) => {
          const doms = document.querySelectorAll(
            `.RAx_KI_highlight_wrap[data-highlight-id='${id}']`
          );
          for (let index = 0; index < doms.length; index++) {
            const element = doms[index];
            const segments = id.split("__");
            //if statement for older pinned items, newer ones data-title is already "connect"
            if (segments[1] === "pin") {
              element.setAttribute("data-title", "connect");
            } else {
              element.setAttribute("data-title", segments[1]);
            }
            element.classList.add("pdf-tooltip-block");
          }
        })
        .on("selection:hover-out", ({ id }) => {
          highlighter.removeClass("pdf-tooltip-block", id);
        })
        .on("selection:create", ({ sources, type, events }) => {
          sources.forEach((s) => {
            const doms = highlighter.getDoms(s.id);
            const position = doms[doms.length - 1].getBoundingClientRect();
            createAnnotationTag(
              doms[doms.length - 1],
              position.height,
              position.left,
              s.id,
              type !== "from-input" ? s.extra : this.selectedActionType,
              this.handleClickEventOnAnnotation.bind(this)
            );
            if (
              this.selectedActionType !== AnnotationType.Connect ||
              this.selectedActionType !== AnnotationType.Attachment
            ) {
              this.setSelectedActionType("");
            }
          });
          if (type === "from-input") {
            // Add highlight data to store
            sources
              .map((i) => {
                const doms = highlighter.getDoms(i.id);
                return {
                  startMeta: i.startMeta,
                  endMeta: i.endMeta,
                  text: selectionFromDoms(doms).replace(/\s+/g, " "),
                  hId: i.id,
                  type: i.extra,
                  type: AnnotationTypeMap[i.extra],
                };
              })
              .forEach(async (j) => {
                const data = await this.$store.dispatch(
                  `${this.annotationStore}/create`,
                  j
                );
                this.$emit('annotationCreated',data)
              });
          }
        })
        .on("selection:remove", ({ ids }) => {
          ids.forEach(async (id) => {
            // class="annotation-icon highlight raxIcon rax-Pin"
            const element = this.$el.querySelectorAll(
              `.annotation-icon[data-id='${id}']`
            );
            if (element.length) {
              for (let index = 0; index < element.length; index++) {
                const ele = element[index];
                ele.parentNode.removeChild(ele);
              }
            }
            const doms = this.$el.querySelectorAll(
              `.RAx_KI_highlight_wrap[data-highlight-id='${id}']`
            );
            if (doms.length) {
              for (let index = 0; index < doms.length; index++) {
                const element = doms[index];
                const segments = id.split("__");
                element.classList.remove(segments[1]);
              }

            }
            if (!this.resetHighlighterInstance) {
              await this.$store.dispatch(`${this.annotationStore}/delete`, id);
              // this.initHighlighter();
            }
          });
        });

      highlighter.hooks.Render.UUID.tap((start, end, text) => {
        //TODO do something to generate your own id consider filename or pageNo
        const id = String(Math.random()).slice(2);
        const newId = generateHighlightId(id, this.selectedActionType, "KI");
        return newId;
      });
      highlighter.hooks.Render.WrapNode.tap((id, node) => {
        // TODO: Null case handling, get type of selection from id
        const segments = id.split("__");
        // Add class for different highlight style based on annotation type
        if (node && segments[1]) {
          node.classList.add(segments[1]);
        }
        return node;
      });

      highlighter.hooks.Serialize.RecordInfo.tap(() => {
        return this.selectedActionType;
      });
    },
    handleClickEventOnAnnotation(id) {
      this.$emit('clickOnAnnotation', id);
    },
    async onActionItemClick(actionType) {
      // {actionType:selectionAction, annotationId:this.selectedAnnotationIdForAction}
      switch (actionType) {
        case ActionItems.Delete:
          // set for remove
          // this.setContextActionClick({actionType:selectionAction, annotationId:this.selectedAnnotationIdForAction});
          this.selectedHighlighter.remove(this.selectedAnnotationIdForAction);
          this.setAnnotationIdForAction(null);
          break;
        case ActionItems.Copy:
          const doms = this.selectedHighlighter.getDoms(this.selectedAnnotationIdForAction);
          let text = selectionFromDoms(doms).replace(/\s+/g, ' ');
          copyToClipboard(text);
          this.setAnnotationIdForAction(null);
          // Delete selected highlight item
          break;
        case ActionItems.Comment:
          let getCurrentItemToBeConvertedMsg = this.highlightData.find(i => i.hId === this.selectedAnnotationIdForAction);
          if (!getCurrentItemToBeConvertedMsg) {
            return;
          }
          this.setFlagForHighlightUpdateTo(true);
          this.setSelectionHighlightData(getCurrentItemToBeConvertedMsg),
          this.$emit('onCreateComment', getCurrentItemToBeConvertedMsg.text);
          // get selected text and open comment panel
          // Add new data in highlight for comment.
          // update the type and repaint everything.
          // Delete selected highlight item
          // Add from source
          break;
        case ActionItems.Note:
          // Add new data to note
          // Delete selected highlight item
          // update type with content
          let getCurrentItemToBeConverted = this.highlightData.find(i => i.hId === this.selectedAnnotationIdForAction);
          let id = String(Math.random()).slice(2);
          let newId = generateHighlightId(id + '', AnnotationType.Note);
          let hsData = {
            startMeta: getCurrentItemToBeConverted.startMeta,
            endMeta: getCurrentItemToBeConverted.endMeta,
            text: getCurrentItemToBeConverted.text,
            content: '',
            literatureId: getCurrentItemToBeConverted.literatureId,
            type: AnnotationTypeEnum.note,
            hId: newId
          }
          // remove highlightId
          this.selectedHighlighter.remove(this.selectedAnnotationIdForAction);
          await delay(500);
          let data = await this.$store.dispatch(`${this.annotationStore}/create`, hsData);
          this.selectedHighlighter.fromStore(
            data.startMeta,
            data.endMeta,
            data.text,
            data.hId,
            AnnotationTypeMap[data.type]
          );
          this.$emit('annotationCreated',data);
          break;
        case ActionItems.Url:
          // Add new data to url attachments
          // Delete selected highlight item
          // update type with content
          getCurrentItemToBeConverted = this.highlightData.find(i => i.hId === this.selectedAnnotationIdForAction);
          id = String(Math.random()).slice(2);
          newId = generateHighlightId(id + 'ki', AnnotationType.Url);
          hsData = {
            startMeta: getCurrentItemToBeConverted.startMeta,
            endMeta: getCurrentItemToBeConverted.endMeta,
            text: getCurrentItemToBeConverted.text,
            content: '',
            literatureId: getCurrentItemToBeConverted.literatureId,
            type: AnnotationTypeEnum.url,
            hId: newId
          }
          // remove highlightId
          this.selectedHighlighter.remove(this.selectedAnnotationIdForAction);
          data = await this.$store.dispatch(`${this.annotationStore}/create`, hsData);
          this.$emit('annotationCreated',data);
          this.setForUrlAnnotation(data);
          this.selectedHighlighter.fromStore(
            data.startMeta,
            data.endMeta,
            data.text,
            data.hId,
            AnnotationTypeMap[data.type]
          );
          setTimeout(() => {
            // TODO: emit event
            this.$emit('openUrlComponent', data.id);
          }, 20);
          break;
        default:
          break;
      }
    },

  }
};
export default summaryAnnotations;
