import findObjectIndex from "@/assets/js/commonHelper";

const defaultLiteratureRefState = () => {
  return {
    data: [],
    fetched: false
  };
};
export default {
  namespaced: true,
  state: defaultLiteratureRefState,

  mutations: {
    setData(state, data) {
      state["data"] = data;
    },
    addData(state, data) {
      state["data"].unshift(data);
    },
    updateData(state, obj) {
      state.data.splice(obj.index, 1, obj.data);
    },
    setFetched(state, data) {
      state["fetched"] = data;
    },
    deleteData(state, index) {
      state.data.splice(index, 1);
    },
    resetState(state) {
      Object.assign(state, defaultLiteratureRefState());
    }
  },

  actions: {
    async fetch({ commit, state, dispatch }, obj) {
      const { data } = await this.$axios.get(
        `/references/?${obj.key}=${obj.slug}&withTags=true`
      );
      commit("setData", data);
      dispatch("setAddedToLiteratures");
    },

    async update({ commit, state }, inputs) {
      const { data } = await this.$axios.put(
        `/references?withTags=true`,
        inputs
      );
      let index = findObjectIndex(state.data, inputs);
      commit("updateData", { data: data[0], index: index });
    },

    async create({ commit }, inputs) {
      inputs.isAttached = 1;
      inputs["docId"] = inputs["id"];
      const { data } = await this.$axios.post("/references", inputs);
      commit("addData", data);
    },

    async createByUrl({ commit }, inputs) {
      const { data } = await this.$axios.post("/references/url/import", inputs);
      commit("addData", data);
    },

    async delete({ commit, state }, inputs) {
      await this.$axios.delete(`/references/${inputs.id}`);
      let index = findObjectIndex(state.data, inputs);
      commit("deleteData", index);
    },

    async setAddedToLiteratures({ state }) {
      state.data.forEach(ref => {
        this.state.literatures.titleList.forEach(f => {
          if (ref && f.title.toLowerCase() === ref.title.toLowerCase()) {
            ref.isAddedLiterature = true;
          }
        });
      });
    },

    addTags({ commit, state }, obj) {
      let index = findObjectIndex(state.data, { id: obj.inputs.itemId });
      obj.data.forEach(item => {
        state.data[index].tags.push({ ...item, id: item.tagId });
      });
    },

    deleteTags({ commit, state }, obj) {
      let index = findObjectIndex(state.data, { id: obj.inputs.id });
      if (index !== -1) {
        const index_ = findObjectIndex(state.data[index].tags, {
          id: obj.data.id
        });
        index_ !== -1 && state.data[index].tags.splice(index_, 1);
      }
    }
  },

  getters: {
    getData(state) {
      return state.data;
    },

    getCount(state) {
      return state.data.length;
    },

    getFetched(state) {
      return state.fetched;
    }
  }
};
