export default function ({ route }) {
  if (process.client) {
    const staticTrackedRoutes = ['/signin', '/signup'];
    const dynamicRoutePattern = /^\/?projects\/[^/]+\/literatures$/;

    if (staticTrackedRoutes.includes(route.path) || dynamicRoutePattern.test(route.path)) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'customPageview',
        page: route.path,
      });
    }
  }
}
