<template>
  <div
    class="md-checkbox md-checkbox--primary"
    :class="{ 'md-checkbox--disabled': disabled }"
  >
    <input
      :id="inputId"
      :checked="isChecked"
      :value="value"
      type="checkbox"
      class="md-checkbox__input"
    />
    <div class="md-checkbox__background">
      <svg class="md-checkbox__checkmark" viewBox="0 0 24 24">
        <path
          class="md-checkbox__checkmark__path"
          fill="none"
          stroke="white"
          d="M1.73,12.91 8.1,19.28 22.79,4.59"
        />
      </svg>
      <div class="md-checkbox__mixedmark" />
    </div>
    <label :id="unid" :for="inputId" class="md-checkbox__label">
      {{ label }}
    </label>
  </div>
</template>
<style>
.md-checkbox {
  margin-right: 0;
}
</style>
<script>
export default {
  props: {
    unid: {
      type: String,
      required: true
    },
    isChecked: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number],
      default: ""
    },
    label: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputId: function() {
      return "input-" + this.unid;
    }
  },
  watch: {
    isChecked(val) {
      if (val && !$(this.$el).hasClass("md-checkbox--checked")) {
        $(this.$el).addClass("md-checkbox--checked");
      } else if (!val && $(this.$el).hasClass("md-checkbox--checked")) {
        $(this.$el).removeClass("md-checkbox--checked");
      }
    }
  },
  mounted() {
    const checkbox = $(this.$el);
    checkbox.MaterialCheckbox();
    checkbox.on("changed.ca.checkbox", (e, data) => {
      this.$emit("changed", data.checked);
    });
  },
  destroy() {
    $(`#${this.unid}`)
      .off("changed.ca.radio")
      .MaterialRadio("destroy");
  }
};
</script>
