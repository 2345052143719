var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-dialog",
    {
      staticClass: "md-dialog--alert",
      attrs: {
        unid: _vm.unid,
        alert: _vm.alert,
        title: _vm.title,
        body: _vm.isShowBody,
        "disable-dismissive": _vm.disableDismissive,
        config: { button: false },
        "affirmative-action-text": _vm.affirmativeActionText,
        "dismissive-action-text": _vm.dismissiveText,
        icon: _vm.icon
      },
      on: { dismissive: _vm.onDismissive, affirmative: _vm.onAffirmative }
    },
    [
      _c("template", { slot: "body" }, [
        _vm._v("\n    " + _vm._s(_vm.body) + "\n  ")
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }