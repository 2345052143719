const defaultSummaryState = () => ({
  data: {
    feedbackSubmitted: false,
    status: 2,  // Failed:0,  Success:1,  InProgress:2,
    data: null,
    remainingKeyInsights: 0,
    keyInsightsUsed: 0,
  },
  currentLiteratureData: null,
  openWithSummary: false,
  isFetching: false,
  pinnedItems:[
  ],
  selectedDataToUnpin: null,
  isPinnedSectionVisible: false,
  selectedStatementInView: null,
  summaryResultsShowing: null,
  selectedStatement: null,
});

export default {
  namespaced: true,
  state: defaultSummaryState,


  mutations: {
    setSelectedStatementInView(state, data){
      // this.$set(state.selectedStatementInView, data);
      state.selectedStatementInView = data;
    },
    setLastPickedStatement(state, payload){
      state.selectedStatement = payload;
    },
    setSelectedDataToUnpin(state, payload){
      state.selectedDataToUnpin = payload;
    },
    setSummaryResultsShowing(state, data){
      state.summaryResultsShowing = data;
    },
    setData(state, data) {
      state.data = data;
    },
    insertNewPin(state, data){
      state.pinnedItems.unshift(data);
    },
    setPinData(state, data) {
      state.pinnedItems = data;
    },
    setIsPinnedVisible(state, data) {
      state.isPinnedSectionVisible = data;
    },
    setOpenWithSummary(state, payload){
      state.openWithSummary = payload;
    },
    isFetching(state, payload){
      state.isFetching = payload;
    },
    setCurrentLiteratureData(state, data){
      state.currentLiteratureData = data;
    },
    feedbackProvided(state, payload){
      state.data.feedbackSubmitted = payload;
    },
    removePinById(state, pinId){
       // delete pin
       const pinIndex = state.pinnedItems.findIndex(j=> j.id == pinId);
       if (pinIndex > -1) {
         state.pinnedItems.splice(pinIndex, 1);
       }
    },
    resetState(state) {
      Object.assign(state, defaultSummaryState());
    }
  },

  actions: {
    async fetch({ commit, state, dispatch }, source) {
      commit('isFetching', true);
      try {
        let { data } = await this.$axios.get(`reviewer-summarizer/${state.currentLiteratureData.id}/${source}`);

        // store has been reset therefore no need to update
        if (!state.currentLiteratureData) {
          return;
        }
        // data is success then mark as read
        if (data.status === 1 && !state.currentLiteratureData.hasReadSummary) {
          commit('reviewerLiteratures/updateLiteratureKeyInsightFetched', state.currentLiteratureData, { root: true });
          if (this.state.user.data && this.state.user.data.flags &&this.state.user.data.flags['viewSummaryTips']) {
            dispatch('user/doNotShowTooltip', {type: 'viewSummaryTips', data: false}, { root: true });
          }
        }

        commit("setData", data);
        commit('isFetching', false);
        return data;
      } catch (error) {
        console.log(error);
        commit("setData", {
          feedbackSubmitted: false,
          status: 0,
          data: null
        });
        commit('isFetching', false);
      }
    },
    async fetchPinnedData({ commit, state }){
      try {
        let { data } = await this.$axios.get(`reviewer-summarizer/pin/${state.currentLiteratureData.id}`);
        commit("setPinData", data);
        return data;
      } catch (error) {
        commit("setPinData", []);
        return [];
      }

    },
    async createPin({ state, commit }){
      try {
        const literatureId = state.currentLiteratureData.id;
        let { data } = await this.$axios.put(`reviewer-summarizer/pin/create`, { ...state.selectedStatement, literatureId });
        document && document.getSelection().empty();

        commit('insertNewPin', data);
        console.log(data);
      } catch (error) {
        console.log(error);

      }
    },
    async deletePin({ state, commit }, pinId){
      let selectedPinId = pinId;
      if (!pinId && state.selectedStatement) {
        const selectedPinStatement = state.pinnedItems.find(j=> j.statementKey == state.selectedStatement.statementKey);
        if (selectedPinStatement) {
          selectedPinId = selectedPinStatement.id
        }else{
          return;
        }
      }

      try {
        let { data } = await this.$axios.delete(`reviewer-summarizer/pin/${selectedPinId}`);
        commit('removePinById', selectedPinId);
        document && document.getSelection().empty();
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    },
    async recordFeedback({ commit, state }, payload) {
      try {
        let { data } = await this.$axios.post(`/reviewer-summarizer/feedback`, {
          rating:payload,
          givenOnLiterature: state.currentLiteratureData.id
          });
        setTimeout(() => {
            commit('feedbackProvided', true);
         }, 10000);

      } catch (error) {
        console.log(error);

      }
    },
    async setSummaryResultsShowing({commit}, data){
      commit('setSummaryResultsShowing', data);
      // Adding delay so that background sheet can be loaded into dom before result method called
      return new Promise((resolve) =>{
        setTimeout(() => {
          resolve(data);
        }, 400);
    })
    },
  },

  getters: {
    getSelectedStatementInView(state){
      return state.selectedStatementInView;
    },
    getSelectedStatement(state){
      return state.selectedStatement;
    },
    getData(state) {
      return state.data;
    },
    hasFeedbackProvided(state){
      return state.data && state.data.feedbackSubmitted;
    },
    getBriefSummary(state){
      const summary = state.data
      return summary.data && summary.data.brief;
    },
    getDetailSummary(state){
      const summary = state.data
      return summary.data && summary.data.detail;
    },
    openWithSummary(state){
      return state.openWithSummary;
    },
    isFetching(state){
      return state.isFetching;
    },
    summaryStatus(state){
      return state.data ? state.data.status : 1 ;
    },
    getPaperData(state){
      return state.currentLiteratureData;
    },
    getPinnedData(state){
      return state.pinnedItems;
    },
    getIsPinnedVisible(state) {
      return state.isPinnedSectionVisible;
    },
    summaryResultsShowing(state) {
      return state.summaryResultsShowing;
    }
  }

}
