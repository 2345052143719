import Vue from "vue";
import { CopilotQnAType } from "~/components/literature/constants";

const defaultCopilotState = () => {
  return {
    data: {
      literatureId: {},
      currentSkip: 0,
      copilotMessagesArray: [],
      hasMoreMessage: false,
      limit: 10,
      selectedText: '',
      selectedTextContext: '',
      copilotAnnotation: {},
      resultAwaited: false,
      currentLastQuestionId: undefined,
      language:'en'
    }
  };
};

export default {
  namespaced: true,
  state: defaultCopilotState,

  mutations: {
    setDataByKey(state, obj) {
      if (obj) state.data[obj.key] = obj.data;
    },
    updateData(state, data) {
      const id = data && data.literature && data.literature.id;
      if (id) {
        Vue.set(state.data.literature, id, data);
      }
    },
    resetState(state) {
      Object.assign(state, defaultReminderState());
    },
    setLanguage(state, language){
      state.data["language"] = language;
    }
  },

  actions: {
    async fetch({ commit, state }) {
      const { data } = await this.$axios.get(
        `${ process.env.apiUrl }copilot/?literatureId=${state.data.literatureId}&limit=${state.data.limit}${state.data.currentLastQuestionId ? `&lastQuestionId=${state.data.currentLastQuestionId}` : ''}`
      );
      const tempArr = !state.data.currentLastQuestionId ? data.copilotMessages : state.data.copilotMessagesArray.concat(...data.copilotMessages);
      await commit("setDataByKey", {
        key: "copilotMessagesArray",
        data: tempArr
      });
      await commit("setDataByKey", {
        key: "hasMoreMessage",
        data: data.hasMore
      });
    },
    async create({ commit, state, rootGetters, dispatch}, reqData) {
      try {
        Vue.set(state.data, 'resultAwaited', true);
        reqData['literatureId'] = state.data.literatureId;
        reqData.language = state.data.language;
        delete reqData["followUpContext"];
        if (reqData.type === CopilotQnAType.SimplifyText) reqData['annotation'] = state.data.copilotAnnotation;
        const { data } = await this.$axios.post(
          `${ process.env.apiUrl }copilot`,
          reqData
        );
        delete reqData["loading"];
        const tempMessageArr = state.data.copilotMessagesArray;
        tempMessageArr[0] = data.copilotMessageObject;
        tempMessageArr[0]['isNewQnA'] = true;
        let annotationhId;
        if (reqData.questionType === CopilotQnAType.SimplifyText) {
          await dispatch("annotations/fetch", null, { root: true });
          if (data.copilotMessageObject.annotationId) annotationhId = rootGetters['annotations/getCurrentAnnotationById'](data.copilotMessageObject.annotationId).hId;
          await dispatch('annotations/update', { hId: annotationhId, key: 'content', value: data.copilotMessageObject.answer }, { root: true });
        }
        Vue.set(state.data, 'copilotMessagesArray', tempMessageArr);
      } catch (e) {
        throw new Error(e);
      } finally {
        Vue.set(state.data, 'resultAwaited', false);
      }
    },
    async update({ commit }, reqData) {
      const { data } = await this.$axios.put(`${ process.env.apiUrl }copilot`, reqData);
      // Vue.set(state.data, 'copilotMessagesArray', tempMessageArr);
    },
    async fetchCopilotQueryCount({ commit }, reqData) {
      const { data } = await this.$axios.get(`${process.env.apiUrl}copilot-query-count/?userId=${reqData.userId}`, reqData);
      return data.remainingQueryCount;
    },
    async fetchCopilotQueryMonthlyQuota({ commit }, reqData) {
      const { data } = await this.$axios.get(`${process.env.apiUrl}copilot-query-quota/?userId=${reqData.userId}`, reqData);
      return data;
    },
  },

  getters: {
    getData(state) {
      return state.data.copilotMessagesArray;
    },
    getHasMoreFlag(state) {
      return state.data.hasMoreMessage;
    },
    getLimit(state) {
      return state.data.limit;
    },
    getSelectedText(state) {
      return state.data.selectedText;
    },
    getSelectedTextContext(state) {
      return state.data.selectedTextContext;
    },
    getCopilotAnnotation(state) {
      return state.data.copilotAnnotation;
    },
    getResultAwaitedFlag(state) {
      return state.data.resultAwaited;
    },
    getCurrentLastQuestionId(state) {
      return state.data.currentLastQuestionId;
    },
    getLanguage(state){
      return state.data.language;
    }
  }
};
