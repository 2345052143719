var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "md-panel" },
    [
      _vm.header
        ? _c("header", { staticClass: "md-panel__header" }, [
            _c(
              "div",
              {
                staticClass:
                  "md-layout md-flex--grow md-align md-align--start-center"
              },
              [
                _vm._t("header", [
                  _c("h3", { staticClass: "md-panel__header-title" }, [
                    _vm._v(_vm._s(_vm.title))
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "md-layout-spacer" }),
                _vm._v(" "),
                _vm._t("button")
              ],
              2
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "md-panel__body" }, [_vm._t("body")], 2),
      _vm._v(" "),
      _vm._t("surface")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }