var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "EOF_pinnedTagsListSection" }, [
    _c(
      "div",
      { staticClass: "tw-flex tw-items-center tw-justify-between tw-p-3" },
      [
        _c("span", { staticClass: "pinned-tags-header-title" }, [
          _vm._v("Pinned Tags")
        ]),
        _vm._v(" "),
        _c(
          "svg",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: {
                  content:
                    "\n          <p\n            class='tw-max-w-xs tw-inline-flex'\n          >\n            " +
                    _vm.pinTagTooltipSVG +
                    " Pin a tag\n          </p>\n          " +
                    (_vm.pinnedTagsList.length === 0
                      ? "<p>You have not added any tags,</p><p>please add tags to pin here</p>"
                      : "") +
                    "\n        ",
                  delay: {
                    show: 300,
                    hide: 100
                  },
                  placement: "top"
                },
                expression:
                  "{\n        content: `\n          <p\n            class='tw-max-w-xs tw-inline-flex'\n          >\n            ${pinTagTooltipSVG} Pin a tag\n          </p>\n          ${pinnedTagsList.length === 0 ? '<p>You have not added any tags,</p><p>please add tags to pin here</p>' : ''}\n        `,\n        delay: {\n          show: 300,\n          hide: 100\n        },\n        placement: 'top'\n      }"
              }
            ],
            staticClass: "tw-cursor-pointer pinned-tags-header-icon",
            attrs: {
              id: "pinned-tag-header-icon",
              width: "12",
              height: "12",
              viewBox: "0 0 12 12",
              fill: "none",
              xmlns: "http://www.w3.org/2000/svg"
            },
            on: { click: _vm.openTagsList }
          },
          [
            _c("path", {
              attrs: {
                d:
                  "M6.66699 1.32878C6.66699 0.960586 6.36852 0.662109 6.00033 0.662109C5.63214 0.662109 5.33366 0.960586 5.33366 1.32878V5.33378H1.33366C0.965469 5.33378 0.666992 5.63226 0.666992 6.00045C0.666992 6.36864 0.965469 6.66711 1.33366 6.66711H5.33366V10.6621C5.33366 11.0303 5.63214 11.3288 6.00033 11.3288C6.36852 11.3288 6.66699 11.0303 6.66699 10.6621V6.66711H10.667C11.0352 6.66711 11.3337 6.36864 11.3337 6.00045C11.3337 5.63226 11.0352 5.33378 10.667 5.33378H6.66699V1.32878Z",
                fill: "#414E62"
              }
            })
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "pinned-tags-list tw-flex tw-flex-col tw-mt-1" },
      _vm._l(_vm.pinnedTagsList, function(tagItem) {
        return _c(
          "button",
          {
            key: tagItem.name + tagItem.color,
            staticClass:
              "tw-flex tw-items-center tw-justify-between pinned-tag-item",
            style: { border: "2px solid " + _vm.chipColors[tagItem.color - 1] },
            on: {
              click: function($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.togglePinTag(tagItem)
              }
            }
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: {
                      content: tagItem.name,
                      delay: {
                        show: 300,
                        hide: 100
                      },
                      placement: "bottom-start"
                    },
                    expression:
                      "{\n          content: tagItem.name,\n          delay: {\n            show: 300,\n            hide: 100\n          },\n          placement: 'bottom-start'\n        }"
                  }
                ],
                staticClass: "pinned-tag-tem-name"
              },
              [_vm._v("\n        " + _vm._s(tagItem.name) + "\n      ")]
            ),
            _vm._v(" "),
            _c("span", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: {
                    content: "Unpin",
                    delay: {
                      show: 300,
                      hide: 100
                    },
                    placement: "bottom-start"
                  },
                  expression:
                    "{\n          content: 'Unpin',\n          delay: {\n            show: 300,\n            hide: 100\n          },\n          placement: 'bottom-start'\n        }"
                }
              ],
              class:
                "rax-icon rax-icon-phase2-pin-tag " +
                (tagItem.isPinned ? "--active" : "") +
                " tw-text-xs",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.removeFromPinnedTagsList(tagItem)
                }
              }
            })
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }