import Vue from "vue";
import { SORT_BY } from "~/components/literature/constants";
const defaultProjectFeedState = () => {
  return {
    literatures: [],
    recentlyAddedSuggestions:[],
    currentSort: SORT_BY.Relevancy,
    shouldShowNewTag: true,
    hideNewCount: false,
    favoriteCount: null,
    projectFeedsCitaion:null,
    selectedItems: {},
    feedCount:5,
    irrelevantSuggestion:null
  }
};
export default {
  namespaced: true,
  state: defaultProjectFeedState,

  mutations: {
    setData(state, obj) {
      state[obj.key] = obj.data;
    },
    addData(state, obj) {
      state[obj.key].push(obj.data);
    },
    setObjData(state, obj) {
      Vue.set(state[obj.name], obj.key, obj.data);
    },
    resetState(state) {
      Object.assign(state, defaultProjectFeedState());
    },
    setCurrentSort(state, data) {
      state.currentSort = data;
    },
    removeSuggestion(state,data){
      let index  = state.literatures.findIndex(suggestion=>suggestion.title===data.title);
      state.irrelevantSuggestion=data;
      if(index<0) return;
      else state.literatures.splice(index,1);
    },
    setDataByKey(state, obj) {
      state[obj.key] = obj.data;
    },
    addToRecentlyAdded(state,obj){
      state.recentlyAddedSuggestions.push(obj);
    },
    resetRecentlyAddedSuggestions(state){
      state.recentlyAddedSuggestions=[];
    }
  },

  actions: {
    async literature({ commit, state, dispatch }, params, loadMore = false) {
      let { data } = await this.$axios.post('feed/project/literature', {projectId: params.id, playground:"1"});
        dispatch("suggestions/updateSuggestionCount",{type:"project-feeds", count:data.length},{root:true});
      data.forEach((item) => {
        item.projectId = params.id
        item.type = "paper"
      });
      if (loadMore) {
        data.forEach((item) => {
          commit("addData", { key: "literatures", data: item });
        });
      } else {
        commit("setData", { key: "literatures", data: data });
      }
      dispatch("updateFeedCount");
    },
    async allLiteratures({ commit, state, dispatch }, params, loadMore = false) {
      let { data } = await this.$axios.post('feed/project/literature', {projectId: 0, playground:"1"});
        dispatch("suggestions/updateSuggestionCount",{type:"project-feeds", count:data.length},{root:true});
      data.forEach((item) => {
        item.type = "paper"
      });
      if (loadMore) {
        data.forEach((item) => {
          commit("addData", { key: "literatures", data: item });
        });
      } else {
        commit("setData", { key: "literatures", data: data });
      }
      dispatch("updateFeedCount");
    },
    async updateSortData({ commit, dispatch, state }, {type}){
      commit('setCurrentSort', type);
    },
    async getProjectFeedsCitation({ state, dispatch },{docIds,isDownload,target}){
      try{
        if(!isDownload){
          this.commit(
            "projectFeeds/setDataByKey",
            {
              key: "literatureCitaion",
              data: null
          });
          let citationData = await this.$axios.get("/export-project-feeds/stylise", {
            params:{docIds,isDownload,target}
          });
          this.commit(
            "projectFeeds/setDataByKey",
            {
              key: "literatureCitaion",
              data: citationData.data.formatCitation
            });
        }
        else{
          var response = await this.$axios.get(`/export-project-feeds/stylise`, {
            params:{docIds,isDownload,target}
          });
          if(window.showSaveFilePicker){
            let data = new Blob([response.data]);
            const handle = await window.showSaveFilePicker({suggestedName: `literature.txt`});
            const writable = await handle.createWritable();
            await writable.write( data );
            writable.close();
          }
          else{
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `literature.txt`); //or any other extension
            link.setAttribute("target", "_blank");
            link.click();
          }
        }
      }
      catch(e){
        throw new Error("Something went wrong");
      }
    },
    updateFeedCount({state,commit}){
      commit("setData", { key: "feedCount", data: 0 });
    },
    addIrrelevantSuggestion({state,dispatch}){
      if(state.irrelevantSuggestion)state.literatures.splice(0,0,state.irrelevantSuggestion);
      state.irrelevantSuggestion=null;
    }
  },

  getters: {
    getLiteratures(state) {
      return state.literatures;
    },
    getCurrentSort(state) {
      return state.currentSort;
    },
    getShouldShowNewTag(state) {
      return state.shouldShowNewTag;
    },
    getHideNewCount(state) {
      return state.hideNewCount;
    },
    getSelectedKeys(state) {
      return Object.keys(state.selectedItems);
    },
    getSelectedCount(state) {
      return Object.keys(state.selectedItems).length;
    },
    getCitationFormatting(state){
      return state.projectFeedsCitaion;
    },
    getRecentlyAdded(state){
      return state.recentlyAddedSuggestions;
    },
    getFeedCount(state){
      return state.feedCount;
    }
  }
};
