const defaultContactState = () => {
  return {
    data: [],
    collaborators: [],
  };
};

export default {
  namespaced: true,
  state: defaultContactState,

  mutations: {
    setData(state, data) {
      state["data"] = data;
    },
    setCollaborators(state, data){
      state.collaborators = data;
    },
    addData(state, data) {
      state["data"].unshift(data);
    },
    updateData(state, obj) {
      state.data.splice(obj.index, 1, obj.data);
    },
    deleteData(state, index) {
      state.data.splice(index, 1);
    },
    resetState(state) {
      Object.assign(state, defaultContactState());
    }
  },

  actions: {
    async fetch({ commit }) {
      const { data } = await this.$axios.get(`/collaborators/fetch/global-contacts`);
      commit("setData", data);
    },
    async fetchUsageCollaborators({ commit }) {
      const { data } = await this.$axios.get(`/collaborators/fetch/uac/contacts`);
      commit("setCollaborators", data);
    }
  },

  getters: {
    getData(state) {
      return state.data;
    },
    getCollaborators(state){
      return state.collaborators;
    }
  }
};
