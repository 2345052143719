import findObjectIndex from "@/assets/js/commonHelper";
import Vue from "vue";
import * as moment from "moment";
const getDiffFromToday = (date) => {
  const diff = moment().diff(moment(date).add(90, 'days'), "days");
  return diff;
};
const defaultProjectState = () => {
  return {
    data: [],
    currentData: {}
  };
};
export default {
  namespaced: true,
  state: defaultProjectState,

  mutations: {
    setData(state, data) {
      state["data"] = data;
    },
    addData(state, data) {
      // Check if exists then override else add
      const diff = getDiffFromToday(data.createdAt);
      data.isDeadlinePassed =  diff > 0;
      let index = findObjectIndex(state.data, data);
      if (index !== -1) {
        state.data.splice(index, 1, data);
      }else{
        state["data"].unshift(data);
      }

    },
    updateData(state, data) {
      const diff = getDiffFromToday(data.createdAt);
      data.isDeadlinePassed =  diff > 0;
      let index = findObjectIndex(state.data, data);
      state.data.splice(index, 1, data);
    },
    updateDataPosition(state, data) {
      let index = findObjectIndex(state.data, data);
      state.data.splice(index, 1);
      state.data.unshift(data);
      Vue.set(state["currentData"], "accessedAt", data.accessedAt);
    },
    deleteData(state, index) {
      state.data.splice(index, 1);
    },
    setCurrentData(state, data) {
      state.currentData = data;
    },
    resetState(state) {
      Object.assign(state, defaultProjectState());
    }
  },

  actions: {
    async fetch({ commit }) {
      const { data } = await this.$axios.get(`/reviewer-projects`);
      const mappedData = data.map((i)=> {
        const diff = getDiffFromToday(i.createdAt);
        return {
          ...i,
          isDeadlinePassed: diff > 0
        }
      })
      commit("setData", mappedData);
    },

    async update({ commit, state }, inputs) {
      const { data } = await this.$axios.put(`/reviewer-projects`, inputs);
      commit("updateData", data);
    },


    async create({ commit, state }, inputs) {
      let index = findObjectIndex(state.data, inputs, "title");
      if (index === -1) {
        const { data } = await this.$axios.post("/reviewer-projects", inputs);
        commit("addData", data);
        return data
      } else {
        throw ("Project with same title already exists.");
      }
    },

    async delete({ commit, state }, inputs) {
      await this.$axios.delete(`/reviewer-projects/${inputs.item.id}`);
      let index = findObjectIndex(state.data, inputs.item);
      commit("deleteData", index);
      this.dispatch("subscription/fetchCurrentUsage");
    },

    async archive({ commit, state }, inputs) {
      const { data } = await this.$axios.post(`/reviewer-projects/archive`, inputs)
      commit("updateData", data);
      this.dispatch("subscription/fetchCurrentUsage");
    }

  },

  getters: {
    getData(state) {
      return state.data;
    }
  }
};
