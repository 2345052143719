import Vue from "vue";
import { SORT_BY } from "~/components/literature/constants";
const defaultAdvanceRecommendationState = () => {
  return {
    literatures: [],
    recentlyAddedSuggestions:[],
    currentSort: SORT_BY.Relevancy,
    shouldShowNewTag: true,
    hideNewCount: false,
    recommendationCount:5,
    irrelevantSuggestion:null
  }
};
export default {
  namespaced: true,
  state: defaultAdvanceRecommendationState,

  mutations: {
    setData(state, obj) {
      state[obj.key] = obj.data;
    },
    addData(state, obj) {
      state[obj.key].push(obj.data);
    },
    setObjData(state, obj) {
      Vue.set(state[obj.name], obj.key, obj.data);
    },
    resetState(state) {
      Object.assign(state, defaultProjectFeedState());
    },
    setCurrentSort(state, data) {
      state.currentSort = data;
    },
    removeSuggestion(state,data){
      let index  = state.literatures.findIndex(suggestion=>suggestion.title===data.title);
      state.irrelevantSuggestion=data;
      if(index<0) return;
      else state.literatures.splice(index,1);
    },
    addToRecentlyAdded(state,obj){
      state.recentlyAddedSuggestions.push(obj);
    },
    resetRecentlyAddedSuggestions(state){
      state.recentlyAddedSuggestions = [];
    }
  },

  actions: {
    async literature({ commit, state,dispatch }, params, loadMore = false) {
      let { data } = await this.$axios.post('feed/project/literature', {projectId: params.id, playground:"0"});
        dispatch("suggestions/updateSuggestionCount",{type:"advance-recommendations", count:data.length},{root:true});
      data.forEach((item) => {
        item.projectId = params.id
        item.type = "paper"
      });
      if (loadMore) {
        data.forEach((item) => {
          commit("addData", { key: "literatures", data: item });
        });
      } else {
        commit("setData", { key: "literatures", data: data });
      }
      dispatch("updateRecommendationCount");
    },
    async allLiteratures({ commit, state, dispatch }, params, loadMore = false) {
      let { data } = await this.$axios.post('feed/project/literature', {projectId: 0, playground:"0"});
        dispatch("suggestions/updateSuggestionCount",{type:"advance-recommendations", count:data.length},{root:true});
      data.forEach((item) => {
        item.type = "paper"
      });
      if (loadMore) {
        data.forEach((item) => {
          commit("addData", { key: "literatures", data: item });
        });
      } else {
        commit("setData", { key: "literatures", data: data });
      }
      dispatch("updateFeedCount");
    },
    async updateSortData({ commit, dispatch, state }, {type}){
      commit('setCurrentSort', type);
    },
    updateRecommendationCount({state,commit}){
      commit("setData", { key: "recommendationCount", data: 0 });
    },
    addIrrelevantSuggestion({state,dispatch}){
      if(state.irrelevantSuggestion) state.literatures.splice(0,0,state.irrelevantSuggestion);
      state.irrelevantSuggestion = null;
      }
  },

  getters: {
    getLiteratures(state) {
      return state.literatures;
    },
    getCurrentSort(state) {
      return state.currentSort;
    },
    getShouldShowNewTag(state) {
      return state.shouldShowNewTag;
    },
    getHideNewCount(state) {
      return state.hideNewCount;
    },
    getRecentlyAdded(state){
      return state.recentlyAddedSuggestions;
    },
    getRecommendationCount(state){
      return state.recommendationCount;
    }
  }
};
