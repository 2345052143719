const defaultLinkState = () => {
  return {
    data: {}
  }
};

export default {
  namespaced: true,
  state: defaultLinkState,

  mutations: {
    setData(state, data) {
      state['data'] = data;
    },
    resetState(state) {
      Object.assign(state, defaultLinkState());
    }
  },

  actions: {
    async fetch({commit}) {
      let {data} =  await this.$axios.get(`/showusersociallinks`);
      commit('setData', data);
    },
    async update({ commit }, inputs) {
      let params = {
        academiaedu: inputs.academiaedu,
        facebook: inputs.facebook,
        googleScholar: inputs.googleScholar,
        researchGate: inputs.researchGate,
        linkedin: inputs.linkedin,
        orcid: inputs.orcid,
        twitter: inputs.twitter
      };
      const {data} = await this.$axios.put(`/usersociallinks`, params);
      commit("setData", data.length? data[0] : data);
    }
  },

  getters: {
    getData(state) {
      return state.data;
    }

  }
};
