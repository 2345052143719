<template>
    <div
      class="eof-left-panel"
      :class="{'open': isLeftPanelOpen }"
    >
      <div class="EOFLeftPanelContents">
        <div class="allProjectText">
          <a @click="onAllClicked" :class="{
            'active': selectedTab=='all'
          }">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.16667 0.506348C1.24619 0.506348 0.5 1.25254 0.5 2.17301V5.50635C0.5 6.42682 1.24619 7.17302 2.16667 7.17302H5.5C6.42047 7.17302 7.16667 6.42682 7.16667 5.50635V2.17301C7.16667 1.25254 6.42047 0.506348 5.5 0.506348H2.16667ZM2.16667 2.17301H5.5V5.50635H2.16667V2.17301Z" fill="#414E62"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 0.506348C9.57952 0.506348 8.83333 1.25254 8.83333 2.17301V5.50635C8.83333 6.42682 9.57952 7.17302 10.5 7.17302H13.8333C14.7538 7.17302 15.5 6.42682 15.5 5.50635V2.17301C15.5 1.25254 14.7538 0.506348 13.8333 0.506348H10.5ZM10.5 2.17301H13.8333V5.50635H10.5V2.17301Z" fill="#414E62"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 10.4938C0.5 9.57336 1.24619 8.82717 2.16667 8.82717H5.5C6.42047 8.82717 7.16667 9.57336 7.16667 10.4938V13.8272C7.16667 14.7476 6.42047 15.4938 5.5 15.4938H2.16667C1.24619 15.4938 0.5 14.7476 0.5 13.8272V10.4938ZM5.5 10.4938H2.16667V13.8272H5.5V10.4938Z" fill="#414E62"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 8.82717C9.57952 8.82717 8.83333 9.57336 8.83333 10.4938V13.8272C8.83333 14.7476 9.57952 15.4938 10.5 15.4938H13.8333C14.7538 15.4938 15.5 14.7476 15.5 13.8272V10.4938C15.5 9.57336 14.7538 8.82717 13.8333 8.82717H10.5ZM10.5 10.4938H13.8333V13.8272H10.5V10.4938Z" fill="#414E62"/>
          </svg>
          <span style="position: relative;top:2px">All</span>
        </a>
        </div>
        <hr id="projects-divider" />
        <div class="createNewProjectSection">
          <a href="javascript:void(0)" @click="openCreateProject">
              <svg v-tooltip="{content: 'Create New Project', placement:'bottom'}" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.66699 1.32878C6.66699 0.960586 6.36852 0.662109 6.00033 0.662109C5.63214 0.662109 5.33366 0.960586 5.33366 1.32878V5.33378H1.33366C0.965469 5.33378 0.666992 5.63226 0.666992 6.00045C0.666992 6.36864 0.965469 6.66711 1.33366 6.66711H5.33366V10.6621C5.33366 11.0303 5.63214 11.3288 6.00033 11.3288C6.36852 11.3288 6.66699 11.0303 6.66699 10.6621V6.66711H10.667C11.0352 6.66711 11.3337 6.36864 11.3337 6.00045C11.3337 5.63226 11.0352 5.33378 10.667 5.33378H6.66699V1.32878Z" fill="#414E62"/>
              </svg>
            <span>Projects</span>
            </a>
        </div>
        <ul v-if = "!isProjectFetching">
          <li v-for="(item, index) in filterdItemList"
          :key="'project-picker-' + index"
          @click="onClicked(item)" :class="{
            'active': selectedTab==item.title
          }"
          v-tooltip="{
            content: getProjectTooltip(item),
            html:true,
            placement: 'bottom-start'
          }"
          >
          <span>
            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3 2.00781C2.73478 2.00781 2.48043 2.11317 2.29289 2.30071C2.10536 2.48824 2 2.7426 2 3.00781V14.0078C2 14.273 2.10536 14.5274 2.29289 14.7149C2.48043 14.9025 2.73478 15.0078 3 15.0078H17C17.2652 15.0078 17.5196 14.9025 17.7071 14.7149C17.8946 14.5274 18 14.273 18 14.0078V6.00781C18 5.7426 17.8946 5.48824 17.7071 5.30071C17.5196 5.11317 17.2652 5.00781 17 5.00781H10C9.73478 5.00781 9.48043 4.90246 9.29289 4.71492L6.58579 2.00781H3ZM0.87868 0.886492C1.44129 0.323883 2.20435 0.0078125 3 0.0078125H7C7.26522 0.0078125 7.51957 0.113169 7.70711 0.300706L10.4142 3.00781H17C17.7957 3.00781 18.5587 3.32388 19.1213 3.88649C19.6839 4.4491 20 5.21216 20 6.00781V14.0078C20 14.8035 19.6839 15.5665 19.1213 16.1291C18.5587 16.6917 17.7957 17.0078 17 17.0078H3C2.20435 17.0078 1.44129 16.6917 0.87868 16.1291C0.31607 15.5665 0 14.8035 0 14.0078V3.00781C0 2.21216 0.31607 1.4491 0.87868 0.886492Z" fill="#414E62"/>
          </svg>
          </span>
          <a>
            {{ item.title }}
          </a>
          <button
            data-autofocus="false"
            class="md-button md-button--icon"
            @click="openMenu($event, item)"
          >
            <i class="material-icons no-user-select">more_vertical</i>
          </button>
          <app-menu :unid="item.id.toString()" @selected="onSelectedProjectMenu($event, item)">
            <template slot="body">
              <button
                v-for="m in menu"
                :key="m.title"
                type="button"
                :data-value="m.value"
                :disabled="m.disabled"
                class="md-menu__item md-button"
              >
                <span :class="`rax-icon rax-icon-v2-${m.icon || m.value} rax-icon-phase1-${m.icon || m.value} --hover project-menu-option`" />
                {{ m.title }}
              </button>
            </template>
          </app-menu>
        </li>
        </ul>
          <shimmerList v-else/>
      </div>

    <div class="EOF_newProjectSection">
      <ul>
        <!-- <li @click="closeTopMenu('Shared with me_CTA')">
          <nuxt-link
            :to="'/shared-with-me/literatures'"
            :class="{
            'active': selectedTab=='shared-with-me'
            }"
          >
            <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.2 2.9C7.2 1.57452 8.27452 0.5 9.6 0.5C10.9255 0.5 12 1.57452 12 2.9C12 4.22548 10.9255 5.3 9.6 5.3C8.87252 5.3 8.22063 4.97632 7.7805 4.46513L4.75296 6.02477C4.78381 6.17839 4.8 6.3373 4.8 6.5C4.8 6.66736 4.78287 6.83073 4.75026 6.98843L7.77184 8.54499C8.21205 8.02795 8.86773 7.7 9.6 7.7C10.9255 7.7 12 8.77452 12 10.1C12 11.4255 10.9255 12.5 9.6 12.5C8.27452 12.5 7.2 11.4255 7.2 10.1C7.2 9.93722 7.21621 9.77822 7.24709 9.62454L4.21965 8.06495C3.77952 8.57625 3.12757 8.9 2.4 8.9C1.07452 8.9 0 7.82548 0 6.5C0 5.17452 1.07452 4.1 2.4 4.1C3.13236 4.1 3.7881 4.42803 4.22832 4.94517L7.24978 3.38866C7.21715 3.23088 7.2 3.06744 7.2 2.9ZM9.6 1.7C8.93726 1.7 8.4 2.23726 8.4 2.9C8.4 3.56274 8.93726 4.1 9.6 4.1C10.2627 4.1 10.8 3.56274 10.8 2.9C10.8 2.23726 10.2627 1.7 9.6 1.7ZM8.48928 9.64495C8.43173 9.78528 8.4 9.93894 8.4 10.1C8.4 10.7627 8.93726 11.3 9.6 11.3C10.2627 11.3 10.8 10.7627 10.8 10.1C10.8 9.43726 10.2627 8.9 9.6 8.9C9.14627 8.9 8.75136 9.15182 8.5474 9.52332C8.53815 9.54995 8.52692 9.57626 8.51363 9.60206C8.50603 9.61681 8.4979 9.63111 8.48928 9.64495ZM3.48686 6.00207C3.47397 5.97706 3.46302 5.95156 3.45393 5.92575C3.25036 5.55291 2.85472 5.3 2.4 5.3C1.73726 5.3 1.2 5.83726 1.2 6.5C1.2 7.16274 1.73726 7.7 2.4 7.7C3.06274 7.7 3.6 7.16274 3.6 6.5C3.6 6.33806 3.56792 6.18361 3.50977 6.04266C3.50168 6.02953 3.49404 6.016 3.48686 6.00207Z" fill="#414E62"/>
            </svg>
            <span>Shared with me</span>
          </nuxt-link>
        </li> -->
        <li @click="closeTopMenu('Project_Archives')">
          <nuxt-link
            :to="'/projects/archive'"
            :class="{ 'active': selectedTab=='archive' }"
          >
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.33333 12C0.966667 12 0.652667 11.8696 0.391333 11.6087C0.130444 11.3473 0 11.0333 0 10.6667V2.33333C0 2.16667 0.0277778 2.01667 0.0833333 1.88333C0.138889 1.75 0.211111 1.62222 0.3 1.5L1.23333 0.366667C1.32222 0.244444 1.43333 0.152667 1.56667 0.0913334C1.7 0.0304445 1.84444 0 2 0H10C10.1556 0 10.3 0.0304445 10.4333 0.0913334C10.5667 0.152667 10.6778 0.244444 10.7667 0.366667L11.7 1.5C11.7889 1.62222 11.8611 1.75 11.9167 1.88333C11.9722 2.01667 12 2.16667 12 2.33333V10.6667C12 11.0333 11.8696 11.3473 11.6087 11.6087C11.3473 11.8696 11.0333 12 10.6667 12H1.33333ZM1.6 2H10.4L9.83333 1.33333H2.16667L1.6 2ZM1.33333 10.6667H10.6667V3.33333H1.33333V10.6667ZM6 9.71667C6.08889 9.71667 6.17222 9.70267 6.25 9.67467C6.32778 9.64711 6.4 9.6 6.46667 9.53333L8.2 7.8C8.32222 7.67778 8.38333 7.52222 8.38333 7.33333C8.38333 7.14444 8.32222 6.98889 8.2 6.86667C8.07778 6.74444 7.92222 6.68333 7.73333 6.68333C7.54444 6.68333 7.38889 6.74444 7.26667 6.86667L6.66667 7.46667V5.33333C6.66667 5.14444 6.60289 4.986 6.47533 4.858C6.34733 4.73044 6.18889 4.66667 6 4.66667C5.81111 4.66667 5.65289 4.73044 5.52533 4.858C5.39733 4.986 5.33333 5.14444 5.33333 5.33333V7.46667L4.73333 6.86667C4.61111 6.74444 4.45556 6.68333 4.26667 6.68333C4.07778 6.68333 3.92222 6.74444 3.8 6.86667C3.67778 6.98889 3.61667 7.14444 3.61667 7.33333C3.61667 7.52222 3.67778 7.67778 3.8 7.8L5.53333 9.53333C5.6 9.6 5.67222 9.64711 5.75 9.67467C5.82778 9.70267 5.91111 9.71667 6 9.71667ZM1.33333 10.6667V3.33333V10.6667Z" fill="#414E62"/>
            </svg>
            <span>Archived</span>
          </nuxt-link>
        </li>
      </ul>
    </div>

    <!-- <pinned-tags-list
      @openTagsList="openTagsList"
    /> -->

    <div
      id="pinned-tag-list-menu"
    >
      <search-pinned-tags
        v-if="searchTagsVisible"
        @hideTagsList="hideTagsList"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { createPopper } from "@popperjs/core";
import { MixpanelEvents, MixpanelEventGroup } from "@/shared/constants";
import shimmerList from "@/components/shimmer/list";
import searchPinnedTags from "@/components/pinnedTag/searchPinnedTags.vue";
import pinnedTagsList from "@/components/pinnedTag/pinnedTagsList.vue";

export default {
  data() {
    return {
      popperInstance: null,
      searchTagsVisible: false,
    }
  },
  props:{
    isLeftPanelOpen:{
      type:Boolean,
      required:false
    },
    selectedTab:{
      type:String,
      required:false
    },
    user:{
      type:Object,
      required:false
    },
    isTopMenulOpen:{
      type:Boolean,
      required:false
    },
    tabsList:{
      type:Array,
      required:false
    },
    menu:{
      type:Array,
      required:false
    }
  },
  components:{
    shimmerList,
    searchPinnedTags,
    // pinnedTagsList
  },
  async mounted(){

    if (this.itemList.length < 2) {
      try {
        await this.$store.dispatch("projects/fetchFirstProject")
        await this.$store.dispatch("projects/fetch");
      } catch (e) {
        // Show Error
      }
    }
  },
  computed: {
    ...mapGetters({
      canAddProject: 'subscription/canAddProject',
      itemList: "projects/getData",
      isProjectFetching:"projects/getIsFetching"
    }),
    filterdItemList() {
      return this.itemList && this.itemList.filter((item) =>
        ["1", "2", "3", "4", "6"].includes(item.status) ||
        !item.hasOwnProperty("status")
      ) || [];
    }
  },
  methods:{
    onAllClicked(){
      this.$emit("allClicked");
    },
    onClicked(item) {
      this.$emit("onClicked",item);
    },
    openCreateProject(){
      this.$emit("openCreateProject")
    },
    getProjectTooltip(item){
      return `
        <div style='max-width:200px;'>
          <div style='font-weight:500'>${item.title}</div>
          <div>${item.description}</div>
        </div>
      `;
    },
    openMenu(e, item){
      this.$emit("openMenu",{e,item})
    },
    onSelectedProjectMenu(obj,item){
      this.$emit("onSelectedProjectMenu",{obj,item})
    },
    closeTopMenu(event){
      this.$emit("closeTopMenu",event);
    },
    tabClick(title){
      this.$emit("tabClick",title);
    },
    hideTagsList() {
      this.searchTagsVisible = false;
      const context = document.querySelector(`#pinned-tag-list-menu`);
      this.$store.commit("core/setIsContextMenuOpen", false);

      if (this.popperInstance) {
        this.popperInstance.destroy();
        this.popperInstance = null;
      }
      if (context) context.classList.remove("block");
    },
    hidePopover({ target }) {
      const popover = $(`#pinned-tag-list-menu`);
      const popoverSource = $('#pinned-tag-header-icon');
      if ($(target).closest('#pinned-tag-list-menu').is(popover)) return;
      else if ($(target).closest('#pinned-tag-header-icon').is(popoverSource)) this.openTagsList();
      else this.hideTagsList();
    },
    openTagsList() {
      this.$mixpanel.track(MixpanelEvents[MixpanelEventGroup.PinnedTag].Pinned_tag_CTA);
      this.searchTagsVisible = true;
      const tooltip = document.querySelector(`#pinned-tag-list-menu`);
      tooltip.classList.add("block");
      this.$store.commit("core/setIsContextMenuOpen", true);
      const parentElement = document.querySelector("#pinned-tag-header-icon");
      document.addEventListener('click', this.hidePopover, true);
      this.popperInstance = createPopper(parentElement, tooltip, {
        placement: 'right',
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [-50, 10]
            }
          }
        ],
        onFirstUpdate: (state) => console.log("Popper positioned on", state.placement)
      });
    },
  }
}
</script>

<style>
.eof-left-panel {
  background-color: #fff;
  display: none;
}
.eof-left-panel.open {
  width: 232px;
  min-width: 232px;
  display: block;
}
#pinned-tag-list-menu {
  z-index: 10;
  background: white;
  box-shadow: 0px 8px 24px rgba(4, 21, 76, 0.32);
  border-radius: 20px;
}
#pinned-tag-list-menu[data-popper-reference-hidden] {
  visibility: hidden;
  pointer-events: none;
}
@media screen and (max-width:1392px) {
  #projects-divider {
  width: 140px !important;
  margin: 0px auto;
}
}
#projects-divider {
  width: 205px;
  margin: 0px auto;
}
@media screen and (max-width: 1400px) {
  #projects-divider {
    width: 150px;
  }
}
</style>
