<template>
  <div class="sidebar">
    <div v-if="hasBackdrop && isPanelOpen" class="sidebar-backdrop" :style="backDropStyle" @click="closeSidebarPanel"/>
    <transition :name="shouldAnimate ? 'slide' : ''">
      <div v-if="isPanelOpen" class="sidebar-panel" :class="{ 'left': isLeftSidePanel, 'right': !isLeftSidePanel }" :style="sidePanelStyle">
        <slot/>
      </div>
    </transition>
  </div>
</template>
<script>
    export default {
        props:{
          isPanelOpen:{
            type: Boolean,
            default: true
          },
          hasBackdrop:{
            type: Boolean,
            default: true
          },
          isLeftSidePanel:{
            type: Boolean,
            default: true
          },
          sideBarPanelStyle:{
            type: Object,
            default: ()=> {
              return { }
            }
          },
          sideBarBackDropStyle:{
            type: Object,
            default: ()=> {
              return { }
            }
          },
          shouldAnimate: {
            type: Boolean,
            default: true
          }

        },
        data(){
          return {
            defaultSideBarStyle: {
                'background-color': '#fff',
                'width': '300px',
                position: 'absolute',
                height: '100%'
              },
            defaultBackDropStyle:{
              background: `linear-gradient(45deg, rgba(0, 0, 0, 0.1), rgba(54, 54, 54, 0.1));`,
              'backdrop-filter': 'blur(3px);',
              'z-index': 999,
              opacity: 1
            }
          };
        },
        methods: {
            closeSidebarPanel() {
              this.$emit('onBackdropClick', true);
            }
        },
        computed:{
          sidePanelStyle(){
            return {
              ...this.defaultSideBarStyle,
              ...this.sideBarPanelStyle
            }
          },
          backDropStyle(){
            return {
              ...this.defaultBackDropStyle,
              ...this.sideBarBackDropStyle
            }
          }
        }
    }
</script>
<style>
    .slide-enter-active,
    .slide-leave-active
    {
        transition: transform 0.6s ease;
    }

    .left.slide-enter,
    .left.slide-leave-to {
        transform: translateX(-100%);
        transition: all 150ms ease-in 0s
    }
    .right.slide-enter,
    .right.slide-leave-to {
        transform: translateX(100%);
        transition: all 150ms ease-in 0s
    }

    .sidebar-backdrop {
        width: 100vw;
        height: 100vh;
        position: fixed;
        box-sizing: border-box;
        left: 0;
        top: 0;
    }

    .sidebar-panel {
        overflow-y: hidden;
        z-index: 999;
    }
    .sidebar-panel:hover {
        overflow-y: scroll;
    }

    .sidebar-panel.left{
      left: 0;
      top: 0;
    }
    .sidebar-panel.right{
      right: 0;
      top: 0;
    }
</style>
