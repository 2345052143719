var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "result-card-container" }, [
    _c("img", {
      staticClass: "result-card-icon",
      attrs: { src: _vm.assetsUrl + "img/icon/pdf.svg" }
    }),
    _vm._v(" "),
    _c("div", { staticClass: "result-card-detail" }, [
      _c("p", { staticClass: "tw-inline-flex tw-items-center" }, [
        _c("span", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: {
                content: _vm.searchItem.literatureTitle,
                delay: {
                  show: 300,
                  hide: 100
                },
                placement: "bottom"
              },
              expression:
                "{\n          content: searchItem.literatureTitle,\n          delay: {\n            show: 300,\n            hide: 100\n          },\n          placement: 'bottom'\n        }"
            }
          ],
          staticClass:
            "result-card-literature-title overflow-ellipsis tw-cursor-pointer",
          domProps: { innerHTML: _vm._s(_vm.literatureTitle) },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.openLiteratureInReadingRoom("Title")
            }
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "result-card-literature-title-divider" }, [
          _vm._v("|")
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "tw-inline-flex tw-items-center" }, [
          _c(
            "svg",
            {
              staticClass: "tw-mx-1",
              attrs: {
                width: "14",
                height: "12",
                viewBox: "0 0 14 12",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("path", {
                attrs: {
                  "fill-rule": "evenodd",
                  "clip-rule": "evenodd",
                  d:
                    "M2.33301 1.33919C2.1562 1.33919 1.98663 1.40943 1.8616 1.53445C1.73658 1.65948 1.66634 1.82905 1.66634 2.00586V9.33919C1.66634 9.516 1.73658 9.68557 1.8616 9.8106C1.98663 9.93562 2.1562 10.0059 2.33301 10.0059H11.6663C11.8432 10.0059 12.0127 9.93562 12.1377 9.8106C12.2628 9.68557 12.333 9.516 12.333 9.33919V4.00586C12.333 3.82905 12.2628 3.65948 12.1377 3.53445C12.0127 3.40943 11.8432 3.33919 11.6663 3.33919H6.99967C6.82286 3.33919 6.65329 3.26895 6.52827 3.14393L4.72353 1.33919H2.33301ZM0.918794 0.591646C1.29387 0.216573 1.80257 0.00585938 2.33301 0.00585938H4.99967C5.17649 0.00585938 5.34606 0.0760972 5.47108 0.201122L7.27582 2.00586H11.6663C12.1968 2.00586 12.7055 2.21657 13.0806 2.59165C13.4556 2.96672 13.6663 3.47543 13.6663 4.00586V9.33919C13.6663 9.86963 13.4556 10.3783 13.0806 10.7534C12.7055 11.1285 12.1968 11.3392 11.6663 11.3392H2.33301C1.80257 11.3392 1.29387 11.1285 0.918794 10.7534C0.543721 10.3783 0.333008 9.86963 0.333008 9.33919V2.00586C0.333008 1.47543 0.543721 0.966719 0.918794 0.591646Z",
                  fill: "#414E62"
                }
              })
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "result-card-project-title overflow-ellipsis" },
            [_vm._v(_vm._s(_vm.searchItem.projectTitle))]
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "result-card-matching-detail" }, [
        _c("p", { staticClass: "result-card-matching" }, [
          _c("span", { staticClass: "result-card-matching-section" }, [
            _vm._v("\n          Related to the section\n        ")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "result-card-matching-section-title" }, [
            _vm._v(_vm._s(_vm.searchItem.sectionHeader))
          ])
        ]),
        _vm._v(" "),
        _c("span", {
          staticClass: "result-card-matching-highlight",
          domProps: { innerHTML: _vm._s(_vm.highlightedText) }
        }),
        _vm._v(" "),
        _c(
          "span",
          {
            staticClass: "result-card-view-more",
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.openLiteratureInReadingRoom("ReadMore")
              }
            }
          },
          [_vm._v("\n        Read more\n      ")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }