import Vue from "vue";
import findObjectIndex from "@/assets/js/commonHelper";
import arraySearch from "@/assets/js/arraySearch";

const defaultSharedDraftsState = () => {
  return {
    data: [],
    currentIndex: 0,
    tabs: [],
    headingId: {},
    outlineHeadings: []
  };
};
export default {
  namespaced: true,
  state: defaultSharedDraftsState,

  mutations: {
    setData(state, data) {
      state["data"] = data;
    },

    addData(state, data) {
      state["data"].unshift(data);
    },

    updateData(state, data) {
      let index = findObjectIndex(state.data, data);
      state.data.splice(index, 1, data);
    },

    updateDataPosition(state, data) {
      let index = findObjectIndex(state.data, data);
      state.data.splice(index, 1);
      state.data.unshift(data);
      Vue.set(state["data"][state.currentIndex], "accessedAt", data.accessedAt);
    },

    deleteData(state, index) {
      state.data.splice(index, 1);
    },

    setCurrentData(state, data) {
      state.currentData = data;
    },

    setTab(state, data) {
      state["tabs"] = data;
    },

    addTab(state, data) {
      state["tabs"].push(data);
    },

    updateTab(state, obj) {
      let index = findObjectIndex(state.data, { id: obj.data.id });
      state.tabs.splice(obj.index, 1, state.data[index]);
    },

    deleteTab(state, index) {
      state.tabs.splice(index, 1);
    },

    resetState(state) {
      Object.assign(state, defaultSharedDraftsState());
    }
  },

  actions: {
    async fetch({ commit }) {
      const { data } = await this.$axios.get(`/shared/with-me/drafts`);
      commit("setData", data);
    },

    async fetchExceptExits({ commit, state }) {
      const { data } = await this.$axios.get(`/shared/with-me/drafts`);
      let filterArray = data.filter((item) => {
        return !arraySearch(state.data, item.id);
      });
      for (let i = 0; i < filterArray.length; i++) {
        commit("addData", filterArray[i]);
      }
    },

    async update({ commit, state }, inputs) {
      const { data } = await this.$axios.put(`/shared/with-me/drafts`, inputs);
      commit("updateData", data[0]);
    },

    async create({ commit, state }, inputs) {
      const { data } = await this.$axios.post("/shared/with-me/drafts", inputs);
      commit("addData", data);
    },

    async revoke({ commit, state }, params) {
      if (!params.userId) {
        delete params.userId;
      }
      const updatedParamas = { ...params };
      if (updatedParamas.isLeft) {
        delete updatedParamas.userId;
        delete updatedParamas.user;
      }
      await this.$axios.post(`/collaborators/revoke`, updatedParamas);
      let index = findObjectIndex(state.data, params);
      commit("deleteData", index);
    },

    closeTabs({ commit, state }, item) {
      let index = findObjectIndex(state.tabs, item);
      commit("deleteTab", index);
    }
  },

  getters: {
    getData(state) {
      return state.data;
    },

    getCurrentData(state) {
      return state.currentData;
    },

    getTabs(state) {
      return state.tabs;
    },

    getOutlineHeadings(state) {
      return state.outlineHeadings;
    }
  }
};
