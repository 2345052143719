
import { AnnotationClasses, AnnotationType } from "./constants";
export const getNextId = () => String(Math.random()).slice(2);
export const createTag = (
  container,
  top,
  left,
  id,
  type = AnnotationType.Note,
  cb
) => {
  const $span = document.createElement("div");
  $span.style.position = `absolute`;
  $span.style.top = `-${16}px`;
  $span.style.right = `-${12}px`;
  $span.style.display = `inline`;
  $span.dataset["id"] = id;
  $span.className = `annotation-icon ${type} ${AnnotationClasses[type]} pdf-tooltip-block`;
  $span.setAttribute('data-title', type)

  $span.addEventListener('click', (_) => {
    cb(id);
  })

  setTimeout(() => {
    container.appendChild($span);
  }, 200);
};
export const createAnnotationTag = (
  container,
  top,
  left,
  id,
  type = AnnotationType.Note,
  cb
) => {
  const $span = document.createElement("span");
  $span.style.position = `absolute`;
  $span.style.top = `-${16}px`;
  $span.style.right = `-${12}px`;
  $span.style.display = `inline`;
  $span.dataset["id"] = id;
  $span.className = `annotation-icon ${type} ${AnnotationClasses[type]} pdf-tooltip-block`;
  $span.setAttribute('data-title', type)

  $span.addEventListener('click', (_) => {
    cb(id);
  })

  setTimeout(() => {
    container.appendChild($span);
  }, 200);
};

export function generateHighlightId(id, type, prefix='') {
  return `${prefix? prefix+'-': ''}annotation__${type}__${id}`;
}

export const copyToClipboard = str => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  const selected =
    document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
};

export const testImage = (url, timeoutVal) => new Promise((resolve, reject) => {
  let timeout = timeoutVal || 5000;
  let timer, img = new Image();
  img.onerror = img.onabort = () => {
    clearTimeout(timer);
    reject("");
  };
  img.onload = () => {
    clearTimeout(timer);
    resolve(url);
  };
  timer = setTimeout(() => {
    /* reset .src to invalid URL so it stops previous
    loading, but doens't trigger new load */
    img.src = "//!!!!/noexist.jpg";
    reject("");
  }, timeout); 
  img.src = url;
});

// Checks if an extra space is required between current and next span
export function checkSpaceRequired(curr, next) {
  // Add space between spans if either (1) The span are on separate lines. To check this, 'top' property of spans' style
  // (2) There is a gap of more than 1px between them. To calculate gap, use: nextSpan.left - (currentSpan.left + currentSpan.width)
  return Math.abs(parseFloat(next.style.top.slice(0, -2)) - parseFloat(curr.style.top.slice(0, -2))) > 1 || (parseFloat(next.style.left.slice(0, -2)) - curr.getBoundingClientRect()['width'] - parseFloat(curr.style.left.slice(0, -2)) > 1);
}

export function getProperSelection(options) {
  try {
    // Optional parameters selectionRange and addContext
    var selectionRange = options.selectionRange || window.getSelection().getRangeAt(0);
    var addContext = options.addContext || false;
    const addCoPilotContext = options.addCoPilotContext || false;

    var selection = "";
    var next;
    var curr = selectionRange.startContainer.parentElement;
    var startOffset = selectionRange.startOffset;

    var end;
    var endOffset;

    // Fix start/endcontainers and their offsets

    // Endcontainer is a span itself, set it to the previous element and set offset to max
    if(selectionRange.endContainer.tagName == "SPAN") {
      end = selectionRange.endContainer.previousElementSibling;
      endOffset = end.textContent.length - 1;
    } else {
      end = selectionRange.endContainer.parentElement;
      endOffset = selectionRange.endOffset;
    }

    // If selection starts inside a highlight, set start = start.parent and fix the offset
    if (curr.classList.contains('RAx_highlight_wrap')) {

      // Fixing the offset. Add the lenth of all the textNodes that appear in this span before the highlight
      var temp = curr.parentElement;

      for (let i = 0; i < temp.childNodes.length; i++) {
        if (temp.childNodes[i] == curr) {
          break;
        }
        startOffset += temp.childNodes[i].textContent.length;
      }
      // Set curr(or start) as its parent(SPAN)
      curr = temp;

    } else {
      // If selection starts outside, fix offset as the current offset is calculated inside the text node that selection starts in
      for (let i = 0; i < curr.childNodes.length; i++) {
        if (((curr.childNodes[i - 1] || null) == selectionRange.startContainer.previousElementSibling) && ((curr.childNodes[i + 1] || null) == selectionRange.startContainer.nextElementSibling)) {
          break;
        }
        startOffset += curr.childNodes[i].textContent.length;
      }
    }

    // Fix the endContainer and endOffset if and a selection ends inside a highlight
    if (end.classList.contains('RAx_highlight_wrap')) {
      var temp = end.parentElement;
      for (let i = 0; i < temp.childNodes.length; i++) {
        if (temp.childNodes[i] == end) {
          break;
        }
        endOffset += temp.childNodes[i].textContent.length;
      }

      end = temp;
    }
    else {
      // If a selection does not end inside highlight but there are highlight(s) inside the same span. fix offset
      // Offset fixed by adding the lengths of all the previous textNodes inside the span appearing before the end of selection span
      for (let i = 0; i < end.childNodes.length; i++) {
        if (((end.childNodes[i - 1] || null) == selectionRange.endContainer.previousElementSibling) && ((end.childNodes[i + 1] || null) == selectionRange.endContainer.nextElementSibling)) {
          break;
        }
        endOffset += end.childNodes[i].textContent.length;
      }

    }
    // Add selection search context if asked and if the length of the current selection does not exceed threshold
    if (addContext && selectionRange.toString().length < 60) {
      var temp_chars = 0;
      var temp_start = curr;
      var temp_end = end;
      const extraChars = 25;

      // Add text appearing before the current start of selection
      // Add text until the current extra characters exceed threshold
      while (temp_chars < extraChars) {
        if (temp_start.previousElementSibling == null) {
          // If selection is at start of page, go to the previous page if it exists
          temp_start = temp_start.closest('#viewerContainer').parentElement;
          if (temp_start.previousElementSibling == null) {
            break;
          }
          var previousPageSpans = temp_start.previousElementSibling.querySelectorAll('span');
          temp_start = previousPageSpans[previousPageSpans.length - 1];

        }
        temp_start = temp_start.previousElementSibling;
        temp_chars += temp_start.textContent.length;
      }

      temp_chars = 0;
      // Add text appearing after the current end of selection
      while (temp_chars < extraChars) {

        // If end of page reached, go to the next page(if exists)
        if (temp_end.nextElementSibling == null) {
          break;
        }
        temp_end = temp_end.nextElementSibling;
        temp_chars += temp_end.textContent.length;

        if (temp_end.classList.contains('endOfContent')) {
          temp_end = temp_end.closest('#viewerContainer').parentElement;
          if (temp_end.nextElementSibling == null) {
            break;
          }
          temp_end = temp_end.nextElementSibling.querySelector('span');
        }

      }

      startOffset = 0;
      endOffset = temp_end.textContent.length - 1;
      curr = temp_start;
      end = temp_end;

    } else if(addContext) {
      // No need to add context if length of current selection is sufficient in size
      return '';
    }

    if (addCoPilotContext) {
      let tempStart = curr;
      let tempEnd = end;

      // Add text appearing before the current start of selection
      // Add text until the current extra characters exceed threshold
      tempStart = checkIfPreviousEleContainFullStop(tempStart.previousElementSibling);
    
      tempEnd = checkIfNextEleContainFullStop(tempEnd.nextElementSibling)
     
      startOffset = 0;
      endOffset = tempEnd.textContent.length - 1;
      curr = tempStart;
      end = tempEnd;
    }

    // If the selection has multiple elements(SPANs) then do this operation
    if (curr != end) {
      selection = curr.textContent.substr(startOffset);

      // If next element is null, go up the DOM tree to find next element
      while (curr.nextElementSibling == null) {
        curr = curr.parentElement;
      }
      next = curr.nextElementSibling;
      try {
        // Add space if (1. Next line, 2. spans are spaced far apart) but if last character in selection is ' ' or '-', don't add extra space
        if (!(selection.slice(-1)[0] == ' ' || selection.slice(-1)[0] == '-') && checkSpaceRequired(curr, next)) {
          selection += ' ';
        }
      }
      catch (err) {
        // To prevent empty catch
        selection += '';
      }
      curr = next;
      while (curr != end) {

        // If endOfContent reached, set curr to the first span of the next page
        if ((curr.classList.contains('endOfContent'))) {
          curr = curr.closest('#viewerContainer').parentElement
          if (curr.nextSibling == null) {
            return selection;
          }
          if (end.classList.contains('textLayer')) {
            // End of document reached
            return selection;
          }
          curr = curr.nextSibling.querySelector('span');
        }
        selection += curr.textContent;

        // If next element is null, go up the DOM tree to find next element
        while (curr.nextElementSibling == null) {
          curr = curr.parentElement;
        }
        next = curr.nextElementSibling;

        try {
          // Add space if (1. Next line, 2. spans are spaced far apart) but if last character in selection is ' ' or '-', don't add extra space
          if (!(selection.slice(-1)[0] == ' ' || selection.slice(-1)[0] == '-') && checkSpaceRequired(curr, next)) {
            selection += ' ';
          }
        }
        catch (err) {
          console.log(err);
        }
        curr = next;
      }
      selection += end.textContent.slice(0, endOffset);
      return selection;
    }
    else {
      // If selection is inside a single span, return using standard selection method
      return window.getSelection().toString();
    }

  }
  catch (err) {
    // If custom selection fails, revert to standard selection method
    return window.getSelection().toString();
  }
}

// Checks if an extra space is required between current and next span
export function checkSpaceRequiredDom(curr, next) {
  // Add space between spans if either (1) The span are on separate lines. To check this, 'top' property of spans' style
  // (2) There is a gap of more than 1px between them. To calculate gap, use: nextSpan.left - (currentSpan.left + currentSpan.width)
  return ((next.getBoundingClientRect()['top'] - curr.getBoundingClientRect()['top']) > 2) || ((next.getBoundingClientRect()['left'] - curr.getBoundingClientRect()['width'] - curr.getBoundingClientRect()['left']) > 1);
}

export function selectionFromDoms(doms) {
  let selection = '';

  try {
    for (let i = 0; i < doms.length; i++) {

      selection += doms[i].textContent;
      try {
        if (checkSpaceRequiredDom(doms[i], doms[i + 1])) {
          selection += ' ';
        }
      }
      catch (err) {
        continue;
      }

    }
  }
  catch(err) {
    return selection;
  }

  return selection;
}


export function generateGetBoundingClientRect(x = 0, y = 0) {
  return () => ({
    width: 0,
    height: 0,
    top: y,
    right: x,
    bottom: y,
    left: x,
  });
}

export function saveSelection() {
  let range = window.getSelection().getRangeAt(0);
  let preSelectionRange = range.cloneRange();
  let containerEl = preSelectionRange.startContainer.parentElement;
  preSelectionRange.selectNodeContents(containerEl);
  preSelectionRange.setEnd(range.startContainer, range.startOffset);
  let start = preSelectionRange.toString().length;

  return {
    rangeData: {start: start,  end: start + range.toString().length},
    ele: containerEl
  }
};

export function restoreSelection({ele:containerEl, rangeData:savedSel}) {
  let charIndex = 0, range = document.createRange();
  range.setStart(containerEl, 0);
  range.collapse(true);
  let nodeStack = [containerEl], node, foundStart = false, stop = false;

  while (!stop && (node = nodeStack.pop())) {
      if (node.nodeType == 3) {
          let nextCharIndex = charIndex + node.length;
          if (!foundStart && savedSel.start >= charIndex && savedSel.start <= nextCharIndex) {
              range.setStart(node, savedSel.start - charIndex);
              foundStart = true;
          }
          if (foundStart && savedSel.end >= charIndex && savedSel.end <= nextCharIndex) {
              range.setEnd(node, savedSel.end - charIndex);
              stop = true;
          }
          charIndex = nextCharIndex;
      } else {
          let i = node.childNodes.length;
          while (i--) {
              nodeStack.push(node.childNodes[i]);
          }
      }
  }

  let sel = window.getSelection();
  sel.removeAllRanges();
  sel.addRange(range);
}

function checkIfPreviousEleContainFullStop(textNode) {
  if (textNode.previousElementSibling.textContent.includes('. ')) {
    if (textNode.previousElementSibling == null) {
      // If selection is at start of page, go to the previous page if it exists
      textNode = textNode.closest('#viewerContainer').parentElement;
      if (textNode.previousElementSibling == null) {
        // break;
      }
      const previousPageSpans1 = textNode.previousElementSibling.querySelectorAll('span');
      textNode = previousPageSpans1[previousPageSpans.length - 1];
    }
    textNode = textNode.previousElementSibling;
  }
  else {
    checkIfPreviousEleContainFullStop(textNode.previousElementSibling);
  }
  return textNode;
}

function checkIfNextEleContainFullStop(textNode) {
  if (textNode.nextElementSibling.textContent.includes('. ')) {
    textNode = textNode.nextElementSibling;
    if (textNode.classList.contains('endOfContent')) {
      textNode = textNode.closest('#viewerContainer').parentElement;
      if (textNode.nextElementSibling == null) {
        // break;
      }
      textNode = textNode.nextElementSibling.querySelector('span');
    }
  }
  else {
    return checkIfNextEleContainFullStop(textNode.nextElementSibling);
  }
    return textNode;
  }
  