<template>
  <img :src="assetsUrl + 'img/blue-logo.svg'" alt="Enago Read logo" />
</template>

<script>
export default {
  data() {
    return {
      assetsUrl: process.env.assetsUrl
    };
  }
};
</script>
