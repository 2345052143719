<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <defs />
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path
      fill="currentColor"
      d="M21 4l-3-2h-1l-1 1h-1l-1-2H4L3 2v17l1 1h13l1-1V8l3-3V4zm-5 14H5V3h8l1 1-7 7v5l4-1 5-5v8zm-7-6l2 1-3 1 1-2zm7-4l-4 4-2-2 5-5 2 2-1 1zm2-2l-2-2h1V3l2 2-1 1zm3 4v12l-1 1H7v-2h12V10h2z"
    />
  </svg>
</template>

<script>
export default {};
</script>
