var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.getShowHeader
    ? _c(
        "header",
        {
          staticClass:
            "md-navbar md-navbar--dense md-navbar--has-menu-button rx-header-animate md-navbar--fixed md-navbar--light md-navbar--casting-border tw-p-0",
          class: { "rx-nav-hide-mobile": !_vm.isAuthenticated },
          staticStyle: {
            "background-color": "#FFFFFF",
            "border-bottom": "1px solid #FFFFFF",
            "box-shadow": "inset 0px -1px 0px #E6E9EE"
          },
          attrs: { role: "banner" },
          on: { click: _vm.hideAppSheet }
        },
        [
          _c("div", { staticClass: "md-container topHeaderContainer" }, [
            _c(
              "div",
              {
                staticClass: "md-navbar__row",
                class: {
                  "tw-justify-between": _vm.displayLandingNav,
                  menuOuterSection: !_vm.isAuthenticated
                }
              },
              [
                _c("div", {
                  staticClass: "pageNameSection",
                  class: { userLogIn: _vm.isAuthenticated }
                }),
                _vm._v(" "),
                _c("app-logo", { staticClass: "app-logo" }),
                _vm._v(" "),
                !_vm.displayLandingNav
                  ? _c("div", { staticClass: "md-layout-spacer" })
                  : _vm._e(),
                _vm._v(" "),
                _vm.isLibraryAccess &&
                !_vm.isAuthenticated &&
                _vm.routerName !== "discover-library"
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "md-nav md-nav--compact hidden--lt-sm md-nav--color-dark-primary md-align md-align--center-center"
                      },
                      [
                        _c(
                          "nuxt-link",
                          {
                            staticClass: "md-nav__link md-text-primary",
                            attrs: { to: "/discover/library", tag: "a" }
                          },
                          [_vm._v("RC Resources")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.displayLandingNav
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "md-nav hidden--lt-sm md-nav--compact md-nav--color-dark-primary md-align md-align--center-center"
                      },
                      _vm._l(_vm.navItems, function(item) {
                        return _c(
                          "a",
                          {
                            key: item.title,
                            staticClass: "md-nav__link tw-relative",
                            staticStyle: { "font-weight": "500" },
                            attrs: { href: _vm.landingUrl + item.value }
                          },
                          [
                            item.value === "review-assistant"
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "tw-absolute tw-font-medium tw-border tw-border-solid tw-border-green-primary tw-rounded tw-text-green-primary tw-px-1",
                                    staticStyle: {
                                      top: "5px",
                                      width: "max-content",
                                      "font-size": "10px",
                                      "margin-left": "3px"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n            Get early access\n          "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(
                              "\n          " + _vm._s(item.title) + "\n        "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showMobileMenu,
                        expression: "showMobileMenu"
                      }
                    ],
                    staticClass:
                      "mobileMenuSection md-nav--color-dark-primary md-align md-align--center-center"
                  },
                  [
                    _vm._l(_vm.navItems, function(item) {
                      return _c(
                        "a",
                        {
                          key: item.title,
                          staticClass: "md-nav__link tw-relative",
                          staticStyle: { "font-weight": "500" },
                          attrs: { href: _vm.landingUrl + item.value }
                        },
                        [
                          item.value === "review-assistant"
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "tw-absolute tw-font-medium tw-border tw-border-solid tw-border-green-primary tw-rounded tw-text-green-primary tw-px-1",
                                  staticStyle: {
                                    top: "5px",
                                    width: "max-content",
                                    "font-size": "10px",
                                    "margin-left": "3px"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            Get early access\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(
                            "\n          " + _vm._s(item.title) + "\n        "
                          )
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _vm._m(0)
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.isAuthenticated
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "md-navbar__toolbar layout-m-r-1 md-nav--color-dark-primary tw-items-center",
                        staticStyle: { "margin-right": "8px !important" }
                      },
                      [
                        _c("search-box"),
                        _vm._v(" "),
                        _c(
                          "tooltip",
                          {
                            attrs: {
                              id: "notification",
                              title: "Notifications"
                            }
                          },
                          [
                            _c("img", {
                              staticClass: "tw-cursor-pointer bell",
                              attrs: {
                                src: _vm.assetsUrl + "img/icon/bell.svg"
                              },
                              on: { click: _vm.onReleaseNoteClicked }
                            })
                          ]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isAuthenticated && !_vm.disableBtn
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "md-navbar__toolbar md-nav--color-dark-primary layout-m-r-1"
                      },
                      [_vm._m(1)]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isAuthenticated
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "tw-flex tw-items-center tw-mr-4 md:tw-mr-16"
                      },
                      [
                        _c(
                          "nuxt-link",
                          {
                            staticClass:
                              "mobileSignUpForFree tw-h-8 tw-px-4 tw-font-medium tw-text-white tw-text-sm tw-rounded tw-bg-blue-primary",
                            attrs: { to: "/auth/signup", tag: "button" }
                          },
                          [_vm._v("\n          Sign up for free\n        ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "nuxt-link",
                          {
                            staticClass:
                              "mobileSignInButton tw-py-1 tw-h-8 tw-px-4 tw-ml-6 tw-text-sm tw-text-blue-primary tw-rounded-sm tw-border tw-border-solid tw-border-blue-primary",
                            attrs: { to: "/auth/signin", tag: "button" }
                          },
                          [_vm._v("\n          Sign in\n        ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "MobileMenuButton tw-h-8 tw-px-4 tw-font-medium tw-text-white tw-text-sm tw-rounded tw-bg-blue-primary",
                            on: {
                              click: function($event) {
                                _vm.showMobileMenu = !_vm.showMobileMenu
                              }
                            }
                          },
                          [_vm._v("Menu")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isAuthenticated
                  ? _c("profile-btn", {
                      staticClass: "tw-mx-2",
                      attrs: {
                        icon: _vm.icon,
                        "is-img": _vm.isImg,
                        "img-src": _vm.profileImage
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]),
          _vm._v(" "),
          false
            ? _c(
                "modal",
                {
                  attrs: {
                    name: "payment-update",
                    transition: "pop-out",
                    adaptive: true,
                    "focus-trap": true,
                    scrollable: true,
                    height: "auto"
                  }
                },
                [_c("PaymentMethodUpdate")],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "resources-parent tw-cursor-pointer" }, [
      _c(
        "div",
        {
          staticClass:
            "md-nav__link resources-tab tw-flex tw-items-center tw-font-bold",
          staticStyle: { position: "relative" }
        },
        [
          _vm._v("\n            Resources\n            "),
          _c("span", {
            staticClass:
              "raxIcon raxIcon-arrow-drop-down raxIcon--black56 tw-transition-transform tw-duration-200"
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "ul",
        {
          staticClass:
            "tw-items-center tw-ml-2 tw-justify-between tw-fixed tw-bg-white tw-text-sm tw-text-gray-500 tw-flex resources-list"
        },
        [
          _c(
            "li",
            {
              staticClass:
                "hover:tw-bg-black-4 tw-transition-colors tw-duration-300"
            },
            [
              _c(
                "a",
                {
                  staticClass:
                    "tw-block tw-py-3 tw-px-4 tw-text-black-56 tw-text-sm tw-font-medium tw-border-b-2 tw-border-transparent",
                  attrs: {
                    href: "https://blog.raxter.io/",
                    rel: "noreferrer noopener",
                    target: "_blank"
                  }
                },
                [_vm._v("\n                Blog\n              ")]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "li",
            {
              staticClass:
                "hover:tw-bg-black-4 tw-transition-colors tw-duration-300"
            },
            [
              _c(
                "a",
                {
                  staticClass:
                    "tw-block tw-py-3 tw-text-black-56 tw-px-4 tw-text-sm tw-font-medium tw-border-b-2 tw-border-transparent",
                  attrs: {
                    href:
                      "https://www.youtube.com/playlist?list=PLeQNr1XWrB0xaDgRN3zgn0ihY9Q1k33p5",
                    rel: "noreferrer noopener",
                    target: "_blank"
                  }
                },
                [_vm._v("\n                Tutorials\n              ")]
              )
            ]
          )
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "hidden md-button md-button--icon",
        attrs: {
          onclick: "$('.md-sheet-layer').toggleClass('md-sheet-layer--visible')"
        }
      },
      [_c("i", { staticClass: "material-icons" }, [_vm._v("notifications")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }