<template>
  <div
    style="cursor: pointer"
    data-autofocus="false"
    data-location="bottom"
    data-target="#app-account-menu"
    :data-toggle="![UserStatus.KIDemoOld, UserStatus.KIDemoNew, UserStatus.ReadingDemo, UserStatus.RedditDemo].includes(user.onboardingStatus) ? 'menu' : ''"
    @click="checkUserEmail"
  >
    <button
      :class="{ 'md-button--active': icon }"
      :style="backgroundImage"
      class="md-button md-button--profile tw-mx-1"
    >
      <template v-if="!isImg && icon">
        <img class="avatar-icon" :src="`${assetsUrl}img/icon/avatar.svg`" />
      </template>
      <template v-else>
        {{ letter }}
      </template>
    </button>
    <!-- <i
      style="margin-left: -4px;margin-right: 0"
      class="material-icons md-button__icon"
    >
      arrow_drop_down
    </i> -->
  </div>
</template>

<style>
.avatar-icon {
  margin: 0% 10%;
}
</style>>

<script>
import { mapGetters } from "vuex";
import { UserStatus } from '~/shared/constants';

export default {
  props: {
    isImg: {
      type: Boolean,
      default: false
    },
    imgSrc: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    letter: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      UserStatus,
      assetsUrl: process.env.assetsUrl
    }
  },
  computed: {
    ...mapGetters({
      user: "user/getData"
    }),
    backgroundImage() {
      return this.isImg ? { backgroundImage: "url(" + this.imgSrc + ")" } : {};
    }
  },
  methods: {
    checkUserEmail(){
      this.$mixpanel.track('(Desk) Profile');
      if([UserStatus.KIDemoOld, UserStatus.KIDemoNew, UserStatus.ReadingDemo, UserStatus.RedditDemo].includes(this.user.onboardingStatus)){
        this.$store.commit("user/setLastAction", "Profile icon click from header");
        this.$store.commit("user/setShowEmailModal", 1);
      }
    }
  }
};
</script>
