import AsyncComponent from "@/assets/js/helper";
import { mapGetters } from "vuex";
import { MixpanelEventGroup, MixpanelEvents, PlanCategory, UserStatus } from "~/shared/constants";
const defaultConfig = {
  adaptive: true,
  scrollable: true,
  height:'auto',
}
const warningModal = {
  data(){
    return {PlanCategory}
  },
  computed: {
    ...mapGetters({
      subscriptionData: "subscription/getSubscriptionData",
      selectedPlan: 'subscription/getSelectedPlan',
      userData: "user/getData",
    })
  },
  methods: {
    showMoreSuggestionInfo(data,restProps){
      this.$store.commit("core/setAppLoading", true);
      const component = async () =>await AsyncComponent(`literature/child/suggestionInfoModal`).component;
      this.$store.commit("core/setAppLoading", false);
      this.$modal.show(component, {
          literatureData: data,
          ...restProps
        },
        {...defaultConfig, width:'1120px', height:'560px', clickToClose:true, name: "suggestion-info-modal"}
      );
    },
    showMoreLiteratureInfo(data, restProps){
      let beforeClose = (evt)=>{
        if (document && document.querySelector('.viewer-container.viewer-backdrop.viewer-in')) {
          evt.stop();
        }else{
          // send close event
          if (data) {
            this.$mixpanel.track(MixpanelEvents[MixpanelEventGroup.PaperMoreInfo].ClosePanel, {
              literature: data.id,
              havePDF: !!data.s3Key
          });
          }
        }
      }
      this.$store.commit("core/setAppLoading", true);
      const component = async () =>
        await AsyncComponent(`literature/child/moreInfoModal`).component;
      this.$store.commit("core/setAppLoading", false);
      this.$modal.show(component, {
          literature: data,
          ...restProps
        },
        {...defaultConfig, width:'1120px', height:'560px', clickToClose:true, name: "more-info-modal"},
        { 'before-close': beforeClose }
      );
    },
    showMoreReviewerLiteratureInfo(data, {pdfUploadCallBack, openKeyInsightCallBack, abstractSwitch, areCarouselArrowsVisible}){
      let beforeClose = (evt)=>{
        if (document && document.querySelector('.viewer-container.viewer-backdrop.viewer-in')) {
          evt.stop();
        }else{
          // send close event
          if (data) {
            this.$mixpanel.track(MixpanelEvents[MixpanelEventGroup.PaperMoreInfo].ClosePanel, {
              literature: data.id,
              havePDF: !!data.s3Key
          });
          }
        }
      }
      this.$store.commit("core/setAppLoading", true);
      const component = async () =>
        await AsyncComponent(`reviewerLiterature/child/moreInfoModal`).component;
      this.$store.commit("core/setAppLoading", false);
      this.$modal.show(component, {
        literature: data,
        pdfUploadCallBack,
        openKeyInsightCallBack,
        abstractSwitch,
        areCarouselArrowsVisible
      },
         {...defaultConfig, width:'70%',height:'85%', clickToClose:true, name: "more-info-modal"},
         { 'before-close': beforeClose }
         );
    },
    openWarningModal(data, modalConfig = { height: "auto", width: "480" }) {
      this.$store.commit("core/setAppLoading", true);
      const component = async () =>
        await AsyncComponent(`ui/warningModal`).component;
      this.$store.commit("core/setAppLoading", false);
      this.$modal.show(component, data, modalConfig);
    },
    upgradePlanWarningModal(mixpanelEvent, mixpanelAction, modalCalledFrom) {
      const mixpanelPayload = {
        current_plan_name: this.selectedPlan.name,
        current_plan_category: parseInt(this.selectedPlan.category),
        current_plan_id: this.subscriptionData.planId,
        current_plan_billing_cycle: this.subscriptionData.billingCycle,
        current_plan_end_date: new Date(this.subscriptionData.planEndDate).toISOString(),
        trial_end_date: new Date(this.subscriptionData.trialEndDate).toISOString(),
        user_current_subscription_status: this.subscriptionData.status,
        action: mixpanelAction,
        from: modalCalledFrom,
      };

      if (mixpanelEvent) {
        this.$mixpanel.track(mixpanelEvent, mixpanelPayload);
      }

      if (true) {
        // Subscribe to plan warning
        this.openWarningModal({
          submitBtnProps:{
            label: 'Subscribe',
            handler: () => {
              this.$mixpanel.track('Click Subscribe From Warning Modal', mixpanelPayload);
              this.$router.push({ name: "subscription" })
            },
          },
          cancelBtnProps: {
            label: 'Not now',
            handler: () => {
              this.$mixpanel.track('Subscribe Plan Warning Modal Closed', mixpanelPayload);
            }
          },
          content:`Seems like you are finding Enago Read quite useful! You are currently using basic plan with limits on feature usage.<br/><br/>
          Kindly subscribe to a suitable plan to continue using all features of Enago Read for uninterrupted research productivity.`,
          header: `Subscribe and continue`
        },)

      }


    },
    extendTrial() {
      // components/subscription/extendTrial.vue
      this.$store.commit("core/setAppLoading", true);
      const component = async () =>
        await AsyncComponent(`subscription/extendTrial`).component;
      this.$store.commit("core/setAppLoading", false);
      this.$modal.show(component, {trialEndDate: this.subscriptionData.planEndDate}, defaultConfig);
    },
    verifyEmail(){
       this.$store.commit("core/setAppLoading", true);
       const component = async () =>
         await AsyncComponent(`authNew/VerifyEmailModal`).component;
       this.$store.commit("core/setAppLoading", false);
       this.$modal.show(component, {openMode:'modal'}, defaultConfig);
    },
    verifyInstituteEmail(){
      this.$store.commit("core/setAppLoading", true);
       const component = async () =>
         await AsyncComponent(`common/create/institutionVerify`).component;
       this.$store.commit("core/setAppLoading", false);
       const aff = this.$store.getters["aff/getData"];
       this.$modal.show(component, {openMode:'modal', formData:{ institutionEmail: aff.institutionEmail }, unid:'institution-verify'}, {...defaultConfig, name:'institution-verify'});
    },
    verifyInstituteEmailNew(){
      this.$store.commit("core/setAppLoading", true);
       const component = async () =>
         await AsyncComponent(`common/create/institutionAddVerify`).component;
       this.$store.commit("core/setAppLoading", false);
       const aff = this.$store.getters["aff/getData"];

       const onVerify = async () => {
        await this.$store.dispatch('aff/fetch');
        this.$store.dispatch('subscription/fetch');
        await this.$store.dispatch('subscription/fetchPlanList');
     }
      this.$modal.show(component, {
        openMode:'modal',
        formData:{
          ...aff
        }, afterVerify:onVerify}, {...defaultConfig, name:'institution-verify-add'});
    },
    submissionExhausted(isSubmission=true){
    const onOk = () => {
    // this.$router.push('/projects');
    }
    const content = `<div>
    <span>
    You have reached the maximum ${isSubmission? '<strong>limit of 6 active submissions</strong> at any given point in time. You can choose to delete existing ones to add more':'<strong>limit of 6 related literatures</strong> for a review'}.
    <br/><br/>
    However, if you want to do a literature review for your research, Enago Read is better suited to your needs.
    </span>
  </div>`
     this.openWarningModal(
          {
          submitBtnProps:{
            label: 'Try RAx',
            handler: onOk.bind(this),
            class: 'tw-bg-blue-primary tw-rounded tw-text-white'
          },
          content: content,
          header: `Maximum limit reached!`
        })
    },
    showRoleModal(redirectToSubscription = true) {
      // this.$store.commit("core/setAppLoading", true);
      //  const component = async () =>
      //    await AsyncComponent(`common/child/userInfoModal`).component;
      //  this.$store.commit("core/setAppLoading", false);
      //  this.$modal.show(component, {clearModal: () => this.$modal.hide('user-info-wrapper'), redirectToSubscription}, {...defaultConfig, name:'user-info-wrapper'});
    },
    errorNonParsedPdf(isTldr = false) {
      this.openWarningModal({
        content: `
        <div class="tw-text-sm tw-text-black-72">
        <p> Sorry, we are not able to generate ${ isTldr ? "Summary" : "Key Insights" } since the information from this PDF cannot be extracted.
        Please try uploading another PDF and check again </p>
        </div>`,
        header: `PDF cannot be processed`,
        haveActionBtn: false
      });
    },
    botUserSignupModal(lastActionMsg=''){
      if ([UserStatus.KIDemoOld, UserStatus.ReadingDemo, UserStatus.RedditDemo].includes(this.userData.onboardingStatus)) {
        this.$store.commit("user/setLastAction", lastActionMsg);
        this.$store.commit("user/setShowEmailModal", 1);
        return false;
      }
      return true;
    },
  }
};
export default warningModal;
