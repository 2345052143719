<template>
  <div
    style="min-height: calc(100vh - 105px)"
    class="md-layout md-align--center-center md-align"
  >
    <div class="md-layout md-layout--column md-flex--grow">
      <div class="rx-empty rx-empty--page">
        <img :src="assetsUrl + currentError.imgSrc" class="rx-empty__img" />
        <div
          class="md-typo--title rx-empty__title my-typo--font-light layout-m-t-1"
        >
          {{ error.title ? error.title : currentError.title }}
        </div>
        <div class="rx-empty__subtext">
          {{ error.message ? error.message : currentError.sueHeading }}
        </div>
        <nuxt-link
          to="/"
          class="layout-m-t-2 md-button md-button--raised md-button--primary"
        >
          Go to homepage
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<style>
.rx-empty--page {
  width: 90%;
  max-width: 520px;
  margin-left: auto;
  margin-right: auto;
}
</style>

<script>
import findObjectIndex from "@/assets/js/commonHelper";
export default {
  props: {
    error: {
      type: [Object, String],
      required: true
    }
  },
  layout: "default", // you can set a custom layout for the error page,
  data() {
    return {
      landingUrl: process.env.landingUrl,
      assistantUrl: process.env.assistantUrl,
      assetsUrl: process.env.assetsUrl,
      baseUrl: process.env.baseUrl,
      imgSrc: "img/state/404.png",
      errorArray: [
        {
          statusCode: "",
          imgSrc: "img/state/something-wrong.png",
          title: "Something went wrong",
          sueHeading: ""
        },
        {
          statusCode: 401,
          imgSrc: "img/state/401.png",
          title: "Unauthorized",
          sueHeading: "You are not authorized to access this page"
        },
        {
          statusCode: 404,
          imgSrc: "img/state/404.png",
          title: "Page not found",
          sueHeading: "The page you looking for is not found"
        },
        {
          statusCode: 500,
          imgSrc: "img/state/500.png",
          title: "Internal Server Error",
          sueHeading:
            "Something went wrong at our end. Don't worry, it's not you - it's us."
        }
      ]
    };
  },
  computed: {
    currentError() {
      let index = findObjectIndex(
        this.errorArray,
        { statusCode: this.error.statusCode ? this.error.statusCode : "" },
        "statusCode"
      );
      return index !== -1 ? this.errorArray[index] : this.errorArray[0];
    }
  }
};
</script>
