import findObjectIndex from "@/assets/js/commonHelper";
const defaultProfileProjectState = () => {
  return {
    data: []
  }
};

export default {
  namespaced: true,
  state: defaultProfileProjectState,

  mutations: {
    setData(state, data) {
      state.data = data;
    },
    addData(state, data) {
      state.data.unshift(data);
    },
    updateData(state, data) {
      let index = findObjectIndex(state.data, data);
      state.data.splice(index, 1, data);
    },
    deleteData(state, data, index) {
      state.data.splice(index, 1);
    },
    resetState(state) {
      Object.assign(state, defaultProfileProjectState());
    }
  },

  actions: {
    async fetch({commit}) {
      let response = await this.$axios.get(`/profileprojects`);
      commit('setData', response.data);
    },

    async update({commit}, inputs) {
      const {data} = await this.$axios.put(`/profileprojects`, inputs);
      commit("updateData", data[0]);
    },

    async create({commit}, inputs) {
      inputs.startDate = inputs.startDate? inputs.startDate + ' 00:00:00': '';
      inputs.endDate = inputs.endDate? inputs.endDate + ' 00:00:00' : '';
      const {data} = await this.$axios.post(`/profileprojects`, inputs);
      commit("addData", data);
    },

    async delete({ commit }, inputs) {
      inputs.startDate = inputs.startDate? inputs.startDate + ' 00:00:00': '';
      inputs.endDate = inputs.endDate? inputs.endDate + ' 00:00:00' : '';
      await this.$axios.delete(`/profileprojects/${inputs.item.id}`);
      commit("deleteData", inputs.index);
    }
  },

  getters: {
    getData(state) {
      return state.data;
    }
  }
};
