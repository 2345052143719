import Vue from "vue";
import { RaxSpecialOffer } from "~/components/literature/constants";
import { Rax_Crypto_Pwd } from '~/shared/constants';

const AES = require("crypto-js/aes");

const cookieparser = process.server ? require("cookieparser") : undefined;
const Cookie = process.client ? require("js-cookie") : undefined;
const dateDiffDays = (date1, date2) => {
  const dt1 = new Date(date1);
  const dt2 = new Date(date2);
  return Math.floor(
    (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
      Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24)
  );
};

export default {
  namespaced: true,

  state: () => ({
    token: {
      token: null,
      refreshToken: null,
      expiresIn: null,
      raxToken:null
    },
    botToken: null,
    referrer: ""
  }),

  mutations: {
    setData(state, obj) {
      state[obj.name] = obj.data;
    },
    setObjData(state, obj) {
      Vue.set(state[obj.name], obj.key, obj.data);
    }
  },

  actions: {
    async authenticateUser({ commit, state }, {access_token, refresh_token, expiresIn, rax_token}) {
      const expiresDay = dateDiffDays(new Date().getTime(), expiresIn*1000);
      commit("setObjData", { name: "token", key: "token", data: access_token });
      commit("setObjData", { name: "token",key:"refreshToken", data: refresh_token });
      commit("setObjData", { name: "token",key:"raxToken", data: rax_token });
      commit("setObjData", {
        name: "token",
        key: "expiresIn",
        data: expiresIn
      });
      if(process.client){
        console.log("In process.client");
        localStorage.setItem(`access_token_${process.env.CookieEnv}`, access_token);
        localStorage.setItem(`rax_refresh_token_${process.env.CookieEnv}`, refresh_token);
        localStorage.setItem(`rax_token_${process.env.CookieEnv}`, rax_token);
        localStorage.setItem(
          `rax_expires_${process.env.CookieEnv}`,
          expiresIn
        );
        Cookie.set(`access_token_${process.env.CookieEnv}`, access_token, {
          domain: process.env.domain,
          expires: 1
        });
        Cookie.set(`rax_expires_${process.env.CookieEnv}`, expiresIn, {
          domain: process.env.domain,
          expires: 1
        });
        Cookie.set(`rax_refresh_token_${process.env.CookieEnv}`, refresh_token, {
          domain: process.env.domain,
          expires: 1
        });
        Cookie.set(`rax_token_${process.env.CookieEnv}`, rax_token, {
          domain: process.env.domain,
          expires: 1
        });
      }
    },
    async updateAccessToken({commit,state,dispatch},{expires_in, refresh_token,token}){
      try{
        let access_token = token;
        let rax_token = state.token.raxToken;
        let expiresIn = expires_in;
        await dispatch("authenticateUser",{access_token,rax_token,expiresIn,refresh_token});
      }catch(err){
        console.log("error", err);
      }
    },
    async signIn({ commit, dispatch }, obj) {
      obj.password = btoa(obj.password);
      const data = await this.$axios.$post("auth/signin", obj).catch(error => {
        return Promise.reject(error);
      });
      await dispatch("authenticateUser", data.token);
      this.commit("user/setCount", data.signInCount);
      this.commit("user/setData", data.user);
      this.commit("profile/setData", data.profile);
      await this.dispatch("subscription/refreshSubscriptionData");
      this.$mixpanel.identify(parseInt(data.user.id));
      return data;
    },

    async signUp({ dispatch, commit }, obj) {
      obj.password = btoa(obj.password); 
      const data = await this.$axios
        .$post("auth/signup", obj)
        .catch(error => {
          return Promise.reject(error);
        });
      await dispatch("logout");
      await dispatch("authenticateUser", data.token);
      this.commit("user/setCount", data.signInCount);
      this.commit("user/setData", data.user);
      this.commit("profile/setData", data.profile);
      await this.dispatch("subscription/refreshSubscriptionData");
      this.$mixpanel.identify(parseInt(data.user.id));
      return data;
    },

    async signUpNew({ dispatch, commit }, obj) {
      const data = await this.$axios
        .$post("auth/signup-user-email", obj)
        .catch(error => {
          return Promise.reject(error);
        });
      dispatch("authenticateUser",data.token);
      this.commit("user/setCount", data.signInCount);
      this.commit("user/setData", data.user);
      this.commit("profile/setData", data.profile);
      this.dispatch("subscription/refreshSubscriptionData");
      this.$mixpanel.identify(parseInt(data.user.id));
      return data;
    },

    async refreshToken({ dispatch, commit }, obj) {
      const {data, status} = await this.$axios
        .$post("auth/refresh-token", obj)
        .catch(error => {
          return Promise.reject(error);
        });
      await dispatch("authenticateUser", data.token);
      this.dispatch("subscription/refreshSubscriptionData");
      return {data,status};
    },

    initAuth({ state, commit, dispatch }, req) {
      let token, expiresIn,refreshToken, raxToken;
      if (process.client) {
        // fill the localStorage if token available in store
        if (state.token.token) {
          localStorage.setItem(
            `access_token_${process.env.CookieEnv}`,
            state.token.token
          );
          localStorage.setItem(
            `rax_expires_${process.env.CookieEnv}`,
            state.token.expiresIn
          );
        }
        if(state.token.refreshToken){
          localStorage.setItem(
            `rax_refresh_token_${process.env.CookieEnv}`,
            state.token.refreshToken
          );
        }
        if(state.token.raxToken){
          localStorage.setItem(
            `rax_token_${process.env.CookieEnv}`,
            state.token.raxToken
          );    
        }
      }

      if (req) {
        if (!req.headers.cookie) {
          console.log("cookies are deleted from line 179");
          dispatch("logout");
          return;
        }
        const Cookie = cookieparser.parse(req.headers.cookie);
        if (!Cookie) {
          console.log("cookues are delted from line 186") 
          dispatch("logout");
          return;
        }
        console.log(Cookie);
        token = Cookie[`access_token_${process.env.CookieEnv}`];
        expiresIn = Cookie[`rax_expires_${process.env.CookieEnv}`];
        refreshToken = Cookie[`rax_refresh_token_${process.env.CookieEnv}`];
        raxToken = Cookie[`rax_token_${process.env.CookieEnv}`];
      }else if (process.client) {
        token = localStorage.getItem(`access_token_${process.env.CookieEnv}`);
        expiresIn = localStorage.getItem(
          `rax_expires_${process.env.CookieEnv}`
        );
        refreshToken = localStorage.getItem(`rax_refresh_token_${process.env.CookieEnv}`);
        raxToken = localStorage.getItem(`rax_token_${process.env.CookieEnv}`);
      }


      if (new Date().getTime() > expiresIn*1000 || !token) {
        console.log("cookies are deleted fron line 206");
        dispatch("logout");
        return;
      }
      commit("setObjData", { name: "token", key: "token", data: token });
      commit("setObjData", {
        name: "token",
        key: "expiresIn",
        data: expiresIn
      });
      commit("setObjData", { name: "token",key:"refreshToken", data: refreshToken });
      commit("setObjData", { name: "token",key:"raxToken", data:  raxToken});
    },

    logout({ commit, dispatch }) {
      commit("setObjData", { name: "token", key: "token", data: null });
      commit("setObjData", {
        name: "token",
        key: "expiresIn",
        data: null
      });
      commit("setObjData", { name: "token",key:"refreshToken", data: null });
      commit("setObjData", { name: "token",key:"raxToken", data: null });
      if (process.client) {
        if(window && window.chrome && window.chrome.runtime){
          chrome.runtime.sendMessage("edfmnnpmhdncblddjhmfmfnoeiliidei", {action:"callFunction",functionToCall:"deleteTokenFromLocalStorage"},
            function(response) {
              console.log(response);
          });
        }
        Cookie.remove(`access_token_${process.env.CookieEnv}`, {
          domain: process.env.domain
        });
        Cookie.remove(`rax_expires_${process.env.CookieEnv}`, {
          domain: process.env.domain
        });
        Cookie.remove(`rax_refresh_token_${process.env.CookieEnv}`, {
          domain: process.env.domain
        });
        Cookie.remove(`rax_token_${process.env.CookieEnv}`, {
          domain: process.env.domain
        });
        localStorage.removeItem(`access_token_${process.env.CookieEnv}`);
        localStorage.removeItem(`rax_expires_${process.env.CookieEnv}`);
        localStorage.removeItem(`rax_refresh_token_${process.env.CookieEnv}`);
        localStorage.removeItem(`rax_token_${process.env.CookieEnv}`);
        localStorage.removeItem(RaxSpecialOffer);
        dispatch("resetAll");
      }
    },

    async updatePassword(vuexContext, inputs) {
      inputs.currentPass = btoa(inputs.currentPass);
      inputs.newPass = btoa(inputs.newPass);
      await this.$axios.put(`/auth/update-password`, inputs).catch(error => {
        return Promise.reject(error);
      });
    },

    async resetPassword(vuexContext, params) {
      params.password = btoa(params.password); 
      await this.$axios.put(`/auth/reset-password`, params).catch(error => {
        return Promise.reject(error);
      });
    },

    resetAll() {
      this.commit("aff/resetState");
      this.commit("bookmarks/resetState");
      this.commit("contacts/resetState");
      this.commit("core/resetState");
      this.commit("draftReferences/resetState");
      this.commit("drafts/resetState");
      this.commit("experience/resetState");
      this.commit("faculty/resetState");
      this.commit("feeds/resetState");
      this.commit("library/resetState");
      this.commit("literatureReferences/resetState");
      this.commit("literatures/resetState");
      this.commit("profile/resetState");
      this.commit("profileProjects/resetState");
      this.commit("projects/resetState");
      this.commit("publications/resetState");
      this.commit("researchAreas/resetState");
      this.commit("results/resetState");
      this.commit("review/resetState");
      this.commit("sharedComments/resetState");
      this.commit("subscription/resetState");
      this.commit("sharedDrafts/resetState");
      this.commit("sharedItemUsers/resetState");
      this.commit("sharedLiteratures/resetState");
      this.commit("sharedReview/resetState");
      this.commit("skills/resetState");
      this.commit("user/resetState");
      this.commit("userSession/resetState");
      this.commit("projectFeeds/resetState");
      this.commit("reminders/resetState");
      this.commit("referralProgram/resetState");
      this.commit("review/resetState");
      this.commit("reviewerDrafts/resetState");
      this.commit("reviewerFeeds/resetState");
      this.commit("reviewerLiteratures/resetState");
      this.commit("reviewerProjectFeeds/resetState");
      this.commit("reviewerProjects/resetState");
      this.commit("reviewerRelatedItems/resetState");
      this.commit("reviewerResults/resetState");
      this.commit("reviewerReview/resetState");
      this.commit("reviewerSummarizer/resetState");
      this.commit("reviewerTldr/resetState");
      this.commit("reviewerLiteratureReferences/resetState");
    },

    async sendOTP(vuexContext, params) {
      await this.$axios
        .$post("auth/generate-password-otp", params)
        .catch(error => {
          return Promise.reject(error);
        });
    },

    async generateOTP(vuexContext, params) {
      await this.$axios.$post("auth/generate-otp", params).catch(error => {
        return Promise.reject(error);
      });
    },

    async verifyOTP(vuexContext, params) {
      return await this.$axios.$post("auth/verify-otp", params).catch(error => {
        return Promise.reject(error);
      });
    },

    async generatePasswordResetOTP(vuexContext, params) {
      return await this.$axios
        .$post("auth/generate-password-otp", params)
        .catch(error => {
          return Promise.reject(error);
        });
    },
    async forgotPassword(vuexContext, params) {
      return await this.$axios
        .$post("auth/forgot-password", params)
        .catch(error => {
          return Promise.reject(error);
        });
    },
    async verifyPasswordRestOTP(vuexContext, params) {
      return await this.$axios
        .$post("auth/verify-password-otp", params)
        .catch(error => {
          return Promise.reject(error);
        });
    },

    async generateInstituteOTP(vuexContext, params) {
      await this.$axios
        .$post("auth/generate-institution-otp", params)
        .catch(error => {
          return Promise.reject(error);
        });
    },

    async verifyInstituteOTP(vuexContext, params) {
      const res = await this.$axios
        .$post("auth/verify-institution-otp", params)
        .catch(error => {
          return Promise.reject(error);
        });
      return { ...res.data, customerInstitutionUser: res.customerInstitutionUser };
    },

    async isEmailVerified(vuexContext, params) {
      return await this.$axios.$post("auth/is-email-verified", params);
    },

    async getReferrer({ commit, state }, { referralCode }) {
      console.log(referralCode);
      const data = await new Promise(function(resolve) {
        setTimeout(resolve, 1000, "Anita");
      });
      this.commit("auth/setData", {
        name: "referrer",
        data: data
      });
    }
  },

  getters: {
    isAuthenticated(state) {
      return !!state.token.token;
    },
    getRefreshToken(state){
      return state.token.refreshToken
    },
    token(state) {
      return state.token.token;
    },
    botToken(state) {
      return state.botToken;
    },
    raxToken(state) {
      return state.token.raxToken;
    },
    referrer(state) {
      return state.referrer;
    }
  }
};
