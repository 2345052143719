var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "reminder-list",
      class: {
        "hover:tw-bg-black-6 tw-transition-colors tw-duration-300": [
          "upcoming",
          "overdue"
        ].includes(_vm.tab)
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "tw-flex tw-items-center",
          attrs: { id: "reminder-" + _vm.index }
        },
        [
          _c(
            "div",
            {
              staticClass: "tw-flex tw-w-fit tw-items-center tw-mt-4 tw-ml-4",
              class: {
                "tw-text-blue-primary tw-cursor-pointer":
                  _vm.tab === "upcoming",
                "tw-text-blue-primary": _vm.tab === "td-reminders",
                "tw-text-red-primary tw-cursor-pointer": _vm.tab === "overdue",
                "tw-text-black-72": _vm.tab === "completed"
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.$emit(
                    "editReminder",
                    "reminder-" + _vm.index,
                    _vm.item.literature,
                    _vm.item
                  )
                }
              }
            },
            [
              _vm.tab !== "completed"
                ? _c(
                    "i",
                    {
                      staticClass:
                        "material-icons tw-text-xl tw-mr-1 tw-p-1 tw-rounded-lg"
                    },
                    [_vm._v("\n        alarm\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.tab === "completed"
                ? _c("span", {
                    staticClass:
                      "raxIcon raxIcon-check raxIcon--black72 tw-mb-1"
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("span", [
                _vm._v(" " + _vm._s(_vm.formatTime(_vm.item.scheduledAt)) + " ")
              ]),
              _vm._v(" "),
              ["upcoming", "overdue"].includes(_vm.tab)
                ? _c("span", {
                    staticClass:
                      "raxIcon raxIcon-edit tw-ml-2 tw-opacity-0 tw-transition-opacity tw-duration-300 tw-flex tw-items-center show-on-hover"
                  })
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          ["upcoming", "overdue"].includes(_vm.tab)
            ? _c(
                "span",
                {
                  staticClass:
                    "tw-mt-4 tw-flex tw-items-center tw-opacity-0 tw-transition-opacity tw-duration-300 show-on-hover"
                },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        " tw-bg-blue-primary tw-ml-15 tw-text-white tw-leading-4 tw-px-4 tw-text-xs tw-h-6 tw-rounded",
                      on: {
                        click: function($event) {
                          return _vm.updateData(1)
                        }
                      }
                    },
                    [_vm._v("\n        Mark as complete\n      ")]
                  ),
                  _vm._v(" "),
                  _c("span", {
                    staticClass:
                      "raxIcon raxIcon-delete tw-ml-8 tw-flex tw-items-center tw-cursor-pointer",
                    on: { click: _vm.deleteReminder }
                  })
                ]
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tw-mt-4 tw-ml-10 tw-mr-4 tw-flex tw-items-center" },
        [
          _vm.item.literature && _vm.item.literature.fileId
            ? [
                _c("span", {
                  staticClass:
                    "raxIcon raxIcon-pdf raxIcon--red tw-mr-2 tw-flex tw-items-center"
                }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "tw-truncate",
                    attrs: {
                      href:
                        "/projects/" +
                        _vm.item.project.slug +
                        "/literatures/" +
                        _vm.item.literature.slug +
                        "?openReminder=1",
                      target: "_blank",
                      title: _vm.item.literature && _vm.item.literature.title
                    },
                    on: {
                      click: function($event) {
                        return _vm.trackEvents("literature")
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.item.literature && _vm.item.literature.title
                        ) +
                        "\n      "
                    )
                  ]
                )
              ]
            : [
                _c(
                  "i",
                  {
                    staticClass: "material-icons tw-mr-2 tw-text-xl",
                    staticStyle: { "margin-bottom": "1px" }
                  },
                  [_vm._v("\n        link\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "tw-truncate",
                    attrs: {
                      title: _vm.item.literature && _vm.item.literature.title
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.item.literature && _vm.item.literature.title
                        ) +
                        "\n      "
                    )
                  ]
                )
              ]
        ],
        2
      ),
      _vm._v(" "),
      _vm.tab !== "completed" || _vm.item.remark
        ? _c(
            "div",
            { staticClass: "tw-mt-2 tw-ml-10 tw-mr-4" },
            [
              _c("resizable-textarea", { ref: "resize" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.item.remark,
                      expression: "item.remark"
                    }
                  ],
                  staticClass:
                    "tw-bg-black-4 tw-p-2 tw-flex tw-items-center tw-rounded tw-w-full tw-resize-none",
                  class: {
                    "hover-white-bg tw-transition-colors tw-duration-300": [
                      "upcoming",
                      "overdue"
                    ].includes(_vm.tab)
                  },
                  attrs: {
                    rows: "1",
                    disabled: !["upcoming", "overdue"].includes(_vm.tab),
                    placeholder: "add details about reminder..."
                  },
                  domProps: { value: _vm.item.remark },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.item, "remark", $event.target.value)
                      },
                      function($event) {
                        _vm.isChanges = true
                      }
                    ],
                    blur: function($event) {
                      return _vm.updateData(2)
                    }
                  }
                })
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "tw-mt-2 tw-ml-10 tw-mr-4 tw-flex tw-items-center",
          class: { "tw-mt-3": _vm.tab === "completed" && !_vm.item.remark }
        },
        [
          _c(
            "i",
            {
              staticClass:
                "material-icons tw-text-xl tw-text-black-40 tw-flex tw-items-center"
            },
            [_vm._v("\n      folder\n    ")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass:
                "tw-text-black-56 tw-text-xs tw-ml-1 tw-truncate tw-leading-4",
              attrs: {
                href: "/projects/" + _vm.item.project.slug + "/literatures/",
                target: "_blank",
                title: _vm.item.project && _vm.item.project.title
              },
              on: {
                click: function($event) {
                  return _vm.trackEvents("project")
                }
              }
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.item.project && _vm.item.project.title) +
                  "\n    "
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("hr", { staticClass: "tw-p-0 tw-w-full tw-mt-4" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }