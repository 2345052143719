import findObjectIndex from "@/assets/js/commonHelper";
const defaultExperienceState = () => {
  return {
    data: []
  };
};

export default {
  namespaced: true,
  state: defaultExperienceState,

  mutations: {
    setData(state, data) {
      state["data"] = data;
    },
    addData(state, data) {
      state["data"].unshift(data);
    },
    updateData(state, obj) {
      state.data.splice(obj.index, 1, obj.data);
    },
    deleteData(state, index) {
      state.data.splice(index, 1);
    },
    resetState(state) {
      Object.assign(state, defaultExperienceState());
    }
  },

  actions: {
    async fetch({ commit }) {
      const { data } = await this.$axios.get(`/userexperiences`);
      commit("setData", data);
    },

    async update({ commit, state }, inputs) {
      const { data } = await this.$axios.put(`/userexperiences`, inputs);
      let index = findObjectIndex(state.data, inputs);
      commit("updateData", { data: data[0], index: index });
    },

    async create({ commit }, inputs) {
      const { data } = await this.$axios.post("/userexperiences", inputs);
      commit("addData", data);
    },

    async delete({ commit }, inputs) {
      await this.$axios.delete(`/userexperiences/${inputs.item.id}`);
      commit("deleteData", inputs.index);
    }
  },

  getters: {
    getData(state) {
      return state.data;
    }

  }
};
