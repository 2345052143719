var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "td" } }, [
    _vm.showBanner && _vm.reminderList.length
      ? _c(
          "aside",
          {
            staticClass:
              "tw-fixed tw-z-50 tw-pointer-events-auto rm-banner tw-bg-white tw-text-black-72 tw-rounded-lg",
            staticStyle: { width: "472px" }
          },
          [
            _c("span", {
              staticClass:
                "raxIcon raxIcon-close tw-absolute tw-right-3 tw-top-3 raxIcon--black72 tw-cursor-pointer",
              on: { click: _vm.hideBanner }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "tw-pl-6 tw-py-3 tw-border-0 tw-font-medium tw-border-solid tw-border-black-24 tw-border-b"
              },
              [_vm._v("\n      Today’s Reminders 👇🏼\n    ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "tw-overflow-y-auto tw-max-h-80 tw-text-sm" },
              _vm._l(_vm.reminderList, function(item, index) {
                return _c(
                  "div",
                  { key: item.id },
                  [
                    _c("reminder-list", {
                      attrs: { item: item, index: index, tab: "td-reminders" }
                    })
                  ],
                  1
                )
              }),
              0
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }