import findObjectIndex from "@/assets/js/commonHelper"
import { ResultTypes, resultTypeTabData } from '@/shared/constants';
import Vue from "vue"
const defaultResultState = () => {
  return {
    data: {
      lectures: [],
      wikis: [],
      lightreads: [],
      literatures: [],
      surveyPapers: [],
      relatedContent: []
    },
    currentData: {
      lectures: [],
      wikis: [],
      lightreads: [],
      literatures: [],
      surveyPapers: [],
      relatedContent: []
    },
    resultType: "",
    prevSearchText: {
      lectures: "",
      wikis: "",
      lightreads: "",
      surveyPapers: "",
      literatures: "",
    },
    prevSearchContext: {
      lectures: "",
      wikis: "",
      lightreads: "",
      surveyPapers: "",
      literatures: ""
    },
    prevLastTab: ResultTypes.CitedBy,
    showMoreSources: true,
  }
}

export default {
  namespaced: true,
  state: defaultResultState,

  mutations: {
    setData(state, obj) {
      Vue.set(state.data, obj.key, obj.data)
    },
    setCurrentData(state, obj) {
      Vue.set(state.currentData, obj.key, obj.data)
    },
    setPrevLastTab(state, obj) {
      state.prevLastTab = obj;
    },
    setShowMoreSources(state, data) {
      state.showMoreSources = data;
    },
    setObjData(state, obj) {
      Vue.set(state[obj.name], obj.KeyName, obj.data)
    },
    addData(state, obj) {
      state.data[obj.key].push(obj.data)
    },
    setResultType(state, data) {
      state["resultType"] = data
    },
    updateArrayData(state, obj) {
      let key = obj.uniqueKey || "_id"
      let index = findObjectIndex(state.data[obj.resultType], obj.item, key)
      if (index === -1) {
        return;
      }
      let item_ = state.data[obj.resultType][index]
      item_[obj.key] = obj.value
      Vue.set(item_, obj.key, obj.value)
      state.data[obj.resultType].splice(index, 1, item_)
    },

    cleanResult(state) {
      state.data.lectures = []
      state.data.wikis = []
      state.data.lightreads = []
      state.data.literatures = []
      state.data.surveyPapers = []
      state.showMoreSources = true
    },
    cleanResultType(state) {
      state.resultType = ""
    },
    resetState(state) {
      Object.assign(state, defaultResultState)
    },
    updateTag(state, {index, data}){
      Vue.set(state.data.relatedContent[index].literatureData, "tags", data);
    }
  },

  actions: {
    async ryg({ commit, state, rootGetters }, inputs) {
      let key = state.resultType
        ? [ResultTypes.SurveyPapers, ResultTypes.Review].includes(state.resultType)
          ? ResultTypes.Literatures
          : state.resultType
        : "literatures";
      let subscriptionData = rootGetters["subscription/getSubscriptionData"];
      if(subscriptionData.category==1){
        inputs.start = 0;
        inputs.rows = 5;
      }
      let { data } = await this.$axios.post(`/recommendation/${key}`, inputs)
      key = state.resultType || 'literatures';
      commit("setCurrentData", { key: key, data: data });
      commit("setData", { key: key, data: data })
    },

    async rygMore({ commit, state, rootGetters }, inputs) {
      let key = state.resultType
      ? [ResultTypes.SurveyPapers, ResultTypes.Review].includes(state.resultType)
        ? ResultTypes.Literatures
        : state.resultType
      : "literatures";
      let subscriptionData = rootGetters["subscription/getSubscriptionData"];
      if(subscriptionData.category==1){
        inputs.start = 0;
        inputs.rows = 5;
      }
      let { data } = await this.$axios.post(`/recommendation/${key}`, inputs)
      key = state.resultType || 'literatures';
      commit("setCurrentData", { key: key, data: data });
      data.forEach(item => {
        commit("addData", { key: key, data: item })
      })
    },

    async fetchRelatedContent({ commit, state, rootState }, inputs) {
      let { data } = await this.$axios.post(
        `/recommendation/section/ryg`,
        inputs
      )
      data.forEach(item => {
        if (item) {
          const itemType = item.literature? 'literature': item.draft? 'draft': 'review'
          const _item =  {
            sectionHeader: item.section_header || '',
            itemId: rootState.literatures.currentData.id,
            type: 'literatures',
            title: item.literature.title,
            text: item.raw_text,
            remarks: '',
            docId: item.doc_id,
            runId: item.run_id,
            owner_id: item[itemType].owner_id,
            owner_fullname: item[itemType].owner_fullname,
            profilePic: item[itemType].profilePic,
            projectSlug: item[itemType].project_slug,
            shared_flag: item.shared_flag,
            suggestedItemSlug: item[itemType].slug,
            suggestedItemId: item[itemType].id,
            suggestedItemType: itemType + 's',
            suggestedItemAccessedAt: item[itemType].accessedAt * 1000,
            suggestedItemCreatedAt: item[itemType].createdAt * 1000,
            authors: item[itemType].authors,
            projectTitle: item[itemType].project_title,
            id: item.id,
            isPinned: !!item.id? true: false,
            literatureData : item.item
          };
          commit("addData", { key: "relatedContent", data: _item })
        }
      });
    },

    clearRelatedContent({ state }) {
      state.data.relatedContent = [];
    },

    addTags({commit,state}, obj){
      let relatedContent = state.data.relatedContent;
      for(let i=0;i<relatedContent.length;i++){
        if(relatedContent[i].literatureData.id == obj.inputs.itemId){
          obj.data.forEach(item => {
            const tag = [...state.data.relatedContent[i].literatureData.tags, { ...item, id: item.tagId }];
            commit('updateTag', {index:i, data: tag});
          });
        }
      }
    },
    updateTags({commit,state},obj){
      let relatedContent = state.data.relatedContent;
      for(let i=0;i<relatedContent.length;i++){
        const _index = relatedContent[i].literatureData.tags.findIndex(tag=>tag.id == obj.data.id);
        if(_index!==-1)
        Vue.set(state.data.relatedContent[i].literatureData.tags,_index,obj.data);
      }
    },
    deleteTags({commit,state},obj){
      let relatedContent = state.data.relatedContent;
      for(let i=0;i<relatedContent.length;i++){
        if(relatedContent[i].literatureData.id == obj.inputs.id){
          let _index = state.data.relatedContent[i].literatureData.tags.findIndex(tag=>tag.id == obj.data.id);
          _index !== -1 &&  state.data.relatedContent[i].literatureData.tags.splice(_index, 1);
        }
      }
    }
  },

  getters: {
    getData(state) {
      return state.data[state.resultType] ? state.data[state.resultType] : []
    },
    getCurrentData(state) {
      return state.currentData[state.resultType] ? state.currentData[state.resultType] : []
    },
    getAllData(state) {
      return state.data
    },
    getResultType(state) {
      return state.resultType
    },
    getPrevSearchText(state) {
      return state.prevSearchText
    },
    getPrevSearchContext(state) {
      return state.prevSearchContext;
    },
    getRelatedContent(state) {
      return state.data.relatedContent
    },
    getShowMoreSources(state) {
      return state.showMoreSources;
    },
    getResultTabsData(state) {
      // TODO improve tab getter logic

      // Source tabs shown will always be in a fixed order with last tab swapped out based on current selection
      let tabs = [
        resultTypeTabData[ResultTypes.Literatures],
        resultTypeTabData[ResultTypes.SurveyPapers],
        resultTypeTabData[ResultTypes.Review],
        resultTypeTabData[state.prevLastTab], // Since only about 4 tabs can fit in view, last tab will be swapped out
      ];

      //
      if(state.resultType && !tabs.some(tab => tab.type === state.resultType)) {
        tabs[tabs.length - 1] = resultTypeTabData[state.resultType];
        state.prevLastTab = state.resultType; // State shouldn't be mutated in a getter but safe here
      }

      tabs = tabs.filter(item => !!item);
      return tabs;
    }
  }
}
