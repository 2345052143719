import findObjectIndex from "@/assets/js/commonHelper";
import Vue from "vue";

const defaultSharedItemUsersState = () => {
  return {
    data: {}
  };
};
export default {
  namespaced: true,
  state: defaultSharedItemUsersState,

  mutations: {
    setData(state, obj) {
      Vue.set(state.data, obj.key, obj.data);
    },
    addData(state, data) {
      state["data"].unshift(data);
    },
    updateData(state, obj) {
      let index = findObjectIndex(state.data[obj.key], obj.data, 'userId');
      state.data[obj.key].splice(index, 1, obj.data);
    },
    deleteData(state, obj) {
      state.data[obj.key].splice(obj.index, 1);
    },
    setCurrentData(state, data) {
      state.currentData = data;
    },
    resetState(state) {
      Object.assign(state, defaultSharedItemUsersState());
    }
  },

  actions: {
    async fetch({ commit }, inputs) {
      const { data } = await this.$axios.post(`/shared/item/contacts`, inputs);
      commit("setData", { key: inputs.type + inputs.itemId, data: data });
    },

    async update({ commit, state }, inputs) {
      const { data } = await this.$axios.put(`/shared-item-users`, inputs);
      commit("updateData", data[0]);
    },

    async create({ commit, state }, inputs) {
      const { data } = await this.$axios.post("/shared-item-users", inputs);
      commit("addData", data);
    },

    deleteMembers({ commit, state }, obj) {
      let key = obj.type + obj.itemId;
      let arr = state.data[key];
      let user = obj.user;
      if (arr && arr.length) {
        let sharedIndex = findObjectIndex(arr, { userId: user }, "userId");
        let obj = { key: key, index: sharedIndex };
        commit("deleteData", obj);
      }
    },

    updateMemberStatus({commit, state}, obj) {
      let key = obj.type + obj.itemId;
      let arr = state.data[key];
      let user = obj.user;
      if (arr && arr.length) {
        let sharedIndex = findObjectIndex(arr, { userId: user }, "userId");
        let newData = { ...arr[sharedIndex], status: obj.status }
        let _obj = { key, data: newData };
        commit("updateData", _obj);
      }
    }

  },

  getters: {
    getData(state) {
      return state.data;
    }
  }
};
