export default function({ store, redirect }) {
  if (store.getters["auth/isAuthenticated"]) {
    if (!store.getters["aff/getDiscoverAccess"]) {
      return redirect("/");
    }
  } else {
    if (!store.getters["library/getDiscoverAccess"]) {
      return redirect("/");
    }
  }
}
