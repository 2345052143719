var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "md-layout md-align--center-center md-align",
      staticStyle: { "min-height": "calc(100vh - 105px)" }
    },
    [
      _c("div", { staticClass: "md-layout md-layout--column md-flex--grow" }, [
        _c(
          "div",
          { staticClass: "rx-empty rx-empty--page" },
          [
            _c("img", {
              staticClass: "rx-empty__img",
              attrs: { src: _vm.assetsUrl + _vm.currentError.imgSrc }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "md-typo--title rx-empty__title my-typo--font-light layout-m-t-1"
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.error.title ? _vm.error.title : _vm.currentError.title
                    ) +
                    "\n      "
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "rx-empty__subtext" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.error.message
                      ? _vm.error.message
                      : _vm.currentError.sueHeading
                  ) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c(
              "nuxt-link",
              {
                staticClass:
                  "layout-m-t-2 md-button md-button--raised md-button--primary",
                attrs: { to: "/" }
              },
              [_vm._v("\n        Go to homepage\n      ")]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }