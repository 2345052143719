<template>
  <div>
    <app-header :display-landing-nav="true" />
    <app-drawer unid="drawer" @openFeedback="openFeedback" />
    <feedback :is="asyncComp" :dialog-id="dialogId" />
    <div
      class="md-container md-container--box md-container--gutter md-container--gutter-40-md"
      data-ready="true"
    >
      <main
        class="nt-active md-layout md-align md-align--center-center rx-full-height"
      >
        <nuxt class="md-flex--grow" />
      </main>
      <app-snackbar
        :action-text="snackbar.actionText"
        :message="snackbar.message"
        class="md-snackbar--primary"
        unid="snackbar"
      />
    </div>
    <div
      v-if="isAppLoading"
      :id="unid + '-block'"
      class="layout-block layout-block--fixed"
    >
      <app-progress
        :unid="unid + '-progress'"
        :config="{
          circle: true,
          center: true,
          overlay: true,
          parent: true,
          target: `#${unid}-block`
        }"
      />
    </div>
  </div>
</template>
<style>
.rx-full-height {
  min-height: 100vh;
}

.rx-full-height > .md-flex--grow {
  margin-top: 32px;
}

.feature-text h3 {
  font-size: 45px;
  font-weight: 300;
  line-height: 56px;
}

.md-navbar--fixed .md-button[href*="/register"] {
  display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.diff .md-progress__progressbar {
  background-color: white;
}

.diff .md-progress__bufferbar {
  background-color: rgba(255, 255, 255, 0.32);
}

.md-round-corner {
  border-radius: 8px;
  overflow: hidden;
}

.rx-login-max {
  max-width: 800px;
  margin: 0 auto;
  width: 100%;
}

.lk-login-feature {
  flex-shrink: 1;
}

.rx-login-wrapper {
  min-width: 440px;
}

.md-panel__body--login {
  padding: 40px 28px;
}

.md-row--wrap {
  margin-left: -8px;
  margin-right: -8px;
}

.md-button--fat {
  padding-left: 26px;
  padding-right: 26px;
}
.no-padding-top {
  padding-top: 0 !important;
}
@media (max-width: 959px) {
  .rx-login-wrapper {
    min-width: 0;
  }
  .md-panel__body--login {
    padding: 24px 16px;
  }
}
</style>
<script>
import appHeader from "@/components/navigation/header";
import appDrawer from "@/components/navigation/drawer";
import AsyncComponent from "@/assets/js/helper";
import { mapGetters } from "vuex";

export default {
  components: {
    appHeader,
    appDrawer
  },
  data() {
    return {
      asyncComp: false,
      menuId: "breadCrumb-project-menu",
      dialogId: "feedback-dialog",
      unid: "app-auth-universal",
      assetsUrl: process.env.assetsUrl
    };
  },
  computed: {
    ...mapGetters({
      feedback: "core/feedback",
      snackbar: "core/snackbar",
      isAppLoading: "core/appLoading"
    })
  },
  watch: {
    feedback: function(val) {
      if (val) {
        this.openFetchDialog("common/child/feedback");
      }
    }
  },
  async mounted() {
    setTimeout(() => {
      let element = document.querySelector("#scispace-extension-root");
      if (element) {
        element.style.display = "none";
      }
    }, 3000);
    $("body").addClass("no-padding-top");
    await this.$store.dispatch("user/setUserCountry");
  },
  beforeDestroy() {
    $("body").removeClass("no-padding-top");
    this.$store.commit("core/setAppLoading", false);
  },
  methods: {
    openFetchDialog(dialogPath) {
      if (!this.asyncComp) {
        this.asyncComp = () => AsyncComponent(dialogPath);
        this.$store.commit("core/setAppLoading", true);
        this.asyncComp()
          .component.then(() => {
            this.$store.commit("core/setAppLoading", false);
          })
          .catch(() => {
            this.asyncComp = false;
            this.$store.commit("core/setAppLoading", false);
          });
      } else {
        $(`#${this.dialogId}`).MaterialDialog("show");
      }
    },
    openFeedback() {
      this.$store.commit("core/setData", { name: "feedback", data: true });
    }
  }
};
</script>
