var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "md-list md-shimmer" },
    _vm._l(4, function(n) {
      return _c("div", { key: n, staticClass: "md-list__item" }, [
        _vm._m(0, true),
        _vm._v(" "),
        _vm._m(1, true)
      ])
    }),
    0
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "md-list-icon" }, [
      _c("div", { staticClass: "md-list-icon__avatar md-shimmer__item" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "md-list-content" }, [
      _c("div", {
        staticClass:
          "md-list-content__title md-shimmer__item md-shimmer__item--headline",
        staticStyle: { "max-width": "none" }
      }),
      _vm._v(" "),
      _c("div", {
        staticClass:
          "md-list-content__subtitle md-shimmer__item md-shimmer__item--paragraph"
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }