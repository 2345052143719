var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass:
        "md-button md-button--progress md-button--primary md-button--raised",
      class: {
        "md-button--loading": _vm.showLoading,
        "md-button--done": _vm.isDone
      },
      staticStyle: {
        "font-size": "16px",
        height: "auto",
        padding: "5px 16px",
        "text-transform": "capitalize"
      },
      attrs: { id: _vm.unid, type: _vm.type, disabled: _vm.disabled },
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        }
      }
    },
    [
      _c(
        "span",
        {
          staticClass:
            "md-progress md-progress--circle md-progress--indeterminate md-progress--visible",
          attrs: {
            id: "progress-4",
            "data-type": "indeterminate",
            "data-theme": "primary",
            "data-circle": "true",
            "data-overlay": "true",
            "data-center": "true",
            "data-fixed": "true",
            "data-parent": "true",
            "data-target": "#parent-4"
          }
        },
        [
          _c(
            "svg",
            {
              staticClass: "md-progress-circle md-progress-circle--primary",
              attrs: {
                id: "progress-circle-59",
                width: "55px",
                height: "55px",
                viewBox: "0 0 56 56",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("circle", {
                staticClass: "md-progress-circle__path",
                staticStyle: {
                  "stroke-dasharray": "166",
                  "stroke-dashoffset": "166"
                },
                attrs: {
                  fill: "none",
                  "stroke-width": "4",
                  "stroke-linecap": "round",
                  cx: "28",
                  cy: "28",
                  r: "26"
                }
              })
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "md-button__text" }, [
        _vm._v("\n    " + _vm._s(_vm.text) + "\n  ")
      ]),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm._m(0)
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "md-button__icon" }, [
      _c("i", { staticClass: "material-icons" }, [_vm._v("done")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }