import Vue from "vue";
const defaultReviewState = () => {
  return {
    data: {},
    currentIndex: 0,
    headingId: {},
    outlineHeadings: []
  }
};
// let headingId;

export default {
  namespaced: true,
  state: defaultReviewState,

  mutations: {
    setData(state, data) {
      state["data"] = data;
    },
    updateData(state, data) {
      state.data = data;
    },
    setParchmentHeadingId(state, data) {
      state["headingId"] = data;
    },
    updateOutlineHeading(state, obj) {
      Vue.set(state.outlineHeadings[obj.index], obj.key, obj.value);
    },
    updateOutlineSubHeading(state, obj) {
      Vue.set(state.outlineHeadings[obj.outlineIndex]["subHeads"][obj.index], obj.key, obj.value);
    },
    addHeadingId(state, obj) {
      state.headingId.add(obj.key, obj.value);
    },
    removeHeadingId(state, data) {
      state.headingId.remove(data);
    },
    resetState(state) {
      Object.assign(state, defaultReviewState());
    }
  },

  actions: {

    async update({ commit, state, dispatch }, inputs) {
      const { data } = await this.$axios.put(`/reviews`, inputs);
      commit("updateData", data[0]);
    },

    async updateOutline({ commit, state, dispatch }) {
      let $changedElements = $(".ql-editor p, .ql-editor h6, .ql-editor h5, .ql-editor h4, .ql-editor h3");
      $changedElements.map((index) => {
        if ($changedElements[index].attr("id")) {
          dispatch("removeHeadingOutlineById", $changedElements[index]);
        }
      });
      let el = $(".ql-editor h1, .ql-editor h2");
      let headNumbers = 0;
      state.outlineHeadings.forEach(function(i) {
        headNumbers += i.subHeads.length;
      });
      headNumbers += state.outlineHeadings.length;
      if (headNumbers !== el.length) {
        dispatch("registerNewHeadings");
      } else {
        el.map((m) => {
          let currentText, outlineText, outlineButton;
          if (el[m].localName === "h1") {
            currentText = el[m].innerText;
            outlineButton = $("[data-heading='" + el[m].getAttribute("id") + "']");
            outlineText = outlineButton.find(".rx-outline__content")[0].innerText;
            if (currentText !== outlineText) {
              commit("updateOutlineHeading", {
                key: "heading",
                value: currentText,
                index: outlineButton.attr("data-index")
              });
            }
          }
          if (el[m].localName === "h2") {
            currentText = el[m].innerText;
            outlineButton = $("[data-subheading='" + el[m].getAttribute("id") + "']");
            outlineText = outlineButton.find(".rx-outline__content")[0].innerText;
            if (currentText !== outlineText) {
              commit("updateOutlineSubHeading", {
                key: "subHeading",
                value: currentText,
                outlineIndex: outlineButton.attr("data-index"),
                index: outlineButton.attr("data-subindex")
              });
            }
          }
        });
      }
    },

    async registerNewHeadings({ state, dispatch, commit }) {
      //  get new heading or subHeading which do not have ids
      let allHeadings = $(".ql-editor h1, .ql-editor h2");
      let unRegisterHeadings = allHeadings.filter((index, item) => {
        // TODO check item is coming or not
        return !item.id;
      });
      await dispatch("setHeadingId", unRegisterHeadings);
      if ($(".ql-editor [id]").length) {
        dispatch("assignNavHeadings");
      }
    },

    async setHeadingId(unRegisterHeadings) {
      if (!unRegisterHeadings.length) {
        return;
      }
      unRegisterHeadings.each(async (index, item) => {
        let newId;
        if (item.localName === "h1") {
          newId = await dispatch("idGenerator");
          commit("addHeadingId", { key: item, data: newId });
        } else {
          newId = await dispatch("idGenerator");
          commit("addHeadingId", { key: item, data: newId });
        }
      });
    },

    async idGenerator() {
      let x = (new Date()).toISOString().replace(/[:,.,-]/g, "");
      return x.substr(0, 9) + (parseInt(x.substr(9, 9)) + (Math.floor(Math.random() * (100000 - 20 + 1)) + 25));
    },

    assignNavHeadings({ dispatch, state }) {
      state.outlineHeadings = [];
      let q = $(".ql-editor [id]");
      if (!q.length) {
        setTimeout(() => {
          dispatch("registerNewHeadings");
        }, 3000);
        return false;
      }
      let c = 0;
      q.map((m) => {
        if (q[m].innerText.replace(/\s/g, "") !== "" && q[m].innerHTML !== "<br>" && q[m].id !== "") {
          if (c === 0 && q[m].localName !== "h1") {
            return true;
          } else {
            c = 1;
          }
          if (q[m].localName === "h1") {
            state.outlineHeadings.push({
              "heading": q[m].innerText,
              "headNavId": m,
              "subHeadHolderId": m,
              "headingId": q[m].id,
              "subHeads": []
            });
          } else if (q[m].localName === "h2") {
            state.outlineHeadings[state.outlineHeadings.length - 1].subHeads.push({
              "subHeading": q[m].innerText,
              "subHeadingId": q[m].id
            });
          }
        }
      });
    },

    async addHeading({ commit, state, dispatch }, obj) {
      let end = obj.editorInstance.getLength();
      let newId = await dispatch("idGenerator");
      obj.editorInstance.clipboard.dangerouslyPasteHTML(end, "<h1 id='" + newId + "'>" + obj.value + "</h1>");
      let q = $(".ql-editor h1");
      commit("addHeadingId", { key: q[q.length - 1], data: newId });
    },

    addSubHeading({ commit, state, dispatch }, obj) {
      let outline = obj.outline;
      let editorInstance = obj.editorInstance;
      let allHeadings = $(".ql-editor h1");
      allHeadings.map(async (item) => {
        if (allHeadings[item].id === (outline.headingId)) {
          let newId = await dispatch("idGenerator");
          if (typeof(allHeadings[item + 1]) !== "undefined") {
            $("#" + allHeadings[item + 1].id).before("<h2 id='" + newId + "'>" + obj.value + "</h2>");
          } else {
            let end = editorInstance.getLength();
            editorInstance.clipboard.dangerouslyPasteHTML(end, "<h2 id='" + newId + "'>" + obj.value + "</h2>");
          }
        }
      });
      let allSubHeading = $(".ql-editor h2");
      allSubHeading.map(async (item) => {
        if (allSubHeading[item].id === "") {
          let newId = await dispatch("idGenerator");
          state.headingId.add(allSubHeading[item], newId);
        }
      });
    },

    removeChangedHeading() {
      // check id type in history
      if (state.headingId[data] === "h1") {
        // remove this id from h1
      } else {
        // remove this id from h2
        // find h1 having the h2
        // then remove h2
      }
    },

    removeHeadingOutlineById({ state }, $el) {
      // find what kind of element it was
      let headingType  = state.headingId[$el.attr("id")];
      if (headingType) {
        if( headingType === 'h1' ) {

        }
      }
    }

  },

  getters: {
    getData(state) {
      return state.data;
    },
    getOutlineHeadings(state) {
      return state.outlineHeadings;
    }
  }
};
