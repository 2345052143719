<template>
  <div class="tw-text-center">
    <div @click="openModal()">
      <slot name="referUserButton">Refer now</slot>
    </div>
    <modal
      v-if="isModalOpen"
      name="refer-user-modal"
      transition="pop-out"
      :adaptive="true"
      :focus-trap="true"
      :scrollable="true"
      :height="'auto'"
      :width="modalWidth"
    >
      <ReferUserModal :close-modal="closeModal" />
    </modal>
  </div>
</template>

<script>
import ReferUserModal from '~/components/refer-user/referUserModal.vue';

export default {
  components: {
    ReferUserModal
  },
  data() {
    return {
      modalWidth: 800,
      isModalOpen: false
    }
  },
  mounted() {
    this.isModalOpen = true;
  },
  methods: {
    openModal(){
      this.$modal.show('refer-user-modal');
    },
    closeModal(){
      console.log('Close modal closed : ');
      this.$modal.hide('refer-user-modal');
    }
  }
};
</script>
