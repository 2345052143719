import processImage from "@/assets/js/processImage";
import Vue from "vue";
const defaultFeedState = () => {
  return {
    news: [],
    literatures: [],
    courses: [],
    lightReads: [],
    books: [],
    thesis: [],
    prevSearchText: {
      news: "",
      literatures: "",
      courses: "",
      lightReads: "",
      books: "",
      thesis: ""
    },
    keywords: {
      news: [],
      literature: [],
      courses: [],
      lightReads: [],
      books: [],
      thesis: []
    },
    moreResults: true
  }
};
export default {
  namespaced: true,
  state: defaultFeedState,

  mutations: {
    setData(state, obj) {
      state[obj.key] = obj.data;
    },
    addData(state, obj) {
      state[obj.key].push(obj.data);
    },
    setObjData(state, obj) {
      Vue.set(state[obj.name], obj.key, obj.data);
    },
    resetState(state) {
      Object.assign(state, defaultFeedState());
    }
  },

  actions: {

    async literature({ commit, state, rootGetters }, inputs) {
      let params = inputs.params;
      let loadMore = inputs.loadMore? inputs.loadMore : false;
      let data, axiosData;
      if (!params.literatureId) {
        axiosData = await this.$axios.post(`/feed/literatures`, params);
        data = axiosData["data"]["results"];
        const keywords = axiosData["data"]["keywords"];
        commit("setObjData", { name: "keywords", key: "literature", data: keywords });
      } else {
        delete params["areasOfInterest"];
        params.reviewMode = 1;
        params.isSelectiveRyg=0;
        let subscriptionData = rootGetters["subscription/getSubscriptionData"];
        if(subscriptionData.category==1){
          params.start = 0;
          params.rows = 5;
        }
        axiosData = await this.$axios.post(`/recommendation/literatures`, params);
        data = axiosData["data"];
        commit("setObjData", { name: "keywords", key: "literature", data: [] });
      }
      commit("setData", { key: "moreResults", data: !!data.length });
      data.forEach((item) => {
        item.type = "paper";
      });
      if (loadMore) {
        data.forEach((item) => {
          commit("addData", { key: "literatures", data: item });
        });
      } else {
        commit("setData", { key: "literatures", data: data });
      }
    },

    async courses({ commit, state, rootGetters }, inputs) {
      let params = inputs.params;
      let loadMore = inputs.loadMore? inputs.loadMore : false;
      let data, axiosData;
      if (!params.literatureId) {
        axiosData = await this.$axios.post(`/feed/courses`, params);
        data = axiosData["data"]["results"];
        const keywords = axiosData["data"]["keywords"];
        commit("setObjData", { name: "keywords", key: "courses", data: keywords });
      } else {
        delete params["areasOfInterest"];
        let subscriptionData = rootGetters["subscription/getSubscriptionData"];
        if(subscriptionData.category==1){
          params.start = 0;
          params.rows = 5;
        }
        axiosData = await this.$axios.post(`/recommendation/lectures`, params);
        data = axiosData["data"];
        commit("setObjData", { name: "keywords", key: "courses", data: [] });
      }
      commit("setData", { key: "moreResults", data: !!data.length });
      data.forEach((item) => {
        item.type = "course";
        item.raw_text = item.text;
      });
      if (loadMore) {
        data.forEach((item) => {
          commit("addData", { key: "courses", data: item });
        });
      } else {
        commit("setData", { key: "courses", data: data });
      }
    },

    async lightReads({ commit, state, rootGetters }, inputs) {
      let params = inputs.params;
      let loadMore = inputs.loadMore? inputs.loadMore : false;
      let data, axiosData;
      if (!params.literatureId) {
        axiosData = await this.$axios.post(`/feed/lightreads`, params);
        data = axiosData["data"]["results"];
        const keywords = axiosData["data"]["keywords"];
        commit("setObjData", { name: "keywords", key: "blogs", data: keywords });
      } else {
        delete params["areasOfInterest"];
        let subscriptionData = rootGetters["subscription/getSubscriptionData"];
        if(subscriptionData.category==1){
          params.start = 0;
          params.rows = 5;
        }
        axiosData = await this.$axios.post(`/recommendation/lightreads`, params);
        data = axiosData["data"];
        commit("setObjData", { name: "keywords", key: "blogs", data: [] });
      }
      commit("setData", { key: "moreResults", data: !!data.length });
      data.forEach((item) => {
        item.type = "lightreads";
      });
      let processData = processImage(data);
      if (loadMore) {
        processData.forEach((item) => {
          commit("addData", { key: "lightReads", data: item });
        });
      } else {
        commit("setData", { key: "lightReads", data: processData });
      }
    },

    async news({ commit, state }, inputs) {
      let params = inputs.params;
      let loadMore = inputs.loadMore? inputs.loadMore : false;
      let { data } = await this.$axios.post(`/feed/news`, params);
      const keywords = data["keywords"];
      data = data["results"];
      commit("setObjData", { name: "keywords", key: "news", data: keywords });
      commit("setData", { key: "moreResults", data: !!data.length });
      data.forEach((item) => {
        item.type = "news";
      });
      let processData = processImage(data);
      if (loadMore) {
        processData.forEach((item) => {
          commit("addData", { key: "news", data: item });
        });
      } else {
        commit("setData", { key: "news", data: data });
      }
    }

  },

  getters: {
    getNews(state) {
      return state.news;
    },
    getLiteratures(state) {
      return state.literatures;
    },
    getCourses(state) {
      return state.courses;
    },
    getLightReads(state) {
      return state.lightReads;
    },
    getKeywords(state) {
      return state.keywords;
    },
    getMoreResults(state) {
      return state.moreResults;
    }
  }
};
