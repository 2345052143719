import { PlanCycle, PlanType, PlanCategory, PaymentIntentStatus } from "~/shared/constants";
import * as moment from "moment";
import Vue from "vue";
const defaultSubscriptionState = () => {
  return {
    headerData: ['Plan'],
    planCardStatic: {
      [1]: {
        features:['10 Summary/month','10 Key Insights/month', 'upto 25 Literatures','5 collaborators','5 Explore related/query', '10 Copilot questions /month'],
        subtitle: 'Ideal when working solo',
        btnLabel: 'Current Plan'
      },
      [2]: {
        features:['50 Summary/month', '30 Key Insights/month', 'Unlimited Literatures', '10 collaborators','Unlimited Explore related/query', '20 Copilot questions /month'],
        subtitle: 'Great for shared projects',
        btnLabel: 'Current Plan'
      },
      [3]: {
        features: ['50 Summary/month', '30 Key Insights/month', 'Unlimited Literatures', 'Unlimited collaborators','Unlimited Explore related/query', 'Unlimited Copilot questions'],
        subtitle: 'For those working with many',
        btnLabel: 'Current Plan'
      },
      [4]: {
        features:['20 Summary/month','10 Key Insights/month', 'upto 30 Literatures','2 collaborators'],
        subtitle: 'Ideal when working solo',
        btnLabel: 'Current Plan'
      },
      [5]: {
        features:['35 Summary/month', '15 Key Insights/month', 'Unlimited Literatures', '5 collaborators'],
        subtitle: 'Great for shared projects',
        btnLabel: 'Current Plan'
      },
      [6]: {
        features: ['50 Summary/month', '50 Key Insights/month', 'Unlimited Literatures', '8 collaborators'],
        subtitle: 'For those working with many',
        btnLabel: 'Current Plan'
      },
      [7]: {
        features: ['50 Summary/month', '50 Key Insights/month', 'Unlimited Literatures', '8 collaborators'],
        subtitle: 'For those working with many',
        btnLabel: 'Current Plan'
      },
    },
    planCardStaticNew: {
      [1]: {copilotQueries: '10/month', summaries: '10/month', ki: '10/month', collaborators: '2', literatures: '25', exploreResults: 'Top 5'},
      [2]: {copilotQueries: '20/month', summaries: '50/month', ki: '30/month', collaborators: '10', literatures: 'Unlimited', exploreResults: 'Unlimited'},
      [3]: {copilotQueries: 'Unlimited', summaries: '50/month', ki: '30/month', collaborators: '10', literatures: 'Unlimited', exploreResults: 'Unlimited'}
    },
    planList: [],
    subscriptionData: {
      category: '0',
      planType: '',
      isTrial: true,
      billingCycle: '',
      isEligibleForExtension: true,
      isEligibleForUpfrontPlan: false,
      hasUpfrontPaidPlan: false,
      hasPendingUpfrontPlanAssignment: false,
      planEndDate: '',
      trialEndDate: '',
      upcomingBillingDate:'',
      isAutoRenewalOff: '',
      isPaymentAttached: false,
      groupPlan: { groupLeaderName: '', email: '' },
      institutionPlan: { groupLeaderName: '', email: '' },
      isRedirectedForPayment: false,
      isRedirectedPaymentStatus: '',  // 0: redirected and payment pending, 1: Payment received, 3: Expired
      paymentRedirectRequestDate: '',
      redirectedPaymentRequestedTo: '',
      isPaymentFailing: '',
      isPayer: false,
      status: '',
      isPaidPlan: false,
      subscriptionCount: 0,
      isCancelRequestPending: false,
      isDowngradePending: false,
      downgradeCategory: null, //'1'/'2'
      specialDiscountOffered : false,
    },
    currentUsage: {
      activeCollaborators: 0,
      activeProjects:0,
      usedStorage: 0,
      usedKeyInsights: 0,
      usedTldr: 0
    },
    currentUsageAmount: 0,
    discounts: [],
    paymentData: {  // This will hold the subscription data till payment from subscription page
      selectedBillingCycle: null,
      category: null,
      paymentMode:'self',
      cost: 0,
      billingDate:'',
      hasPaid: false,
      payeeEmail: '',
      isAnnual:'monthly'
    },
    paymentMethod: null,
    billingHistory: [],
    paymentIntentStatus: PaymentIntentStatus.Null,
  };
};

export default {
  namespaced: true,
  state: defaultSubscriptionState,

  mutations: {
    updateHeaderData(state, payload) {
      switch (payload.type) {
        case 'plan-page':
          state.headerData = ['Plan'];
          break;
        case 'payment-page':
          state.headerData = ['Plan', 'Payment'];
          break;
        case 'clear':
          state.headerData = [];
          break;
        default:
          state.headerData = defaultSubscriptionState().headerData;
          break;
      };
    },

    updateKICurrentUsage(state, payload){
      Vue.set(state.currentUsage, 'usedKeyInsights', payload);
    },
    updateTldrCurrentUsage(state, payload){
      Vue.set(state.currentUsage, 'usedTldr', payload);
    },
    updatePaymentData(state, payload){
      // { key, data }
      switch (payload.key) {
        case 'category':
          {
          let selectedPlan = state.planList.find( i=> i.category === payload.data);
          let cost = selectedPlan.price[state.paymentData.selectedBillingCycle].discounted ||  selectedPlan.price[state.paymentData.selectedBillingCycle].actual;
          state.paymentData.cost = cost;
          }

        break;
        case 'selectedBillingCycle':
          {
          let selectedPlan = state.planList.find( i=> i.category === state.paymentData.category);
          let cost = selectedPlan.price[payload.data].discounted ||  selectedPlan.price[payload.data].actual;
          state.paymentData.cost = cost;
          }
          break;

        case 'isAnnual':{
          state.paymentData.isAnnual =payload.data;
        }
        default:

      }
      state.paymentData[payload.key] = payload.data;

    },
    syncPaymentData(state){
      const selectedPlan = state.planList.find( i=> i.category == state.subscriptionData.category);
      if (!selectedPlan.price[state.subscriptionData.billingCycle]) {
        return;
      }
      state.paymentData = {
        ...state.paymentData,
        selectedBillingCycle: state.subscriptionData.billingCycle,
        category: state.subscriptionData.category,
        cost: selectedPlan.price[state.subscriptionData.billingCycle].discounted ||  selectedPlan.price[state.subscriptionData.billingCycle].actual,
        billingDate: state.subscriptionData.upcomingBillingDate,
      }
    },
    updateSubscriptionData(state, payload) {
      state.subscriptionData = payload;
    },
    updateAutoRenew(state, payload){
      state.subscriptionData.isAutoRenewalOff = payload;
    },
    updateState(state, payload){
      state[payload.key] = payload.data;
    },
    updatePlanListData(state, payload){
      state.planList = payload;
    },
    updateDiscountData(state, payload){
      state.discounts = payload;
    },
    updatePaymentIntentStatus(state, payload) {
      state.paymentIntentStatus = payload;
    },
    resetState(state) {
      Object.assign(state, defaultSubscriptionState());
    },
    updateBtnLabel(state){
      state.planList.forEach(i=>{
        const btnLabel = i.category == state.subscriptionData.category && state.subscriptionData.isPaidPlan ? 'Current Plan' :
        (state.subscriptionData.isPaidPlan? (i.category < state.subscriptionData.category ? 'Downgrade' : 'Upgrade') : '');
        state.planCardStatic[i.category].btnLabel = btnLabel;
      })
    }
  },

  actions: {
    async refreshSubscriptionData({ commit, dispatch }){
      await dispatch('fetch');
      await dispatch('fetchPlanList');
    },
    async fetch({ commit, dispatch }) {
      let { data } = await this.$axios.get(`/stripe/fetch-current-subscription-status`);
      data.billingCycle = data.billingCycle === '1'? PlanCycle.Monthly : PlanCycle.Annual;
      commit('updateSubscriptionData', data);
      dispatch('fetchCurrentUsage');
    },
    async fetchPlanList({ commit }) {
      let { data } = await this.$axios.get(`/plan-details/applicable-plans`);
      commit('updatePlanListData', data.plans);
      commit('updateDiscountData', data.discounts);
      commit('updateBtnLabel');
    },
    async fetchCurrentUsage({commit}){
      let { data } = await this.$axios.get(`/plan-details/current/usage`);
      commit('updateState',{key: 'currentUsage', data});
      // currentUsage
    },
    async fetchCurrentUsageAmount({commit}){
      let { data } = await this.$axios.get(`/stripe/user/current-usage-amount`);
      commit('updateState',{key: 'currentUsageAmount', data});
      // currentUsage
    },

    async fetchBillingHistory({commit}){
      let { data } = await this.$axios.get(`/stripe/customer/invoices`);
      commit('updateState',{key: 'billingHistory', data});
    },
    async fetchPaymentMethod({commit}){
      let { data } = await this.$axios.get(`/stripe/payment-methods`);
      commit('updateState',{key: 'paymentMethod', data:data[0]});
    },
    async cancelSubscriptionFeedback({dispatch}, payload) {
      this.$axios.post(`/feedback/cancel-subscription`, payload);
      dispatch('cancelSubscription');
    },
    async cancelSubscription({dispatch}) {
      await this.$axios.get(`/stripe/cancel/subscription`);
    },
    async voidPendingCancelSubscriptionRequest({dispatch, state, commit}) {
      try {
        await this.$axios.get(`/plan-details/void/pending-cancel-subscription-request`);
        let data = state.subscriptionData;
        data["isCancelRequestPending"] = false;
        commit('updateSubscriptionData', data);
      } catch (e) {
        return false;
      }
    },
    // param: status: true/false
    async toggleAutoRenewal({}, payload){
      await this.$axios.post('/stripe/set/auto-renewal', payload)
    },
    async requestMoreUsage({ dispatch }, payload) {
      try {
        const { data } = await this.$axios.post('/plan-details/request/extra-usage', payload);
        if (!!data.status) dispatch('fetchCurrentUsage');
        return !!data.status;
      } catch (e) {
        return false;
      }
    }

   },

  getters: {
    getHeaderData(state){
      return state.headerData;
    },
    getPlanList(state){
      return state.planList;
    },
    getDiscounts(state){
      return state.discounts;
    },
    getPlanCardStatic(state){
      return state.planCardStatic;
    },
    getPlanCardStaticNew(state) {
      return state.planCardStaticNew;
    },
    getSubscriptionData(state){
      return state.subscriptionData;
    },
    getSelectedPlan(state){
      if (state.paymentData && state.paymentData.category !== null) {
        return state.planList.find( i=> i.category == state.paymentData.category);
      }
      const d = state.planList.find( i=> i.category == state.subscriptionData.category);
      return d;
    },

    getPaymentData(state){
      return state.paymentData
    },
    isPaymentSuccessful(state){
      return state.paymentData.hasPaid;
    },
    getAllocatedPlanUsage(state){
      const d = state.planList.find( i=> i.category === state.subscriptionData.category) || state.planList[1];

      if (d) {
        return d.usageLimits;
      }
      return {};
    },
    getCurrentUsage(state){
      const data = { ...state.currentUsage };
      if (isNaN(data.usedStorage)) {
        data.usedStorage = 0;
      }
      return data;
    },
    getCurrentUsageAmount(state){
      return state.currentUsageAmount;
    },
    getBillingHistory(state){
      return state.billingHistory;
    },
    getPaymentMethod(state){
      return state.paymentMethod;
    },
    getSelectedBillingCycle(state){
      return (state.paymentData && state.paymentData.selectedBillingCycle) || state.subscriptionData.billingCycle;
    },
    getPaymentIntentStatus(state) {
      return state.paymentIntentStatus;
    },
    hasChangedBillingCycle(state){
      return state.subscriptionData.billingCycle !== state.paymentData.selectedBillingCycle;
    },
    hasChangedPlan(state){
      console.log(state.paymentData);
      return state.paymentData.category ? !((state.subscriptionData.category == state.paymentData.category)&&((state.subscriptionData.duration == 12 && state.paymentData.isAnnual == 'annual')||(state.subscriptionData.duration == 1 && state.paymentData.isAnnual == 'monthly'))) : false;
    },
    isUpgrading(state){
      return +state.paymentData.category > +state.subscriptionData.category
    },
    isTrial(state){
      return state.subscriptionData.isTrial
    },

    hasPlanExpired(state){
      if (state.subscriptionData.status === 'canceled') {
        return true;
      }
      return false;
    },
    havePaidPlan(state){
      return  state.subscriptionData.isPaidPlan;
    },
    haveNoPreviousPaidPlan(state){
      return (state.subscriptionData.subscriptionCount || 0) === 0;
    },
    haveFullAccess(state){
      const subData = state.subscriptionData;
      if(!subData.payer){
        return true;
      }
      if (subData && subData.status === 'canceled') {
        return false;
      }
      return  subData && (subData.status === 'trialing' || subData.isPaidPlan || !subData.isPayer);
    },

    trialDaysRemain(state){
      const planEnding = moment(state.subscriptionData.trialEndDate);
      const today = moment();
      return planEnding.diff(today ,'days');
    },
    isEligibleForExtension(state, getters){
      return state.subscriptionData && state.subscriptionData.isEligibleForExtension && getters.trialDaysRemain <= 10;
    },

    canAddProject(state, getters){
      const haveFullAccess  = getters.haveFullAccess;
      if (state.subscriptionData.planType !== PlanType.Individual || (state.subscriptionData.category === PlanCategory.Premium && haveFullAccess)) {
        return true;
      }
      const currentUsage = getters.getCurrentUsage;
      const allocatedPlanUsage = getters.getAllocatedPlanUsage;
      return (parseInt(allocatedPlanUsage.allowedProjects) - currentUsage.activeProjects) > 0 && haveFullAccess;
    },
    canRequestSummary(state, getters){
      const haveFullAccess  = getters.haveFullAccess;
      const currentUsage = getters.getCurrentUsage;
      const allocatedPlanUsage = getters.getAllocatedPlanUsage;
      if (state.subscriptionData.category === PlanCategory.Premium && haveFullAccess) {
        return true;
      }
      return currentUsage.usedKeyInsights < +allocatedPlanUsage.allowedKeyInsights;
    },
    canRequestTldr(state, getters){
      const haveFullAccess  = getters.haveFullAccess;
      const currentUsage = getters.getCurrentUsage;
      const allocatedPlanUsage = getters.getAllocatedPlanUsage;
      if (state.subscriptionData.category === PlanCategory.Premium && haveFullAccess) {
        return true;
      }
      return currentUsage.usedTldr < +allocatedPlanUsage.allowedTldrs;
    },
    canAddCollaborator(state, getters){
      const haveFullAccess  = getters.haveFullAccess;
      if (state.subscriptionData.planType !== PlanType.Individual || state.subscriptionData.category === PlanCategory.Premium && haveFullAccess) {
        return true;
      }
      const currentUsage = getters.getCurrentUsage;
      const allocatedPlanUsage = getters.getAllocatedPlanUsage;
      return (allocatedPlanUsage.allowedCollaborators - currentUsage.activeCollaborators) > 0 && haveFullAccess;
    },
    canShowSpecialOffer(state) {
      console.log("this is the subscription data", state.subscriptionData);
      const haveAccessToOffer = state.subscriptionData.specialDiscountOffered;
      return haveAccessToOffer;
    }
  }
};
