const middleware = {}

middleware['acs-demo'] = require('../middleware/acs-demo.js');
middleware['acs-demo'] = middleware['acs-demo'].default || middleware['acs-demo']

middleware['auth-signup'] = require('../middleware/auth-signup.js');
middleware['auth-signup'] = middleware['auth-signup'].default || middleware['auth-signup']

middleware['auth'] = require('../middleware/auth.js');
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['check-auth'] = require('../middleware/check-auth.js');
middleware['check-auth'] = middleware['check-auth'].default || middleware['check-auth']

middleware['checkDemoUser'] = require('../middleware/checkDemoUser.js');
middleware['checkDemoUser'] = middleware['checkDemoUser'].default || middleware['checkDemoUser']

middleware['checkOnboarding'] = require('../middleware/checkOnboarding.js');
middleware['checkOnboarding'] = middleware['checkOnboarding'].default || middleware['checkOnboarding']

middleware['checkReviewAccess'] = require('../middleware/checkReviewAccess.js');
middleware['checkReviewAccess'] = middleware['checkReviewAccess'].default || middleware['checkReviewAccess']

middleware['faculty'] = require('../middleware/faculty.js');
middleware['faculty'] = middleware['faculty'].default || middleware['faculty']

middleware['gtm-custom'] = require('../middleware/gtm-custom.js');
middleware['gtm-custom'] = middleware['gtm-custom'].default || middleware['gtm-custom']

middleware['guest'] = require('../middleware/guest.js');
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['library'] = require('../middleware/library.js');
middleware['library'] = middleware['library'].default || middleware['library']

middleware['live'] = require('../middleware/live.js');
middleware['live'] = middleware['live'].default || middleware['live']

middleware['maintenance'] = require('../middleware/maintenance.js');
middleware['maintenance'] = middleware['maintenance'].default || middleware['maintenance']

middleware['onboarding'] = require('../middleware/onboarding.js');
middleware['onboarding'] = middleware['onboarding'].default || middleware['onboarding']

middleware['payment'] = require('../middleware/payment.js');
middleware['payment'] = middleware['payment'].default || middleware['payment']

export default middleware
