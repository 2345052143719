<template>
  <div>
    <app-dialog
      :unid="unid"
      :config="{ button: false, backdrop: true }"
      :header="false"
      :footer="false"
      class="md-dialog--datepicker"
      @affirmative="onDateAffirmative"
      @dismissive="onDateDismissive"
    >
      <template slot="body">
        <div class="md-picker">
          <div class="md-picker__header">
            <div
              :class="{ 'is-active': isShowingYear }"
              class="md-picker__year"
              @click="showYear"
            >
              {{ curr.year }}
            </div>
            <div
              :class="{ 'is-inactive': isShowingYear }"
              class="md-picker__date"
              @click="showDay"
            >
              {{ langConf.days[curr.dayIndex] | truncate }}, {{ curr.day }}
              {{ langConf.month[curr.month] | truncate }}
            </div>
          </div>
          <div class="md-picker__body">
            <div v-if="!isShowingYear" class="md-picker-navigation">
              <div class="md-picker-navigation__right">
                <button
                  class="md-button md-button--icon"
                  :aria-disabled="prevMonthDisabled"
                  :disabled="prevMonthDisabled"
                  @click="prevMonth"
                >
                  <i class="material-icons">keyboard_arrow_left</i>
                </button>
              </div>
              <div class="md-picker-navigation__text">
                <span class="">{{ langConf.month[month] }} {{ year }}</span>
              </div>
              <div class="md-picker-navigation__right">
                <button
                  type="button"
                  class="md-button md-button--icon"
                  :aria-disabled="nextMonthDisabled"
                  :disabled="nextMonthDisabled"
                  @click="nextMonth"
                >
                  <i class="material-icons">keyboard_arrow_right</i>
                </button>
              </div>
            </div>
            <div v-if="!isShowingYear" class="md-picker-content">
              <div class="md-weeks">
                <div class="md-weeks__row md-weeks__row--header">
                  <div
                    v-for="item in langConf.week"
                    :key="item"
                    class="md-weeks__cell"
                  >
                    {{ item.charAt(0) }}
                  </div>
                </div>
                <div
                  v-for="(month_, monthIndex) in monthDays"
                  :key="monthIndex"
                  class="md-weeks__row"
                >
                  <div
                    v-for="(day_, dayIndex_) in month_"
                    :key="dayIndex_"
                    class="md-weeks__cell"
                  >
                    <button
                      v-if="day_"
                      :class="classDay(day_)"
                      :aria-disabled="isDisabledDate(day_, month)"
                      :disabled="isDisabledDate(day_, month)"
                      type="button"
                      class="md-weeks__day md-button md-button--icon"
                      @click="choiceDay(day_, $event)"
                    >
                      {{ day_ }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="isShowingYear"
              class="md-picker-list md-menu md-menu--dense md-card--border-bottom"
            >
              <button
                v-for="n in yearsDiff"
                :key="'year' + n"
                :class="{ 'md-menu__item--active': startYear + n === year }"
                :aria-disabled="isDisabledYear(startYear + n)"
                :disabled="isDisabledYear(startYear + n)"
                type="button"
                class="md-picker-list__item md-button md-menu__item"
                @click="choiceYear(startYear + n)"
              >
                {{ startYear + n }}
              </button>
            </div>
            <footer class="md-dialog__footer">
              <button
                :data-target="`#${unid}`"
                data-dismissive="true"
                data-action="dialog"
                class="md-dialog__action md-button md-button--compact"
              >
                CANCEL
              </button>
              <button
                :data-target="`#${unid}`"
                data-affirmative="true"
                data-action="dialog"
                class="md-dialog__action md-button md-button--compact"
              >
                OK
              </button>
            </footer>
          </div>
        </div>
      </template>
    </app-dialog>
  </div>
</template>

<script>
export default {
  filters: {
    truncate: function(value) {
      if (!value) return "";
      value = value.toString();
      return value.substring(0, 3);
    }
  },
  props: {
    unid: {
      type: String,
      default: null,
      required: true
    },
    value: {
      type: String,
      default: ""
    },
    format: {
      type: String,
      default: "yyyy-mm-dd"
    },
    startView: {
      type: String,
      default: "day",
      validator: value => {
        return ["day", "year"].indexOf(value) >= 0;
      }
    },
    forward: {
      type: Boolean,
      default: false
    },
    startYear: {
      type: Number,
      default: 1990
    },
    endYear: {
      type: Number,
      default: 0 // no endYear
    },
    conf: {
      type: Object,
      default: function() {
        return {};
      }
    },
    min: {
      type: String,
      default: null
    },
    max: {
      type: String,
      default: null
    }
  },
  data() {
    let dateObj, year, month, day, dayIndex;
    if (this.value) {
      dateObj = new Date(this.value);
    } else {
      dateObj = new Date();
    }
    year = dateObj.getFullYear();
    month = dateObj.getMonth();
    day = dateObj.getDate();
    dayIndex = dateObj.getDay();
    return {
      langConf: {
        week: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday"
        ],
        days: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday"
        ],
        month: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ]
      },
      dayPanelIsShow: false,
      startViewLocal: "day",
      isMouseOver: false,
      tempValue: "",
      year,
      month,
      day,
      dayIndex
    };
  },
  computed: {
    monthArr() {
      let { month } = this.langConf;
      let res = [];
      for (let i = 0; i <= 3; i++) {
        let temp = [];
        for (let j = 1; j <= 3; j++) {
          let id = i * 3 + j - 1;
          temp.push({ id, name: month[id] });
        }
        res.push(temp);
      }
      return res;
    },
    curr() {
      let { value } = this,
        dateObj,
        year,
        month,
        day,
        dayIndex;
      if (value) {
        dateObj = new Date(value);
      } else {
        dateObj = new Date();
      }
      year = dateObj.getFullYear();
      month = dateObj.getMonth();
      day = dateObj.getDate();
      dayIndex = dateObj.getDay();
      return {
        year,
        month,
        day,
        dayIndex
      };
    },
    monthDays() {
      let { year, month } = this,
        dayNum;
      month++;
      dayNum = this.getDaysNumber(month, year);
      // Generate an array of dates for the corresponding week
      let firstDay = new Date(year, month - 1, 1).getDay();
      let dayArr = new Array(6).fill(0).map(function() {
        return new Array(7).fill("");
      });
      let row = 0,
        col = firstDay;
      for (let d = 1; d <= dayNum; d++) {
        dayArr[row][col] = d;
        if (col < 6) {
          col++;
        } else {
          col = 0;
          row++;
        }
      }
      return dayArr;
    },
    today() {
      let dateObj = new Date(),
        year = dateObj.getFullYear(),
        month = dateObj.getMonth(),
        day = dateObj.getDate();
      return { year, month, day };
    },
    yearsDiff() {
      return this.today.year + 6 - this.startYear;
    },
    prevMonthDisabled() {
      if (this.min && this.validateDate(this.min)) {
        let month, year;
        if (this.month - 1 > 1) {
          month = this.month - 1;
          year = this.year;
        } else {
          month = 11;
          year = this.year - 1;
        }
        return this.isBefore(year, month);
      }
      return false;
    },
    nextMonthDisabled() {
      if (this.max && this.validateDate(this.max)) {
        let month, year;
        if (this.month + 1 < 11) {
          month = this.month + 1;
          year = this.year;
        } else {
          month = 0;
          year = this.year + 1;
        }
        const maxTime = Date.parse(this.max);
        const maxTimeToCheck = new Date(year, month).getTime();
        return maxTimeToCheck > maxTime;
      }
      return false;
    },
    isShowingYear() {
      return this.startViewLocal === "year";
    }
  },
  watch: {
    year: function(val) {
      this.tempValue = this.dateFormat(val, this.month, this.day, this.format);
    }
  },
  beforeMount() {
    this.startViewLocal = this.startView;
  },
  methods: {
    dateFormat(y, m, d, fm) {
      if (!fm) {
        fm = "mm-dd-yyyy";
      }
      m = ("0" + (parseInt(m) + 1)).slice(-2);
      d = ("0" + d).slice(-2);
      return fm
        .replace("yyyy", y)
        .replace("YYYY", y)
        .replace("mm", m)
        .replace("MM", m)
        .replace("DD", d)
        .replace("dd", d);
    },
    dateCompare(date1, date2) {
      if (date1.day && date2.day) {
        return (
          date1.year > date2.year ||
          (date1.year === date2.year && date1.month > date2.month) ||
          (date1.year === date2.year &&
            date1.month === date2.month &&
            date1.day > date2.day)
        );
      } else {
        return (
          date1.year > date2.year ||
          (date1.year === date2.year && date1.month > date2.month)
        );
      }
    },
    showYear() {
      this.startViewLocal = "year";
    },
    showDay() {
      this.startViewLocal = "day";
    },
    choiceYear(y) {
      this.year = y;
      this.startViewLocal = "day";
    },
    classDay(d) {
      let { month, year, curr, today, forward } = this;
      let isValid = forward && this.dateCompare(today, { year, month, day: d });
      return {
        "md-weeks__day--active":
          curr.day === d && curr.month === month && curr.year === year,
        "md-weeks__day--today":
          today.day === d && today.month === month && today.year === year,
        "md-weeks__day--invalid": !isValid
      };
    },
    prevMonth() {
      let { year, month, today, forward } = this;
      if (
        forward &&
        (today.year > year || (today.year === year && today.month >= month))
      ) {
        return false;
      }
      if (month > 1) {
        this.month--;
      } else {
        this.year--;
        this.month = 11;
      }
    },
    nextMonth() {
      if (this.month < 11) {
        this.month++;
      } else {
        this.year++;
        this.month = 0;
      }
    },
    choiceDay(d) {
      let { year, month, today, forward } = this;
      if (
        d !== "" &&
        !(forward && this.dateCompare(today, { year, month, day: d }))
      ) {
        this.day = d;
        this.tempValue = this.dateFormat(
          this.year,
          this.month,
          this.day,
          this.format
        );
      }
    },
    onInput() {
      this.$emit(
        "input",
        this.dateFormat(this.year, this.month, this.day, this.format)
      );
    },
    onDateDismissive() {
      this.tempValue = this.value;
      $(`#${this.unid}`).MaterialDialog("hide");
    },
    onDateAffirmative() {
      this.onInput();
      $(`#${this.unid}`).MaterialDialog("hide");
    },
    validateDate(dateString) {
      const date = Date.parse(dateString);
      return !isNaN(date);
    },
    getDaysNumber(month, year) {
      let dayNum;
      if (month === 2) {
        if (year % 4 === 0 && !(year % 100 === 0 && year % 400 !== 0)) {
          dayNum = 29;
        } else {
          dayNum = 28;
        }
      } else {
        if ([1, 3, 5, 7, 8, 10, 12].includes(month)) {
          dayNum = 31;
        } else {
          dayNum = 30;
        }
      }
      return dayNum;
    },
    isDisabledYear(year) {
      if (this.min || this.max) {
        return this.isBefore(year) || this.isAfter(year);
      }
      return false;
    },
    isDisabledDate(day, month) {
      if (this.min || this.max) {
        return (
          this.isBefore(this.year, month, day) ||
          this.isAfter(this.year, month, day)
        );
      }
      return false;
    },
    isBefore(year, month = 11, day) {
      day = day !== undefined ? day : this.getDaysNumber(month, year);
      if (!this.min) {
        return false;
      }
      const currentTime = new Date(year, month, day).getTime();
      const minTime = Date.parse(this.min);
      return currentTime < minTime;
    },
    isAfter(year, month = 0, day) {
      day = day !== undefined ? day : this.getDaysNumber(month, year);
      if (!this.max) {
        return false;
      }
      const currentTime = new Date(year, month, day).getTime();
      const maxTime = Date.parse(this.max);
      return currentTime > maxTime;
    }
  }
};
</script>

<style>
.md-picker__body {
  padding: 0;
}

.md-weeks__day--active {
  color: var(--color-primary-contrast);
  background-color: var(--color-primary);
}

.md-weeks__day--existed {
  border: 1px solid var(--color-dark-secondary);
}

.md-picker-content,
.md-picker-navigation {
  padding-left: 16px;
  padding-right: 16px;
}

.md-picker-content {
  padding-bottom: 0;
}

.md-picker-navigation {
  margin-top: 16px;
}

.md-picker-list .md-menu__item.md-button {
  text-align: center;
}

.md-picker-list .md-menu__item--active {
  color: var(--color-primary);
  font-size: 24px;
  line-height: 1;
}

.md-picker__year,
.md-picker__date {
  user-select: none;
  cursor: pointer;
}

.md-dialog--datepicker .md-dialog__body {
  padding: 0;
}

.md-dialog--datepicker .md-dialog__surface {
  width: auto;
  background-color: transparent;
}

.md-picker {
  background-color: var(--color-light);
}

.md-textfield--datepicker
  .md-textfield__input::-webkit-calendar-picker-indicator,
.md-textfield--datepicker .md-textfield__input::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}

.md-textfield--outline.md-textfield--datepicker .md-textfield__label {
  margin-left: 0;
  transform: translateY(-130%) scale(0.75);
}

.md-textfield--datepicker .md-notched__outline {
  opacity: 0;
}

.md-textfield--datepicker .md-notched {
  opacity: 1;
}

.md-textfield--datepicker:not(.md-textfield--focused) .md-notched__path {
  stroke: rgba(0, 0, 0, 0.24);
}
</style>
