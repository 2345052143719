<template>
  <button
    :id="unid"
    :type="type"
    :disabled="disabled"
    :class="{ 'md-button--loading': showLoading, 'md-button--done': isDone }"
    class="md-button md-button--progress md-button--primary md-button--raised"
    style="font-size: 16px;height: auto;padding: 5px 16px; text-transform: capitalize;"
    @click="$emit('click', $event)"
  >
    <span
      id="progress-4"
      class="md-progress md-progress--circle md-progress--indeterminate md-progress--visible"
      data-type="indeterminate"
      data-theme="primary"
      data-circle="true"
      data-overlay="true"
      data-center="true"
      data-fixed="true"
      data-parent="true"
      data-target="#parent-4"
    >
      <svg
        id="progress-circle-59"
        class="md-progress-circle md-progress-circle--primary"
        width="55px"
        height="55px"
        viewBox="0 0 56 56"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          class="md-progress-circle__path"
          fill="none"
          stroke-width="4"
          stroke-linecap="round"
          cx="28"
          cy="28"
          r="26"
          style="stroke-dasharray: 166; stroke-dashoffset: 166;"
        />
      </svg>
    </span>
    <span class="md-button__text">
      {{ text }}
    </span>
    <slot />
    <span class="md-button__icon">
      <i class="material-icons">done</i>
    </span>
  </button>
</template>
<style>
.rx-progress {
  padding-left: 48px;
  text-transform: none;
}
.rx-progress .md-progress {
  left: 24px;
}
</style>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "button"
    },
    unid: {
      type: String,
      default: null
    },
    href: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isDone: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    showLoading() {
      return this.isLoading && !this.isDone;
    }
  }
};
</script>
