const defaultTourV2State = () => {
  return {
    data: {}
  };
};

export default {
  namespaced: true,
  state: defaultTourV2State,

  mutations: {
    setData(state, data) {
      state["data"] = data;
    },
    updateData(state, data) {
      state["data"][data.feature].state = data.setting;
    },
    resetState(state) {
      Object.assign(state, defaultTourV2State());
    }
  },

  actions: {
    async updateTour({ commit }, payload) {
      try {
        await this.$axios.$patch(`tour/v2`, payload);
        commit("updateData", payload)
      } catch (error) {
        console.log(error);
      }
    },
    async fetchTours({ commit }) {
      let { data } = await this.$axios.get(`tour/v2`);
      const tour = {};
      Object.keys(data).forEach((item) => {
        tour[item] = {
          asyncComp: false,
          state: data[item]
        }
      });
      commit("setData", tour);
    }
  },

  getters: {
    getData(state) {
      return state.data;
    }
  }
};
