export const delay = ms => new Promise(res => setTimeout(res, ms));
const findObjectIndex = (arr, obj, key='id') => {
  return arr.findIndex((item) => {
    let x = item[key];
    let y = obj[key];
    if (!(x || y)) {
      return false;
    } else {
      x = (typeof(x) === 'string')? x.toLowerCase().replace(/ /ig, '') : x;
      y = (typeof(y) === 'string')? y.toLowerCase().replace(/ /ig, '') : y;
    }
    return x === y;
  });
};

export const initCap = (item) => {
  return item && item.toLowerCase().split(" ").map(i => i.charAt(0).toUpperCase() + i.slice(1)).join(" ");
}

export const findObjectIndexBookmarks = (arr, obj) => {
  return arr.findIndex((item) => {
    return item["docId"] === obj["doc_id"];
  });
};

export const validateEmail = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isValidUrl = url => {
  try {
    new URL(url);
  } catch (_) {
    return false;
  }
  return true;
};

export const sortObjectsByDate = (arrayOfObj, key, type) => {
  let sorted=[];
  if (type === "ascending") {
    sorted = arrayOfObj.sort(function (a, b) {
      return new Date(a[key]).getTime() - new Date(b[key]).getTime();
    });
  } else {
    sorted = arrayOfObj.sort(function (a, b) {
      return new Date(b[key]).getTime() - new Date(a[key]).getTime();
    });
  }
  return sorted;
};

export const materialDate = (val, time = true, materialFormatWithoutTime = "month DD, YYYY") => {
  if (!val) {
    return "";
  }
  const dateObj = new Date(val);
  let d = dateObj.getDate();
  let min = dateObj.getMinutes();
  let sec = dateObj.getSeconds();
  let hour = dateObj.getHours();
  let mid = "AM";
  const y = dateObj.getFullYear();
  const materialFormat = "month DD, YYYY, 00:00:00 AM";
  // const materialFormatWithoutTime = "month DD, YYYY";
  if (hour === 0) { //At 00 hours we need to show 12 am
    hour = 12;
  }
  else if (hour > 12) {
    hour = hour % 12;
    mid = "PM";
  }
  d = ("0" + d).slice(-2);
  min = ("0" + min).slice(-2);
  sec = ("0" + sec).slice(-2);
  hour = ("0" + hour).slice(-2);
  let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  if(time) {
    return materialFormat
      .replace("YYYY", y)
      .replace("month", months[dateObj.getMonth()])
      .replace("DD", d)
      .replace("00:00:00", `${hour}:${min}:${sec}`)
      .replace("AM", mid);
  }
  return materialFormatWithoutTime
    .replace("YYYY", y)
    .replace("month", months[dateObj.getMonth()])
    .replace("DD", d)
};

export const normaliseString = (string = '', readable = false) => {
  if (!readable) {
    return string.replace(/[^.\w\s]|_/g, "").replace(/ /g, '').toLowerCase();
  } else {
    return string.replace(/[^.\w\s]|_/g, "-").replace(/ /g, '-').toLowerCase();
  }
};

export const getBrowserType = () => {
  let userAgent = navigator.userAgent;
  let browserName = null;

  if(userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "chrome";
  } else if(userAgent.match(/firefox|fxios/i)) {
    browserName = "firefox";
  } else if(userAgent.match(/safari/i)) {
    browserName = "safari";
  } else if(userAgent.match(/opr\//i)) {
    browserName = "opera";
  } else if(userAgent.match(/edg/i)) {
    browserName = "edge";
  } else {
    console.log("No browser detection");
  }
  return browserName;
}

export default findObjectIndex;
