import Vue from "vue";
import findObjectIndex from "@/assets/js/commonHelper";

const defaultFacultyState = () => {
  return {
    students: [],
    institutionStudents: [],
    sharedPapers: 0,
    draftsCreated: [],
    draftsUpdated: [],
    readingPapersUpload: [],
    readingNotesMade: [],
    readingHighlightsMade: [],
    readingActivityPapersCount: 0,
    searchActivityData: {
      searchCount: [],
      searchQueryCount: 0,
      bookBookmarksCount: [],
      literatureBookmarksCount: [],
      lectureBookmarksCount: [],
      lightReadBookmarksCount: [],
      newsBookmarksCount: [],
      eBookBookmarksCount: [],
      thesisBookmarksCount: []
    },
    searchAttachmentData: {
      attachmentsCount: [],
      literaturesCount: 0,
      attachmentsViaRaxCount: [],
      attachmentsExternalCount: []
    },
    searchRecoData: {
      recoCount: [],
      literaturesCount: 0,
      literatureRecosCount: [],
      wikiRecosCount: [],
      lectureRecosCount: [],
      lightReadRecosCount: []
    }
  };
};

export default {
  namespaced: true,
  state: defaultFacultyState,

  mutations: {
    setData(state, obj) {
      state[obj.key] = obj.data;
    },
    addData(state, obj) {
      state[obj.key].push(obj.data);
    },
    setObjData(state, obj) {
      Vue.set(state[obj.name], obj.key, obj.data);
    },
    updateData(state, obj) {
      let index = findObjectIndex(state[obj.name], obj.data);
      state[obj.name].splice(index, 1, obj.data);
    },
    deleteData(state, obj) {
      state[obj.key].splice(obj.index, 1);
    },
    resetState(state) {
      Object.assign(state, defaultFacultyState());
    }
  },

  actions: {
    async fetchStudents({commit}) {
      const {data} = await this.$axios.get(`/faculty/students`);
      commit("setData", {key: "students", data: data});
    },
    async showStudent({commit, state}, obj) {
      const {data} = await this.$axios.get(`/faculty/student/show/${obj.id}`);
      commit("addData", {key: "students", data: data});
      return data;
    },
    async removeStudent({commit, state}, obj) {
      await this.$axios.post(`/faculty/revoke/student`, obj);
      let index = findObjectIndex(state.students, obj);
      commit("deleteData", {key: "students", index: index});
    },
    async fetchInstitutionStudents({commit}) {
      const {data} = await this.$axios.get(`/institution/students`);
      commit("setData", {key: "institutionStudents", data: data});
    },
    async fetchLiteratureUploadedCount({commit}, obj) {
      const {data} = await this.$axios.post(`/student/report/literature-count`, obj);
      commit("setData", {key: "readingPapersUpload", data: data});
    },
    async fetchAnnotationCount({commit}, obj) {
      const {data} = await this.$axios.post(`/student/report/annotation-count`, obj);
      commit("setData", {key: "readingActivityPapersCount", data: data['literaturesCount']});
      commit("setData", {key: "readingHighlightsMade", data: data['weeklyHighlightsCount']});
      commit("setData", {key: "readingNotesMade", data: data['weeklyNotesCount']});
    },
    async fetchRecoCount({commit}, obj) {
      const {data} = await this.$axios.post(`/student/report/reco-count`, obj);
      commit("setObjData", {name: "searchRecoData", key: "recoCount", data: data['recoCount']});
      commit("setObjData", {name: "searchRecoData", key: "literaturesCount", data: data['literaturesCount']});
      commit("setObjData", {name: "searchRecoData", key: "literatureRecosCount", data: data['literatureRecosCount']});
      commit("setObjData", {name: "searchRecoData", key: "wikiRecosCount", data: data['wikiRecosCount']});
      commit("setObjData", {name: "searchRecoData", key: "lectureRecosCount", data: data['lectureRecosCount']});
      commit("setObjData", {name: "searchRecoData", key: "lightReadRecosCount", data: data['lightReadRecosCount']});
    },
    async fetchAttachmentCount({commit}, obj) {
      const {data} = await this.$axios.post(`student/report/attachment-count`, obj);
      commit("setObjData", {name: "searchAttachmentData", key: "attachmentsCount", data: data['attachmentsCount']});
      commit("setObjData", {name: "searchAttachmentData", key: "literaturesCount", data: data['literaturesCount']});
      commit("setObjData", {name: "searchAttachmentData", key: "attachmentsViaRaxCount", data: data['attachmentsViaRaxCount']});
      commit("setObjData", {name: "searchAttachmentData", key: "attachmentsExternalCount", data: data['attachmentsExternalCount']});
    },
    async fetchSearchCount({commit}, obj) {
      const {data} = await this.$axios.post(`/student/report/search-count`, obj);
      commit("setObjData", {name: "searchActivityData", key: "searchCount", data: data['searchCount']});
      commit("setObjData", {name: "searchActivityData", key: "searchQueryCount", data: data['searchQueryCount']});
      commit("setObjData", {name: "searchActivityData", key: "bookBookmarksCount", data: data['bookBookmarksCount']});
      commit("setObjData", {name: "searchActivityData", key: "literatureBookmarksCount", data: data['literatureBookmarksCount']});
      commit("setObjData", {name: "searchActivityData", key: "lectureBookmarksCount", data: data['lectureBookmarksCount']});
      commit("setObjData", {name: "searchActivityData", key: "lightReadBookmarksCount", data: data['lightReadBookmarksCount']});
      commit("setObjData", {name: "searchActivityData", key: "newsBookmarksCount", data: data['newsBookmarksCount']});
      commit("setObjData", {name: "searchActivityData", key: "eBookBookmarksCount", data: data['eBookBookmarksCount']});
      commit("setObjData", {name: "searchActivityData", key: "thesisBookmarksCount", data: data['thesisBookmarksCount']});
    },
    async fetchDraftsCount({commit}, obj) {
      const {data} = await this.$axios.post(`student/report/draft-count`, obj);
      if (obj.action === 'createdAt') {
        commit("setData", {key: "draftsCreated", data: data});
      } else {
        commit("setData", {key: "draftsUpdated", data: data});
      }
    },
    async fetchSharedLiteratureCount({commit}, obj) {
      const {data} = await this.$axios.post(`/student/report/shared-literature-count`, obj);
      commit("setData", {key: "sharedPapers", data: data.count});
    }
  },

  getters: {
    getStudents(state) {
      return state.students;
    },
    getInstitutionStudents(state) {
      return state.institutionStudents;
    },
    getSearchActivity(state) {
      return state.searchActivityData.searchCount;
    },
    getSearchAttachment(state) {
      return state.searchAttachmentData.attachmentsCount;
    },
    getSearchReco(state) {
      return state.searchRecoData.recoCount;
    },
    getSharedPapers(state) {
      return state.sharedPapers;
    },
    getDraftsCreated(state) {
      return state.draftsCreated;
    },
    getDraftsUpdated(state) {
      return state.draftsUpdated;
    },
    getDraftsCreatedCount(state) {
      let count = 0;
      state.draftsCreated.forEach((item) => {
        count += item['count']
      });
      return count
    },
    getDraftsUpdateCount(state) {
      let count = 0;
      state.draftsUpdated.forEach((item) => {
        count += item['count']
      });
      return count
    },
    getReadingPapersUpload(state) {
      return state.readingPapersUpload
    },
    getReadingNotesMade(state) {
      return state.readingNotesMade
    },
    getReadingHighlightsMade(state) {
      return state.readingHighlightsMade
    },
    getReadingActivityPapersCount(state) {
      return state.readingActivityPapersCount
    },
    getReadingPapersUploadCount(state) {
      let count = 0;
      state.readingPapersUpload.forEach((item) => {
        count += item['count']
      });
      return count
    },
    getReadingNotesMadeCount(state) {
      let count = 0;
      state.readingNotesMade.forEach((item) => {
        count += item['count']
      });
      return count
    },
    getReadingHighlightsMadeCount(state) {
      let count = 0;
      state.readingHighlightsMade.forEach((item) => {
        count += item['count']
      });
      return count
    },
    getRecoCountObj(state) {
      let countObj = {literatureRecosCount: 0, wikiRecosCount: 0, lectureRecosCount: 0, lightReadRecosCount: 0};
      for (let prop in countObj) {
        let count = 0;
        state['searchRecoData'][prop].forEach((item) => {
          count += item['count']
        })
        countObj[prop] = count
      }
      return countObj
    },
    getAttachmentCountObj(state) {
      let countObj = {attachmentsViaRaxCount: 0, attachmentsExternalCount: 0};
      for (let prop in countObj) {
        let count = 0;
        state['searchAttachmentData'][prop].forEach((item) => {
          count += item['count']
        })
        countObj[prop] = count
      }
      return countObj
    },
    getSearchCountObj(state) {
      let countObj = {
        bookBookmarksCount: 0,
        literatureBookmarksCount: 0,
        lectureBookmarksCount: 0,
        lightReadBookmarksCount: 0,
        newsBookmarksCount: 0,
        eBookBookmarksCount: 0,
        thesisBookmarksCount: 0
      };
      for (let prop in countObj) {
        let count = 0;
        state['searchActivityData'][prop].forEach((item) => {
          count += item['count']
        })
        countObj[prop] = count
      }
      return countObj
    }
  }
};
