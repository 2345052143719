import Vue from "vue";
import findObjectIndex from "@/assets/js/commonHelper";

const ip = require("ip");
const defaultLibraryState = () => {
  return {
    newcomers: [],
    books: [],
    eBooks: [],
    thesis: [],
    literatures: [],
    prevSearchText: {
      books: "",
      literatures: "",
      thesis: "",
      eBooks: ""
    },
    libraryIPs: {
      2981: ['14.139.122.114/28', '220.225.53.60/27','14.142.231.30/32'],
      8824: ['14.139.122.114/28', '220.225.53.60/27','14.142.231.30/32'],
      8912: ['14.139.122.114/28', '220.225.53.60/27','14.142.231.30/32'],
      8943: ['14.139.122.114/28', '220.225.53.60/27','14.142.231.30/32'],
      8931: ['14.139.122.114/28', '220.225.53.60/27','14.142.231.30/32'],
      8980: ['14.139.122.114/28', '220.225.53.60/27','14.142.231.30/32'],
      8957: ['14.139.122.114/28', '220.225.53.60/27','14.142.231.30/32'],
      9103: ['14.139.122.114/28', '220.225.53.60/27','14.142.231.30/32'],
      3102: ['14.139.122.114/28', '220.225.53.60/27','14.142.231.30/32'],
      3112: ['14.139.122.114/28', '220.225.53.60/27','14.142.231.30/32']
    },
    userIP: "",
    requests: [],
    keywords: {
      literature: [],
      newcomers: [],
      books: [],
      eBooks: [],
      thesis: []
    },
    moreResults: true
  };
};

export default {
  namespaced: true,
  state: defaultLibraryState,

  mutations: {
    setData(state, obj) {
      state[obj.key] = obj.data;
    },
    addData(state, obj) {
      state[obj.key].push(obj.data);
    },
    setObjData(state, obj) {
      Vue.set(state[obj.name], obj.key, obj.data);
    },
    updateData(state, obj) {
      let index = findObjectIndex(state[obj.name], obj.data);
      state[obj.name].splice(index, 1, obj.data);
    },
    resetState(state) {
      Object.assign(state, defaultLibraryState());
    }
  },

  actions: {

    async literature({ commit, state, rootGetters }, inputs) {
      let params = inputs.params;
      let loadMore = inputs.loadMore? inputs.loadMore : false;
      let data, axiosData;
      if (!params.literatureId) {
        axiosData = await this.$axios.post(`/feed/literatures`, params);
        data = axiosData["data"]["results"];
        const keywords = axiosData["data"]["keywords"];
        commit("setObjData", { name: "keywords", key: "literature", data: keywords });
      } else {
        delete params["areasOfInterest"];
        params.reviewMode = 1;
        params.isSelectiveRyg=0;
        let subscriptionData = rootGetters["subscription/getSubscriptionData"];
        if(subscriptionData.category==1){
          params.start = 0;
          params.rows = 5;
        }
        axiosData = await this.$axios.post(`/recommendation/literatures`, params);
        data = axiosData["data"];
        commit("setObjData", { name: "keywords", key: "literature", data: [] });
      }
      commit("setData", { key: "moreResults", data: !!data.length });
      data.forEach((item) => {
        item.type = "paper";
      });
      if (loadMore) {
        data.forEach((item) => {
          commit("addData", { key: "literatures", data: item });
        });
      } else {
        commit("setData", { key: "literatures", data: data });
      }
    },

    async newcomers({ commit, state }, inputs) {
      let params = inputs.params;
      let loadMore = inputs.loadMore? inputs.loadMore : false;
      let { data } = await this.$axios.post(`/feed/book`, params);
      const keywords = data["keywords"];
      commit("setObjData", { name: "keywords", key: "newcomers", data: [] });
      data = data["results"];
      commit("setData", { key: "moreResults", data: !!data.length });
      data.forEach((item) => {
        item.type = "books";
        item.imageUrl = ""
      });
      if (loadMore) {
        data.forEach((item) => {
          commit("addData", { key: "newcomers", data: item });
        });
      } else {
        commit("setData", { key: "newcomers", data: data });
      }
    },

    async books({ commit, state, dispatch }, inputs) {
      let params = inputs.params;
      let loadMore = inputs.loadMore? inputs.loadMore : false;
      let { data } = await this.$axios.post(`/feed/book`, params);
      const keywords = data["keywords"];
      commit("setObjData", { name: "keywords", key: "books", data: keywords? keywords : [] });
      data = data["results"];
      commit("setData", { key: "moreResults", data: !!data.length });
      data.forEach((item) => {
        item.type = "books";
        item.imageUrl = ""
      });
      if (loadMore) {
        data.forEach((item) => {
          commit("addData", { key: "books", data: item });
        });
      } else {
        commit("setData", { key: "books", data: data });
      }
    },

    async eBooks({ commit, state }, inputs) {
      let params = inputs.params;
      let loadMore = inputs.loadMore? inputs.loadMore : false;
      let { data } = await this.$axios.post(`/feed/ebook`, params);
      const keywords = data["keywords"];
      commit("setObjData", { name: "keywords", key: "eBooks", data: keywords? keywords : [] });
      data = data["results"];
      commit("setData", { key: "moreResults", data: !!data.length });
      data.forEach((item) => {
        item.type = "eBooks";
        item.imageUrl = ""
      });
      if (loadMore) {
        data.forEach((item) => {
          commit("addData", { key: "eBooks", data: item });
        });
      } else {
        commit("setData", { key: "eBooks", data: data });
      }
    },

    async thesis({ commit, state }, inputs) {
      let params = inputs.params;
      let loadMore = inputs.loadMore? inputs.loadMore : false;
      let { data } = await this.$axios.post(`/feed/thesis`, params);
      const keywords = data["keywords"];
      commit("setObjData", { name: "keywords", key: "thesis", data: keywords? keywords : [] });
      data = data["results"];
      commit("setData", { key: "moreResults", data: !!data.length });
      data.forEach((item) => {
        item.type = "thesis";
      });
      if (loadMore) {
        data.forEach((item) => {
          commit("addData", { key: "thesis", data: item });
        });
      } else {
        commit("setData", { key: "thesis", data: data });
      }
    },

    async requestResourceFromLibrary({}, obj) {
      await this.$axios.post(`/library/requestpdf`, obj);
    },

    async setUserIP({ commit }, data) {
      commit("setData", { key: "userIP", data: data });
    },

    async setLibraryIPs({ commit }) {
      let { data } = await this.$axios.get(`/library/authenticate`);
      commit("setData", { key: "libraryIPs", data: data });
    },

    getKeywords(state) {
      return state.keywords;
    }

  },

  getters: {
    getLiteratures(state) {
      return state.literatures;
    },

    getBooks(state) {
      return state.books;
    },

    getNewcomers(state) {
      return state.newcomers;
    },

    getEBooks(state) {
      return state.eBooks;
    },

    getThesis(state) {
      return state.thesis;
    },

    getDiscoverAccess(state) {
      let grantAccess = false;
      let allowedLibrary;
      for (let key in state.libraryIPs) {
        allowedLibrary = state.libraryIPs[key].filter(ipRange => ip.cidrSubnet(ipRange.trim()).contains(state.userIP));
        if (allowedLibrary.length) {
          grantAccess = true;
          break;
        }
      }
      return grantAccess;
    },

    getKeywords(state) {
      return state.keywords;
    },
    getMoreResults(state) {
      return state.moreResults;
    },
    getUserIP(state) {
      return state.userIP;
    }

  }
};
