const defaultUserSessionState = () => {
    return {
        isTracking: false,
        interval: null
    };
};
export default {
    namespaced: true,
    state: defaultUserSessionState,

    mutations: {
        setIsTracking(state, data) {
            state["isTracking"] = data.isTracking;
            state["interval"] = data.interval;
        },
        resetState(state) {
            clearInterval(state.interval);
            Object.assign(state, defaultUserSessionState());
        }
    },

    actions: {
        async track({ commit, state }) {
            let requestUrl = `${process.env.serverLessBaseUrl}/user-session/check`;
            // let requestUrl = "http://localhost:4018/check"
            let isTracking = state.isTracking;
            if (!isTracking) {
                try {
                    this.$axios.get(requestUrl);
                } catch (error) {
                    console.error(error);
                }
                const interval = setInterval(() => {
                    if (document.visibilityState === 'visible') {
                        try {
                            this.$axios.get(requestUrl);
                        } catch (error) {
                            console.error(error);
                        }
                    }
                }, 60000);
                commit("setIsTracking", {
                    isTracking:true,
                    interval
                });
            }
            return isTracking;
        },
    },
};
