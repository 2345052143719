<template>
    <div>

    <div style="background-color: #EFF6FF;color: #1A64E2;">
        <div style="display: flex;padding:14px 30px;">
            <span>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25ZM12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25Z" fill="#1A64E2"/>
               <path d="M12 10.25C12.41 10.25 12.75 10.59 12.75 11L12.75 16C12.75 16.41 12.41 16.75 12 16.75C11.59 16.75 11.25 16.41 11.25 16L11.25 11C11.25 10.59 11.59 10.25 12 10.25Z" fill="#1A64E2"/>
               <path d="M12 6.99999C12.13 6.99999 12.26 7.02999 12.38 7.07999C12.5 7.12999 12.61 7.2 12.71 7.29C12.8 7.39 12.87 7.49 12.92 7.62C12.97 7.74 13 7.86999 13 7.99999C13 8.12999 12.97 8.26 12.92 8.38C12.87 8.5 12.8 8.61 12.71 8.71C12.61 8.8 12.5 8.87 12.38 8.92C12.14 9.02 11.86 9.02 11.62 8.92C11.5 8.87 11.39 8.8 11.29 8.71C11.2 8.61 11.13 8.5 11.08 8.38C11.03 8.26 11 8.13 11 8C11 7.87 11.03 7.74 11.08 7.62C11.13 7.49 11.2 7.39 11.29 7.29C11.39 7.2 11.5 7.12999 11.62 7.07999C11.74 7.02999 11.87 6.99999 12 6.99999Z" fill="#1A64E2"/>
            </svg>
        </span>
        <span style="margin-left: 5px;">Upcoming changes on your Enago Read Account</span>

        </div>
    </div>
    <div>
    <div style = "padding: 14px 30px;font-size:14px;font-weight:400;line-height: 24px;color:#111A27">
        <p>Dear User,</p>
        <p style="margin:5px 0px;">We always strive to provide you with the best experience on our platform. In our continuous effort to improve, we closely monitor user behaviour and gather feedback on the features we introduce. Through this process, we have noticed that some features are hardly used by our community.</p>
        <p style="margin:5px 0px;">After careful analysis and consideration, we have made the decision to discontinue these features. This allows us to redirect our resources towards enhancing the features that are more widely used, ultimately aiming to provide you with an even better reading experience.</p>
        <p>Starting from <b>March 1st, 2024</b>, the following features will be discontinued:</p>
        <div style="margin-left:5px;">
          <p>1. Resources</p>
          <p>2. Discover</p>
          <p>3. Pin a Tag</p>
          <p>4. Status and Reminders</p>
        </div>
        <p style="margin: 5px 0px;">
            We understand that you may have valuable data associated with these features, so we highly recommend you to back up any relevant data before February 28, 2024. Should you have any questions or require assistance, please don't hesitate to reach out to us at 
            <span style="color:#1A64E2">support@read.enago.com</span>.
        </p>
        <p style="margin:5px 0px;">
            Thank you for being our valued user. We genuinely appreciate your support and look forward to serving you better in the future.
        </p>
    </div>
    </div>
    <div>
        <button @click="closeModal" style="float:right;margin-right:20px;border:1px solid #1A64E2;border-radius:5px;color:#1A64E2;padding:4px 17px;">Close</button>
    </div>     
    </div>
  </template>
  
  <script>
  export default {
  props:{
    closeModal:{
        type:Function,
        required:false
    }
  },
  };
  </script>
  